const pageData = {
  'niruma-gnanday': [
    {
      question: 'મોટા ભાઈની મમ્મી માટે શું ફરિયાદ છે?',
      options: [
        'આખો દિવસ મારી દીકરી મારી દીકરી કરે છે',
        'તેની પાસે જ સુવે છે',
        'એને ગમતું જ ખાવાનું શાક બનાવે છે',
        'આપેલા તમામ'
      ],
      answer: ['આપેલા તમામ']
    },
    {
      question:
        'જ્યારે બા દાદા ને પૂછતાં કે શું શાક લાવું ત્યારે દાદા શું જવાબ માં કહેતા?',
      options: ['બટાકા લાવજો', 'ભીંડા લાવજો', 'કારેલા ના લાવતા', 'એક પણ નહી'],
      answer: ['એક પણ નહી']
    },
    {
      question: 'છોકરા ને જમવાના સમયે ભૂખ કેમ નથી લાગતી?',
      options: [
        'તેણે પહેલેથી જ જમી લીધું હોય છે',
        'તેણે નાસ્તો કર્યો હોય છે',
        'તેણે જંકફૂડ ખાધું હોય છે',
        'તેણે પાણી પીધું હોય છે'
      ],
      answer: ['તેણે પાણી પીધું હોય છે']
    },
    {
      question: 'સાતમાંથી કેટલા દિવસ એની મમ્મી બેનને ગમતું શાક બનાવે?',
      options: ['૬', '૪', '૭', '૫'],
      answer: ['૪']
    },
    {
      question: 'છોકરાને એનો નાનો ભાઈ શું કરીને હેરાન કરે છે?',
      options: [
        'એની ચોકલેટ ખાઈ જાય છે',
        'એની પેન્સિલ લઇ લે છે',
        'છોકરાને બાથરૂમ (ટોયલેટ) માં પુરી દે છે',
        'છોકરાની જોડે બહુ મસ્તી કરે છે'
      ],
      answer: ['છોકરાને બાથરૂમ (ટોયલેટ) માં પુરી દે છે']
    },
    {
      question:
        'નીરુમા મોટા સીબલીન્ગ્સને દાદાનું કયું વાક્ય વાપરવાનું કરવાનું કહે છે?',
      options: [
        'એડજસ્ટ એવરીવ્હેર',
        'બન્યું તે જ ન્યાય',
        'ભોગવે એની ભૂલ',
        'અથડામણ ટાળો'
      ],
      answer: ['ભોગવે એની ભૂલ'],
      questionImage: 'nirumas-gnan-day-6.png'
    },
    {
      question: 'Bonus Question #1: સરખામણી કરવાથી શું થાય છે?',
      options: ['પ્રગતિ', 'અશાંતિ', 'ક્રોધ', 'ઉપાધી'],
      answer: ['અશાંતિ']
    },
    {
      question: 'દાદા ભગવાન ક્યાં રહેતા હતા?',
      options: ['તરસાલી', 'મામા ની પોળ', 'ભાદરણ', 'અડાલજ'],
      answer: ['મામા ની પોળ']
    },
    {
      question:
        'માતા-પિતા જ્યારે નાના ભાઈ-બહેનને પ્રેમ આપે છે ત્યારે આપણને શું ઉભું થાય છે?',
      options: ['આડાઈ', 'શંકા', 'જેલસી', 'આપેક્ષા'],
      answer: ['જેલસી']
    },
    {
      question: 'છોકરી નો નાનો ભાઈ કંઇક મસ્તી કરે તો એની મમ્મી શું કરે?',
      options: [
        'છોકરી ને સમજવાનું કહે',
        'છોકરી ને નાના ભાઈ ને સમજાવાનું કહે',
        'મમ્મી બંને ને સમજાવે',
        'મમ્મી કોઈને પણ નથી સમજાવતા'
      ],
      answer: ['છોકરી ને સમજવાનું કહે']
    },
    {
      question:
        'માં-બાપને બન્ને છોકરાઓ માટે પ્રેમ જ હોય છે પણ બધા હિસાબ શેના આધારે હોય છે?',
      options: ['વેર', 'ઋણાનુબંધ', 'ઉંમરના', 'બાળકની હોંશિયારી'],
      answer: ['ઋણાનુબંધ'],
      preview: {
        type: 'video',
        title: 'Bonus Video #2: પદ - "વનરાનાં બાળકો"',
        id: '3s098LgvF4E'
      }
    },
    {
      question:
        'Bonus Question #2: ખરું કે ખોટું? : "ઈર્ષા એ તો વહોરી લીધેલું દુઃખ છે."',
      options: ['ખરું', 'ખોટું'],
      answer: ['ખરું']
    },
    {
      question: 'શાક વાળો ક્યાં બેઠેલો હોય છે?',
      options: ['પોળના નાકે', 'બજાર માં', 'પોળ માં', 'પોળ ની બહાર'],
      answer: ['પોળના નાકે']
    },
    {
      question: 'મમ્મી કોના પ્રત્યે વધારે લગાવ રાખતા હોય છે?',
      options: [
        'મોટા છોકરા પ્રત્યે',
        'નાના છોકરા પ્રત્યે',
        'A અને B',
        'એક પણ નહી'
      ],
      answer: ['નાના છોકરા પ્રત્યે']
    },
    {
      question:
        'માતા-પિતાએ મોટા બાળકોને તરછોડવા તો ના જોઈએ પણ તેમનાથી શું થઇ જાય છે?',
      options: [
        'એટેન્શનમાં ડીફ્રંસ થઇ જાય છે.',
        'મોટા બાળકો પર સાવ બેધ્યાન થઇ જાય છે',
        'મોટા બાળકો પર દ્વેષ કરતા હોય છે',
        'મોટા બાળકો પર રાગ વધી જાય છે'
      ],
      answer: ['એટેન્શનમાં ડીફ્રંસ થઇ જાય છે.']
    },
    {
      question: 'આ બધામાં વધુ સફરિંગ કોને આવે છે?',
      options: ['મોટા બાળકોને', 'નાના બાળકોને', 'માતા-પિતાને', 'ઉપરનાં બધા'],
      answer: ['મોટા બાળકોને'],
      questionImage: 'nirumas-gnan-day-16.png'
    },
    {
      question: 'કોને ચેન્જ કરવાનું મુશ્કેલ છે?',
      options: [
        'નાના ભાઈ-બહેનને',
        'મોટા ભાઈ-બહેનને',
        'પેરેન્ટ્સને',
        'આપેલા તમામ'
      ],
      answer: ['પેરેન્ટ્સને']
    },
    {
      question:
        'Bonus Question #3: ખરું કે ખોટું? : "આપણે મોટું મન રાખી નથી શકતા એટલે ઈર્ષા થાય છે."',
      options: ['ખરું', 'ખોટું'],
      answer: ['ખરું']
    },
    {
      question: 'નીરુમા કહે છે આપણું મન કેવું હોય તો આવા વિચાર જ ના આવે?',
      options: ['આજ્ઞાધિન', 'નોબલ', 'સંકુચિત', 'એક પણ નહી'],
      answer: ['નોબલ']
    },
    {
      question: 'નીરુમા કઈ રીતે આ સફરિંગમાંથી નીકળવાનું કહે છે?',
      options: ['સમજીને', 'એક્સેપ્ટ કરીને', 'કમ્પેરીઝન કરીને', 'A અને B'],
      answer: ['A અને B'],
      preview: {
        type: 'video',
        title: 'Bonus Video #2 પદ - "અમારા દિલમાં વસે નીરુમા"',
        id: 'muFDPcC7a4U'
      }
    }
  ],
  'jagat-kalyan': [
    {
      question: 'જગત કલ્યાણ ની ભાવના માં કેટલા ભાગ પડે છે?',
      options: ['૪', '૨', '૩', '૫'],
      answer: ['૨']
    },
    {
      question: 'જગત કલ્યાણ માં કઈ - કઈ ભાવનાઓ હોય?',
      options: [
        'લોકો ને સાચી સમજણ પહોંચે, સાચું જ્ઞાન પહોંચે',
        'લોકો સુખ શાંતિ ને પામે',
        'લોકો દાદા નું વિજ્ઞાન પામે અને તમામ દુઃખો થી મુક્તિ પામે',
        'આપેલા તમામ'
      ],
      answer: ['આપેલા તમામ']
    },
    {
      question: 'જગત કલ્યાણ ની ભાવના ના ભાગ કયા છે?',
      options: [
        'જગત ના લોકો ને સાચી સમજણ ને જ્ઞાન મળે',
        'જગત કલ્યાણ ના નિમિતો માટે પણ ભાવના',
        'A અને B',
        'એક પણ નહિ'
      ],
      answer: ['A અને B']
    },
    {
      question: 'દાદા એ કયો પ્રોજેક્ટ અનંત અવતાર થી પકડી રાખ્યો હતો?',
      options: [
        'ત્રિમંદીર બનવું જોઈએ',
        'જ્ઞાની પુરુષ બનવું જોઈએ',
        'કોનટ્રેકટનાં ધંધો માટે',
        'જગત માં દુઃખ મટવું જોઈએ'
      ],
      answer: ['જગત માં દુઃખ મટવું જોઈએ']
    },
    {
      question: 'જગત _A_ આરા માંથી _B_ આરા તરફ તણાઈ રહ્યું છે.',
      options: [
        'A = ચોથા ; B = પાંચમાં',
        'A = પાંચમાં ; B = છઠ્ઠા',
        'A = છઠ્ઠા ; B = પાંચમાં',
        'A = પાંચમાં ; B = ચોથા'
      ],
      answer: ['A = પાંચમાં ; B = છઠ્ઠા'],
      questionImage: 'jagat-kalyan-5.png'
    },
    {
      question: 'સૌથી વધારે જગત કલ્યાણ નું કામ કોણ કરી શકે છે?',
      options: [
        'પરિણીત મહાત્માઓ',
        'યુવા ભાઈઓ બેહનો',
        'મુમુક્ષુઓ',
        'બ્રહ્મચારી ભાઈઓ બહેનો'
      ],
      answer: ['બ્રહ્મચારી ભાઈઓ બહેનો']
    },
    {
      question:
        'દાદા કહેતા કે "અમારા હાથે અડધી દુનિયા નું કલ્યાણ થશે, અને બાકી દુનિયા નું કલ્યાણ _નાં હાથે થશે."',
      options: ['નીરુમા', 'પૂજ્યશ્રી દીપક ભાઈ', 'મહાત્માઓ', 'આપેલા તમામ'],
      answer: ['મહાત્માઓ']
    },
    {
      question:
        'Bonus Question: દાદાશ્રી: જગતનું કલ્યાણ થાવ એ ભાવના કેવી હોય? લોક જાણે કે કો’કના માટે ભાવી, પણ એ જ પોતાની ભાવના કહેવાય. આ ભાવના પહેલું _ હિત કરે. અભ્યુદય ને આનુષંગિક બેઉ ફળ આપે.',
      options: ['મહાત્માઓનું', 'બીજાનું', 'પારકાનું', 'પોતાનું'],
      answer: ['પોતાનું']
    },
    {
      question:
        'જગત કલ્યાણ ના નિમિત્તો માટે પૂજ્યશ્રી, કેવી પ્રાર્થના અને ભાવના રાખે છે?',
      options: [
        'કષાય-વિષય રહિત, પ્યોરીટી પ્રાપ્ત થાય',
        'દીર્ઘઆયુષ્ય અને તંદુરસ્તી પ્રાપ્ત થાય',
        'વાણી સ્યાદવાદ, અને મન નિર્ગ્રંથ થાય',
        'આપેલા તમામ'
      ],
      answer: ['આપેલા તમામ']
    },
    {
      question:
        'કોના માટે ભાવ નથી બગાડવા જેવું, બદલે કલ્યાણ ની ભાવના કરવી જોઈએ?',
      options: ['સંતો', 'નેતા - અભિનેતા', 'આતંકવાદી', 'આપેલા તમામ'],
      answer: ['આપેલા તમામ']
    },
    {
      question:
        'પોતે આત્મા છે, અનંત સુખવાળો, અનંત જ્ઞાન, અનંત દર્શન વાળો - તોહ દુઃખી શેના લીધે છે?',
      options: [
        'અપેક્ષાઓ પૂરી ન થવા ને લીધે',
        'સ્વાસ્થ્ય અને પૈસા ન હોવા ને લીધે',
        'અણસમજણ, અજ્ઞાનતા , ક્રોધ-માન-માયા-લોભ ને લીધે',
        'આપેલા તમામ'
      ],
      answer: ['અણસમજણ, અજ્ઞાનતા , ક્રોધ-માન-માયા-લોભ ને લીધે']
    },
    {
      question: 'શું થાય તો માણસ હજારો જાત નું નેગેટિવ ચીતરી નાખે છે',
      options: [
        'અપમાન થાય ત્યારે',
        'વિષય માં ધાર્યું ના થાય ત્યારે',
        'અણહક્ક ના ગોટાળા કરે ત્યારે',
        'ઉપર ના બધા'
      ],
      answer: ['ઉપર ના બધા'],
      questionImage: 'jagat-kalyan-12.png'
    },
    {
      question: 'વલોણું ફેરવે તો શું બહાર આવે?',
      options: ['દહીં', 'માખણ', 'છાસ', 'ઉપર ના બધા'],
      answer: ['માખણ']
    },
    {
      question:
        'Bonus Question: દાદાશ્રી: સંત પુરુષ, સત્ પુરુષ અને જ્ઞાની પુરુષ - આ ત્રણ જ છેને, આ જગતનું કલ્યાણ કરવાનું નિમિત્ત હોય છે. બાકી ભગવાન પણ કલ્યાણ ન કરી શકે આ જગતનું. કારણ કે કલ્યાણનું નિમિત્ત _ રૂપે હોવા જોઈએ!',
      options: ['પ્રજ્ઞા', 'સમકિત', 'મહાત્મા', 'દેહધારી'],
      answer: ['દેહધારી']
    },
    {
      question: 'બધા લોકો એ શાની ભાવના કરવી જોઈએ?',
      options: [
        'સૌ જીવો આત્મજ્ઞાન પામે',
        'બધા માટે કલ્યાણ ની ભાવના કરવી',
        'લોકો બધી તકલીફો માંથી બહાર આવે',
        'ઉપર ના બધા'
      ],
      answer: ['ઉપર ના બધા']
    },
    {
      question: 'સાચી સમજણ અને સાચું જ્ઞાન કેવી રીતે લોકો સુધી પહોંચે છે?',
      options: ['પુસ્તકો', 'ટીવી, ઈન્ટરનેટ', 'મેગેઝીન', 'ઉપર ના બધા'],
      answer: ['ઉપર ના બધા']
    },
    {
      question: 'સત્યુગ માં માણસ ની વૃત્તિ કેવી હતી?',
      options: [
        'બધા ને આપવું છે હેલ્પ કરવી છે',
        'બધું ભેગું કરવું પડાવી લેવું છે',
        'મારું એ મારું અને તારું એ તારું',
        'મારું એ મારું અને તારું એ પણ મારું'
      ],
      answer: ['બધા ને આપવું છે હેલ્પ કરવી છે'],
      questionImage: 'jagat-kalyan-17.png'
    },
    {
      question: 'કળિયુગ માં માણસ ની વૃત્તિઓ કેવી છે?',
      options: [
        'બધું ભેગુ કરવું, પડાવી લેવું',
        'મારું એ મારું અને તારું એ તારું',
        'મારું એ મારું અને તારું એ પણ મારું',
        'આપેલા તમામ'
      ],
      answer: ['બધું ભેગુ કરવું, પડાવી લેવું']
    },
    {
      question: 'બીજા લોકો માટે શું ભાવના કરવી જોઈએ?',
      options: [
        'પોતે દુઃખી થાય અને બીજા ને સુખી કરે',
        'બધાના દુઃખો દુર કરે',
        'પોતે દુઃખી ના થાય અને કોઈ ને દુઃખી ના કરે',
        'આપેલા તમામ'
      ],
      answer: ['પોતે દુઃખી ના થાય અને કોઈ ને દુઃખી ના કરે']
    },
    {
      question:
        'Bonus Question: દાદાશ્રી: આ અમારી સ્ટેજે આવેલો અટકે નહીં, તે મોક્ષે જ ચાલ્યો જાય. પણ આ અમારું અટક્યું છે તેનું કારણ _ થવાનું છે, તેથી જ અમારું અટક્યું છે.',
      options: ['સમાજ કલ્યાણ', 'સુખ-શાંતિ', 'જગત કલ્યાણ', 'આપેલા તમામ'],
      answer: ['જગત કલ્યાણ']
    }
  ],
  'abhipray-etle-chashma': [
    {
      question: 'અભિપ્રાય એટલે?',
      options: ['આગ્રહ', 'અપેક્ષા', 'તિરસ્કાર', 'ચશ્મા'],
      answer: ['ચશ્મા']
    },
    {
      question: 'અભિપ્રાય ના કારણે શું શું બંને છે?',
      options: [
        'સામા માણસ માં થયેલા ફેરફાર ને આપણે જોઈ શકતા નથી',
        'કોઈ માટે ખરાબ અભિપ્રાય પડતા તરત જ આપણો આનંદ ઊડી જાય છે',
        'આપણે તેની સાથે ખુલ્લા દિલ થી વાત ના કરી શકીએ અને તેની સાથે ના દ્વેષ માંથી છૂટી શકતા નથી',
        'આપેલા તમામ'
      ],
      answer: ['આપેલા તમામ']
    },
    {
      question:
        'દાદાશ્રી ના કહેવા પ્રમાણે અભિપ્રાય બંધાઈ ગયો હોય તો એને છોડવા માટે આપણે શું કરવું?',
      options: [
        'આપણે શાંત રહેવું જોઈએ',
        'તરત જ પ્રતિક્રમણ કરી ભૂંસી નાખવું જોઈએ',
        'અથડામણ ટાળવી જોઈએ',
        'સમભાવે નિકાલ કરવો જોઈએ'
      ],
      answer: ['તરત જ પ્રતિક્રમણ કરી ભૂંસી નાખવું જોઈએ']
    },
    {
      question: 'અભિપ્રાય બદલવા શું કરવું પડે?',
      options: [
        'આ અક્કલ નો કોથળો છે કહીએ',
        'તેની પર અકળામણ અને અભાવ કરીએ',
        'આ શુદ્ધાત્મા છે, આપણો જ ખોટો અભિપ્રાય બેઠો છે એવું મહીં ગોઠવવું પડે',
        'તેની બધી વાતો માં સહમત થઇએ'
      ],
      answer: [
        'આ શુદ્ધાત્મા છે, આપણો જ ખોટો અભિપ્રાય બેઠો છે એવું મહીં ગોઠવવું પડે'
      ]
    },
    {
      question: 'આપણે અભિપ્રાય સહિત જોઈએ, એના દોષ જોઈએ તો શું થાય?',
      options: [
        'એને પણ આપણા પ્રત્યે ના અભિપ્રાય તૂટી જાય',
        'એને અંદર તરત જ ખબર પડી જાય',
        'એને એના દોષ પર પસ્તાવો થાય',
        'આપણી વાણી ખૂબ જ કડક નીકળે'
      ],
      answer: ['એને અંદર તરત જ ખબર પડી જાય']
    },
    {
      question: 'સામા માટે ખરાબ અભિપ્રાય બેસી ગયો હોય તો આપણ ને તે?',
      options: [
        'વ્યક્તિ ખૂંચ્યા કરે',
        'તેના માટે અભાવ રહ્યા કરે',
        'તે વ્યક્તિ બહુ ગમે',
        'A અને B'
      ],
      answer: ['A અને B']
    },
    {
      question: 'સામા માટે નો ખરાબ અભિપ્રાય તૂટે તો?',
      options: [
        'આપણે તેની સાથે ખુશ થઇ વાત કરી શકીએ જેથી તે પણ ખુશ થાય',
        'આપણને  તેની સાથે વાત કરવું ના ગમે',
        'આપણી વાણી કડક નીકળી જાય',
        'આપણ ને તેના વધુ દોષ દેખાય'
      ],
      answer: ['આપણે તેની સાથે ખુશ થઇ વાત કરી શકીએ જેથી તે પણ ખુશ થાય']
    },
    {
      question:
        'નમિતાના શિક્ષકે તેને રાજશ્રી જોડીદાર તરીકે આપી ત્યારે તેને રાજશ્રી માટે કયા નેગેટિવ અભિપ્રાયો હતા?',
      options: [
        'તે બુદ્ધુ છે',
        'આવી જોડીદાર સાથે તે આ પ્રોજેક્ટ માં પાછળ રહી જશે',
        'જેને વાળ ઓળતા પણ નથી આવડતું તે વિજ્ઞાન ના પ્રોજેક્ટ ને કેવી રીતે કરી શકશે?',
        'ઉપર ના બધા જ'
      ],
      answer: ['ઉપર ના બધા જ']
    },
    {
      question:
        'Bonus Question #1 Aptavani-10 (P): દાદાશ્રી: મનના ફાધર અને મધર કોઈને જડ્યાં જ નથી. વર્લ્ડ (દુનિયા) હશે ત્યારથી નથી કહેવાયું. તે આ પહેલી વખત માઈન્ડના ફાધર-મધર કહેવામાં આવે છે. એટલે આ _ આપે તો મન ઊભું થઈ જાય. _ આપવાની લોકોને ટેવ હોય છે? પ્રશ્નકર્તા: હોય છે.દાદાશ્રી: તેનાથી આ મન ઊભું થયેલું છે, બીજું કશું નહીં. ફાધર-મધર છેટાં, તો જન્મે ના બેટા!',
      options: ['પૂર્વગ્રહ', 'નોંધ', 'અભિપ્રાય', 'સર્ટિફિકેટ'],
      answer: ['અભિપ્રાય']
    },
    {
      question: 'નમિતા અને રાજશ્રીના દ્રષ્ટાંત પર થી સમજાય કે',
      options: [
        'કોઈ ના માટે ઊંધો અભિપ્રાય રાખવો યોગ્ય નથી',
        'બધા માં કંઈ ને કંઈ સારું હોય જ છે માટે બધા નું પોઝિટિવ જ જોવું',
        'અભિપ્રાય સાથે જોવા થી તે વ્યક્તિ એવી જ દેખાય',
        'ઉપર ના બધા જ'
      ],
      answer: ['ઉપર ના બધા જ'],
      questionImage: 'abhipray-10.png'
    },
    {
      question: 'શાહ સાહેબ ના કોટ માંથી મનજી શું ચોરી કરવા જાય છે?',
      options: ['હીરા ની વીંટી', 'સો ની નોટ', 'પાંચસો રૂપિયા', 'સોના ની ચેઇન'],
      answer: ['સો ની નોટ']
    },
    {
      question: 'મનજી શા માટે ચોરી કરવા પ્રેરાયો?',
      options: [
        'તેને દારૂ પીવા જવું હતું',
        'તેને ફિલ્મ જોવા જવું હતું',
        'તેની માંદી દીકરી માટે દવા લેવા પૈસા ની જરૂર હતી',
        'તેને ચોરી કરવાની આદત હતી'
      ],
      answer: ['તેની માંદી દીકરી માટે દવા લેવા પૈસા ની જરૂર હતી']
    },
    {
      question:
        'આદિત્યની મમ્મી તેના નેગેટિવ અભિપ્રાય ને ભૂંસી નાખવાનો શો ઉપાય બતાવે છે?',
      options: [
        'મનજી ની માફી માંગવાનો',
        'ફરી ક્યારેય ગુસ્સો નહીં કરવાનો',
        'મમ્મી ને પૂછી ને જ કામ કરવાનું',
        'પચાસ સો વાર બોલી મનજી બહુ સારો છોકરો છે'
      ],
      answer: ['પચાસ સો વાર બોલી મનજી બહુ સારો છોકરો છે']
    },
    {
      question:
        'બુદ્ધિ એ જેમાં સુખ માન્યું હોય તેના આધારે અભિપ્રાય પડે તેનો શો દાખલો આપ્યો છે?',
      options: [
        'ટીવી જોવા માં મજા આવે છે તેથી ટીવી વગર તો ચાલે જ નહીં',
        'ભણવાનું ગમતું નથી તેથી તે અઘરું લાગ્યા કરે',
        'મીઠી કેરી ખાવા ની મજા આવે છે તે ખાવા માં સુખ છે',
        'A અને B'
      ],
      answer: ['A અને B']
    },
    {
      question: 'અભિપ્રાય આપણ ને _  કરાવે.',
      options: ['રાગ-દ્વેષ', 'ફરિયાદ', 'અપેક્ષા', 'પ્રેમ ઉત્પન્ન'],
      answer: ['રાગ-દ્વેષ']
    },
    {
      question:
        'Bonus Question #2: દાદાશ્રી: તમે કોઈ માણસને માટે ખરાબ _A_ કરો ને, એટલે તમારા મનમાં એ માણસને જોતાની સાથે જ કકળાટ ઉછળે. ઉછળે કે ના ઉછળે? શાથી? _A_ પ્રિજ્યુડીસ થયેલો છે.એક છોકરાને માટે ખરાબ _A_ બેઠો. એક દહાડો ખરાબ બેઠો, બીજે દહાડે બેઠો, એટલે પછી ‘તું બહુ ખરાબ છું, બહુ ખરાબ છું, બહુ ખરાબ છું.’ અરે! બધી વખતે ખરાબ હોતો હશે મૂઆ? શું કાયમને માટે, બધા કેસમાં એ ગુનેગાર હોય? કઈ જાતનો ન્યાયાધીશ થઈ બેઠો છું મૂઆ, બાપ થયો એટલે? પણ એને આ _A_ બેસી ગયા એટલે પોતે જ _B_ થઈ ગયો. એ એમને છે તે પ્રિજ્યુડીસ હોય છે કે બાબો આવો જ છે, બાબો આવો જ છે. દરેક વખતે એવા નથી હોતા પણ પેલું પ્રિજ્યુડીસ આગળ જ કામ કરે.',
      options: [
        'A = ભાવ ; B = માંદો',
        'A = અભિપ્રાય ; B = પરવશ',
        'A = ભાવ ; B = સાજો',
        'A = અભિપ્રાય ; B = સ્વતંત્ર'
      ],
      answer: ['A = અભિપ્રાય ; B = પરવશ']
    },
    {
      question:
        'બંને કસોટી માંથી પાસ થવા છતાં પણ કોણ શ્રેણિક ને કંઈ પણ આપતું નથી?',
      options: [
        'રાજા રણજીત',
        'રાજા હરિશચંદ્ર',
        'રાજા પ્રસન્નજીત',
        'રાજા પ્રેમચંદ્ર'
      ],
      answer: ['રાજા પ્રસન્નજીત'],
      questionImage: 'abhipray-17.png'
    },
    {
      question: 'શ્રેણિક ના લગ્ન ક્યાં થાય છે?',
      options: ['એકનાતટ નગર', 'બેનાતટ નગર', 'ત્રીનાતટ નગર', 'ભાવનગર'],
      answer: ['બેનાતટ નગર']
    },
    {
      question: 'શ્રેણિક ના લગ્ન કોની સાથે થાય છે?',
      options: ['સાવિત્રી', 'સુનંદા', 'ચંપાવતી', 'સુગંધા'],
      answer: ['સુનંદા']
    },
    {
      question: 'રાજા શ્રેણિક શાનો ઢંઢેરો પિટાવે છે?',
      options: [
        'જે કુવા કાંઠે ઉભા ઉભા વીંટી કાઢશે તેને મહામંત્રી બનાવશે',
        'કુવા નું પાણી ખાલી કરશે તેને મંત્રી બનાવશે',
        'A અને B બંને',
        'એક પણ નહીં'
      ],
      answer: ['જે કુવા કાંઠે ઉભા ઉભા વીંટી કાઢશે તેને મહામંત્રી બનાવશે']
    },
    {
      question: 'રાજગૃહી માં કોણ પધારે છે?',
      options: ['ગૌતમ સ્વામી', 'બુદ્ધ ભગવાન', 'મહાવીર પ્રભુ', 'સીમંધર સ્વામી'],
      answer: ['મહાવીર પ્રભુ']
    },
    {
      question: 'અભય કુમાર ને પ્રભુ ની વાણી થી શું થાય છે?',
      options: [
        'આનંદ ઉત્પન્ન થયો',
        'વૈરાગ્ય ઉત્પન્ન થયો',
        'સમાધાન થયું',
        'ક્રોધ આવ્યો'
      ],
      answer: ['વૈરાગ્ય ઉત્પન્ન થયો']
    },
    {
      question: 'નીરુમા ને કોઈ વ્યક્તિ માટે શું ના હોય?',
      options: ['માન', 'પ્રેમ', 'મમતા', 'અભિપ્રાય'],
      answer: ['અભિપ્રાય']
    },
    {
      question:
        'Bonus Question #3 Dadavani June-2018: દાદાશ્રી: એક માણસને માટે એકનો એક _A_ રાખવો એ _B_ કહેવાય, એટલે કે _A_નો ગુણાકાર. _B_ એટલે એક પ્રકારનો દ્વેષ છે. એ પાપ બંધાવે છે, એટલે બહુ જોખમી છે. આ _B_ રાખવાથી આગળ જતા એ _C_માં પરિણમે છે. _C_ એ મોટી નિર્બળતા છે, આત્મઘાતી છે. _C_ થતા જ સામા માટે મોટી જુદાઈ પડી જાય છે. _A_ એ તંતીલી વાણીનું કારણ છે, જ્યારે _C_ એ _B_નું કારણ છે.',
      options: [
        'A = શંકા ; B = અભિપ્રાય ; C = પૂર્વગ્રહ',
        'A = અભિપ્રાય ; B = શંકા ; C = પૂર્વગ્રહ',
        'A = પૂર્વગ્રહ ; B = અભિપ્રાય ; C = શંકા',
        'A = અભિપ્રાય ; B = પૂર્વગ્રહ ; C = શંકા'
      ],
      answer: ['A = અભિપ્રાય ; B = પૂર્વગ્રહ ; C = શંકા']
    },
    {
      question: 'બહુ મોહ હોય તો શું થાય?',
      options: ['પ્રેમ', 'લોભ', 'કરુણા', 'વેર'],
      answer: ['વેર'],
      questionImage: 'abhipray-26.png'
    },
    {
      question: 'મોહ માં શું રાખવું જોઈએ?',
      options: ['આગ્રહ', 'નિરાગ્રહ', 'ગંભીરતા', 'નોર્માલીટી'],
      answer: ['નોર્માલીટી']
    },
    {
      question: 'જેટલી વાર દ્વેષ થાય એટલી વાર શું કરવું જોઈએ?',
      options: ['અતિક્રમણ', 'પ્રતિક્રમણ', 'પ્રાર્થના', 'ક્રમણ'],
      answer: ['પ્રતિક્રમણ']
    },
    {
      question: 'શું કરવાથી સામેવાળાનેય સુખ રહેશે અને આપણનેય સુખ રહેશે?',
      options: ['ઝઘડો કરવાથી', 'એડજસ્ટ એવરીવ્હેર', 'કષાય કરવાથી', 'એક પણ નહીં'],
      answer: ['એડજસ્ટ એવરીવ્હેર']
    },
    {
      question: 'કોઈ ને દુઃખ થાય તો શું કરવું જોઈએ?',
      options: [
        'ભૂલી જવું જોઈએ',
        'માફ કરી દેવું જોઈએ',
        'માફી માગવી જોઈએ',
        'એક પણ નહીં'
      ],
      answer: ['માફી માગવી જોઈએ']
    },
    {
      question: "દાદા ભગવાન ના 'અસીમ જય જય કર હો' બોલવા થી શું થાય?",
      options: [
        'ચિત્ત એકાગ્ર થાય',
        'ચિત્ત પ્રસન્ન રહે',
        'ચિત્ત મસ્ત બંને',
        'એક પણ નહીં'
      ],
      answer: ['ચિત્ત એકાગ્ર થાય']
    },
    {
      question: 'અભયકુમારની માતા તેને શું વાંચવા આપે છે?',
      options: ['ચોપડી', 'ગ્રંથ', 'પત્ર', 'લાકડા નો ટુકડો'],
      answer: ['લાકડા નો ટુકડો']
    },
    {
      question:
        'Bonus Question #4: આપણે કોની સાથે નો વ્યવહાર આગ્રહ, અપેક્ષા, અભિપ્રાય વગર કરવો છે?',
      options: ['ઉપરી-અન્ડરહેન્ડ', 'મા-બાપ છોકરા', 'હસબંડ-વાઈફ', 'આપેલા તમામ'],
      answer: ['આપેલા તમામ'],
      preview: {
        type: 'video',
        title:
          'Bonus Question #4 & #5: Watch below video and answer the two questions below.',
        id: 'Pu0ak47C_vQ'
      }
    },
    {
      question:
        'Bonus Question #5: અપેક્ષા ન હોય, દોષ-દ્રષ્ટિ ન હોય, કોઈ કંડીશન ન હોય, તો _ રહેવાનો જ છે. _ થી વ્યવહાર કરી શકીએ, એ આપણો અલ્ટીમેટ ગોલ છે.',
      options: ['નિઃસ્વાર્થ ભાવ', 'વિનય', 'પ્રેમ', 'સમભાવ'],
      answer: ['પ્રેમ']
    }
  ],
  'satyug-vartshe-simandhar-swamini-bhaktithi': [
    {
      question: 'પૂજ્ય દાદા ભગવાનને સીમંધર સ્વામી ભગવાનનું _ હતું.',
      options: ['અનુશાસન', 'અનુસંધાન', 'સમાધાન', 'અનુભૂતિ'],
      answer: ['અનુસંધાન']
    },
    {
      question: 'સીમંધર સ્વામી ભગવાન ના શાસન દેવ - દેવી કોણ છે?',
      options: [
        'ચાંદ્રાયણ યક્ષદેવ - અંબિકા યક્ષિણીદેવી',
        'ધરણેન્દ્ર યક્ષદેવ - પદ્માવતી દેવી',
        'ચાંદ્રાયણ યક્ષદેવ - પાંચાંગુલી યક્ષિણીદેવી',
        'ધરણેન્દ્ર યક્ષદેવ - પાંચાંગુલી યક્ષિણીદેવી'
      ],
      answer: ['ચાંદ્રાયણ યક્ષદેવ - પાંચાંગુલી યક્ષિણીદેવી']
    },
    {
      question:
        'નીરુમા કહે છે કે ઘણા લોકો દિવસ માં _ વાર ભગવાન ને નમસ્કાર કરે છે અને એમના જીવન મન શાંતિ થઇ જાય છે.',
      options: ['૧૦૧', '૪૦', '૧૦૮', '૫૦૦'],
      answer: ['૧૦૮']
    },
    {
      question: 'સીમંધર સ્વામી ભગવાન ને ડાયરેક્ટ નમસ્કાર કેવી રીતે પહોંચે છે?',
      options: [
        'ફક્ત યાદ કરવાથી',
        'દાદાને યાદ કરીને, અત્યંત ભક્તિ પૂર્વક નમસ્કાર કરું છું  એવું ફક્ત કહેવાથી',
        'મંદિર જઈને, ફૂલ-હાર ચઢાવીને, આરતી કરવાથી',
        'અંદર બેઠેલા દાદા ભગવાન ની સાક્ષીએ, અભેદ ભાવે, અત્યંત ભક્તિ પૂર્વક નમસ્કાર કરું છું  એવા ભાવથી કહેવાથી'
      ],
      answer: [
        'અંદર બેઠેલા દાદા ભગવાન ની સાક્ષીએ, અભેદ ભાવે, અત્યંત ભક્તિ પૂર્વક નમસ્કાર કરું છું  એવા ભાવથી કહેવાથી'
      ]
    },
    {
      question: "Bonus Question #1: દાદાશ્રી: 'તીર્થંકર' એટલે '_ નો ચંદ્રમા'.",
      options: ['બીજ', 'આઠમ', 'ચૌદશ', 'પૂનમ'],
      answer: ['પૂનમ']
    },
    {
      question: 'દાદા ની શું ભાવના હતી?',
      options: [
        'ઘેર ઘેર સીમંધર સ્વામી ભગવાન ના ફોટા મુકાશે',
        'ઘેર ઘેર સીમંધર સ્વામી ના દેરાસરો થશે',
        'ગામે-ગામ માં સીમંધર સ્વામી ના મૂળ નાયકના દેરાસરો થશે',
        'આપેલા તમામ'
      ],
      answer: ['આપેલા તમામ'],
      questionImage: 'swami-bhakti-5.png'
    },
    {
      question:
        'સીમંધર સ્વામી ભગવાન ના દેરાસર થી અને દેવ-દેવીઓ ની કૃપા થી, શું કાળ પરિવર્તન આવશે?',
      options: [
        'સત્યુગ માં કળિયુગ વર્તાશે',
        'હળાહળ કળિયુગ માં થોડો કાળ સત્યુગ વર્તાશે',
        'હળાહળ કળિયુગ માં પુરેપૂરું સત્યુગ વર્તાશે',
        'આપેલા તમામ'
      ],
      answer: ['હળાહળ કળિયુગ માં થોડો કાળ સત્યુગ વર્તાશે']
    },
    {
      question: 'કયા આધારે આપણી ગતિ બંધાય છે?',
      options: [
        'અંદર વિચાર વાણી જેવી હોય તે આધારે આપણી ગતિ બંધાય છે',
        'બહાર વર્તન જેવા હોય તે આધારે આપણી ગતિ બંધાય છે',
        'અંદર પરિણતી જેવી હોય તે આધારે આપણી ગતિ બંધાય છે',
        'બહાર પરિણતી જેવી હોય તે આધારે આપણી ગતિ બંધાય છે'
      ],
      answer: ['અંદર પરિણતી જેવી હોય તે આધારે આપણી ગતિ બંધાય છે']
    },
    {
      question:
        'અંદર કષાયો ક્રોધ - માન - માયા - લોભ ભરપટ્ટે હોય, મારુ - તારું હોય તો કઈ ગતિ થાય?',
      options: ['મનુષ્યગતિ', 'દેવગતિ', 'અધોગતિ', 'સિદ્ધગતિ'],
      answer: ['અધોગતિ']
    },
    {
      question:
        'Bonus Question #2: દાદાશ્રી: સીમંધર સ્વામી એ બીજા ક્ષેત્રમાં છે, એ બુધ્ધિની બહારની વાત છે બધી. પણ મારા જ્ઞાનમાં આવેલી છે, આ લોકોને સમજાય નહિ. પણ અમને એક્ઝેક્ટ (જેમ છે તેમ) સમજાય. હવે એનાં _ કરવાથી લોકોનું કલ્યાણ બહુ થઈ જાય.',
      options: ['દર્શન', 'મનન', 'વાંચન', 'શ્રવણ'],
      answer: ['દર્શન']
    },
    {
      question: 'અણહક્કનું લે/ભોગવે તેને કઈ ગતિ થાય?',
      options: ['દેવગતિ', 'મનુષ્યગતિ', 'જાનવરગતિ', 'એક પણ નહી'],
      answer: ['જાનવરગતિ'],
      preview: {
        type: 'video',
        title: 'Special Video #1: ઓળખાણ સીમંધર સ્વામીની',
        id: 'ORROE69LB0k'
      }
    },
    {
      question:
        'અંદર રાગ-દ્વેષ ના રહે, કોઈ ની જોડે ચોંટ/ઋણાનુબંધ ના રહે, અભેદતા હોય, બધાને આત્મદ્રષ્ટિ જોઈએ તો શું થાય?',
      options: [
        'અંદર ની પરિણતી પાંચમાં આરા/ કળિયુગ જેવી વર્તાય, અને જ્યાં તીર્યંચગતિ ચાલતી હોય ત્યાં ઋણાનુબંધ બંધાય',
        'અંદર ની પરિણતી ચોથા આરા/ સત્યુગ જેવી વર્તાય, અને જ્યાં દેવગતિ ચાલતી હોય ત્યાં ઋણાનુબંધ બંધાય',
        'અંદર ની પરિણતી ચોથા આરા/ સત્યુગ જેવી વર્તાય, અને જ્યાં ચોથો આરો/સત્યુગ ચાલતો હોય ત્યાં ઋણાનુબંધ બંધાય',
        'અંદર ની પરિણતી પાંચમાં આરા/ કળિયુગ જેવી વર્તાય, અને જ્યાં પાંચમો આરો/કળિયુગ ચાલતો હોય ત્યાં ઋણાનુબંધ બંધાય'
      ],
      answer: [
        'અંદર ની પરિણતી ચોથા આરા/ સત્યુગ જેવી વર્તાય, અને જ્યાં ચોથો આરો/સત્યુગ ચાલતો હોય ત્યાં ઋણાનુબંધ બંધાય'
      ]
    },
    {
      question: 'પરમાત્મા ની જોડે લગની લાગે તો શું થાય?',
      options: [
        'તેમની જોડે અભેદ થઇ જવાય, તે રૂપ થવાય',
        'આપણને આનંદ થાય',
        'આપણા સંસાર ના દુઃખો દૂર થાય',
        'આપણ ને ઇચ્છિત વસ્તુઓ મળે'
      ],
      answer: ['તેમની જોડે અભેદ થઇ જવાય, તે રૂપ થવાય']
    },
    {
      question:
        'સંસાર માં તો આપણે અનેક વાર લગ્ન કર્યા અનંત અવતાર માં હવે કોની જોડે ની લગની લાગવી જોઈએ?',
      options: [
        'હવે પરમાત્મા જોડે ની લગની લાગવી જોઈએ',
        'હવે રાત દહાડો પરમાત્મા ની સીમંધર સ્વામી ની ભક્તિ કરવી જોઈએ',
        'હવે સંસાર ની જવાબદારીઓ માંથી છૂટી જવું જોઈએ',
        'A અને B'
      ],
      answer: ['A અને B']
    },
    {
      question:
        'Bonus Question #3: પ્રશ્નકર્તા : આપણા મહાત્મા (મહાવિદેહ) જશે. એ પાછા અહીં આવવાના? દાદાશ્રી : એ ના આવે. એ તો આવે જ નહિ. આ _ના આધારે તો ઉપર ચઢ્યા. પછી પાછાં ના પડે.',
      options: ['કાળ', 'વિજ્ઞાન', 'ભક્તિ', 'ક્ષેત્ર'],
      answer: ['વિજ્ઞાન'],
      questionImage: 'swami-bhakti-15.png'
    },
    {
      question:
        'પ્રત્યક્ષ ભગવાન સીમંધર સ્વામી ની ખૂબ જ ભક્તિ કરવા થી શું થાશે?',
      options: [
        'દાદા ભગવાન ખુશ થશે',
        'આપણ ને સુખ શાંતિ મળશે',
        'તેમની જોડે ઋણાનુબંધ બંધાશે',
        'A અને B'
      ],
      answer: ['તેમની જોડે ઋણાનુબંધ બંધાશે']
    },
    {
      question: 'આ ભરતક્ષેત્ર થી આ કાળ માં મોક્ષે જવાનું શક્ય નથી',
      options: ['સાચું છે', 'ખોટું છે'],
      answer: ['સાચું છે']
    },
    {
      question: 'આ કાળ માં મોક્ષે જવું હોઈ તો કઈ રીતે જવાય?',
      options: [
        'ખૂબ જ પુણ્યકર્મ બાંધીને જવાય',
        'ખૂબ જ દેવદર્શન ને ભક્તિ કરીએ તો જવાય',
        'આપણને મહાવિદેહ ક્ષેત્ર થી શ્રી સીમંધર સ્વામી ના દર્શન થી કેવળજ્ઞાન પ્રાપ્ત કરી ને જવાય',
        'આપેલા તમામ'
      ],
      answer: [
        'આપણને મહાવિદેહ ક્ષેત્ર થી શ્રી સીમંધર સ્વામી ના દર્શન થી કેવળજ્ઞાન પ્રાપ્ત કરી ને જવાય'
      ]
    },
    {
      question:
        'દાદા ભગવાન, શ્રીમદ્ રાજચંદ્ર તેમજ તમામ જ્ઞાનીઓ નો શો સંદેશો છે?',
      options: [
        'મોક્ષ માર્ગ ક્યારેય બંધ હોતો જ નથી',
        'અહીં થી એક અવતારી પદ થઇ શકે તેમ છે',
        'અહીં થી આ કાળ માં મોક્ષ ના થઇ શકે માટે બારણાં બંધ કરી દેવા',
        'A અને B'
      ],
      answer: ['A અને B'],
      preview: {
        type: 'video',
        title:
          'Special Video #2: नीरुमा रचित यह पद "वर्तमान तीर्थंकर श्री सीमंधर स्वामी" पुस्तक का \'समर्पण\' है / This pad composed by Niruma is \'samarpan\' of the book "Vartmaan Tirthankar Shri Simandhar Swami" | Pad Title: Bhed Pratyaksh Paroksh',
        id: '07jt4uBi_Ok'
      }
    },
    {
      question:
        'Bonus Question #4: દાદાશ્રી: પણ જો કદી _ તીર્થંકરને સંભારીએ તો એની વાત જ જુદી! _ની જ કિંમત બધી, રોકડા રૂપિયા હશે તેની કિંમત. પછી આવશે એ રૂપિયા ભાવિ! અને ગયા એ તો ગયા! એટલે રોકડી વાત જોઈએ આપણને! તેથી રોકડી ઓળખાણ કરાવી આપું છું ને! અને આ વાતે ય બધી રોકડી છે. ધીસ ઈઝ ધી કૅશ બેંક ઑફ ડિવાઈન સોલ્યુશન!',
      options: ['ભૂતકાળ', 'ભાવી', 'સિદ્ધ', 'વર્તમાન'],
      answer: ['વર્તમાન']
    },
    {
      question:
        'નીરુમા કહે છે કે મોક્ષે જવા, સીમંધર સ્વામી પાસે જવા આપણી વારે વાર છે, કારણ કે?',
      options: [
        'સીમંધર સ્વામી આપણા માટે હાજર જ છે અને તૈયાર જ છે',
        'સીમંધર સ્વામી ને ભરતક્ષેત્ર સાથે જબરજસ્ત ઋણાનુબંધ છે',
        'આપણે બધું ચોખ્ખું કરી નાખીએ તો તેમના શરણ માં લઇ જ લેશે',
        'આપેલા તમામ'
      ],
      answer: ['આપેલા તમામ']
    },
    {
      question:
        'સવાર ના પહોર માં શ્રી સીમંધર સ્વામી ને શું પ્રાર્થના કરવી જોઈએ?',
      options: [
        'તેમને યાદ કરી ને જે જે શક્તિઓ ખૂટતી હોઈ તે માંગીએ',
        'મને તમારી પાસે જ આવવું છે',
        'મને મોક્ષ સિવાય કોઈ ચીજ ખપતી નથી',
        'આપેલા તમામ'
      ],
      answer: ['આપેલા તમામ']
    },
    {
      question:
        'મહાવિદેહ ક્ષેત્ર જ્યાં સીમંધર સ્વામી બિરાજે છે તે ક્યા ખૂણા માં છે?',
      options: ['ઈશાન', 'દક્ષિણ', 'પૂર્વ', 'નેઋત્ય'],
      answer: ['ઈશાન'],
      questionImage: 'swami-bhakti-23.png'
    },
    {
      question:
        'નીરુમા આપણને આ વિડિયોમાં શું ભાવના અને પ્રાર્થના કરવાનું કહે છે?',
      options: [
        'જગત માં લોકો અધ્યાત્મક માર્ગ પર વળે',
        'કળિયુગી જીવો સત્યુગ માણતા થઇ જાય',
        'દુષમકાળ માંથી લોકો સુષમ કાળ માં આવી જાય',
        'આપેલા તમામ'
      ],
      answer: ['આપેલા તમામ']
    },
    {
      question:
        'Bonus Question #5: દાદાશ્રી: અમારે સીમંધર સ્વામી જોડે સંબંધ છે. અમે બધા મહાત્માઓની મોક્ષની જવાબદારી લીધી છે. અમારી _ જે પાળશે, તેની અમે જવાબદારી લઈએ છીએ.',
      options: ['આજ્ઞા', 'સત્સંગ', 'વાણી', 'વીતરાગતા'],
      answer: ['આજ્ઞા'],
      preview: {
        type: 'video',
        title: 'Special Video #3: તીર્થંકર ભગવાન તેરી જય હોવે!',
        id: 'wWWxXH7lNA8'
      }
    }
  ],
  'niruma-aptsinchan': [
    {
      question: '"નીરુમા" શબ્દ સાંભળતા જ બધાને શું યાદ આવી જાય?',
      options: ['વાત્સલ્ય', 'આજ્ઞા', 'સત્સંગ', 'રીયલ મા'],
      answer: ['રીયલ મા']
    },
    {
      question:
        "જ્યારે કોઈ પણ મુશ્કેલી આવે ત્યારે 'નીરુમા છે ને' એવું રહે તેણે શું કહેવાય?",
      options: ['આધીનતા', 'યાદ', 'પ્રેમ', 'હૂંફ'],
      answer: ['હૂંફ']
    },
    {
      question:
        'જેને પણ બ્રહ્મચર્ય પાડી દાદાના મિશનમાં સમર્પણ થવું હોઈ એ માટે પાંચ વર્ષની ટ્રેનીંગ લેવાની હોઈ છે, તેનું શું નામ છે?',
      options: ['આપ્તકુમાર', 'આપ્તસંકુલ', 'આપ્તસિંચન', 'સેવાર્થી'],
      answer: ['આપ્તસિંચન']
    },
    {
      question: 'દાદાએ કહ્યું હતું કે નીરૂમાને અમે જુદા નહિ કરીએ કારણ કે?',
      options: [
        'નીરુમા ખૂબ સરસ અને ઉત્કૃષ્ટ સેવા કરે છે.',
        'નીરુમાએ સરસ બ્રહ્મચર્ય સિદ્ધ કર્યું છે.',
        'નીરુમા જબરદસ્ત પાંચ આજ્ઞા પાડે છે.',
        'નીરુમા જગતકલ્યાણનું બહુ મોટું નિમિત્ત છે.'
      ],
      answer: ['નીરુમા જગતકલ્યાણનું બહુ મોટું નિમિત્ત છે.']
    },
    {
      question: 'આ નીરુ માટે અમે એટલા માટે માથાકૂટ કરીએ કે...?',
      options: [
        'તેઓ ખૂબ પ્યોર બની શકે.',
        'એ કોકનો ધ્રુવ કાંટો અમારી હાજરી ન હોય ત્યારે ફેરવી આપે.',
        'તેઓ દીપકભાઈને જગતકલ્યાણ માટે તૈયાર કરે છે.',
        'તેઓ સરસ બધા આપ્તપુત્રોને તૈયાર કરે છે.'
      ],
      answer: ['એ કોકનો ધ્રુવ કાંટો અમારી હાજરી ન હોય ત્યારે ફેરવી આપે.']
    },
    {
      question: 'દાદાની હાજરીમાં નીરૂમાએ શું સુંદર પાળ્યું કે એ અજાયબી કહેવાય?',
      options: ['આજ્ઞા', 'પાંચ મહાવ્રત', 'સેવા ચાકરી', 'ચારિત્ર'],
      answer: ['ચારિત્ર'],
      questionImage: 'niruma-nu-aptasinchan-6.png'
    },
    {
      question: 'નીરૂમાએ બ્રહ્મચર્યવ્રત કઈ રીતે એક્ઝેક્ટ કરી બતાવ્યું?',
      options: [
        'વિચારે કરીનેય ભૂલ થયેલી નહીં',
        'વાણીથી ક્યારેય ભૂલ થઇ નહીં',
        'કયાંથી પણ ક્યારેય ભૂલ થઇ નહીં',
        'આપેલા તમામ'
      ],
      answer: ['વિચારે કરીનેય ભૂલ થયેલી નહીં']
    },
    {
      question: 'નીરૂબેન માટે દાદા શું કહે છે?',
      options: [
        'તમારે જગતકલ્યાણ કરવાનું છે',
        'તમારે આખા જગતના મધર થવાનું છે',
        'તમારે બધાને બ્રહ્મચર્ય શીખવવાનું છે',
        'તમારે ત્રિમંદિર બંધાવવાનું છે'
      ],
      answer: ['તમારે આખા જગતના મધર થવાનું છે']
    },
    {
      question: 'દાદા નીરુમાના પ્રેમની સરખામણી કોની સાથે કરે છે?',
      options: ['હીરાબા', 'ઝવેરબા', 'તીર્થંકર', 'દેવી દેવતા'],
      answer: ['ઝવેરબા']
    },
    {
      question:
        'દાદા નીરુમાની સેવા માટે શું કહે છે? "નીરૂબેન તમે _A_ ની જેમ અમારી નર્સરી કરી છે. _B_ ની જેમ ચાકરી કરી છે. _C_ જેમ સેવા કરી છે.',
      options: [
        'A = દીકરી ; B = મધર ; C = નર્સ',
        'A = નર્સ ; B = દીકરી ; C = મધર',
        'A = મધર ; B = દીકરી ; C = નર્સ',
        'A = નર્સ ; B = મધર ; C = દીકરી'
      ],
      answer: ['A = મધર ; B = દીકરી ; C = નર્સ']
    },
    {
      question: 'નીરુમાએ આપ્તપુત્રોને બ્રહ્મચર્ય માટે શું કહ્યું?',
      options: [
        'બ્રહ્મચર્ય પાળવું એટલે લોઢાના ચણા ચાવવા જેવું છે.',
        'આમાં સિન્સિયર રહેવું જ પડે.',
        'બ્રહ્મચર્ય અખંડ હોવું જોઈએ, અહી પોપાબાઈનું રાજ નથી',
        'આપેલા તમામ'
      ],
      answer: ['આપેલા તમામ'],
      preview: {
        type: 'video',
        title: 'Special Video #1: પૂજ્ય નીરુમાનો મહાત્મા પ્રત્યે નો પ્રેમ',
        id: 'D97mJxLDuLA'
      }
    },
    {
      question:
        'દાદાએ એમના આખા જીવનમાં કોની તરફ નહોતું જોયું અને મોઢું નીચું રાખીને વાત કરતા?',
      options: ['ભાભી', 'પોળના બહેનો', 'સત્સંગમાં બહેનો', 'સ્કૂલમાં છોકરીઓ'],
      answer: ['ભાભી']
    },
    {
      question: 'નીરુમા પાસે કઈ વાતો કરીએ તો તેમના મોઢા પર અલગ તેજ હોય?',
      options: ['પાંચ આજ્ઞાની', 'દાદાની', 'બ્રહ્મચર્ય', 'જગતકલ્યાણ'],
      answer: ['બ્રહ્મચર્ય']
    },
    {
      question:
        'નીરુમા બ્રહ્મચારી બેહનોને ખૂબ ઝીણવટપૂર્વક ને પ્રેમપૂર્વક શું શીખવાડતા?',
      options: [
        'કપડાનાં રંગનું ધ્યાન રાખે - આ રંગ ન શોભે અને વાળ કેવી રીતે ઓળવા',
        'કેવી રીતે ઉઠવું-બેસવું, કોઈના ઘરે જઈએ ત્યારે કેવો વ્યવહાર કરવો',
        'રસોડામાં કામ કેવી રીતે કરવું',
        'આપેલા તમામ'
      ],
      answer: ['આપેલા તમામ']
    },
    {
      question: 'જ્ઞાની પુરુષ આપણો _ કહેવાય.',
      options: ['મિત્ર', 'સહારો', 'અરીસો', 'આપેલા તમામ'],
      answer: ['અરીસો']
    },
    {
      question: 'જ્ઞાની પાસે પોતાની ભૂલો કેવી રીતે ખુલ્લી કરવી કે તે મદદ કરે?',
      options: [
        'બધાની હાજરીમાં',
        'લખીને વર્ષી રિપોર્ટ આપીને',
        'A અને B',
        'જ્ઞાની ને તમારી ભૂલો ખબર હોય, એટલે ખુલ્લી કરવીની જરૂરત નથી'
      ],
      answer: ['A અને B'],
      questionImage: 'niruma-nu-aptasinchan-16.png'
    },
    {
      question: 'દાદા કહે છે ને કે _ તો ખુલ્લો અહંકાર છે, ઉઘાડો અહંકાર છે.',
      options: ['કપડાં', 'ગુસ્સો', 'વિષય', 'વાણી'],
      answer: ['વાણી']
    },
    {
      question: 'નીરુમા સેવા માટે શું કહેતા હતા?',
      options: [
        'જેટલા લાડવા ખાધા હોય ને એટલા લોકોનું કલ્યાણ તમારા હાથે થવું જોઈએ.',
        'જેટલા ભાતના દાણા ખવડાવ્યા હોય ને એટલા લોકોનું કલ્યાણ તમારા હાથે થવું જોઈએ.',
        'સેવા નું ફળ આવતા ભાવ માં આવશે',
        'ફક્ત જ્ઞાનીની સેવા કરવી જોઈએ'
      ],
      answer: [
        'જેટલા ભાતના દાણા ખવડાવ્યા હોય ને એટલા લોકોનું કલ્યાણ તમારા હાથે થવું જોઈએ.'
      ]
    },
    {
      question:
        'પ્રેમથી રહેજો... પ્રોમીસ? - આ અંતિમ વાક્ય; કોણે, કોને કીધું હતું?',
      options: [
        'દાદાશ્રીએ નીરુમાને કીધું હતું',
        'નીરુમાએ પૂજ્યશ્રી દીપકભાઈને ઈશારાથી કીધું હતું',
        'દાદાશ્રીએ નીરુમા અને પૂજ્યશ્રી દિપકભાઈને ઈશારાથી કીધું હતું',
        'નીરુમાએ ડિમ્પલભાઈને લખી ને કીધું હતું'
      ],
      answer: ['નીરુમાએ ડિમ્પલભાઈને લખી ને કીધું હતું']
    },
    {
      question: 'પ્રેમથી રહેજો... પ્રોમીસ? - આ વાક્ય પાછળ આશય શું છે?',
      options: [
        'બધા આપ્તપુત્રો એક બીજા સાથે પ્રેમથી રહે',
        'અમારે બધાએ એક બીજા સાથે પ્રેમથી રહેવાનું છે',
        'બધા મહાત્માઓ એક બીજા સાથે પ્રેમથી રહે',
        'ઘરની બધી વ્યત્કિતો એક બીજા જોડે પ્રેમથી રહે'
      ],
      answer: ['અમારે બધાએ એક બીજા સાથે પ્રેમથી રહેવાનું છે']
    },
    {
      question:
        'નીરુમા અમેરિકાથી આવેને તો બધા બ્રહ્મચારી ભાઈઓ-બહેનો માટે શું લાવે?',
      options: [
        'ઘડિયાળ કે લોકેટ લાવે',
        'દાગીનો કે વીંટી લાવે',
        'ચોકલૅટ કે કેડબરી લાવે',
        'ટૂથબ્રશ કે ટૂથપેસ્ટ લાવે'
      ],
      answer: ['ચોકલૅટ કે કેડબરી લાવે'],
      preview: {
        type: 'video',
        title: "Special Video #2: Niruma's Pure Love",
        id: 'NnffEVwM5QU'
      }
    },
    {
      question: 'ભાઈથી બહેનને કેમ ભૂલાય? - નીરુમા ની આ ટકોર શું રીયલાઇઝ થયું?',
      options: [
        'ભાઈએ બહેન ને રક્ષાબંધન પર ગિફ્ટ આપવી જોઈએ',
        'હંમેશા બીજાનો વિચાર આવવો જોઈએ',
        'ફરીથી એકલા એકલા ચોકલૅટ નહિ ખાવાની',
        'પોતાના સિવાય બીજાનો કોઈનો વિચાર ન આવે'
      ],
      answer: ['હંમેશા બીજાનો વિચાર આવવો જોઈએ']
    },
    {
      question: 'નીરુમાએ સામાની પ્રકૃતિ વિષે શું કહ્યું હતું?',
      options: [
        'નીરુમાને સામાની પ્રકૃતિનો ખ્યાલ હોય પણ એની નોંધ ન હોય',
        'નીરુમાને સામાની પ્રકૃતિનો ખ્યાલ ન હોય એની નોંધ પણ ન હોય',
        'નીરુમાને સામાની પ્રકૃતિનો ખ્યાલ હોય અને એની નોંધ પણ હોય',
        'નીરુમાને સામાની પ્રકૃતિનો ખ્યાલ ન હોય પણ એની નોંધ હોય'
      ],
      answer: ['નીરુમાને સામાની પ્રકૃતિનો ખ્યાલ હોય પણ એની નોંધ ન હોય']
    },
    {
      question:
        'નીરુમાની વ્યવહાર સૂઝ ના દાખલા માં, ટૂથબ્રશ આપવાનું કહે તો શું કરવાનું?',
      options: [
        'ખાલી ટૂથબ્રશ આપવાનું',
        'ટૂથબ્રશ અને ટૂથપેસ્ટ સાથે આપવાનું',
        'ટૂથબ્રશ અને ટૂથપેસ્ટ સાથે, બ્રશ ધોઈ આપવાનું',
        'ટૂથબ્રશ અને ટૂથપેસ્ટ સાથે, બ્રશ ધોઈ, ટંગ ક્લીનર આપવાનું'
      ],
      answer: ['ટૂથબ્રશ અને ટૂથપેસ્ટ સાથે, બ્રશ ધોઈ, ટંગ ક્લીનર આપવાનું']
    },
    {
      question: 'કોઈ પણ _ને જરા પણ દુઃખ થાય તો નીરુમાનું હૃદય વલોવાઈ જતું.',
      options: ['બાળક', 'આપ્તપુત્ર-આપ્તપુત્રી', 'પશુ', 'મહાત્મા'],
      answer: ['મહાત્મા']
    },
    {
      question: '૨૦૦૪ માં કેટલા _આપ્તકુમારી બહેનો હતા?',
      options: ['૨', '૨૬', '૬૨', '૧૦૦'],
      answer: ['૨૬']
    },
    {
      question: 'નીરુમાએ કુમારી બહેનો માટે શું સેટિંગ કર્યું?',
      options: [
        'રહેવાનું & જમવાનું ઘરે, એકલા',
        'રહેવાનું & જમવાનું ઘરે મમ્મી-પાપા જોડે',
        'રહેવાનું ઘરે મમ્મી-પાપા જોડે, અથવા એકલા અને જમવાનું ઊણોદરીમાં',
        'રહેવાનું સંકુલમાં અને જમવાનું ઊણોદરીમાં'
      ],
      answer: ['રહેવાનું ઘરે મમ્મી-પાપા જોડે, અથવા એકલા અને જમવાનું ઊણોદરીમાં']
    },
    {
      question: 'નીરુમાનો કુમારી બહેનો માટેનું સેટિંગ પાછળ આશય શું હતો?',
      options: [
        'રસોઈ કરતા આવડે',
        'સાથે રહી એડજસ્ટમેન્ટ લેતા ફાવે',
        'સાથે રહી એડજસ્ટમેન્ટ લેતા ફાવે & ખાવાની વૃત્તિઓ છૂટી જાય',
        'એક બીજાની પ્રકૃતિની ઓળખાણ પડે, ખાવાની વૃત્તિઓ છૂટે અને કઈ પણ વસ્તુમાં એડજસ્ટમેન્ટ લેતા ફાવે'
      ],
      answer: [
        'એક બીજાની પ્રકૃતિની ઓળખાણ પડે, ખાવાની વૃત્તિઓ છૂટે અને કઈ પણ વસ્તુમાં એડજસ્ટમેન્ટ લેતા ફાવે'
      ],
      questionImage: 'niruma-nu-aptasinchan-27.png'
    },
    {
      question: 'ઊણોદરીથી કુમારી બહેનોને શું ફાયદો થયો?',
      options: [
        'ધીરે-ધીરે બધું ખાતા શીખ્યા અને ખાવાની વૃત્તિઓ છૂટી ગઈ',
        'જાગૃતિ બહુ વધી',
        'એડજસ્ટમેન્ટ લેતા શીખ્યા અને એક-બીજાનું ધ્યાન રાખતા શીખ્યા',
        'આપેલા તમામ'
      ],
      answer: ['આપેલા તમામ']
    },
    {
      question:
        'નીરુમા વખતે જે સંકુલનું ઘડતર થતું અત્યારે જે સિંચનની પ્રોસેસ માં શું ફેર છે?',
      options: [
        'ઘણો ફેર છે',
        'ફક્ત મેન્ટાલીટી માં ફેર છે',
        'કઈ ફેર નથી',
        'ફેર એટલો છે કે નીરુમા બધુજ ડાયરેક્ટ લઇ લેતા'
      ],
      answer: ['કઈ ફેર નથી']
    },
    {
      question: 'પૂજ્યશ્રી કોને સંચાલન ચલાવવાનું કારણ કીધું?',
      options: [
        'નીરુમાની કૃપા, નીરુમાના આશીર્વાદ અને નીરુમાના સંચાલન ',
        'દેવ - દેવીઓ , દાદા નીરુમા અને સીમંધર સ્વામીના આશીર્વાદ છે',
        'ઘણાં સંચાલન વ્યવસ્થિત શક્તિ થી ચાલે છે',
        'આપેલા તમામ'
      ],
      answer: ['આપેલા તમામ']
    },
    {
      question: 'નીરુમા જગતકલ્યાણના _.',
      options: ['સુકાની', 'માં', 'દેવી', 'સારથી'],
      answer: ['સુકાની'],
      preview: {
        type: 'video',
        title: 'Special Video #3: ઓ નીરુમા...',
        id: 'i1vsidXNxw0'
      }
    }
  ],
  'gnani-purush-1-pg-56-70': [
    {
      question: 'ધંધો કરવાનો રેગ્યુલર પણ જીવન _.',
      options: ['જીવવાનું', 'સ્વતંત્ર', 'સેવાભાવી', 'સંયમી'],
      answer: ['સ્વતંત્ર']
    },
    {
      question: 'દાદાશ્રીને શું પહેલેથી જ બોજારૂપ લાગતું હતું?',
      options: ['આગ્રહ', 'વ્યવહાર', 'પરિગ્રહ', 'સંસાર'],
      answer: ['પરિગ્રહ']
    },
    {
      question:
        'જ્યારે બીજા લોકો ત્રીસ રૂપિયામાં મહિનાની જરૂરિયાત પૂરી કરતા હતા ત્યારે દાદા કેટલા રૂપિયામાં મહિનાની જરૂરિયાત પૂરી કરતા હતા?',
      options: ['ત્રીસ', 'પચ્ચીસ', 'બાવીસ', 'ત્રેવીસ'],
      answer: ['બાવીસ']
    },
    {
      question:
        'દાદાશ્રી ના મત મુજબ ચીજો ઓછી હશે તો ચાલશે પણ સ્વતંત્રતાને  _ ના ચાલે.',
      options: ['બ્રેક', 'આગ્રહ', 'છુટ્ટી', 'ઉપરના બધા'],
      answer: ['બ્રેક']
    },
    {
      question: 'દાદાશ્રીને _ સહેજે પણ પસંદ ના હતું.',
      options: ['સ્વતંત્રતા', 'પરતંત્રતા', 'પરવશતા', 'B અને C'],
      answer: ['B અને C']
    },
    {
      question: 'જો દાદાશ્રી મેટ્રિક પાસ થઈ જાત તો એમને ક્યાં મોકલવામાં આવત?',
      options: ['અમેરિકા', 'ઇંગ્લૈંડ', 'કેનેડા', 'ઓસ્ટ્રેલીયા'],
      answer: ['ઇંગ્લૈંડ'],
      preview: {
        type: 'video',
        title: "Bonus Video: Dada's Nobility",
        id: 'VwKI4nPuhw0'
      }
    },
    {
      question: 'દાદાશ્રીએ મેટ્રિકની પરીક્ષા કેટલા વર્ષની ઉમરમાં પાસ કરી હતી?',
      options: ['પંદર', 'સત્તર', 'સોળ', 'અઢાર'],
      answer: ['અઢાર']
    },
    {
      question: 'દાદાશ્રી મેટ્રિકની પરીક્ષા આપવા માટે ક્યાં ગયા હતા?',
      options: ['ભાદરણ', 'તરસાલી', 'વડોદરા', 'અમદાવાદ'],
      answer: ['વડોદરા']
    },
    {
      question: 'દાદાશ્રીના બ્રધર કયો ધંધો કરતા હતા?',
      options: ['કરિયાણાનો', 'કોન્ટ્રાકટરનો', 'સોની', 'શાકભાજીનો'],
      answer: ['કોન્ટ્રાકટરનો']
    },
    {
      question: 'દાદાશ્રીના બ્રધર વડોદરામાં ક્યાં રહેતા હતા?',
      options: [
        'જોગીદાસ વિઠ્ઠલની પોળ',
        'મામાની પોળ',
        'કરશનદાસ કાકાની પોળ',
        'શાહ વિઠ્ઠલની પોળ'
      ],
      answer: ['જોગીદાસ વિઠ્ઠલની પોળ']
    },
    {
      question:
        'દાદાશ્રી હોસ્ટેલમાં રહેતા હતા ત્યારે સ્ટેશન પર શું ખાવા જતા હતા?',
      options: ['પાણી પૂરી', 'પૂરી શાક', 'આઇસક્રીમ', 'ભેળપુરી'],
      answer: ['આઇસક્રીમ'],
      preview: {
        type: 'video',
        title: 'Bonus Video - Mama Ni Pol',
        id: 'vaxW2KqdlBI'
      }
    },
    {
      question: 'દાદાશ્રી કયા વર્ષમાં મેટ્રિકમાં નાપાસ થયા હતા?',
      options: ['૧૯૨૭', 'વિક્રમ સંવત ૧૯૮૩ - ૮૪', 'A અને B', '૧૯૮૫'],
      answer: ['A અને B']
    },
    {
      question: 'દાદાશ્રી પાસે નાનપણમાં જ્ઞાન ન હતું પણ _ હતું.',
      options: ['પૈસા', 'સંસ્કાર', 'સંતોષરૂપી ધન', 'સોનું'],
      answer: ['સંતોષરૂપી ધન']
    },
    {
      question:
        'દાદાશ્રી કહેતા કે, "હું _A_ કરવા આવ્યો નથી, _B_ થવા આવ્યો છું."',
      options: [
        'A = શેઠ ; B = નોકર',
        'A = આત્મા ભણવા ; B = ભગવાન',
        'A = નાપાસ ; B = પાસ',
        'A = નોકરી ; B = શેઠ'
      ],
      answer: ['A = નોકરી ; B = શેઠ']
    },
    {
      question: '_ એટલે વટ્ટવાળો.',
      options: ['અહંકાર', 'ખુમારી', 'માન', 'લોભ'],
      answer: ['ખુમારી']
    },
    {
      question: '_ માંથી મુક્ત કરે, એનું નામ જ્ઞાન.',
      options: ['અહંકાર', 'માયા', 'ગુલામી', 'સ્વભાવ'],
      answer: ['ગુલામી'],
      preview: {
        type: 'video',
        title: 'Bonus Video - Dada Mandir',
        id: '-YJ7HtPag_U'
      }
    },
    {
      question: 'બુદ્ધિનો આશય શું ખોળી કાઢવાનો હતો?',
      options: ['નોકરી', 'આત્મા', 'ધંધો', 'વ્યવહાર'],
      answer: ['આત્મા']
    },
    {
      question:
        '_A_  દ્રષ્ટિએ મેટ્રિક ફેલ થયેલા છે પણ  _B_ દ્રષ્ટિએ ટોપ છે દાદાશ્રી.',
      options: [
        'A = વ્યવહારિક ; B = આધ્યાત્મિક',
        'A = આધ્યાત્મિક ; B = વ્યવહારિક',
        'A = સાધુ ; B = સંસારિક',
        'A = સંસારિક ; B = સાધુ'
      ],
      answer: ['A = વ્યવહારિક ; B = આધ્યાત્મિક']
    }
  ],
  'gnani-purush-1-pg-71-80': [
    {
      question: 'દાદાશ્રી શાનો ધંધો કરતાં હતા?',
      options: ['પાનની દુકાન', 'કોન્ટ્રાક્ટનો', 'કરિયાણાનો', 'કપડાંનો'],
      answer: ['કોન્ટ્રાક્ટનો']
    },
    {
      question: 'ધંધામાં દાદાને કોણ રોકડા આપતું હતું?',
      options: ['લોકો', 'શેઠ', 'ભાગીદાર', 'સરકાર'],
      answer: ['સરકાર']
    },
    {
      question:
        '૧૯૨૫ - ૨૬માં દાદા કેટલા રુપિયા લઈને આખો દિવસ ભાઈબંધો જોડે ફરતા હતા?',
      options: ['200', '20', '2', '25'],
      answer: ['2']
    },
    {
      question:
        'પહેલાના જમાનામાં ગળ્યું આવે તો લોકોને કોણ મળ્યા હોય એવો આભાસ થતો હતો?',
      options: ['ભગવાન', 'મિત્ર', 'સંબંધી', 'ઉપરના બધા'],
      answer: ['ભગવાન']
    },
    {
      question: 'એ જમાનામાં કયું શાકભાજી એક રૂપિયે રતલ વેચાતું હતું?',
      options: ['પાલક', 'પરવળ', 'કાંટોળા', 'બટાકા'],
      answer: ['પરવળ']
    },
    {
      question:
        'જ્યારે _ના ભાવની કિંમત વધી જાય, એની સાથે માણસના ભાવની કિંમત વધી જાય.',
      options: ['સરસ્વતી', 'ભગવાન', 'લક્ષ્મી', 'સોનું'],
      answer: ['લક્ષ્મી'],
      preview: {
        type: 'video',
        title: "Bonus Video: Dada's Nobility",
        id: 'VwKI4nPuhw0'
      }
    },
    {
      question: '૧૯૨૮ની સાલમાં કઈ નગરી બહુ રૂપાળી હતી?',
      options: ['વડોદરા', 'મુંબઈ', 'અમદાવાદ', 'ભાદરણ'],
      answer: ['મુંબઈ']
    },
    {
      question: 'ક્યાંની ચા બહુ વખણાતી હતી?',
      options: ['મહાકાળીની', 'સ્ટેશનની', 'ઈરાનીની', 'લોકલની'],
      answer: ['ઈરાનીની']
    },
    {
      question: 'દાદાશ્રીએ ક્યાં બાર આનાની ચા પીધી હતી?',
      options: [
        'ઈરાની ને ત્યાં',
        'તાજ હોટલમાં',
        'A અને B બંને',
        'રેલવે સ્ટેશન પર'
      ],
      answer: ['તાજ હોટલમાં']
    },
    {
      question: 'દાદાશ્રી શું ખાવા માટે ત્રણ માઈલ દૂર ગયા હતા?',
      options: ['ભજિયાં', 'સમોસા', 'ખમણ', 'બિસ્કિટ'],
      answer: ['ભજિયાં']
    },
    {
      question: 'દાદાશ્રીને કઈ મીઠાઈ બહુ ભાવતી હતી?',
      options: ['ગુલાબ જાંબુ', 'પેંડા', 'જલેબી', 'દૂધ પાક'],
      answer: ['જલેબી'],
      preview: {
        type: 'video',
        title: 'Bonus Video - Mama Ni Pol',
        id: 'vaxW2KqdlBI'
      }
    },
    {
      question: 'દાદાશ્રીને કઈ મીઠાઈથી ચીડ ચડી ગઈ હતી?',
      options: ['ગુલાબ જાંબુ', 'પેંડા', 'જલેબી', 'દૂધ પાક'],
      answer: ['દૂધ પાક']
    },
    {
      question: 'દાદાશ્રીનાં કપડાંને _A_ ની છૂટ, પણ _B_ ની છૂટ નહીં.',
      options: [
        'A = સાંધવાની ; B = થીગડું',
        'A = સાંધવાની ; B = કાપવાની',
        'A = થીગડું ; B = સાંધવાની',
        'A = કાપવાની ; B = થીગડું'
      ],
      answer: ['A = સાંધવાની ; B = થીગડું']
    },
    {
      question: 'પુદગલની કરામત તો _ ની કરામત છે.',
      options: ['ચેતન', 'દુનિયા', 'જાદુગર', 'જડ'],
      answer: ['જડ']
    },
    {
      question: 'દાદાશ્રીના નાનપણમાં ખેતરોમાં પંપ કેમ મૂકતાં હતા?',
      options: [
        'નાહવા માટે',
        'પાણી પાકને પીવડાવા માટે',
        'લોકો માટે',
        'બોઈલરનું પાણી ઠંડું કરવા માટે'
      ],
      answer: ['બોઈલરનું પાણી ઠંડું કરવા માટે']
    }
  ],
  navratri: [
    {
      question: 'દાદાજી ની માતાનું નામ શું હતું?',
      options: ['હીરાબા', 'અંબામાં', 'ઝવેરબા', 'દિવાળીબા'],
      answer: ['ઝવેરબા'],
      preview: {
        type: 'video',
        title: 'Bonus Video - Dada Mandir',
        id: '-YJ7HtPag_U'
      }
    },
    {
      question: '_ ઓગાળવા માટે માતાજી ની ભક્તિ બહુ જરૂરી છે.',
      options: ['ગુસ્સો', 'પ્રકૃતિ', 'કષાયો', 'કર્મો'],
      answer: ['પ્રકૃતિ']
    },
    {
      question: 'માતાજી દાદા પર શાથી ખુશ હોય?',
      options: [
        'દાદા પાસે સંસારનાં દુઃખ ઓછા થાય છે',
        'દાદા અંબેમાંની ભક્તિ કરાવે છે',
        'દાદા પાસે મોક્ષે જ જવાની વાત હોય',
        'આપેલા તમામ'
      ],
      answer: ['દાદા પાસે મોક્ષે જ જવાની વાત હોય']
    },
    {
      question: 'દાદા પોતાને કોના લાલ કહેતા હતા?',
      options: ['સરસ્વતી માતા', 'લક્ષ્મી માતા', 'મહાકાળી માતા', 'અંબે માતા'],
      answer: ['અંબે માતા']
    },
    {
      question: 'સરસ્વતી માતા પાસે શું માંગવાનું?',
      options: [
        'સ્યાદવાદ વાણી',
        'સ્યાદવાદ વર્તન',
        'સ્યાદવાદ મનન',
        'આપેલા તમામ'
      ],
      answer: ['સ્યાદવાદ વાણી']
    },
    {
      question: 'માતૃ ભક્તિ શું ચોખ્ખું કરે?',
      options: ['સ્વભાવ', 'મન', 'સંસાર', 'વ્યવહાર'],
      answer: ['વ્યવહાર']
    },
    {
      question:
        'કોની પ્રાર્થના કરવાથી પ્રામાણિક, નીતિ અને હક્ક વાળા પૈસા ઘરમાં આવે?',
      options: ['સરસ્વતી માતા', 'લક્ષ્મી માતા', 'મહાકાળી માતા', 'અંબે માતા'],
      answer: ['લક્ષ્મી માતા']
    },
    {
      question: 'અંબામાં પાસે શું શક્તિ માંગવાની છે?',
      options: [
        'સમતામાં રહી કર્મો પુરા કરું',
        'અભ્યુદય અને આનુષંગિક ફળ પ્રાપ્ત કરું',
        'આવી પડેલા કર્મોનું નિકાલ કરું',
        'સેવા કરી સેવ્ય પદ પ્રાપ્ત કરું'
      ],
      answer: ['સમતામાં રહી કર્મો પુરા કરું']
    },
    {
      question: 'દાદા શું લઇ ને અંબા માતાના દર્શન કરવા જવાનું કહેતા?',
      options: ['તાર', 'પ્રસાદ', 'ચિઠ્ઠી', 'આરતી'],
      answer: ['ચિઠ્ઠી']
    },
    {
      question: 'અંબામાં ને કયું વાક્ય લાગુ નથી પડતું?',
      options: [
        'આદ્યશક્તિ સ્વરૂપ છે.',
        'પ્રકૃતિને સહજ કરવા માટે છે',
        'કષાયોને હળવા કરવા માટે છે',
        'સ્યાદવાદ વાણી માંગવા માટે છે'
      ],
      answer: ['સ્યાદવાદ વાણી માંગવા માટે છે'],
      preview: {
        type: 'video',
        title: 'Bonus Video : Importance of Garba & Aarti',
        id: 'K-Kacn5vh4o'
      }
    },
    {
      question: 'સરસ્વતી માતા પાસે બીજું શું માંગવાનું?',
      options: [
        'મન-વચન-કાયાથી ચોરી ન કરું',
        'મન-વચન-કાયાથી અહિંસા પાળું',
        'મન-વચન-કાયાથી જુઠ્ઠું ન બોલું',
        'આપેલા તમામ'
      ],
      answer: ['મન-વચન-કાયાથી જુઠ્ઠું ન બોલું']
    },
    {
      question: 'દરેકનો હેતુ કેવો હોવો જોઈએ?',
      options: [
        'છૂટવા માટે, મોક્ષ માટે',
        'માતાજીની ભક્તિ થાય',
        'પ્રકૃતિમાં થી છૂટવાનો',
        'એક પણ નહીં'
      ],
      answer: ['છૂટવા માટે, મોક્ષ માટે']
    },
    {
      question: 'જ્ઞાન લીધા પછી માતાજીને કયા હેતુ થી, લક્ષ્યથી પગે લાગવાનું?',
      options: [
        'સદબુદ્ધિ આપો',
        'સુખ-સંપત્તિ આપો',
        'પ્રકૃતિને સહજ કરી એમાંથી છૂટવા',
        'આપેલા તમામ'
      ],
      answer: ['પ્રકૃતિને સહજ કરી એમાંથી છૂટવા']
    },
    {
      question: 'માતાજી કોના માટે ખડે પગે હાજીર છે?',
      options: [
        'જે પારકાને સુખ આપે છે',
        'જે બીજાને દુઃખ નથી આપતો',
        'જે મોક્ષનું કામ કરે છે',
        'આપેલા તમામ'
      ],
      answer: ['જે મોક્ષનું કામ કરે છે']
    },
    {
      question: 'અંબા માતાને શું કહેવામાં આવે છે?',
      options: ['આદ્યશક્તિ', 'વિદ્યાશક્તિ', 'આરાધ્ય શક્તિ', 'પ્રાકૃતિક શક્તિ'],
      answer: ['આદ્યશક્તિ']
    }
  ],
  'akram-express-jul-2017': [
    {
      question: 'ડેડી જોડે શેની ટિકિટ હતી?',
      options: ['સર્કસ', 'કૉમેડી શો', 'મેજિક શો', 'મૂવી'],
      answer: ['મેજિક શો']
    },
    {
      question:
        'દાદાના મોટા ભાઈએ દાદાને વહેલી તકે, _A_ થી _B_ રકમ લઇ આવવાનું કહેવડાવ્યું હતું.',
      options: [
        'A = બરોડા ; B = અમદાવાદ',
        'A = ભાદરણ ; B = અમદાવાદ',
        'A = ભાદરણ ; B = બરોડા',
        'A = અમદાવાદ ; B = બરોડા'
      ],
      answer: ['A = ભાદરણ ; B = બરોડા']
    },
    {
      question: 'મમ્મી-પપ્પા ગયા પછી પ્રીતિ એ શું કર્યું?',
      options: [
        'હોમવર્ક કરવા સ્ટડી ટેબલ પર બેસી ગઈ',
        'ફોને લઇને સોફા ના એક ખૂણા પર ગોઠવાઈ',
        'સોફા પર બેસી, ટી.વી. ઓન કરી ચેનલ બદલવા લાગી',
        'બેહેનો જોડે ઝઘડવા માંડી'
      ],
      answer: ['સોફા પર બેસી, ટી.વી. ઓન કરી ચેનલ બદલવા લાગી']
    },
    {
      question: 'સફાઈ કરતા _A_ ને _B_ મળ્યું/મળ્યા.',
      options: [
        'A = ગૌરી ; B = ૧ કવર',
        'A = ગૌરી ; B = ૩ કવર',
        'A = ચેતના ; B = ૩ કવર',
        'A = પ્રીતિ ; B = ૧ કવર'
      ],
      answer: ['A = ગૌરી ; B = ૩ કવર']
    },
    {
      question: 'નેલ્સન મંડેલા જેલમાં કેટલા વર્ષ કેદી રહ્યા હતા?',
      options: ['૨૦', '૨૪', '૨૭', '૨૯'],
      answer: ['૨૭']
    },
    {
      question: 'જીન શીલ ને કઈ સોનેરી ચાવીઓ આપે છે?',
      options: [
        'એડજસ્ટ એવરીવ્હેર',
        'અથડામણ ટાળો',
        'ભોગવે તેની ભૂલ',
        'એક અને બે બંને'
      ],
      answer: ['A અને B બંને']
    },
    {
      question: 'કામથી બહાર જતી વખતે મમ્મીએ બેહેનો ને શું કીધું?',
      options: [
        'લંચ બનાવવાનું',
        'ઘર સાફ કરવાનું',
        'હોમવર્ક કરવાનું',
        'આપેલા તમામ'
      ],
      answer: ['ઘર સાફ કરવાનું'],
      preview: {
        type: 'video',
        title: 'Bonus Video #1: મમ્મી સાથે એડજસ્ટમેન્ટ',
        id: 'W0ZXUfYFSL8'
      }
    },
    {
      question:
        'બળભદ્ર શેઠ ના ૪ દીકરા હતા. બધા દીકરા ગુણવાન તથા બુદ્ધિમાન હતા.',
      options: ['સાચું', 'ખોટું'],
      answer: ['ખોટું']
    },
    {
      question: 'ગૌરી _A_ પર બેસી, અને એની નજર _B_ પર પડી.',
      options: [
        'A = સ્ટડી ટેબલ ચેર ; B = તૂટેલી ફોટો ફ્રેમ',
        'A = સોફા ; B = તૂટેલી ફોટો ફ્રેમ',
        'A = સોફા ; B = ટી.વી',
        'A = સ્ટડી ટેબલ ચેર ; B = ફાટેલી ચોપડી'
      ],
      answer: ['A = સ્ટડી ટેબલ ચેર ; B = તૂટેલી ફોટો ફ્રેમ']
    },
    {
      question: 'ઝવેરીએ દરેક રત્નોની કિંમત કેટલી આંકી?',
      options: [
        'એકસો કરોડ મુદ્રા',
        'પાંચ કરોડ મુદ્રા',
        'બે કરોડ મુદ્રા',
        'એક કરોડ મુદ્રા'
      ],
      answer: ['એક કરોડ મુદ્રા']
    },
    {
      question: 'નાગદેવતાએ રત્નપેટીમાં ૩ રત્નોમાંથી ૧ રત્ન મોમાં લઇ ગયા.',
      options: ['સાચું', 'ખોટું'],
      answer: ['સાચું']
    },
    {
      question:
        'નેલ્સન મંડેલા ને દ્રઢ વિશ્વાસ હતો કે દેશનું સંગઠન શેના નિમિત્તે શક્ય છે?',
      options: ['ક્રિકેટ મેચ', 'ફૂટબૉલ મેચ', 'રગ્બી મેચ', 'એક પણ નહી'],
      answer: ['રગ્બી મેચ']
    },
    {
      question: 'શીલ ના પગ માં શું ટકરાય છે?',
      options: [
        'પ્લાસ્ટિક નો લૅમ્પ',
        'સ્ટીલ નો લૅમ્પ',
        'કાચ નો લૅમ્પ',
        'ચાંદી નો લૅમ્પ'
      ],
      answer: ['કાચ નો લૅમ્પ'],
      preview: {
        type: 'video',
        title: 'Bonus Video #2: बड़ो के साथ एडजस्टमेंट',
        id: 'tdHsWIHVNpk'
      }
    },
    {
      question: 'મમ્મી-પપ્પા ગયા પછી ચેતના એ શું કર્યું?',
      options: [
        'બેહેનો જોડે ઝગડવા મંડી',
        'સોફા પર બેસી, ટી.વી ઓન કરી ચેનલ બદલવા લાગી',
        'હોમવર્ક કરવા સ્ટડી ટેબલ પર બેસી ગઈ',
        'ફોને લઇને સોફા ના એક ખૂણા પર ગોઠવાઈ'
      ],
      answer: ['ફોને લઇને સોફા ના એક ખૂણા પર ગોઠવાઈ']
    },
    {
      question: 'હોશિરાયી વાપરીને દાદાએ વધારે પૈસા કોના ખીસામાં રાખ્યા?',
      options: ['પોતાના', 'ભાઈબંધ', 'નોકર', 'મોટા ભાઈ'],
      answer: ['નોકર']
    },
    {
      question: 'ઘરમાં એકબીજા સાથે એડજસ્ટમેન્ટ લેવાથી શું થાય?',
      options: [
        'ભવિષ્ય માં બીજા લોકો સાથે એડજસ્ટમેન્ટ થતા ફાવશે',
        'પ્રોબ્લેમ સોલ્વ કરવાની શક્તિ ખીલશે',
        'કોમન સેન્સે ખીલશે',
        'આપેલા તમામ'
      ],
      answer: ['આપેલા તમામ'],
      preview: {
        id: 'akram-express-july-2017-16.png'
      }
    },
    {
      question:
        '૧૯૮૨ ના સત્સંગ ના પ્રસંગમાં દાદા એ સામાના મનનું સમાધાન કેવી રીતે કર્યું?',
      options: [
        'આશિર્વદ અને વિધિથી',
        'વચનબળ અને કોમનસેન્સથી',
        'આગવી સમજ અને કોમનસેન્સથી',
        'આગવી સમજ અને વચનબળ'
      ],
      answer: ['આગવી સમજ અને કોમનસેન્સથી']
    },
    {
      question: 'ઊંચી કોમનસેન્સ વાળા લોકોમાં કયો એક ગુણ સામાન્ય છે?',
      options: ['સમજણ', 'સરળતા', 'એડજસ્ટમેન્ટ', 'અહંકાર રહિત'],
      answer: ['એડજસ્ટમેન્ટ']
    },
    {
      question: 'કોઈના માં કોમનસેન્સ ઓછી છે એવી ક્યારે ખબર પડે?',
      options: [
        'જયારે એ ઝગડો કરે ત્યારે',
        'જે મતભેદ પાડે એ',
        'જે મતભેદ ના પાડે એ',
        'આપેલા તમામ'
      ],
      answer: ['જે મતભેદ પાડે એ'],
      preview: {
        type: 'video',
        title: 'Bonus Video #3: પત્ની સાથે એડજસ્ટમેન્ટ',
        id: 'KenbEwQWYag'
      }
    },
    {
      question:
        'એન્યુઅલ ડે માટે શીલ, ગાર્ગી અને તન્વી શાના પર ડ્રામા કરવાના હોય છે?',
      options: ['ફ્રોઝન', 'બ્યુટી એન્ડ ધ બીસ્ટ', 'રામાયણ', 'અલાદીન'],
      answer: ['બ્યુટી એન્ડ ધ બીસ્ટ']
    },
    {
      question:
        'આ વાક્ય કોણ બોલે છે? - "આપણને કેવી ભઠ્ઠી આપી છે પ્રેકટીસ કરવા અને સિનિયર્સને તો એ.સી. વળી રૂમ મળી છે."',
      options: ['તન્વી', 'શીલ', 'ગાર્ગી', 'એક પણ નહી'],
      answer: ['તન્વી']
    },
    {
      question: 'કોનું વાગ્યુદ્ધ ચાલુ થાય છે ડ્રામા ને લઇને?',
      options: [
        'ગાર્ગી અને તન્વી',
        'ગાર્ગી, શીલ અને તન્વી',
        'તન્વી અને શીલ',
        'ગાર્ગી અને શીલ'
      ],
      answer: ['ગાર્ગી અને શીલ']
    },
    {
      question: 'લૅમ્પ માંથી કોણ નીકળે છે?',
      options: [
        'અલાદીન નો જીન',
        'સુંદર અને યુવાન જીન',
        'દુબળો અને વૃદ્ધ જીન',
        'દુબળો પણ યુવાન જીન'
      ],
      answer: ['દુબળો અને વૃદ્ધ જીન']
    },
    {
      question: 'શું કરવા થી કોઈ પણ સમસ્યામાં ના ફસાવાય?',
      options: [
        'એડજસ્ટ થવાથી',
        'કોમનસેન્સ વાપરવાથી',
        'અથડામણ ટાળવાથી',
        'એક અને ત્રણ બંને'
      ],
      answer: ['કોમનસેન્સ વાપરવાથી']
    },
    {
      question: 'કોમનસેન્સ કેવી રીતે વધે?',
      options: [
        'મિલનસાર સ્વભાવ થી',
        'બધા જોડે હળીમળીને રહેવા થી',
        'તિરસ્કાર કાઢી નાખવાથી',
        'આપેલા તમામ'
      ],
      answer: ['આપેલા તમામ'],
      preview: {
        type: 'video',
        title: 'Bonus Video #4: એડજસ્ટમેન્ટથી વધે શક્તિ',
        id: 'Kbzjm29mLYg'
      }
    }
  ],
  'dadai-vision-bhakti-pad': [
    {
      question: '_ સાથે થાજો દાદાઈ વિઝન.',
      options: ['શુદ્ધાત્મા', 'પરમાત્મા', 'આત્મા', 'આતમરામ'],
      answer: ['આત્મા']
    },
    {
      question: '_ બધાને ખૂબ રહે કરવા દાદાના કામ...',
      options: ['જોશ', 'હોંશ', 'જુસ્સો', 'જોમ'],
      answer: ['હોંશ']
    },
    {
      question: 'જુદી જુદી ડીરેક્શન કોણ દેખાડે છે?',
      options: ['બ્લેક બોર્ડ', 'બોર્ડ', 'ગુરુ', 'સાઈન બોર્ડ'],
      answer: ['સાઈન બોર્ડ']
    },
    {
      question: 'કોણ આકાશમાં ગુંલાટો ખાય છે?',
      options: ['હવા', 'હવાઈ જહાજ', 'પતંગ', 'પક્ષી'],
      answer: ['પતંગ']
    },
    {
      question: 'કઈ પઝલના ટુકડા એકલા નકામા લાગે?',
      options: ['જીગ્સો', 'સુડોકુ', 'ક્રોસવર્ડ', 'રુબીક ક્યુબ'],
      answer: ['જીગ્સો']
    },
    {
      question: 'મહાત્માઓ ભાવથી કયા કાર્યો કર્યે જાય છે?',
      options: ['સેવાના', 'જગત કલ્યાણના', 'મંદિરના', 'મહાત્માઓના'],
      answer: ['જગત કલ્યાણના'],
      preview: {
        type: 'video',
        title: "Bonus Audio #1: વારસ અહો ! 'મહાવીર'ના (દાદા શ્રીમુખે)",
        id: 'xg_fN82kowk'
      }
    },
    {
      question: 'જો દાદાઈ વિઝન મળે તો દાદાઈ ભાવોનું શું થાય?',
      options: ['સંપૂર્ણ થાય', 'સંતોષાય જાય', 'પરિપૂર્ણ થાય', 'વધતા જાય'],
      answer: ['પરિપૂર્ણ થાય']
    },
    {
      question: 'વિઝન પ્રમાણે હવે શું સાંધવાનું છે?',
      options: ['બાણ', 'તીર', 'ધ્યેય', 'લક્ષ'],
      answer: ['લક્ષ']
    },
    {
      question: 'દાદાઈ વિઝન સ્ટેટમેન્ટ શું છે?',
      options: [
        'પ્રેમથી રહેજો, પ્રોમીસ?',
        'આખા જગતનું કલ્યાણ થાવ',
        'જગત પરમ શાંતિને પામો, કેટલાક મોક્ષ ને પામો',
        'જગતમાં પરમ શાંતિ રહે, કષાય રહિત વ્યવહાર થાય'
      ],
      answer: ['જગત પરમ શાંતિને પામો, કેટલાક મોક્ષ ને પામો'],
      preview: {
        type: 'video',
        title: 'Bonus Video #1: વારસ અહો દાદાના ',
        id: 'qG_roCzElkc'
      }
    },
    {
      question:
        'દાદાઈ વિઝન પૂરું કરવા માટે મહાત્માઓ પાસે નીચેનામાંથી કઈ વેલ્યુ હોવી જોઈએ?',
      options: ['પ્યોરીટી', 'સ્વાધીનતા', 'આધીનતા ગુરુની', 'નિર્મોહી'],
      answer: ['પ્યોરીટી']
    },
    {
      question:
        'દાદાઈ વિઝન પૂરું કરવા માટે મહાત્માઓ પાસે નીચેનામાંથી કઈ વેલ્યુ હોવી જોઈએ?',
      options: ['કર્તાભાવ', 'અહંકાર રહિત', 'કષાય રહિત વ્યવહાર', 'નિરાહારી'],
      answer: ['કષાય રહિત વ્યવહાર']
    },
    {
      question:
        'દાદાઈ વિઝન પૂરું કરવા માટે મહાત્માઓ પાસે નીચેનામાંથી કઈ વેલ્યુ હોવી જોઈએ?',
      options: [
        'આધીનતા માતા-પિતાની',
        'આધીનતા પ્રત્યક્ષ જ્ઞાનીની',
        'આધીનતા પ્રત્યક્ષ ગુરુની',
        'આધીનતા સીમંધર સ્વામીની'
      ],
      answer: ['આધીનતા પ્રત્યક્ષ જ્ઞાનીની']
    },
    {
      question:
        'દાદાઈ વિઝન પૂરું કરવા માટે મહાત્માઓએ કેવા ભાવ સાથે તૈયાર થવું પડશે?',
      options: ['શુદ્ધભાવ', 'મોહભાવ', 'વીતરાગ ભાવ', 'અકર્તા  ભાવ'],
      answer: ['અકર્તા  ભાવ']
    },
    {
      question: 'વ્યવહાર _A_ રહિતનો ને _B_ રહે પાયામાં...',
      options: [
        'A = પ્યોરીટી ; B = કષાય',
        'A = પ્યોરીટી ; B = અકર્તા ભાવ',
        'A = કર્તાભાવ ; B = પ્યોરીટી',
        'A = કષાય ; B = પ્યોરીટી'
      ],
      answer: ['A = કષાય ; B = પ્યોરીટી']
    },
    {
      question: 'વિઝન પ્રમાણે હવે _ વાળીશું અમે.',
      options: ['વ્યક્તિ', 'શક્તિ', 'ગાડી', 'પ્રવાહ'],
      answer: ['શક્તિ']
    },
    {
      question: 'વિઝન સ્ટેટમેન્ટ પ્રમાણે _ અડીશું અમે...',
      options: ['ધ્યેયને', 'રસ્તાને', 'મંજિલને', 'ચરણને'],
      answer: ['મંજિલને']
    },
    {
      question: 'વિઝન પ્રમાણે હવે _ સાધીશું અમે...',
      options: ['મૂલ્યો', 'વેલ્યુઝ', 'ધ્યેય', 'અકર્તા ભાવ'],
      answer: ['મૂલ્યો']
    },
    {
      question: 'વિઝન પ્રમાણે હવે આપણે એકબીજાના શું બનીશું?',
      options: ['ભાઈ-બહેન', 'સહભાગી', 'મિત્ર', 'પૂરક'],
      answer: ['પૂરક']
    }
  ],
  'adjust-everywhere': [
    {
      question:
        'ધણી-ધણિયાણી એક બીજા જોડે "એડજસ્ટ" કરવાનો નિશ્ચય કરે તો શું થાય?',
      options: [
        'બન્ને વચ્ચે સંપ વધે',
        'બન્નેનો ઉકેલ આવે',
        'ઝઘડા બંધ થાય',
        'પ્રેમ વધે'
      ],
      answer: ['બન્નેનો ઉકેલ આવે']
    },
    {
      question: 'એક માણસ નો હાથ દુ:ખતો હતો તો એણે કેવી રીતે એડજસ્ટમેન્ટ લીધું?',
      options: [
        'બીજાને કહી ને એડજસ્ટમેન્ટ લીધું',
        'બીજા કોઈ ની મદદ લઇને એડજસ્ટમેન્ટ લીધું',
        'પોતાના બીજા હાથથી દબાવીને એડજસ્ટમેન્ટ લીધું',
        'દવા લઈને એડજસ્ટમેન્ટ લીધું'
      ],
      answer: ['પોતાના બીજા હાથથી દબાવીને એડજસ્ટમેન્ટ લીધું']
    },
    {
      question: 'એવું એડજસ્ટમેન્ટ કરવું કે _.',
      options: [
        'ઉકેલ આવે',
        'લાભ થાય',
        'સુખ શાંતિ અને સંપત્તિ આવે',
        'આપેલા તમામ'
      ],
      answer: ['ઉકેલ આવે']
    },
    {
      question: 'ગાંડા કેમ થવાય?',
      options: [
        'ડાહ્યા ન રહેવાથી',
        'એડજસ્ટ એવરીવ્હેર ન કરવા થી',
        'સામાને છંછેડવાથી',
        'B અને C'
      ],
      answer: ['B અને C']
    },
    {
      question:
        'જેને "એડજસ્ટ એવરીવ્હેર" ની કળા આવડી, તે દુનિયામાંથી મોક્ષ તરફ વળ્યો.',
      options: ['સાચું', 'ખોટું'],
      answer: ['સાચું']
    },
    {
      question: 'જેણે _ માં થી છૂટવું હોય, તેણે એડજસ્ટ થઇ જ જવું જોઈએ.',
      options: [
        'જન્મ-મરણ માંથી',
        'સંસાર ઘટમાળ માંથી',
        'ઘર માંથી',
        'આપેલા તમામ'
      ],
      answer: ['સંસાર ઘટમાળ માંથી']
    },
    {
      question: 'જો એક ફાડ ફાડ કરતું હોય તો બીજાને શું કરવું જોઈએ?',
      options: ['એડજસ્ટ કરવું', 'વધારે ફાડવું', 'સાંધી દેવું', 'A અને C'],
      answer: ['A અને C']
    },
    {
      question:
        'જેવો _A_ હોય તેવો સ્વભાવ થઇ જાય. _B_ માં બધા એક મેળ રહ્યા કરે. _C_ માં દાદાજી કહે તો ચોપડાવી દે.',
      options: [
        'A = યુગ ; B = સતયુગ ; C = સતયુગ',
        'A = કળિયુગ ; B = યુગ ; C = સતયુગ',
        'A = યુગ ; B = સતયુગ ; C = કળિયુગ',
        'A = યુગ ; B = કળિયુગ ; C = સતયુગ'
      ],
      answer: ['A = યુગ ; B = સતયુગ ; C = કળિયુગ']
    },
    {
      question: 'વાઇફે જમવાનું બનાવ્યું હોય, તેમાં ભૂલ કાઢવી એ _.',
      options: ['મિસ્ટેક', 'બ્લન્ડર્સ', 'ગુનો', 'એક પણ નહીં'],
      answer: ['બ્લન્ડર્સ']
    },
    {
      question: 'શક્તિ ક્યાં કેળવવાની છે?',
      options: [
        'જેની જોડે ફાવે છે ત્યાં',
        'સુંવાળા જોડે',
        'જેની જોડે ન ફાવે ત્યાં',
        'આપેલા તમામ'
      ],
      answer: ['જેની જોડે ન ફાવે ત્યાં'],
      preview: {
        type: 'video',
        title: 'Bonus Video #1: મમ્મી સાથે એડજસ્ટમેન્ટ',
        id: 'W0ZXUfYFSL8'
      }
    },
    {
      question: 'જગતમાં  એડજસ્ટ થવાનું કારણ દાદાશ્રી એ આપે છે કે _.',
      options: [
        'અથડામણથી તમે તમારી જાતને નુકશાન કરો છો',
        'સામાનું નુકશાન કરો છો',
        'દરેક વસ્તુ એન્ડવાળી હોય છે',
        'આપેલા તમામ'
      ],
      answer: ['આપેલા તમામ']
    },
    {
      question: 'સામાને સમજાવીએ પછી એ ના સમજે તો શું થાય?',
      options: [
        'આપણી જવાબદારી એટલી જ છે',
        'આપણે પ્રાથના કરવી કે હે દાદા ભગવાન આને સદબુદ્ધિ આપજો',
        'ના સમજે તો વઢી નાખવાનું',
        'A અને B'
      ],
      answer: ['A અને B']
    },
    {
      question: 'ડિસ-એડજસ્ટમેન્ટ એ જ  _.',
      options: ['નાલાયકી', 'મૂર્ખાઈ', 'વ્યવસ્થિત', 'ન્યાયસ્વરૂપ છે'],
      answer: ['મૂર્ખાઈ']
    },
    {
      question: 'ડિસ-એડજસ્ટમેન્ટ થવાનું કારણ છે કે _.',
      options: [
        'વાતાવરણ કળિયુગી છે',
        'મારું ધણીપણું હું છોડું નહીં',
        'મારું ચલણ રહેવું જ જોઈએ',
        'B અને C'
      ],
      answer: ['B અને C']
    },
    {
      question: 'બૈરી કહે છે કે "તમે નાલાયક છો" તો શું કહેવું?',
      options: [
        'તારી વાત ખોટી છે',
        'તું નાલાયક છું, હું નહીં',
        'બધા મને ડાહ્યો કહે છે',
        'બહુ સારું'
      ],
      answer: ['બહુ સારું']
    },
    {
      question: 'દાદાશ્રી કહે છે કે સારો વ્યવહાર તો એનું નામ કહેવાય કે.....',
      options: [
        'શુદ્ધ ઉપયોગમાં રહી વ્યવહાર કરે',
        'ખોટું ચલાવી ન લઈએ',
        'પાડોશી કહે કે "બધા ઘેર ઝઘડા છે, આ ઘેર ઝઘડા નથી."',
        'વાંકા સાથે વાંકાં, સીધા સાથે સીધા રહીયે'
      ],
      answer: ['પાડોશી કહે કે "બધા ઘેર ઝઘડા છે, આ ઘેર ઝઘડા નથી."']
    },
    {
      question: 'ના ફાવે એ તો _ છે.',
      options: ['વ્યવસ્થિત', 'સામો વાંકો', 'આપણી નબળાઈ', 'A અને B'],
      answer: ['આપણી નબળાઈ']
    },
    {
      question: 'સાચી સમજણ કયારે થશે?',
      options: [
        'બધી ઊંધી સમજણ નીકળી જશે',
        'સામો આપણી વાત માનશે',
        'સામાને દુઃખ નહીં થાય',
        'સામાનું ખોટું ચલાવી લઈશું'
      ],
      answer: ['બધી ઊંધી સમજણ નીકળી જશે']
    },
    {
      question: 'શું કરવાથી બધી જાતના એડજસ્ટમેન્ટ આવડી જાય?',
      options: [
        'શક્તિઓ કેળવવાથી',
        'સાચી સમજણ મેળવવાથી',
        'જ્ઞાની પુરુષને જોઈને ચાલીએ',
        'નિ:સ્પૃહ થઇ જઇએ તો'
      ],
      answer: ['જ્ઞાની પુરુષને જોઈને ચાલીએ']
    },
    {
      question: 'સ્ટેશન પર મજૂર જોઈતો હોય અને તે આનાકાની કરતો હોય તો?',
      options: [
        'જાતે સમાન ઊંચકી લેવો',
        '૪ રૂપિયા ઓછા-વત્તા કરી મનાવી લેવો',
        'તેને કાયદા બતાવવા',
        'તેની સાથે ઝઘડો કરવો'
      ],
      answer: ['૪ રૂપિયા ઓછા-વત્તા કરી મનાવી લેવો'],
      preview: {
        type: 'video',
        title: 'Bonus Video #2: પત્ની સાથે એડજસ્ટમેન્ટ',
        id: 'KenbEwQWYag'
      }
    },
    {
      question: 'દાદાશ્રી કહે છે, "ડોન્ટ સી લૉઝ, પ્લીઝ _."',
      options: ['સમભાવ રાખો', 'સહન કરો', 'સેટલ', 'ફરિયાદ કરો'],
      answer: ['સેટલ']
    },
    {
      question: 'દાદાશ્રી આ કાળને માટે કહે છે કે _.',
      options: [
        'છેલ્લે પાટલે આવી ગયેલું છે',
        'લોક ગૂંચાઈ ગયેલા છે',
        'ક્યાંય નિરાંત નહીં',
        'આપેલા તમામ'
      ],
      answer: ['આપેલા તમામ']
    },
    {
      question: 'દાદાશ્રી ખાસ કહે છે કે _ એડજસ્ટ થતાં આવડવું જોઈએ.',
      options: ['ઘરમાંય', 'ઓફિસમાંય', 'મિત્રો સાથે', 'સત્સંગમાંય'],
      answer: ['ઘરમાંય']
    },
    {
      question:
        'તે દહાડે સત્તામાં આવ્યો ત્યારે ફરિયાદો ખુબ કરી હતી તેમાં કોનો દાખલો આપે છે?',
      options: [
        'રાજા પાસે પ્રજા ખૂબ ફરિયાદો કરે છે, આજે સહન નથી થાતું',
        'બોસ પાસે ફરિયાદ કરે પણ કંઈ વળે નહીં',
        'બળદને ઘોંચ મારે હેંડવા માટે ને તે મૂંગું પ્રાણી ફરિયાદ ન કરે',
        'આપેલા તમામ'
      ],
      answer: ['બળદને ઘોંચ મારે હેંડવા માટે ને તે મૂંગું પ્રાણી ફરિયાદ ન કરે']
    },
    {
      question: 'દાદાશ્રી ફરિયાદી થવા કરતાં શું કરવાનું કહે છે?',
      options: [
        'હવે પ્લસ-માઈનસ કરી નાંખો',
        'સામો ગાલ ભાંડે તો તેણે જમા કરી દેવાનું',
        'પહેલેથી જ એડજસ્ટ થઇ જવાનું',
        'આપેલા તમામ'
      ],
      answer: ['આપેલા તમામ']
    },
    {
      question: 'વ્યવહારમાં એડજસ્ટમેન્ટ લેવું તેણે આ કાળમાં શું કહ્યું?',
      options: ['વિજ્ઞાન', 'સદવ્યવહાર', 'સહનશીલતા', 'જ્ઞાન'],
      answer: ['જ્ઞાન']
    },
    {
      question: 'આપણે કોઈની જોડે અવળું બોલી જવાય તો ત્યાં શું ઉપાય કરવો?',
      options: [
        'આપણી ભૂલ થઇ ખબર પડે પછી તરત જઈને કહેવું કે મારી ભૂલ થઇ માટે માફ કરજે',
        'ત્યાં બન્યું તે જ ન્યાય કહી દેવું',
        'ભોગવે તેની ભૂલ છે કહી દેવું',
        'ત્યાં સામાને એડજસ્ટ થવાનું કહી દેવું'
      ],
      answer: [
        'આપણી ભૂલ થઇ ખબર પડે પછી તરત જઈને કહેવું કે મારી ભૂલ થઇ માટે માફ કરજે'
      ]
    },
    {
      question: 'દાદાશ્રી એડજસ્ટમેન્ટ બે નહીં પણ કેટલા જણ સાથે લઇ શકાય કહે છે?',
      options: ['પંદર', 'સાત', 'ચાર', 'સો'],
      answer: ['સાત']
    },
    {
      question:
        'દાદાશ્રી બધાને "હા, બા તારા કહ્યા પ્રમાણે કરીશું" કહેવાનું શા માટે કહે છે?',
      options: [
        'વ્યવસ્થિતની બહાર થવાનું નથી',
        'ગમે તે રીતે ઝઘડો ન ઊભો કરશો',
        'બધાને ખુશ રાખવા માટે',
        'A અને B'
      ],
      answer: ['A અને B']
    },
    {
      question: 'એડજસ્ટમેન્ટ લેવા ને ઝઘડો ટાળવા દાદાશ્રી શું સૂત્ર આપે છે?',
      options: [
        'બન્યું તે જ ન્યાય',
        'ફરિયાદ ન કરવી',
        'હા કહ્યું તો મુક્તિ છે, પણ "ના" કહ્યું તો મહાઉપાધિ',
        'સામાને સદબુદ્ધિ મળે તેવી પ્રાર્થના કરવી'
      ],
      answer: ['હા કહ્યું તો મુક્તિ છે, પણ "ના" કહ્યું તો મહાઉપાધિ'],
      preview: {
        type: 'video',
        title: 'Bonus Video #3: એડજસ્ટમેન્ટથી વધે શક્તિ',
        id: 'Kbzjm29mLYg'
      }
    }
  ],
  ghantnaad: [
    {
      question: 'ભવ્ય મંદિર કયાં હતું?',
      options: ['ગામ માં', 'શહેર માં', 'નદી કિનારે', 'ટેકરી પર'],
      answer: ['ગામ માં']
    },
    {
      question: 'મંદિર વિષે શું કહેવાતું હતું?',
      options: [
        'મીઠાઈ ભગવાન ને અર્પણ થતી ત્યારે ઘંટ આપોઆપ વાગવા માંડતો',
        'નારિયેળ ભગવાન ને અર્પણ થતા ત્યારે ઘંટ આપોઆપ વાગવા માંડતો',
        'રૂપિયા ભગવાન ને અર્પણ થતા ત્યારે ઘંટ આપોઆપ વાગવા માંડતો',
        'સૌથી સારી ભેંટ ભગવાન ને અર્પણ થતી ત્યારે ઘંટ આપોઆપ વાગવા માંડતો'
      ],
      answer: [
        'સૌથી સારી ભેંટ ભગવાન ને અર્પણ થતી ત્યારે ઘંટ આપોઆપ વાગવા માંડતો'
      ]
    },
    {
      question: 'ઘણા વરસોથી શું વાગ્યું ન હતું?',
      options: ['નગારું', 'ઘંટ', 'પીપુડી', 'મંજીરા'],
      answer: ['ઘંટ']
    },
    {
      question: 'બેસતા વર્ષને દિવસે લોકો કયાં જતા હતા?',
      options: ['હિલ સ્ટેશન', 'સગા સંબંધી ને ત્યાં', 'મંદિર', 'મસ્જીદ'],
      answer: ['મંદિર']
    },
    {
      question: 'લોકો મંદિરમાં જઈ શું કરતા હતા?',
      options: ['દર્શન', 'ભજન', 'વિધિ', 'વાંચન'],
      answer: ['દર્શન']
    },
    {
      question: 'ભેંટ ચઢાવતી વખતે લોકોના મનમાં શું વિચાર આવતો?',
      options: [
        'પોતાની નામના વધવાનો',
        'પોતાનો લોભ તોડવાનો',
        'પોતાની માયા અર્પણ કરવાનો',
        'પોતાના માન ઓગાળવાનો'
      ],
      answer: ['પોતાની નામના વધવાનો']
    },
    {
      question: 'શેઠાણી મોંઘા ઘરેણાં કેમ ચઢાવે છે?',
      options: ['ગમે છે', 'ગમતા નથી', 'ભગવાન માટે', 'કામના નથી'],
      answer: ['ગમતા નથી']
    },
    {
      question: 'બાળક તેની મા ને શું પૂછે છે?',
      options: [
        'આપણે ભેંટ કેમ ચઢાવી જોઈએ?',
        'આપણે શું આપીશું?',
        'હું ઘંટ વગાડી શકું?',
        'આ બધા કેમ આપે છે?'
      ],
      answer: ['આપણે શું આપીશું?']
    },
    {
      question: 'મા શું જવાબ આપે છે કે આપણે _ આપીશું?',
      options: ['પેંડા', 'નાળિયેર', 'ફૂલ', 'સોનાનો સિક્કો '],
      answer: ['સોનાનો સિક્કો ']
    },
    {
      question: 'કઈ ભેંટ થી ઘંટ વાગ્યો?',
      options: ['પેંડા', 'ઘરેણાં', 'હાર', 'સિક્કો'],
      answer: ['સિક્કો']
    },
    {
      question: 'ગામ ના શેઠ ને શું શંકા થાય છે?',
      options: [
        'ઘરેણા થી ઘંટ વાગે કે નહિં ',
        'પહેલા ક્યારે ઘંટ વાગ્યો હશે ખરો',
        'ભગવાન ને ભેંટ અપાઈ કે નહિં ',
        'દર્શન કરવામાં લાભ થશે ખરો'
      ],
      answer: ['પહેલા ક્યારે ઘંટ વાગ્યો હશે ખરો']
    },
    {
      question: 'મંદિરે શાનો કાર્યક્રમ હતો?',
      options: ['દિવાળી', 'રક્ષા બંધન', 'દશેરા', 'બેસતું વર્ષ'],
      answer: ['બેસતું વર્ષ']
    },
    {
      question: 'શેઠ શું ભાવ થી સોનું ચઢાવે છે?',
      options: [
        'જરાઈ ઈચ્છા નથી, લોકો નાં કહેવાથી પરાણે આપું છું',
        'ખૂબ ઈચ્છા છે, લોકો એ પણ કહ્યું છે આપવાનું',
        'ખૂબ ઈચ્છા છે, પણ લોકો આપવા નથી દેતા',
        'જરાઈ ઈચ્છા નથી, પણ આજે હું આપું છું'
      ],
      answer: ['જરાઈ ઈચ્છા નથી, લોકો નાં કહેવાથી પરાણે આપું છું']
    },
    {
      question: 'કોની ભેંટ થી ઘંટ વાગ્યો?',
      options: ['બાળક', 'શેઠાણી', 'પુજારી', 'ખેડૂત'],
      answer: ['બાળક']
    },
    {
      question: 'કઈ ભેંટ થી ઘંટ વાગ્યો એ જોઈને લોકો ને શું થયું?',
      options: [
        'ખરાબ લાગ્યું',
        'ખૂબ આનંદ થયો',
        'આશ્ચર્યચકિત થયા',
        'એક પણ નહીં '
      ],
      answer: ['આશ્ચર્યચકિત થયા']
    },
    {
      question: 'પૂજારી નું શું કહેવું હતું?',
      options: [
        'ઘંટ સિક્કા ની કિંમત થી નહીં, એના કિંમતી તત્વથી વાગ્યો છે.',
        'ઘંટ સિક્કા ની કિંમત થી નહીં, એના અંદર શુદ્ધતા ની બનાવટથી વાગ્યો છે.',
        'ઘંટ સિક્કા ની કિંમત થી નહીં, છોકરાની અમારી પાસે માંગ ના કારણે વાગ્યો છે.',
        'ઘંટ સિક્કા ની કિંમત થી નહીં, છોકરાની અંદર ના શુદ્ધ ભાવ ના કારણે વાગ્યો છે.'
      ],
      answer: [
        'ઘંટ સિક્કા ની કિંમત થી નહીં, છોકરાની અંદર ના શુદ્ધ ભાવ ના કારણે વાગ્યો છે.'
      ]
    },
    {
      question: 'પૂજારી નું શું કહેવું હતું?',
      options: [
        'ભગવાન તમારી ક્રિયા અને ભાવ બંને જુએ છે.',
        'ભગવાન તમારી ક્રિયા નથી જોતા, ભાવ જુએ છે.',
        'ભગવાન તમારી ક્રિયા જુએ છે, ભાવ નથી જોતા.',
        'ભગવાન ક્રિયા અને ભાવ બંને નથી જોતા.'
      ],
      answer: ['ભગવાન તમારી ક્રિયા નથી જોતા, ભાવ જુએ છે.']
    },
    {
      question: 'પૂજારી નું શું કહેવું હતું?',
      options: [
        'અંદર ભાવ બગાડી નાખ્યે, તો ભગવાન પાસે કઈ જમા ન થાય.',
        'અંદર ભાવ બગાડી નાખ્યે, તો ભગવાન તમારી ક્રિયા જોશે.',
        'અંદર ભાવ બગાડી નાખ્યે, તો ભગવાન તમને માફ કરશે.',
        'અંદર ભાવ બગાડી નાખ્યે, તો ભગવાન પણ વાહ-વાહ કરશે.'
      ],
      answer: ['અંદર ભાવ બગાડી નાખ્યે, તો ભગવાન પાસે કઈ જમા ન થાય.']
    },
    {
      question: 'ગામના લોકો ને તેમની કઈ ભૂલ સમજાઈ ગઈ?',
      options: [
        'ઘંટ સિક્કા ની કિંમત થી નહીં, એના અંદર શુદ્ધતા ની બનાવટથી વાગ્યો છે.',
        'સ્પર્ધા માં જીતવાની લાલચ',
        'કીર્તિ ફેલાવવાની લાલચ',
        'પુજાવવાની લાલચ'
      ],
      answer: ['કીર્તિ ફેલાવવાની લાલચ']
    },
    {
      question: 'આ વાર્તા નો સારાંશ શું છે?',
      options: [
        'કોઈ પણ કામ કરવું, ભાવ જોવું નહિં ',
        'કોઈ પણ કામમાં ભાવ ના બગડવા દેવું',
        'કોઈ પણ કામ બગાડવું નહિં, ભાવ ભલે બગડે',
        'કોઈ પણ કામમાં લોકો ની વાહ-વાહ થવી જોઈએ'
      ],
      answer: ['કોઈ પણ કામમાં ભાવ ના બગડવા દેવું']
    }
  ],
  'banyu-te-j-nyay': [
    {
      question: 'લોકો કઈ જાત્રાએ જઈ રહ્યા હતા?',
      options: ['અમરનાથ', 'બદ્રી', 'કેદાર', 'બદ્રી અને કેદાર'],
      answer: ['બદ્રી અને કેદાર']
    },
    {
      question: 'ભગવાન મહાવીર ની દીક્ષાનું કયું વર્ષ ચાલી રહ્યું હતું?',
      options: ['આઠમું', 'સત્તરમું', 'વીસમું', 'તેરમું'],
      answer: ['તેરમું']
    },
    {
      question: 'ભગવાન મહાવીર કયા ગામની બહાર તપ કરી રહ્યા હતા?',
      options: ['છમ્માણિ', 'રામેશ્વર', 'અયોધ્યા', 'ભરતપુર'],
      answer: ['છમ્માણિ']
    },
    {
      question: 'કોણ ભગવાન મહાવીર ને ગાયો સોંપી ને જાય છે?',
      options: ['સુથાર', 'કુંભાર', 'ગોવાળ', 'સોની'],
      answer: ['ગોવાળ']
    },
    {
      question: 'અકળાયેલો ગોવાળ ભગવાન મહાવીર ના કાન માં શું નાખે છે?',
      options: ['દર્ભમૂલ(શૂળ)', 'લાકડી', 'પાન', 'કપડા નો ટુકડો'],
      answer: ['દર્ભમૂલ(શૂળ)']
    },
    {
      question: 'ભગવાનના કાન માં શૂળો ઠોકાવા થી _ વેદના હતી.',
      options: ['ખૂબ', 'સાધારણ', 'સહ્ય', 'અસહ્ય'],
      answer: ['અસહ્ય']
    },
    {
      question:
        'Bonus Question. દાદાશ્રી: ન્યાય ખોળવાનો હોતો હશે? જે બન્યું એ કરેક્ટ, તરત તૈયાર. કારણ કે _ સિવાય કશું બનતું જ નથી. નકામી હાયવોય ! હાયવોય !!',
      options: ['સંજોગ', 'વ્યવસ્થિત', 'કર્મ', 'પુરુષાર્થ'],
      answer: ['વ્યવસ્થિત']
    },
    {
      question: 'શૂળો ઠોકાવા થી પણ ભગવાન મહાવીર સાગર સમાન _A_ અને _B_ હતા.',
      options: [
        'A = અશાંત ; B = દુઃખી',
        'A = શાંત ; B = ગંભીર',
        'A = સ્થિર ; B = સહજ',
        'A = નરમ ; B = સુખી'
      ],
      answer: ['A = શાંત ; B = ગંભીર']
    },
    {
      question: 'આ ઘટના પછી પણ ભગવાન મહાવીર ને મન માં _ નો એક અંશ પણ ન હતો.',
      options: ['પ્રસન્નતા', 'અસમતા', 'ખિન્નતા', 'મમતા'],
      answer: ['ખિન્નતા']
    },
    {
      question: 'ભગવાને ગોવાળ ને _ ગણ્યો.',
      options: ['ઉપકારી', 'નિમિત્ત', 'નિર્દોષ', 'ઉપર ના બધા'],
      answer: ['ઉપર ના બધા']
    },
    {
      question: 'ભગવાન આગલા ભવ માં અર્ધ પૃથ્વીના _ હતા.',
      options: ['રાજા', 'સેનાપતિ', 'દ્વારપાલ', 'ચક્રવર્તી'],
      answer: ['રાજા']
    },
    {
      question: 'ભગવાન મહાવીર નો જીવ _ તરીકે અવતર્યો હતો.',
      options: [
        'શ્રી કૃષ્ણ વાસુદેવ',
        'ત્રિકૃષ્ટ વાસુદેવ',
        'ત્રિપૃષ્ટ વાસુદેવ',
        'ઉપર માંથી એક પણ નહીં '
      ],
      answer: ['ત્રિપૃષ્ટ વાસુદેવ']
    },
    {
      question: 'દ્વારપાલ કહે છે હું તને છોડીશ નહીં રાજા આનો _ હું જરૂર લઈશ.',
      options: ['બદલો', 'વેર', 'હિસાબ', 'ઉપર માંથી એક પણ નહીં '],
      answer: ['બદલો']
    },
    {
      question: 'Bonus Question. દાદાશ્રી: _ એ તો લોકભાષા છે.',
      options: [
        'કર્મ-દુષ્કર્મ',
        'વ્યવસ્થિત-અવ્યવસ્થિત',
        'ન્યાય-અન્યાય',
        'પ્રારબ્ધ-પુરુષાર્થ'
      ],
      answer: ['ન્યાય-અન્યાય']
    },
    {
      question: 'અસહ્ય વેદના ને કારણે દ્વારપાલ નું _ થાય છે.',
      options: ['કાન બંધ', 'મૃત્યુ', 'અપમાન', 'ઉપર માંથી એક પણ નહીં '],
      answer: ['મૃત્યુ']
    },
    {
      question: 'દ્વારપાલ નો દેહ રાજા સાથે _ બાંધે છે.',
      options: ['વેર', 'હિસાબ', 'ઝેર', 'દુશ્મનાવટ'],
      answer: ['વેર']
    },
    {
      question: 'ત્રિપુષ્ટ વાસુદેવ નો જીવ _ રૂપે જન્મ લે છે.',
      options: [
        'ભગવાન શ્રી કૃષ્ણ',
        'ભગવાન રામ',
        'ભગવાન મહાવીર',
        'ઉપર માંથી એક પણ નહીં '
      ],
      answer: ['ભગવાન મહાવીર']
    },
    {
      question: 'તારો અપરાધ અક્ષમ્ય છે. તને _ દંડ ની સજા આપવા માં આવશે.',
      options: ['આજીવન કેદ', 'મૃત્યુ', '૬ મહિના કેદ', '૬ વર્ષ કેદ'],
      answer: ['મૃત્યુ']
    },
    {
      question: 'દ્વારપાલ કહે છે, રાજા અત્યારે હું _ છું.',
      options: ['લાચાર', 'મજબૂર', 'અશક્ત', 'ઉપર માંથી એક પણ નહીં '],
      answer: ['લાચાર']
    },
    {
      question: 'સંગીત સાંભળવા માં _ થયેલા દ્વારપાલ ને ખ્યાલ રહેતો નથી.',
      options: ['મગ્ન', 'એકાગ્ર', 'તલ્લીન', 'ધ્યાનગન'],
      answer: ['તલ્લીન']
    },
    {
      question:
        'Bonus Question. દાદાશ્રી: કોર્ટો થઈ હશે, કોર્ટમાં બધું ચાલે પણ કુદરત _ થઈ જ નથી.',
      options: ['ન્યાયી', 'અન્યાયી', 'વ્યવસ્થિત', 'હિસાબ ની બાહર'],
      answer: ['અન્યાયી']
    },
    {
      question:
        'આ ભવ માં ભગવાન મહાવીર પાસે એવી સમજણ હતી કે જેથી પાછલા કર્મ ભોગવતા _ બંધાતા નથી.',
      options: ['ખરાબ કર્મ', 'શુભ કર્મ', 'સારા કર્મ', 'નવા કર્મ'],
      answer: ['નવા કર્મ']
    },
    {
      question: 'ગોવાળ એમના પાછલા ભવ માં કોણ હોય છે?',
      options: ['રાજા', 'દ્વારપાલ', 'પ્રધાન', 'સેવક'],
      answer: ['દ્વારપાલ']
    },
    {
      question: 'ત્રિપુષ્ટ વાસુદેવ ને કઈ ગતિ થાય છે?',
      options: ['સ્વર્ગ ગતિ', 'મનુષ્ય ગતિ', 'જાનવર ગતિ', 'નર્ક ગતિ'],
      answer: ['નર્ક ગતિ']
    },
    {
      question: 'કુદરતની કોર્ટમાં કદી પણ _A_ થતો નથી. એથી _B_ તે જ ન્યાય!!!',
      options: [
        'A = અન્યાય ; B = બન્યું',
        'A = અન્યાય ; B = બની ગયું',
        'A = ન્યાય ; B = બન્યું',
        'A = ન્યાય ; B = બની ગયું'
      ],
      answer: ['A = અન્યાય ; B = બન્યું']
    }
  ],
  'dharm-no-marm': [
    {
      question: 'દેવો શા માટે મનુષ્યદેહ ને ઝંખે છે?',
      options: [
        'મનુષ્યદેહ થી જ રામ બની શકાય છે',
        'મનુષ્યદેહ થી જ શાસ્ત્રો ભણી શકાય છે',
        'મનુષ્યદેહ થી જન્મ મરણ ના ફેરા માંથી મુક્તિ મળી શકે છે',
        'મનુષ્યદેહ થી જ યંત્રયુગમાં યંત્ર વાપરી શકાય છે'
      ],
      answer: ['મનુષ્યદેહ થી જન્મ મરણ ના ફેરા માંથી મુક્તિ મળી શકે છે']
    },
    {
      question: 'મનુષ્યદેહ સાર્થક કરવા શું કરવું જોઈએ?',
      options: [
        'ગીતા વાંચવી જોઈએ',
        'આત્માનો સાક્ષાત્કાર કરવો જોઈએ',
        'મહાવીર સ્વામી ના પાંચ મહાવ્રતો પાલન કરવા જોઈએ',
        'ધર્મ-ધ્યાનનો ઉપયોગ રાખવા જોઈએ'
      ],
      answer: ['આત્માનો સાક્ષાત્કાર કરવો જોઈએ']
    },
    {
      question: 'આપણે એક શાશ્વત આત્મા છીએ. શાશ્વત એટલે?',
      options: ['નિર્મળ', 'અખંડ', 'મહાન', 'ક્યારેય નાશ ના થાય'],
      answer: ['ક્યારેય નાશ ના થાય']
    },
    {
      question: 'આત્મજ્ઞાની કોને કહેવાય છે?',
      options: [
        'આત્મતત્વ ના જાણકાર ને',
        'આત્મા/અનાત્મા ના ગુણધર્મ જાણે તેને',
        'આત્મા/અનાત્મા નું ભેદાંકન કરી શકે તેને',
        'ઉપર ના બધા'
      ],
      answer: ['ઉપર ના બધા']
    },
    {
      question: 'કૃષ્ણ ભગવાન અર્જુનને આત્માના કયા ગુણધર્મો સંભળાવે છે?',
      options: [
        'આત્મા અનિત્ય અને સનાતન છે',
        'શસ્ત્ર તેને કાપી શકતું નથી',
        'આત્મા અવિકારી/અવિનાશી છે',
        'ઉપર ના બધા'
      ],
      answer: ['ઉપર ના બધા']
    },
    {
      question:
        'Bonus Question (ભાવના સુધારે ભવોભવ): દાદાશ્રી: સ્યાદવાદ એટલે દરેકનું પ્રમાણપૂર્વક. _ ડિગ્રી હોય તો બધાનું સાચું, પણ એની ડિગ્રી સુધી એનું સાચું અને આની ડિગ્રી સુધી આનું સાચું.',
      options: ['૧૮૦', '૨૭૦', '૩૦૦', '૩૬૦ '],
      answer: ['૩૬૦ ']
    },
    {
      question: 'નિષ્કામ કર્મ નો અર્થ શું છે?',
      options: [
        'કામના અને વિષય વગર ના કર્મ',
        'કર્મફળ ની ઈચ્છા વગર નું કર્મ',
        'અકર્તા પદ થી થયેલું કર્મ',
        'કામ ની ઈચ્છા વગર નું કર્મ'
      ],
      answer: ['કર્મફળ ની ઈચ્છા વગર નું કર્મ']
    },
    {
      question: 'ધૃતરાષ્ટ્ર ને કોણ યુદ્ધભૂમિ નું વર્ણન કરે છે?',
      options: ['દુર્યોધન', 'ભીષ્મ', 'સંજય', 'ગાંધારી'],
      answer: ['સંજય']
    },
    {
      question: 'ધૃતરાષ્ટ્ર અને સંજય નો દ્રષ્ટાંત શું સૂચવે છે?',
      options: [
        'પ્રત્યક્ષ જ્ઞાની વિના જ્ઞાન ન થાય',
        'ભજન કીર્તન કરવા થી જ્ઞાન થાય',
        'જ્ઞાની ની વાતો વાંચવા થી કે સાંભળવા થી જ્ઞાન થાય',
        'કૃષ્ણ ભગવાન ની ભક્તિ કરવા થી જ્ઞાન થાય'
      ],
      answer: ['પ્રત્યક્ષ જ્ઞાની વિના જ્ઞાન ન થાય']
    },
    {
      question: 'ઘરેણાં માં સોના જોડે શું મિશ્રિત હોય છે?',
      options: ['તાંબું', 'પીતળ', 'લોખંડ', 'પ્લેટિનમ'],
      answer: ['તાંબું']
    },
    {
      question:
        'Audio-Video Question: વિડિયોમાં બતાવેલા કયા પુરુષો પોતાના કલ્યાણની સાથે અનેક ના કલ્યાણનો નિમિત્ત બની ગયા?',
      options: [
        'સંત પુરુષ, સત્ પુરુષ, જ્ઞાની પુરુષ, રામ, બલરામ, ચક્રવર્તી રાજા અને તીર્થંકર ભગવાન',
        'સંત પુરુષ, સત્ પુરુષ, જ્ઞાની પુરુષ, પ્રતિવાસુદેવ, વાસુદેવ, બલરામ, ચક્રવર્તી રાજા અને તીર્થંકર ભગવાન',
        'સંત પુરુષ, સત્ પુરુષ, જ્ઞાની પુરુષ, અર્ધ-ચક્રવર્તી રાજા, બલરામ, ચક્રવર્તી રાજા અને તીર્થંકર ભગવાન',
        'સંત પુરુષ, સત્ પુરુષ, જ્ઞાની પુરુષ, વાસુદેવ, બલરામ, ચક્રવર્તી રાજા અને તીર્થંકર ભગવાન'
      ],
      answer: [
        'સંત પુરુષ, સત્ પુરુષ, જ્ઞાની પુરુષ, વાસુદેવ, બલરામ, ચક્રવર્તી રાજા અને તીર્થંકર ભગવાન'
      ],
      preview: {
        type: 'video',
        title: 'સ્પેશિયલ વિડિયો!',
        id: 'IPvcRye1x5U'
      }
    },
    {
      question: 'અર્જુન ને ધનુર વિદ્યા કોણે શીખડાવી હતી?',
      options: ['ભીષ્મ', 'ગુરુદ્રોણ', 'યુધિષ્ઠિર', 'કૃષ્ણ'],
      answer: ['ગુરુદ્રોણ']
    },
    {
      question: 'શ્રી કૃષ્ણ અર્જુન ને શાની ઈચ્છા નહીં રાખવાનું કહે છે?',
      options: ['કર્મ', 'જીવન', 'કર્મફળ', 'મૃત્યુ'],
      answer: ['કર્મફળ']
    },
    {
      question: 'શ્રીકૃષ્ણ ને કોણ અત્યંત પ્રિય છે?',
      options: ['ભગવાન', 'જ્ઞાની', 'અર્જુન', 'બલરામ'],
      answer: ['જ્ઞાની']
    },
    {
      question:
        'Bonus Question (ભાવના સુધારે ભવોભવ): દાદાશ્રી: એટલે એ સ્યાદવાદ માર્ગ એવો હોય. દરેકના _ને સ્વીકાર કરવો પડે. સામો બે ધોલ મારે તે પણ આપણે સ્વીકાર કરવો જોઈએ.',
      options: ['ધર્મ', 'અભિપ્રાય', 'કાર્ય', 'કર્મ'],
      answer: ['ધર્મ']
    },
    {
      question: 'આત્મ દીપ પ્રગટાવા કોની જરૂર પડે?',
      options: ['પ્રત્યક્ષ જ્ઞાની', 'પરોક્ષ જ્ઞાની', 'સંત પુરુષ', 'સત્ પુરુષ'],
      answer: ['પ્રત્યક્ષ જ્ઞાની']
    },
    {
      question:
        'કબીર સાહેબ કહે છે કે ભગવાન મારા હૃદય માં જ વસે છે પરંતુ _ ગાંઠ પડી હોવા થી તને દેખાતું નથી.',
      options: ['લોભ', 'કપટ', 'મોહ', 'માન'],
      answer: ['કપટ']
    },
    {
      question:
        'Audio-Video Question: કેટલી લાખ યોની માં ભટકી ને માનવદેહ પ્રાપ્ત થાય છે?',
      options: ['૨૫', '૪૪', '૭૪', '૮૪'],
      answer: ['૮૪'],
      preview: {
        type: 'video',
        title: 'સ્પેશિયલ વિડિયો!',
        id: 'jrJ3la1yP3M'
      }
    },
    {
      question: 'મોહમ્મદ પયગંબર નો સંદેશ છે, "અલ્લાહ એક હૈ ઔર _ પૂર્ણ હૈ."',
      options: ['સબ મેં', 'અપને આપ મેં', 'આત્મા મેં', 'ઉપર માંથી એક પણ નહિં '],
      answer: ['અપને આપ મેં']
    },
    {
      question:
        'શ્રીમદ્ કહે છે, "હું કોઈ ગચ્છ-મત માં નથી હું આત્મા માં જ છું. જેણે _જાણ્યું, તેને સર્વ જાણ્યું."',
      options: ['શુદ્ધાત્મા', 'પરમાત્મા', 'આત્મા', 'વ્યવહારિક આત્મા'],
      answer: ['આત્મા']
    },
    {
      question: 'દાદા ભગવાન કહે છે "મને _ સિવાય કોઈ વસ્તુ પ્રિય નથી"',
      options: ['પરમાત્મા', 'અંતરાત્મા', 'વ્યવહારિક આત્મા', 'આત્મા'],
      answer: ['આત્મા']
    },
    {
      question:
        'Audio-Video Question: શ્રી કૃષ્ણ ભગવાન અર્જુન માટે _A_ હતા જ્યારે ધૃતરાષ્ટ્ર અને સંજય માટે _B_ હતા.',
      options: [
        'A = પરોક્ષ ; B = પ્રત્યક્ષ',
        'A = દૃશ્ય ; B = અદૃશ્ય',
        'A = અદૃશ્ય ; B = દૃશ્ય',
        'A = પ્રત્યક્ષ ; B = પરોક્ષ'
      ],
      answer: ['A = પ્રત્યક્ષ ; B = પરોક્ષ'],
      preview: {
        type: 'video',
        title: 'સ્પેશિયલ વિડિયો!',
        id: '5BeF1Mi6ReA'
      }
    },
    {
      question:
        'આટલા વર્ષો પછી પણ આવા મહાન પુરુષો ની વાત આપણી સમક્ષ _ રીતે જીવંત ભાસે છે.',
      options: [
        'ધાર્મિક',
        'આધ્યાત્મિક',
        'ધર્મનો મર્મ',
        'ઉપર માંથી એક પણ નહિં '
      ],
      answer: ['ધર્મનો મર્મ']
    },
    {
      question: 'બધા જ પાત્રો એકબીજા ને _ છે.',
      options: ['ઉપકારી', 'આધારિત', 'નિમિત્ત', 'સંબંધિત'],
      answer: ['આધારિત']
    },
    {
      question:
        'સોના ના ઘરેણાં માંથી શુદ્ધ સોનું મેળવવા માટે _ ની પાસે જવું પડે.',
      options: ['લુહાર', 'સોની', 'સુથાર', 'કુંભાર'],
      answer: ['સોની']
    },
    {
      question:
        "Bonus Question (ભાવના સુધારે ભવોભવ): દાદાશ્રી : તો 'બે' એ સંખ્યા કહેવાય કે ના કહેવાય? પ્રશ્નકર્તા : હા, કહેવાય. દાદાશ્રી : તો આપણા 'સો'વાળા શું કહે? 'અમારું સાચું, તમારું ખોટું' એવું ના કહેવાય. બધાનું સાચું છે. 'એક'નું 'એક'ના _માં, 'બે'નું 'બે'ના _માં, દરેકનું એનાં _માં સાચું છે. એટલે દરેકનું જે સ્વીકાર કરે છે, એનું નામ સ્યાદવાદ.",
      options: ['માપ', 'ડિગ્રી', 'પ્રમાણ', 'સર્કલ'],
      answer: ['પ્રમાણ']
    }
  ],
  'maafi-maangvanu-mahatva': [
    {
      question: 'કોણ ખુબ જ સારા મિત્રો હતા?',
      options: [
        'સસલો અને ઉંદર',
        'ખિસકોલી અને રીંછ',
        'સસલો અને રીંછ',
        'રીંછ અને ખિસકોલી'
      ],
      answer: ['સસલો અને રીંછ']
    },
    {
      question: 'રીંછ ની ગુફા પાસે કોણ આવે છે?',
      options: ['મિસિસ આઉલી', 'સસલો', 'ખિસકોલી', 'ઊંદર'],
      answer: ['સસલો']
    },
    {
      question: 'રીંછ શા માટે પરેશાન હતો?',
      options: [
        'રીંછ નું માથું દુખતું હતું',
        'રીંછ નો ગમતો કંપાસ બોક્સ ખોવાઈ ગયો હતો',
        'રીંછ ને સસલા ની મિત્રતા ગમતી ન હતી',
        'રીંછ ની તબિયત સારી ન હતી'
      ],
      answer: ['રીંછ નો ગમતો કંપાસ બોક્સ ખોવાઈ ગયો હતો']
    },
    {
      question: 'દુઃખી હૃદયે સસલો કેમ જતો રહે છે?',
      options: [
        'રીંછ માટે દવા લેવા જાય છે',
        'રીંછ સસલાની વાત નહતો માનતો',
        'રીંછ તેનું કડક શબ્દો થી અપમાન કરે છે',
        'સસલો રીંછ નો કંપાસ શોધવા જાય છે'
      ],
      answer: ['રીંછ તેનું કડક શબ્દો થી અપમાન કરે છે']
    },
    {
      question: 'સસલો અને રીંછ ની લડાઈ કોણ સાંભળે છે?',
      options: ['મિસિસ આઉલી', 'ખિસકોલી', 'ઉંદર', 'ઉપર ના બધા'],
      answer: ['મિસિસ આઉલી']
    },
    {
      question: 'રીસેસ માં કોણ એક સાથે નાસ્તો કરવા સાથે નથી બેસતુ?',
      options: ['ઉંદર', 'ખિસકોલી', 'સસલો', 'રીંછ'],
      answer: ['રીંછ']
    },
    {
      question:
        'Bonus Question (Dadavani Aug 2007-Pratikraman): દાદાશ્રી : પાપથી પાછા વળવું! ભગવાને કેવો સરસ ન્યાય કર્યો છે કે પાપથી પાછા વળવાનું એનું નામ _!',
      options: ['અતિક્રમણ', 'પ્રતિક્રમણ', 'ક્ષમા', 'માફી'],
      answer: ['પ્રતિક્રમણ']
    },
    {
      question: 'ખિસકોલી કહે છે એનો શર્ટ કોણે ફાડ્યો હતો?',
      options: ['ઉંદર', 'સસલો', 'રીંછ', 'મિસિસ આઉલી'],
      answer: ['ઉંદર']
    },
    {
      question: 'રીંછ નો કંપાસ બોક્સ ક્યાં થી મળે છે?',
      options: ['ગાર્ડનમાં થી', 'હોલ પાસે થી', 'રોડ પર થી', 'એક પણ નહિ'],
      answer: ['હોલ પાસે થી']
    },
    {
      question: 'શબ્દોને શેની સાથે સરખામણી કરાય છે?',
      options: ['ગાજર', 'એરપ્લેન', 'કંપાસ', 'તીર'],
      answer: ['કંપાસ']
    },
    {
      question: 'મિસિસ આઉલી વાણી ના કેટલા પ્રકાર સમજાવે છે? કયા કયા?',
      options: [
        'કડવાશ વાળી ને મીઠાશ વાળી વાણી',
        'માન ને પ્રશંસા આપતી ને પ્રશંસા આપતી ને અપમાન આપતી વાણી',
        'મતભેદ ઉભા કરતી ને મિત્રતા કરતી',
        'સાચું બોલતી ને જૂઠું બોલતી વાણી'
      ],
      answer: ['કડવાશ વાળી ને મીઠાશ વાળી વાણી']
    },
    {
      question: 'શબ્દો માં શું હોય છે?',
      options: ['મીઠાશ', 'કડવાશ', 'A અને B', 'સાચું અને જૂઠું'],
      answer: ['A અને B']
    },
    {
      question:
        'Bonus Question: વિડીયો પ્રમાણે અક્રમ વિજ્ઞાન માં કયું પ્રતિક્રમણ આવે છે?',
      options: [
        'ભાવ પ્રતિક્રમણ',
        'દ્રવ્ય પ્રતિક્રમણ',
        'નો પ્રતિક્રમણ',
        'ક્રિયા પ્રતિક્રમણ'
      ],
      answer: ['ભાવ પ્રતિક્રમણ'],
      preview: {
        type: 'video',
        title: 'સ્પેશિયલ વિડિયો!',
        id: 'QBcS7RgUJLM'
      }
    },
    {
      question:
        'Bonus Question: વિડીયો પ્રમાણે પ્રતિક્રમણ કેવી રીતે કરવાનું છે?',
      options: [
        'શબ્દે શબ્દ બોલીને પ્રતિક્રમણ',
        'ધાર્મિક ક્રિયા કરીને પ્રતિક્રમણ',
        'અંદર મનમાં જ ભાવ થી પ્રતિક્રમણ',
        'દાન આપીને પ્રતિક્રમણ'
      ],
      answer: ['અંદર મનમાં જ ભાવ થી પ્રતિક્રમણ']
    },
    {
      question: 'Bonus Question: વિડીયો પ્રમાણે પ્રતિક્રમણ ક્યારે કરવું જોઈએ?',
      options: [
        'સંવત્સરી નાં દિવસે બધા દોષો યાદ કરીને',
        'મહિના માં એક વાર બધા દોષો યાદ કરીને',
        'છ મહીને જ્યારે દોષ થાય ત્યારે',
        'હરતા-ફરતા , ઉઠતા-બેસતા જ્યાં આપણો દોષ થાય ત્યારે'
      ],
      answer: ['હરતા-ફરતા , ઉઠતા-બેસતા જ્યાં આપણો દોષ થાય ત્યારે']
    },
    {
      question: 'શેના ઘા વધુ ઊંડા હોય છે?',
      options: ['તીર', 'શબ્દો', 'તલવાર', 'બોલ'],
      answer: ['શબ્દો']
    },
    {
      question: 'લખાણ ની ભૂલ શાનાથી ભૂંસાય છે?',
      options: ['પેન્સિલ', 'રબર', 'પેન', 'એક પણ નહિ'],
      answer: ['રબર']
    },
    {
      question: 'વાણી ના ઘા રૂઝવવા માટે એક માત્ર શો ઉપાય છે?',
      options: [
        'પરાણે માફી માંગી લેવી',
        'માંગવા ખાતર માફી માંગી લેવી',
        'દિલ થી માફી માંગી લેવી',
        'ઝઘડી લેવું'
      ],
      answer: ['દિલ થી માફી માંગી લેવી']
    },
    {
      question: 'રીંછ સસલા ને શું મોકલે છે?',
      options: ['વોટ્સએપ મેસેજ', 'ફેસબુક મેસેજ', 'ગિફ્ટ', 'ચિઠ્ઠી'],
      answer: ['ચિઠ્ઠી']
    },
    {
      question: 'વાણી થી આપેલ દુઃખ ના ઘા શેનાથી ભૂંસી શકાય છે?',
      options: [
        'રડી પડી ને',
        'દિલ થી માફી માંગી ને',
        'માંગવા ખાતર માફી માંગીને',
        'ઈશ્વર ને પ્રાર્થના કરી ને'
      ],
      answer: ['દિલ થી માફી માંગી ને']
    },
    {
      question: 'રીંછે સસલા ને પેન્સિલ થી શું લખ્યું?',
      options: [
        'સસલા ના વખાણ કર્યા',
        'હૃદય થી માફી માંગી ને એકરાર કર્યો',
        'સસલા ને ચીડવ્યું',
        'સસલા ની મજાક ઊડાવી'
      ],
      answer: ['હૃદય થી માફી માંગી ને એકરાર કર્યો']
    },
    {
      question: 'માફી માંગવાની ચાવી થી શું કમાલ કરી શકાય?',
      options: [
        'બધા ને દોસ્ત બનાવી શકાય',
        'કોઈ ને ફરિયાદ ન થાય',
        'બધા ને ખુશ કરી શકાય',
        'ઉપર નાં બધા'
      ],
      answer: ['ઉપર નાં બધા']
    },
    {
      question:
        "Bonus Question (Dadavani Aug 2007-Pratikraman): હવે _A_ કર્યું તો પછી ફરી એવું અતિક્રમણ ન થાય. તો 'ફરી હવે નહીં કરું' એનું નામ _B_.",
      options: [
        'A = આલોચના ; B = પ્રતિક્રમણ',
        'A = પ્રત્યાખ્યાન ; B = પ્રતિક્રમણ',
        'A = પ્રતિક્રમણ ; B = પ્રત્યાખ્યાન',
        'A = પ્રત્યાખ્યાન ; B = આલોચના'
      ],
      answer: ['A = પ્રતિક્રમણ ; B = પ્રત્યાખ્યાન']
    },
    {
      question: 'જો આપણા થી ભૂલ થઇ જાય તો શું કરવું જોઈએ?',
      options: [
        'માફી માંગવી જોઈએ',
        'ભૂલી જવી જોઈએ',
        'ઇગ્નોર કરવું જોઈએ',
        'ભૂલ પાછી કરવી જોઈએ'
      ],
      answer: ['માફી માંગવી જોઈએ']
    },
    {
      question: 'માફી માંગવાને શાની સાથે સરખાવ્યું છે?',
      options: [
        'જાદૂઈ પેન્સિલ સાથે',
        'જાદૂઈ પેન સાથે',
        'જાદૂઈ રબર',
        'જાદૂઈ કંપાસ'
      ],
      answer: ['જાદૂઈ રબર']
    }
  ],
  'why-should-we-not-eat-eggs': [
    {
      question: 'નાના પક્ષી ને પકડવા જઈએ તો તેઓ શું કરે?',
      options: [
        'સહેલાઇ થી આપણી પાસે આવે',
        'ગભરાઈ ને ભાગી જાય',
        'પક્ષી રિસાય જાય',
        'ઊડી જાય'
      ],
      answer: ['ગભરાઈ ને ભાગી જાય']
    },
    {
      question: 'ચકલા ને પકડવા જઈએ તો શા માટે ભાગી જાય છે?',
      options: [
        'બીજે સારું ખાવા મળશે',
        'તેમને ઊડી જવાનું ગમે છે',
        'ડર થી દુઃખી થઇ ને કે મને મારી નાખશે',
        'તેઓ આપણી સાથે રમે છે'
      ],
      answer: ['ડર થી દુઃખી થઇ ને કે મને મારી નાખશે']
    },
    {
      question: 'કોઈ જીવ ને મારી નાખીએ તો તેને શું થાય?',
      options: ['દુઃખ થાય છે', 'સુખ થાય છે', 'કઈ થતું નથી', 'સુઈ જાય છે'],
      answer: ['દુઃખ થાય છે']
    },
    {
      question:
        'Bonus Question #1: દાદાશ્રીએ મોક્ષ માર્ગનાં બાધક કારણો ત્રણ કહ્યા છે: ૧) અણહક્કનાં વિષય (વ્યભિચાર), ૨) દારૂ કે બીજો કોઈ નશો, ૩) _.',
      options: ['કપટ', 'ખાવામાં લુભ્ધપણું', 'અણહક્કની લક્ષ્મી', 'માંસાહાર'],
      answer: ['માંસાહાર'],
      questionImage: 'bonus1.png'
    },
    {
      question: 'ઈંડામાંથી નીકળતા પંખી માં જીવ હોય છે?',
      options: ['હા', 'ના'],
      answer: ['હા']
    },
    {
      question: 'દરેક જીવ ને શું ગમે છે?',
      options: ['દુઃખ', 'ખાવાનું', 'સુખ', 'રમવાનું'],
      answer: ['સુખ']
    },
    {
      question: 'કોઈ પણ વસ્તુ નાના માંથી મોટી grow થાય છે તે શું સૂચવે છે?',
      options: [
        'તેને આપણે મોટું બનાવીને grow થાય છે',
        'એ જાદૂથી grow થાય છે',
        'તેમાં જીવ છે તેથી જ તે grow થાય છે',
        'એક પણ નહિ'
      ],
      answer: ['તેમાં જીવ છે તેથી જ તે grow થાય છે']
    },
    {
      question:
        'Bonus Question #2 (અહિંસા): દાદાશ્રી: આ બધા ઘઉં ને ચોખા ને આ બધું ખાઈએ છીએ, આવડા આવડા દૂધીયાં ખાઈ જઈએ છીએ, એ બધાં જીવો જ છે ને ! નથી જીવો? પણ ભગવાને ખાવાની બાઉન્ડ્રી આપી છે કે આ જીવો છે તે ખાજો. પણ જે જીવ તમારાથી _ તેને મારો નહીં, એને ખાવ નહીં, એને કશું જ ના કરો.',
      options: [
        'દુર ભાગતો હોઈ',
        'સુખી થતો હોઈ',
        'ત્રાસ પામતો હોઈ',
        'શરણમાં આવતો હોઈ'
      ],
      answer: ['ત્રાસ પામતો હોઈ'],
      questionImage: 'bonus2.png'
    },
    {
      question: 'પાણી માં નાનો કાંકરો અને નાનો મગ નો દાણો નાખીએ તો?',
      options: [
        'પથરો મોટો થવા માંડશે',
        'મગ નો દાણો ઉગશે',
        'પથરો પાણી માં ઓગળી જશે',
        'મગ નો દાણો ઓગળી જશે'
      ],
      answer: ['મગ નો દાણો ઉગશે']
    },
    {
      question: 'ઈંડા માં કેટલી ઇન્દ્રિય વાળો જીવ હોય છે?',
      options: ['એકેન્દ્રિય', 'બેઇન્દ્રિય', 'ત્રણેન્દ્રિય', 'પંચેન્દ્રિય'],
      answer: ['પંચેન્દ્રિય']
    },
    {
      question: 'કોઈ પણ જીવ ની હિંસા કરીએ તો શું થાય?',
      options: [
        'પાપ લાગે',
        'આપણી આધ્યાત્મિક સમજણ પર આવરણ આવે',
        'ચોખ્ખું ને સાચું સમજાય નહીં',
        'આપેલા તમામ'
      ],
      answer: ['આપેલા તમામ']
    },
    {
      question:
        'Bonus Question #3 (અહિંસા): પ્રશ્નકર્તા: કેટલાંક લોકો તો એવી દલીલ કરે છે કે ઈંડાં બે પ્રકારનાં હોય છે, એક _A_વાળા અને બીજાં _B_. તો એ ખવાય કે નહીં? દાદાશ્રી : ફોરેનમાં એ લોકો દલીલ કરતા હતા કે અહિંસક ઈંડાં ! એટલે મેં કહ્યું, આ જગતમાં _A_ વગર કશું ખવાય જ નહીં. _B_ વસ્તુ છેને, એ ખવાય જ નહીં. ઈંડામાં જો _A_ ના હોય તો ઈંડું ખવાય નહીં, એ જડ વસ્તુ થઈ ગઈ.',
      options: [
        'A = આત્મા ; B = પરમાણુ',
        'A = અજીવ ; B = જીવ',
        'A = પરમાણુ ; B = આત્મા',
        'A = જીવ ; B = અજીવ'
      ],
      answer: ['A = જીવ ; B = અજીવ'],
      questionImage: 'bonus3.png'
    },
    {
      question: 'ઈંડા કેમ ન ખવાય?',
      options: [
        'ઈંડા વેજીટેરિયન હોય છે',
        'ઈંડા ખાવા થી પંચેન્દ્રિય જીવ ની હિંસા થાય છે',
        'ઈંડા નો સ્વાદ ખરાબ હોય છે',
        'ઈંડા માં ખુબ જ પ્રોટીન હોય છે'
      ],
      answer: ['ઈંડા ખાવા થી પંચેન્દ્રિય જીવ ની હિંસા થાય છે']
    },
    {
      question: 'વિડિઓ અનુસાર ચશ્માં ઉપર શું કરી દઈએ તો કંઈ ના દેખાઈ?',
      options: [
        'વ્હાઈટ પેઇન્ટિંગ',
        'બ્લેક પેઇન્ટિંગ',
        'યેલ્લો પેઇન્ટિંગ',
        'ગ્રીન પેઇન્ટિંગ'
      ],
      answer: ['વ્હાઈટ પેઇન્ટિંગ']
    },
    {
      question:
        'Bonus Question #4 (અહિંસા): આહારમાં ઊંચામાં ઊંચો આહાર કયો? એકેન્દ્રિય જીવોનો ! બે ઇન્દ્રિયથી ઉપરના જીવોના આહારનો, જેને _ એને અધિકાર નથી. એટલે બે ઇન્દ્રિયથી વધારે ઇન્દ્રિયોવાળા જીવોની જવાબદારી આપણે ના કરવી જોઈએ.',
      options: [
        'સંસારમાં રહેવું છે',
        'સુખ-શાંતિ જોઈએ છે',
        'મોક્ષે જવું છે',
        'પૈસા કમાવવા છે'
      ],
      answer: ['મોક્ષે જવું છે']
    }
  ],
  'karta-kaun': [
    {
      question: 'શાના દ્વારા સમજાવા માં આવે છે કે "જગત નો સાચો કર્તા કોણ?"',
      options: ['ટોક શૉ', 'દ્રષ્ટાંત', 'જાદુ', 'નૃત્ય'],
      answer: ['દ્રષ્ટાંત']
    },
    {
      question: 'ચંદુભાઈ નો વ્યવસાય શું છે?',
      options: ['સરપંચ', 'બિઝનેસ મેન', 'ડૉક્ટર', 'હાઈકોર્ટ ના વકીલ'],
      answer: ['હાઈકોર્ટ ના વકીલ']
    },
    {
      question: 'ચંદુભાઈ ની પત્ની નું નામ શું હોય છે?',
      options: ['શીતલ', 'સુશીલા', 'ચંદ્રિકા', 'માયા'],
      answer: ['ચંદ્રિકા']
    },
    {
      question: 'સવાર ના સમયે ચંદુભાઈ બાથરૂમ માં કઈ આફત નો સામનો કરે છે?',
      options: [
        'પાણી નથી આવતું',
        'પગ લપસી જાય છે',
        'ટૂથપેસ્ટ ખતમ થઇ જાય છે',
        'ઉપર માંથી એક પણ નહિ'
      ],
      answer: ['પાણી નથી આવતું']
    },
    {
      question: 'ચંદુભાઈ બાથરૂમ ના અરીસા માં શું જોવે છે?',
      options: ['કંઈ નહીં', 'પોતાનું પ્રતિબિંબ', 'ભૂત', 'B અને C '],
      answer: ['પોતાનું પ્રતિબિંબ']
    },
    {
      question: 'સૂરજ નો પ્રકાશ દરિયા ના પાણી પર પડવા થી શું બને છે?',
      options: ['વરાળ', 'હવા', 'બરફ', 'મીઠું'],
      answer: ['વરાળ']
    },
    {
      question:
        'Bonus Question (Dadavani July 2009): દાદાશ્રી: એટલે એ વ્યવસ્થિત એટલે _A_ની ચિંતા કરવાની ન્હોય, _B_ ભૂલી જવાનું અને નિરંતર _C_માં રહેવું એ વ્યવસ્થિત.',
      options: [
        'A = વર્તમાન ; B = ભૂતકાળ ; C = ભવિષ્ય',
        'A = ભવિષ્ય ; B = ભૂતકાળ ; C = વર્તમાન',
        'A = ભૂતકાળ ; B = વર્તમાન ; C = ભવિષ્ય',
        'A = ભવિષ્ય ; B = વર્તમાન ; C = ભૂતકાળ'
      ],
      answer: ['A = ભવિષ્ય ; B = ભૂતકાળ ; C = વર્તમાન']
    },
    {
      question: 'વીજળી ના ગડગડાટ કઇ ઋતુ માં થાય છે?',
      options: ['શિયાળો', 'ઉનાળો', 'ચોમાસુ', 'વસંત'],
      answer: ['ચોમાસુ']
    },
    {
      question: 'શાની સુગંધ થી મન પ્રફુલ્લિત થઇ જાય છે?',
      options: [
        'અત્તર ની સુગંધ',
        'ફૂલો ની સુગંધ',
        'ખાવા ની સુગંધ',
        'ભીની માટી ની સુગંધ'
      ],
      answer: ['ભીની માટી ની સુગંધ']
    },
    {
      question: 'વરસાદ નું પાણી એકઠું થઇ ને શામાં મળે છે?',
      options: ['નદી', 'દરિયો', 'ડેમ', 'સરોવર'],
      answer: ['નદી']
    },
    {
      question: 'વિડીયો પ્રમાણે પાણી નો સંગ્રહ ક્યાં થાય છે?',
      options: ['કૂવો', 'દરિયો', 'ડેમ', 'સરોવર'],
      answer: ['ડેમ']
    },
    {
      question:
        'સંગ્રહ થયેલું પાણી શહેર ની મોટા માં મોટી ટાંકી માં કેવી રીતે લઇ જવા માં આવે છે?',
      options: [
        'ટેન્કર ભરી ને',
        'પાઇપ લાઈન થી',
        'A અને B',
        'ઉપર માંથી એક પણ નહિ'
      ],
      answer: ['પાઇપ લાઈન થી']
    },
    {
      question:
        'સોસાઈટી માં આવેલું પાણી શાની મદદ થી ઘર ની અંદર ટાંકી માં ભરવા માં આવે છે?',
      options: [
        'જનરેટર થી',
        'ઇલેક્ટ્રિક મોટર થી',
        'પ્રેસર જનરેટ કરી ને',
        'ડોલ ભરી ને'
      ],
      answer: ['ઇલેક્ટ્રિક મોટર થી']
    },
    {
      question:
        'Bonus Question: દાદાશ્રી: આ કાચનો પ્યાલો છે. તે તમારા હાથમાંથી છૂટવા માંડ્યો. તે તમે આમથી તેમ ને તેમથી આમ હાથ હલાવીને તેને છેક નીચે સુધી બચાવવા પ્રયત્ન કર્યો. તે છતાંય તે પડ્યો ને ફૂટી ગયો. તો તે કોણે ફોડ્યો? તમારી ઈચ્છા જરાય નહોતી કે આ પ્યાલો ફૂટે. ઊલટાનો તમે તો છેક સુધી બચાવવાનો પ્રયત્ન કર્યો. ત્યારે શું પ્યાલાને ફૂટવાની ઇચ્છા હતી? ના. તેને તો તેવું હોય જ નહીં. બીજું કોઈ ફોડનાર તો હાજર નથી તો પછી કોણે ફોડ્યો? _.',
      options: ['તમે, ચંદુભાઈ એ જ', 'કર્મો એ', 'વ્યવસ્થિતે', 'પ્રારભ્ધએ'],
      answer: ['વ્યવસ્થિતે']
    },
    {
      question: 'વીજળી પમ્પ સુધી પોંહચાડવા માટે કયા સંજોગો ની જરૂર પડે છે?',
      options: [
        'થાંભલા અને વાયરો',
        'ટ્રાન્સફોર્મર',
        'સબસ્ટેશન્સ',
        'ઉપર ના બધા જ'
      ],
      answer: ['ઉપર ના બધા જ']
    },
    {
      question: 'બીજા ને દોષિત જોવા થી શું થાય છે?',
      options: [
        'ઝઘડા થાય છે',
        'રાગ દ્વેષ થાય છે',
        'કર્મો બંધાય છે',
        'પાપ લાગે છે'
      ],
      answer: ['કર્મો બંધાય છે']
    },
    {
      question: 'આ ચા ની પત્તી કયા કયા સંજોગો ને કારણે મને મળી?',
      options: [
        'ખેતરો માં ઊગી અને માણસો એ લણી',
        'મશીન થી સુકવી ને પેક થઇ અને દુકાન માં આવી',
        'આપણે ખરીદી ને ઘેર લાવ્યા',
        'ઉપર ના બધા જ'
      ],
      answer: ['ઉપર ના બધા જ']
    },
    {
      question:
        "Bonus Question: દાદાશ્રી: વર્તમાનમાં વર્તાવે, '_A_'! _B_ છે તો હંમેશાં વર્તમાનમાં જ રહે. ભૂતકાળ તો ચાલ્યો ગયો. ભૂતકાળને રડે નહીં કે વર્ષ દા'ડા પહેલા લાખ રૂપિયા ખોટ ગઇ હતી. એને આજે સંભારીને યાદ ના કરે ને રડે નહીં. અને ભવિષ્યકાળ તો '_C_'ના તાબામાં છે. એટલે પોતે વર્તમાનમાં રહેવું.",
      options: [
        'A = જ્ઞાની ; B = વ્યવસ્થિત ; C = જ્ઞાની',
        'A = વ્યવસ્થિત ; B = જ્ઞાની ; C = વ્યવસ્થિત',
        'A = જ્ઞાની ; B = વ્યવસ્થિત ; C = વ્યવસ્થિત',
        'A = જ્ઞાની ; B = જ્ઞાની ; C = વ્યવસ્થિત'
      ],
      answer: ['A = વ્યવસ્થિત ; B = જ્ઞાની ; C = વ્યવસ્થિત']
    },
    {
      question: 'એક કપ ચા બનાવામાં કયા કયા સંજોગો ની જરૂર પડે છે?',
      options: [
        'પાણી,સ્ટવ',
        'દીવાસળી, દૂધ, ખાંડ, ચા ની પત્તી',
        'ચા પીનાર',
        'ઉપર ના બધા જ'
      ],
      answer: ['ઉપર ના બધા જ']
    },
    {
      question: 'મનુષ્ય શા કારણે કહે છે કે મેં ચા બનાવી?',
      options: ['ક્રોધ થી', 'અહંકાર થી', 'બુદ્ધિ થી', 'લોભ થી'],
      answer: ['અહંકાર થી']
    },
    {
      question: 'જગત માં આપણે સ્વતંત્ર કર્તા છીએ કે નૈમિત્તિક?',
      options: ['સ્વતંત્ર', 'નૈમિત્તિક', 'ઉપર ના બન્ને', 'એક પણ નહીં'],
      answer: ['નૈમિત્તિક']
    },
    {
      question: 'કર્તા થવા થી મનુષ્ય ને શું થાય છે?',
      options: [
        'ભોક્તા બને છે',
        'સફળતા મળે છે',
        'નિષ્ફળતા મળે છે',
        'એક પણ નહીં'
      ],
      answer: ['ભોક્તા બને છે']
    },
    {
      question: 'બધી ઘટનાઓ કઈ રીતે સર્જાય છે?',
      options: [
        'સંજોગો ની પરંપરા થી',
        'અજ્ઞાન માન્યતા થી',
        'બીજા ને નુકસાન કરવા થી',
        'બીજા ને મદદ કરવા થી'
      ],
      answer: ['સંજોગો ની પરંપરા થી']
    },
    {
      question: 'આપણે ક્યા ભાવે જીવી લેવાનું છે?',
      options: ['પ્રેમ ભાવ થી', 'વેર ભાવ થી', 'નૈમિત્તિક ભાવે', 'એક પણ નહીં'],
      answer: ['નૈમિત્તિક ભાવે']
    },
    {
      question: 'Bonus Question: વ્યવસ્થિત શું છે? વ્યવસ્થિત એટલે _.',
      options: [
        'વૈજ્ઞાનિક પ્રારબ્ધિક પૂરાવાઓ.',
        'વૈજ્ઞાનિક સાંયોગિક પૂરાવાઓ',
        'વૈજ્ઞાનિક ક્રિયાકારી પૂરાવાઓ.',
        'વૈજ્ઞાનિક સૈદ્ધાંતિક પૂરાવાઓ.'
      ],
      answer: ['વૈજ્ઞાનિક સાંયોગિક પૂરાવાઓ']
    }
  ],
  'jj-100-part-09': [
    {
      question:
        'આ વિડિઓ માં પૂજ્યશ્રી નું બાળકો સાથે નું કયું સેશન રાખવામાં આવ્યું છે?',
      options: [
        'જનરલ સત્સંગ',
        'ટોપિક પર સત્સંગ',
        'ધમાલ મસ્તી સત્સંગ',
        'ઉપર ના બધા'
      ],
      answer: ['ધમાલ મસ્તી સત્સંગ']
    },
    {
      question:
        'વિડીયો પ્રમાણે જો પૂજ્યશ્રી ને દાદા ન મળ્યા હોત તો પૂજ્યશ્રી શું કરી રહ્યા હોત?',
      options: [
        'બિઝનેસ',
        'બાબલા ને પૈણાવા માટે છોકરી શોધતા હોત',
        'નોકરી',
        'એક પણ નહીં'
      ],
      answer: ['બાબલા ને પૈણાવા માટે છોકરી શોધતા હોત']
    },
    {
      question: 'પૂજ્યશ્રી ની ઉંમર શું છે? આ વિડિઓ મુજબ એ સમયે?',
      options: ['૫૪ વર્ષ', '૬૦ વર્ષ', '૫૬ વર્ષ', '૬૨ વર્ષ'],
      answer: ['૫૪ વર્ષ']
    },
    {
      question: 'છોકરો પૂજ્યશ્રી ને કઈ પેન થી લાલ લખવાનું કહે છે?',
      options: ['લાલ', 'ભૂરી', 'લીલી', 'વાદળી'],
      answer: ['વાદળી']
    },
    {
      question:
        'છોકરો જયારે પૂજ્યશ્રી પાસે બર્થડે ગિફ્ટ માંગે છે ત્યારે પૂજ્યશ્રી કઈ ગીફ્ટ આપવાનું કહે છે?',
      options: ['સ્કૂલ બેગ', 'આપ્તપુત્ર બનવાની વાત', 'બાઈક', 'ચોકલૅટ'],
      answer: ['આપ્તપુત્ર બનવાની વાત']
    },
    {
      question:
        'Bonus Question #1 (Aptavani-1): જગતમાં ‘આપ્ત પુરુષ’ તે એકલા જ્ઞાની પુરુષ જ કહેવાય. આપ્ત એટલે _. સાંસારિક જ બાબતો માટે નહીં, પણ મોક્ષપ્રાપ્તિ માટે, ઠેઠ સુધી વિશ્વાસ કરવા યોગ્ય હોય. જ્યાં સુધી પોતાને આત્મભાન થયું નથી, આત્માની ઓળખાણ થતી નથી ત્યાં સુધી પ્રત્યક્ષ જ્ઞાની પુરુષ જ પોતાનો આત્મા છે.',
      options: [
        'બધી રીતે ઉપકારી',
        'બધી રીતે વિશ્વાસ કરવા યોગ્ય',
        'બધી રીતે ઉકેલ લાવવાની કળા',
        'બધી રીતે પ્રેમસ્વરૂપ થયેલા'
      ],
      answer: ['બધી રીતે વિશ્વાસ કરવા યોગ્ય'],
      questionImage: 'bonus1.png'
    },
    {
      question: 'પૂજ્યશ્રી નો બર્થ ડે કયારે આવે છે?',
      options: ['૯ મે', '૬ મે', '૯ જૂન', '૬ જૂન'],
      answer: ['૯ મે']
    },
    {
      question:
        'વિડીયોમાં પૂજ્યશ્રી સફેદ કપડાં પહેરવા પાછળ નું શું કારણ બતાવે છે?',
      options: [
        'પૂજ્યશ્રી ને સફેદ કપડાં ગમે છે',
        'સફેદ કપડાં પર ડાઘ પડે તો તરત દેખાય',
        'A અને B બંને',
        'એક પણ નહીં'
      ],
      answer: ['સફેદ કપડાં પર ડાઘ પડે તો તરત દેખાય']
    },
    {
      question: 'વિડીયો પ્રમાણે પૂજ્યશ્રી કઈ જગ્યા એ ફરવાનું પસંદ કરશે?',
      options: ['અડાલજ ની વાવ', 'USA', 'સીમંધર સિટી, ત્રિમંદીર', 'ભાદરણ'],
      answer: ['સીમંધર સિટી, ત્રિમંદીર']
    },
    {
      question: 'પૂજ્યશ્રી ની smile પાછળ નું શું કારણ કહે છે?',
      options: [
        'અહંકાર જતો રહ્યો એટલે',
        'ક્રોધ જતો રહ્યો એટલે',
        'મોહ જતો રહ્યો એટલે',
        'માન જતું રહ્યું એટલે'
      ],
      answer: ['અહંકાર જતો રહ્યો એટલે']
    },
    {
      question: 'પૂજ્યશ્રી કઈ ટ્રેન માં ચઢ્યા હોવાની વાત કરે છે?',
      options: [
        'વડોદરા ની ટ્રેન',
        'દાદા ની ટ્રેન',
        'અડાલજ ની ટ્રેન',
        'મહાવિદેહ ની ટ્રેન'
      ],
      answer: ['દાદા ની ટ્રેન']
    },
    {
      question:
        'Bonus Question #2 (Aptavani-1): જ્ઞાનીમાં બાળક જેવી _તા હોય. બાળકને અણસમજણમાં _તા હોય, જ્યારે જ્ઞાનીમાં સંપૂર્ણ ટોચ પરની સમજસહિત તા હોય. તે પોતે _ દ્રષ્ટિ કરી સ્વયં _ થયા હોય અને આખાય જગતને _ જ જુએ !',
      options: ['વીતરાગ', 'શુદ્ધ', 'કારુણ્ય', 'નિર્દોષ'],
      answer: ['નિર્દોષ'],
      questionImage: 'bonus2.png'
    },
    {
      question: 'પૂજ્યશ્રી દિપકભાઈ ને રસોઈ બનાવતા કોણે શીખવાળ્યું હતું?',
      options: ['તેમના મમ્મી', 'તેમના બેન', 'નીરુમા', 'તેમના પપ્પા'],
      answer: ['નીરુમા']
    },
    {
      question: 'સૌથી પેહલા પૂજ્યશ્રી દિપકભાઈ એ શું બનાવ્યું હતું?',
      options: ['ગુલાબજાંબુ', 'રોટલી', 'બટાટા પૌહા', 'તીખી ભાખરી'],
      answer: ['તીખી ભાખરી']
    },
    {
      question: 'સ્કૂલ માં પૂજ્યશ્રી ને ક્યા નામે બધા બોલાવતા હતા?',
      options: ['દિપકભાઈ', 'D. R. દેસાઈ', 'D. I. દેસાઈ', 'દિપક દેસાઈ'],
      answer: ['D. I. દેસાઈ']
    },
    {
      question: 'પૂજ્યશ્રી ના mother નું નામ શું હતું?',
      options: ['ઝવેર બા', 'દિવાળી બા', 'રૂક્ષ્મણી બેન', 'ઇન્દુબેન'],
      answer: ['રૂક્ષ્મણી બેન']
    },
    {
      question: 'પૂજ્યશ્રી ના father નું નામ શું હતું?',
      options: ['મુળજીભાઈ', 'ઈન્દુભાઈ', 'બાલુભાઈ', 'કાંતિભાઈ'],
      answer: ['ઈન્દુભાઈ']
    },
    {
      question:
        'Bonus Question #3 (Aptavani-1): પૂર્વેના કાળમાં તો લોકો એટલા બધા ડેવલપ થયેલા હતા કે જ્ઞાનીની આંખ જોઈને તે _ ઓળખી જાય. જ્ઞાની પુરુષ સમય માત્ર _ વિહોણા ના હોય ! બાકી જ્ઞાની પુરુષને એમના ગુણોથી ઓળખવા જોઈએ. પણ એમના ગુણોની ઓળખાણ સામાન્ય મનુષ્યને શી રીતે પડે?',
      options: ['કારુણ્યતા', 'નિર્દોષતા', 'હૂંફ', 'વીતરાગતા'],
      answer: ['વીતરાગતા'],
      questionImage: 'bonus3.png'
    },
    {
      question: 'વિડીયો પ્રમાણે કોણ કોણ સાથે રહી શકે?',
      options: [
        'એક ડીપાર્ટમેન્ટ માં હોય તે',
        'પૂજ્યશ્રી ની સેવા માં રહે તે',
        'પૂજ્યશ્રી નું કહ્યું કરે તે',
        'એક ધ્યેયવાળા હોય તે'
      ],
      answer: ['એક ધ્યેયવાળા હોય તે']
    },
    {
      question:
        'વિડીયો પ્રમાણે પૂજ્યશ્રી ને વાત્સલ્ય માં સૌથી વધારે કોની સાથે વાતચીત કરવાનું ગમે?',
      options: ['નીરુમા', 'દાદા', 'દિપક અને શુદ્ધાત્મા જોડે', 'આપ્તપુત્રો'],
      answer: ['દિપક અને શુદ્ધાત્મા જોડે']
    },
    {
      question:
        'વિડીયો પ્રમાણે પૂજ્યશ્રી ને માઉન્ટ એવરેસ્ટ કરતા સીમંધર સિટી માં કેમ વધુ જવાનું ગમે?',
      options: [
        'સીમંધર સિટી માં ઠંડી ના પડે',
        'સીમંધર સિટી માં ભક્તિ ને જ્ઞાન માં રેહવાય',
        'સીમંધર સિટી માં આરામ કરાય',
        'સીમંધર સિટી માં બધા આપ્તપુત્રો હોય'
      ],
      answer: ['સીમંધર સિટી માં ભક્તિ ને જ્ઞાન માં રેહવાય']
    },
    {
      question:
        'વિડીયો પ્રમાણે પૂજ્યશ્રી ને કેવી એકટિંગ કરવાનું સૌથી વધુ અઘરું લાગે?',
      options: [
        'બધા ને હસાવવાની',
        'અમિતાભ બચ્ચન ની',
        'કોઈ પર ચિઢાવાની',
        'શુદ્ધાત્મા ની'
      ],
      answer: ['કોઈ પર ચિઢાવાની']
    },
    {
      question: 'પૂજ્યશ્રી દાદા ને મળ્યા તે પછી કેવા બન્યા?',
      options: ['ડહાપણવાળા', 'શાંત', 'આનંદી', 'બરકત વગર ના'],
      answer: ['ડહાપણવાળા']
    },
    {
      question: 'પૂજ્યશ્રી ના પિતાજી કેમ પરેશાન થતા હતા?',
      options: [
        'પૂજ્યશ્રી પરણતા નોહતા',
        'પૂજ્યશ્રી સત્સંગ માં જતા હતા',
        'પૂજ્યશ્રી તેમને રસોઈ કરી જમાડતા હતા',
        'A અને B બંને'
      ],
      answer: ['A અને B બંને']
    },
    {
      question:
        'Bonus Question #4 (Dadavani May-2005): દાદાશ્રી: આપણા મહાત્માઓને તો અહીં સત્સંગમાં પેલું આવું હસવાનું થાય ને, આ જે હાસ્ય છે ને, તે તીર્યંચગતિ આખી ઊડી જાય મહીં. આખા પરમાણુ જ ઊડી જાય એક જ ફેરામાં. તો કેટલા વખત આપણે આવું ચાલ્યું? તે આ બધાને ખોટ ભાંગી ગઈ બધી. ઘા રૂઝાઈ ગયાં બધા. તીર્યંચગતિનાં ઘા પડ્યા હોય મહીં. મહીં ઘા પડેલા હોય, આપણને યાદ આવ્યા કરે. એ ઘા પાછું રૂઝાય નહીં બળ્યો, રૂઝાય નહીં પાછો. એટલે આ અમે કરીએને ત્યારે ઘા-બા બધા રૂઝાય જાય અને મોક્ષને લાયક થાય, _ને લાયક થઈ જાય.',
      options: ['ત્રીજા આરા', 'ચોથા આરા', 'પાંચમાં આરા', 'છઠા આરા'],
      answer: ['ચોથા આરા'],
      preview: {
        type: 'video',
        title: 'સ્પેશિયલ વિડિયો!'
      }
    }
  ],
  'jj-100-part-10': [
    {
      question:
        'પૂજ્યશ્રીએ દાદા-નીરુમા સાથે ધીંગા મસ્તી ને બદલે શું કર્યું હતું?',
      options: ['તોફાન', 'રમતો રમવાની', 'હસવાનું ને આનંદ', 'B અને C બંને'],
      answer: ['હસવાનું ને આનંદ']
    },
    {
      question: 'પૂજ્યશ્રી બેનો ને કેમ ઝાડી નાખતા નથી?',
      options: [
        'બેનો માં ખુબ શક્તિ હોય છે',
        'બેનો ડાહ્યા હોય છે',
        'બધા માં શુદ્ધાત્મા ભગવાન હોય છે',
        'બેનો રડી પડે છે'
      ],
      answer: ['બધા માં શુદ્ધાત્મા ભગવાન હોય છે']
    },
    {
      question: 'પૂજ્યશ્રી નું ફેવરીટ પદ કયું છે?',
      options: [
        'સ્વપદ',
        'અપદ',
        'દાદા ભગવાન ના અસીમ જય જય કાર હો',
        'A અને C બંને'
      ],
      answer: ['A અને C બંને']
    },
    {
      question: 'પૂજ્યશ્રી ની સ્માયલ પર બધા ફિદા છે તેનું રાઝ શું છે?',
      options: [
        'અમિતાભ બચ્ચન ની એક્ટિંગ કરે છે',
        'ગાંઠો બધી ક્રોધ, માન, માયા, લોભ ખલાસ થઇ ગયા છે',
        'અંદર કષાય ને વિષય ના પરિણામ ખલાસ થયા છે',
        'B અને C બંને'
      ],
      answer: ['B અને C બંને']
    },
    {
      question: 'પૂજ્યશ્રી તેમના બર્થડે ના દિવસે શું કરે?',
      options: [
        'દાદા ભગવાનને પ્રાર્થના કરે,શક્તિઓ માંગે',
        'પાર્ટી, નાસ્તો કરે',
        'બધા ને પગે લાગી આશીર્વાદ લઇ લે',
        'A અને C બંને'
      ],
      answer: ['A અને C બંને']
    },
    {
      question: 'બેનો હું રહી ગઈ બોલે તે ડાયલોગ નો પૂજ્યશ્રી શું અર્થ કરે છે?',
      options: [
        'બેનો જેલસ થતી હોય છે',
        'બેનો ને બીજા જેવું જ જોતું હોય છે',
        'A અને B બંને',
        'બેનો દુઃખી થતી હોય છે'
      ],
      answer: ['A અને B બંને']
    },
    {
      question: 'Bonus Question #1: જન્મ-જયંતી ૧૦૦ ક્યાં ઉજવાઈ હતી?',
      options: ['અડાલજ', 'મેહસાણા', 'આણંદ', 'ભાદરણ'],
      answer: ['અડાલજ'],
      questionImage: 'bonus1.png'
    },
    {
      question: 'પૂજ્યશ્રી નો આગળ નો દાંત વાંકો કેમ છે?',
      options: [
        'ઝાડ પર થી પડી ગયેલા',
        'મિત્ર સાથે કુસ્તી કરતા પડી ગયેલા',
        'સાયકલ પર થી પડી ગયેલા',
        'દિવાલ સાથે ભટકાય ગયેલા'
      ],
      answer: ['મિત્ર સાથે કુસ્તી કરતા પડી ગયેલા']
    },
    {
      question: 'પૂજ્યશ્રી ને કયા ફેમસ સિંગર ના ગીતો ગમતા હતા?',
      options: ['મુકેશ', 'કિશોર કુમાર', 'મોહમ્મદ રફી', 'લતા મંગેશકર'],
      answer: ['મુકેશ']
    },
    {
      question:
        'પૂજ્યશ્રી ને કઈ રીતે ટ્રાવેલ કરવાનું ગમે છે પૂછતાં પૂજ્યશ્રી કયું જ્ઞાન હાજર કરે છે?',
      options: [
        'વિહારી વિહાર કરે છે હું નિરવિહારી જાણું છું',
        'હું ચાલતો જ નથી',
        'સંજોગો આવે તે પ્રમાણે પૂરું કરવાનું',
        'ઉપર ના બધા જ'
      ],
      answer: ['ઉપર ના બધા જ']
    },
    {
      question:
        'પૂજ્યશ્રી ને બીજો કોઈ મોહ નથી પણ ખાસ કરી ને એક વાત માટે ખાસ ધ્યાન રાખે છે, કે?',
      options: [
        'તેમના કપડાં ઈસ્ત્રી વાળા હોય',
        'વાળ વ્યવસ્થિત હોય લઘર-વઘર ના હોય',
        'સરસ મોટી ગાડી માં આવવાનું',
        'આપેલા તમામ'
      ],
      answer: ['વાળ વ્યવસ્થિત હોય લઘર-વઘર ના હોય']
    },
    {
      question:
        'પૂજ્યશ્રી નાના બાળકો ને રમાડી તરત પાછા માં-બાપ ને પાછા કેમ આપી દે?',
      options: [
        'પૂજ્યશ્રી ને નાના બાળકો ને રમાડવાનું ના ગમે',
        'બાળકો ભેંકડો માંડે તે ના ગમે',
        'બાળકો લાળ પાડે તે ના ગમે',
        'બાળકો ડાયપર બગાડે તે ના ગમે'
      ],
      answer: ['બાળકો ભેંકડો માંડે તે ના ગમે']
    },
    {
      question: 'પૂજ્યશ્રી નાનપણ માં ક્યારે રડી પડતા?',
      options: [
        'ફિલ્મ ના કરુણ સીન જોઈ ને',
        'કોઈ ને રડતા જોઈ ને, કોઈ ને દુઃખી જોવે તો',
        'કોઈ તેમને વઢે તો',
        'A અને B બંને'
      ],
      answer: ['A અને B બંને']
    },
    {
      question:
        'Bonus Question #2 (Dadavani November-2018): દાદાશ્રી: આપણે ત્યાં ત્રણ દિવસ ઉત્તમ, _A_, એક આ જન્મ જયંતિ અને _B_. તે દહાડે બીજા જોડે ભાંજગડ ના હોયને, એટલે અમે પેલા પૂર્ણ સ્વરૂપે એકાકાર હોઈએ ! હું (જ્ઞાની પુરુષ) પેલા સ્વરૂપની અંદર (દાદા ભગવાન જોડે) એકાકાર હોઉં, એટલે ફળ તેનું મળે ! તેથી દર્શન કરવાનું માહાત્મ્યને !',
      options: [
        'A = બેસતું વર્ષ ; B = ગુરુ પૂર્ણિમા',
        'A = ગુરુ પૂર્ણિમા ; B = દિવાળી',
        'A = મકર સંક્રાંતિ ; B = દિવાળી',
        'A = મકર સંક્રાંતિ ; B = બેસતું વર્ષ'
      ],
      answer: ['A = બેસતું વર્ષ ; B = ગુરુ પૂર્ણિમા'],
      questionImage: 'bonus2.png'
    },
    {
      question: 'પૂજ્યશ્રી ના મતે બાળકો કોની પાસે શોભે?',
      options: ['ટીચર પાસે', 'પૂજ્યશ્રી પાસે', 'માં-બાપ પાસે', 'ઉપર ના બધા'],
      answer: ['માં-બાપ પાસે']
    },
    {
      question: 'પૂજ્યશ્રી ને શા કારણે પેન્ટ શર્ટ ને બુટ પહેરવા પડ્યા હતા?',
      options: [
        'ફોટો પડાવા માટે',
        'વિઝા લેવા માટે',
        'ફરવા જવા માટે',
        'એક પણ નહિ'
      ],
      answer: ['વિઝા લેવા માટે']
    },
    {
      question:
        'જો પૂજ્યશ્રી ને એક દિવસ માટે ભારત ના પ્રેસિડેન્ટ બનાવે તો શુ કરે?',
      options: [
        'બધાને જ્ઞાનવિધિ માં બેસાડી દે',
        'ત્રિમંદિર ની વ્યવસ્થા માં મદદ કરે',
        'A અને B બંને ',
        'એક પણ નહિ'
      ],
      answer: ['A અને B બંને ']
    },
    {
      question: 'પૂજ્યશ્રી નું બાળપણ માં મોટા થઇ ને શું બનવાનું સપનું હતું?',
      options: ['ડૉક્ટર', 'વકીલ', 'એન્જીન્યર', 'એક પણ નહિ'],
      answer: ['એક પણ નહિ']
    },
    {
      question: 'દાદાશ્રી પૂજ્યશ્રી ને શું કેહતા હતા?',
      options: [
        'દુઃખીયા માં દુઃખીયો છે આ છોકરો',
        'સુખી માં સુખી છોકરો છે',
        'શાંત છોકરો છે',
        'A અને C બંને '
      ],
      answer: ['દુઃખીયા માં દુઃખીયો છે આ છોકરો']
    },
    {
      question: 'પૂજ્યશ્રી કોઈ સેવા કરવા આવે તો શું કરતા?',
      options: [
        'સેવા લેવા ની ના પાડી દેતા',
        'જે સેવા કરવા આવે એની સેવા કરતા',
        'સેવા કરાવી લેતા',
        'A અને B બંને '
      ],
      answer: ['જે સેવા કરવા આવે એની સેવા કરતા']
    },
    {
      question:
        'Bonus Question #3 (Dadavani November-2018): દાદાશ્રી: જેણે અમારા _ કર્યા છે, તેને અમારા પછી પણ લાઈટ (પ્રકાશ) મળ્યા કરશે. અમે છીએ ત્યાં સુધી લોકનું કામ થશે, પછી કંઈ વળશે નહીં. પ્રગટનું મહાતમ છે.',
      options: ['પરોક્ષ દર્શન', 'પ્રત્યક્ષ દર્શન'],
      answer: ['પ્રત્યક્ષ દર્શન'],
      questionImage: 'bonus3.png'
    },
    {
      question: 'પૂજ્યશ્રી ને શું બોજારૂપ લાગતું?',
      options: [
        'એમને કોઈ ની સેવા કરવી પડે એ',
        'એમને કામ કરવું પડે તે',
        'A અને B બંને ',
        'કોઈ એમની સેવા કરવા આવે તે'
      ],
      answer: ['કોઈ એમની સેવા કરવા આવે તે']
    },
    {
      question:
        'જો પૂજ્યશ્રી નું ફેમિલી વાત્સલ્ય માં રહેવા આવે તો પૂજ્યશ્રી શું કરે?',
      options: [
        'એમને વાત્સલ્ય માં રહેવા દેતા',
        'બીજે કશે વ્યવસ્થા કરી આપતા',
        'હોટેલ માં મોકલી દેતા',
        'ઘરે પાછા મોકલી દેતા'
      ],
      answer: ['બીજે કશે વ્યવસ્થા કરી આપતા']
    },
    {
      question:
        'વિડિઓ મુજબ પૂજ્યશ્રી ની ફેમિલી જેના બધા મહાત્માઓ આવે તેમના રહેવા માટે શું બની રહ્યું હતું?',
      options: ['સીમંધર સિટી', 'નિરાંત', 'એ.ટી.પી.એલ.', 'ડોરમેટોરી'],
      answer: ['સીમંધર સિટી']
    },
    {
      question: 'છોકરો પૂજ્યશ્રી ની જોડે ક્યા "પ્રાઈઝ" ની વાત કરે છે?',
      options: [
        'મિસ્ટર ઇન્ડિયા ટાઇટલ',
        'નોબલ પ્રાઈઝ',
        'દાદા સાહેબ ફાળકે એવોર્ડ',
        'એક પણ નહિ'
      ],
      answer: ['નોબલ પ્રાઈઝ']
    },
    {
      question: 'છોકરો પૂજ્યશ્રી ને શું કરવાની "રીક્વેસ્ટ" કરે છે?',
      options: ['પદ ગાવા ની', 'એકટિંગ કરવાની', 'ગરબા કરવાની', 'પિઝા ખાવાની'],
      answer: ['પદ ગાવા ની']
    },
    {
      question: 'પૂજ્યશ્રી કયું પદ ગાય છે?',
      options: [
        'જ્ઞાની હાજર છે અહીં...',
        'વીતરાગી સેના આ વીરો ની...',
        'વારસ અહો દાદા ના...',
        'વનરા ના બાળકો ફૂલ ઘણા જનમે...'
      ],
      answer: ['વનરા ના બાળકો ફૂલ ઘણા જનમે...']
    },
    {
      question:
        'Bonus Question #4 (Dadavani November-2018): દાદાશ્રી: જન્મજયંતી વખતેય આવજો ચોક્કસ, બધા ભેગા થાય ત્યારે. જન્મજયંતી અમારી ઉજવાય તે ઘડીએ ખુદ _ બેઠો હોય એના જ દર્શન થાય એટલે લોકોનું કામ નીકળી જાય ને ! તે દર્શન કંઈક ઓર જ હશે ! ફુલ દર્શન એટલા માટે લોક દોડધામ કરે, અને બધો સમૈયો ભેગો થાય અને લોકોના દર્શન થાય ને ! મહાત્માઓના દર્શન થાય ને ! મુંબઈથી આવે, અમદાવાદથી આવે, મદ્રાસથી આવે, કલકત્તાથી આવે બધા, એટલે લાભ ઉઠાવજો.',
      options: ['જ્ઞાની પુરુષ', 'આત્મા', 'પરમાત્મા', 'બાવો'],
      answer: ['પરમાત્મા'],
      questionImage: 'bonus4.png'
    }
  ],
  'mahavideh-javaanu': [
    {
      question:
        'હમણાં બે-ત્રણ મહાત્માના અવસાન થયા તો બધા શું કહે કે આ મહાત્માઓ ક્યાં ગયા?',
      options: ['સ્વર્ગ માં', 'દેવલોક માં', 'તિર્યંચ માં', 'મહાવિદેહ માં'],
      answer: ['મહાવિદેહ માં']
    },
    {
      question: 'એક અવતારીની મહાવિદેહ થી મોક્ષે જવાની શું લિમીટ છે?',
      options: ['બાર લાખ', 'સાડા બાર હજાર', 'ચૌદ લાખ', 'એક કરોડ'],
      answer: ['સાડા બાર હજાર']
    },
    {
      question:
        'મહાત્મા ને ચિંતા થતા કે ત્યાં નંબર નહીં લાગે ત્યારે નીરુમા શું કરવાનું કહે છે?',
      options: [
        'વહેલી તૈયારી કરો કે રહી ના જઈએ',
        'વધુ દાદા ના કામ કરો',
        'સીમંધર સ્વામી ની ભક્તિ કરો',
        'સીમંધર સીટી માં રહેવા આવી જાઓ'
      ],
      answer: ['વહેલી તૈયારી કરો કે રહી ના જઈએ']
    },
    {
      question: 'નીરુમા કહે છે કે કેવું મરણ થાય તો મહાવિદેહે જાય?',
      options: [
        'દુઃખદ મરણ',
        'એકસિડેન્ટલ મરણ',
        'સીમંધર સીટી માં મરણ',
        'સમાધી મરણ'
      ],
      answer: ['સમાધી મરણ']
    },
    {
      question:
        'Bonus Question #1: આપણી પૃથ્વીથી _A_ દિશામાં _B_ માઈલ દૂર મહાવિદેહ ક્ષેત્ર આવેલું છે.',
      options: [
        'A = ઈશાન ; B = લાખો',
        'A = ઉર્ધ્વ ; B = કરોડો',
        'A = ઉત્તર ; B = લાખો',
        'A = પૂર્વ ; B = કરોડો'
      ],
      answer: ['A = ઈશાન ; B = લાખો'],
      questionImage: 'bonus1.png'
    },
    {
      question:
        'આ જ્ઞાનમાં જે હશે અને છેલ્લી ઘડીએ "દાદા"નું નામ લેશે, તેની માટે દાદા શું કહે છે?',
      options: [
        'તેમને મૃત્યુનું દુઃખ ઓછું થશે',
        'ત્યારે દાદા તેમને હાજર થઇ જશે',
        'ત્યારે દાદા દેહ બચાવી લેશે',
        'તેમને મૃત્યુ સમયે આર્તધ્યાન ઓછુ થશે'
      ],
      answer: ['ત્યારે દાદા તેમને હાજર થઇ જશે']
    },
    {
      question:
        'સંસાર વ્યવહારમાં કોઈનું મરણ થાય તો વ્યવહારમાં સામાન્ય પણે શું કહે?',
      options: [
        'સ્વર્ગે ગયા, દેવ થયા',
        'મોક્ષે ગયા, સિદ્ધ થયા',
        'મહાવિદેહ ગયા',
        'ગયા છે, પાછા આવશે'
      ],
      answer: ['સ્વર્ગે ગયા, દેવ થયા']
    },
    {
      question: 'સીમંધર સ્વામીનું કેટલા વર્ષનું આયુષ્ય બાકી છે?',
      options: ['૧ કરોડ', 'દોઢ લાખ', 'પાંચ હજાર', 'પચીસસો વર્ષ'],
      answer: ['દોઢ લાખ']
    },
    {
      question:
        'Bonus Question #2: ભગવાન શ્રી સીમંધર સ્વામી મહાવિદેહ ક્ષેત્રમાં ક્યાં છે?',
      options: [
        'મહાવિદેહ ક્ષેત્રના આઠમા વિભાગ સોનેરી રાજધાની પુંડરીકગીરીમાં સીમંધર સ્વામી છે',
        'મહાવિદેહ ક્ષેત્રના દસમા વિભાગ ક્લાવતીની રાજધાની પુંડરીકગીરીમાં સીમંધર સ્વામી છે',
        'મહાવિદેહ ક્ષેત્રના કુલ ૩૨ વિભાગોમાંથી આઠમા વિભાગ પુષ્પક્લાવતીની રાજધાની પુંડરીકગીરીમાં સીમંધર સ્વામી છે',
        'મહાવિદેહ ક્ષેત્રના સાતમા વિભાગ પુષ્પકની રાજધાની પુંડરીકગીરીમાં સીમંધર સ્વામી છે'
      ],
      answer: [
        'મહાવિદેહ ક્ષેત્રના કુલ ૩૨ વિભાગોમાંથી આઠમા વિભાગ પુષ્પક્લાવતીની રાજધાની પુંડરીકગીરીમાં સીમંધર સ્વામી છે'
      ],
      questionImage: 'bonus2.png'
    },
    {
      question:
        'દાદાશ્રીના કહ્યા પ્રમાણે શાના દર્શન કર્યા હશે તો પણ મહાવિદેહમાં એન્ટ્રી મળી જશે?',
      options: [
        'દાદાશ્રીના ચરણોના',
        'દાદાશ્રીની પૂંઠના',
        'દાદાશ્રીના હાથના',
        'દાદાશ્રીના વાળના'
      ],
      answer: ['દાદાશ્રીની પૂંઠના']
    },
    {
      question:
        'વિડીયોમાં મહાત્મા કેટલા લોકો એકાવતારી થઇ ને મોક્ષે જવાના છે એની વાત કરે છે?',
      options: ['૧૧ લાખ', '૧૪ લાખ', '૧૫ લાખ', '૧૨ લાખ'],
      answer: ['૧૨ લાખ']
    },
    {
      question: 'Bonus Question #3: મહાવિદેહ ક્ષેત્ર માં કેવી રીતે જવાય?',
      options: [
        'પુણ્યથી જવાય',
        'દાદાની માત્ર ભક્તિ કરવાથી',
        'દાદાનું માત્ર નામ સ્મરણથી',
        'દાદાની આજ્ઞા પાળવાથી'
      ],
      answer: ['દાદાની આજ્ઞા પાળવાથી'],
      questionImage: 'bonus3.png'
    },
    {
      question: 'વસુબેન કોને કન્ફયુઝ કરી નાખે એવા છે?',
      options: ['દાદા ને', 'સીમંધર સ્વામી ને', 'નીરુમા ને', 'દીપકભાઈ ને'],
      answer: ['સીમંધર સ્વામી ને']
    },
    {
      question: 'આપણી દ્રષ્ટિ અને ભાવના પ્રમાણે દાદાનાં મહાત્મા ક્યાં જાય?',
      options: ['અડાલજ', 'અયોધ્યા', 'મહાવિદેહ ક્ષેત્રે', 'ઐરાવત ક્ષેત્રે'],
      answer: ['મહાવિદેહ ક્ષેત્રે']
    },
    {
      question: 'Bonus Question #4: મોક્ષમાર્ગ ખુલ્લો જ છે પણ વાયા _.',
      options: ['દેવલોક', 'ભરત ક્ષેત્ર', 'ઐરાવત ક્ષેત્ર', 'મહાવિદેહ ક્ષેત્ર'],
      answer: ['મહાવિદેહ ક્ષેત્ર']
    }
  ],
  'karm-bandhan-kai-rite': [
    {
      question: '_A_ કર્મ _B_.',
      options: [
        'A = આપણું શરીર ; B = કરે છે',
        'A = આપણી બુદ્ધિ ; B = કરે છે',
        'A = આપણને ભાસે છે ; B = હું કરું છું',
        'A = આપેલા તમામ ; B = આપેલા તમામ'
      ],
      answer: ['A = આપણું શરીર ; B = કરે છે']
    },
    {
      question: 'દાળ-ભાત-શાક-રોટલી કોનાં લીધે ખવાય છે?',
      options: [
        'શરીરના લીધે',
        'ખાવાનું બનાવનારના લીધે',
        'દાળ-ભાત-શાક-રોટલી પહોંચાડનારાના લીધે',
        'આપેલા તમામ'
      ],
      answer: ['આપેલા તમામ']
    },
    {
      question: 'ખાવા માટે શરીર _ .',
      options: ['જરૂર નથી', 'જરૂરી છે', 'માધ્યમ છે', 'એક પણ નહીં'],
      answer: ['માધ્યમ છે']
    },
    {
      question: 'અંદર કેવું રહેવું જોઈએ?',
      options: [
        'હું જ્ઞાતા-દ્રષ્ટા પરમાનંદી છું',
        'હું કર્તા નથી',
        'A અને B',
        'હું અકર્તા છું'
      ],
      answer: ['A અને B']
    },
    {
      question: 'કર્મ ક્યારે ન બંધાય?',
      options: [
        'હું કરું છું એમ માનવાથી',
        'બધાને દોષિત માનવાથી',
        'A અને B',
        'સંજોગો કરે છે એમ માને ત્યારે'
      ],
      answer: ['સંજોગો કરે છે એમ માને ત્યારે']
    },
    {
      question: 'આપણે ક્યારે માનવા લાગીએ છીએ કે મેં જ કર્યું છે?',
      options: [
        'બધું સારું થાય ત્યારે',
        'બધું ખરાબ થાય ત્યારે',
        'બધા વાહ વાહ કરે ત્યારે',
        'આપેલા તમામ'
      ],
      answer: ['બધું સારું થાય ત્યારે']
    },
    {
      question: 'અહંકાર શું માને છે?',
      options: ['મેં ખાધું', 'ખાવાનું સરસ છે', 'ખાવાનું ખરાબ છે', 'આપેલા તમામ'],
      answer: ['આપેલા તમામ']
    },
    {
      question: 'કયું વાક્ય સાચું છે?',
      options: [
        'આત્મા કર્તા છે',
        'અહંકાર સાચો કર્તા છે',
        'આત્મા અકર્તા છે',
        'શરીર કર્તા છે'
      ],
      answer: ['આત્મા અકર્તા છે']
    },
    {
      question: 'આ બધું કરી રહ્યું છે કોણ?',
      options: [
        'સાયન્ટીફીક સર્કમ્સસ્ટેનશીયલ એવીડેન્સીસ',
        'સુપીર્યર એનર્જી',
        'પ્યોર સોલ',
        'ઓથોરીટી ઓફ ધી ડિવાઈન'
      ],
      answer: ['સાયન્ટીફીક સર્કમ્સસ્ટેનશીયલ એવીડેન્સીસ']
    },
    {
      question: 'તમે પોતે ખરેખર કોણ છો?',
      options: ['અહંકાર', 'આત્મા', 'શરીર', 'સંયોગો'],
      answer: ['આત્મા']
    }
  ],
  'vhala-niruma': [
    {
      question: 'ડેમો. આજની ક્વિઝ કયા વિષય ઉપર છે?',
      options: ['દીપકભાઈ', 'નીરુમા', 'જગત કલ્યાણ ભાવના', 'દાદા'],
      answer: ['નીરુમા'],
      questionImage: 'love.png'
    },
    {
      question:
        'નીરુમાના કહેવા મુજબ તેમને દાદાજી મળ્યા એ કોની સેવાનું ફળ હતું?',
      options: ['ફાધરની', 'મધરની', 'ભાઈની', 'બહેનની'],
      answer: ['મધરની'],
      questionImage: 'seva.png'
    },
    {
      question: 'નીરુમા દાદાના દેહની એવી રીતે કાળજી લેતા જાણે પોતાનો _ ના હોય.',
      options: ['આત્મા', 'જીવ', 'દેહ', 'પ્રાણ'],
      answer: ['દેહ'],
      questionImage: 'deh.png'
    },
    {
      question: 'દાદાના કહેવા મુજબ નીરુમાને જબરજસ્ત _ ભાવ ઉત્પન્ન થયો છે.',
      options: ['સમર્પણ', 'વીતરાગ', 'અકર્તા', 'અહો'],
      answer: ['સમર્પણ'],
      preview: {
        type: 'video',
        title: 'સ્પેશિયલ વિડિયો',
        id: '2ej6Rvhfask'
      },
      questionImage: 'bhaav.png'
    },
    {
      question: 'નીરુમાના પિતાશ્રીનું કયા વર્ષે અવસાન થયું?',
      options: ['૧૯૫૮', '૧૯૬૩', '૧૯૮૬', '૧૯૬૮'],
      answer: ['૧૯૬૮'],
      questionImage: 'pitashree.png'
    },
    {
      question: 'નીરુમાને જ્ઞાન થયું ત્યારે તેમની ઉમર _વર્ષની હતી.',
      options: ['૨૦', '૨૨', '૨૩', '૨૬'],
      answer: ['૨૩'],
      questionImage: 'gnan.png'
    },
    {
      question: 'નીરુમાને દાદાજીએ આખા જગતના _થવાનું કહ્યું હતું.',
      options: ['ગુરુ', 'શિષ્ય', 'ફાધર', 'મધર'],
      answer: ['મધર'],
      questionImage: 'mother1.png'
    },
    {
      question: 'નીરુમા બ્રહ્મચારી બહેનો માટે અમેરિકાથી શું લાવ્યા હતા?',
      options: ['કપડાં', 'મીઠાઈઓ', 'સ્વેટર', 'ચોકલેટ'],
      answer: ['સ્વેટર'],
      questionImage: 'brahmachari.png'
    },
    {
      question: 'દાદાએ પહેલું પ્રોમિસ કોને આપ્યું હતું?',
      options: ['નીરુમાને', 'હીરાબાને', 'ઝવેરબાને', 'મણિબાને'],
      answer: ['હીરાબાને'],
      questionImage: 'promise.png'
    },
    {
      question: 'દાદાના કહેવા મુજબ નીરુમાનો આવતો અવતાર _ નો હશે.',
      options: ['પુરુષનો', 'સ્ત્રીનો', 'મોક્ષનો', 'વીતરાગનો'],
      answer: ['પુરુષનો'],
      questionImage: 'avtar.png'
    },
    {
      question: 'દાદાને કેવા કેવા પુડા ભાવતા હતા?',
      options: ['ગરમ', 'ઠંડા', 'નરમ', 'એક પણ નહી'],
      answer: ['ઠંડા'],
      questionImage: 'pudla.png'
    },
    {
      question: 'નીરુમાએ _ ની જેમ દાદાની નર્સરી કરી.',
      options: ['નર્સ', 'હીરાબા', 'ઝવેરબા', 'દીકરી'],
      answer: ['ઝવેરબા'],
      preview: {
        type: 'video',
        title: 'સ્પેશિયલ વિડિયો',
        id: 'HDQuzomk5GY'
      },
      questionImage: 'seva2.png'
    },
    {
      question: 'નીરુમાને વસ્તુ કરતાં કોની કિમત વધારે હતી?',
      options: ['ભાવની', 'પૈસાની', 'મોક્ષની', 'વ્યક્તિની'],
      answer: ['વ્યક્તિની'],
      questionImage: 'kimat.png'
    },
    {
      question: 'નીરુમાનો ધ્યેય શું હતો?',
      options: ['મોક્ષનો', 'જગત કલ્યાણનો', 'A અને B', 'ઉપરના એક પણ નહી'],
      answer: ['A અને B'],
      questionImage: 'mother2.png'
    },
    {
      question: 'નીરુમાને કેટલા ભાઈઓ હતા?',
      options: ['૪', '૫', '૬', '૩'],
      answer: ['૫'],
      questionImage: 'bhaio.png'
    },
    {
      question: 'નીરુમાનો અંતિમ સંદેશો _.',
      options: [
        'પ્રેમથી રહેજો... પ્રોમીસ?',
        'જોડે રહેજો... પ્રોમીસ?',
        'હસતાં રહેજો... પ્રોમીસ?',
        'જ્ઞાનમા રહેજો... પ્રોમીસ?'
      ],
      answer: ['પ્રેમથી રહેજો... પ્રોમીસ?'],
      questionImage: 'prem-svaroop.png'
    }
  ]
}
export default pageData
