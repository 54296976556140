<template>
  <div>
    <v-row class="justify-center mb-1">
      <span
        class="font-weight-bold primary--text"
        :class="$vuetify.breakpoint.mdAndUp ? 'text-h4' : 'text-h5'"
        >{{ $t(i18nTitle) }}</span
      >
    </v-row>
    <v-row class="justify-center mb-1">
      <v-img
        class="shrink"
        :width="$vuetify.breakpoint.smAndUp ? 250 : 150"
        contain
        src="../../assets/heading-bottom.svg"
      >
      </v-img>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    i18nTitle: {
      type: String,
      default: ''
    }
  }
}
</script>
