import Utils from '../util/Utils'

const pageData = [
  {
    level: '0',
    question: { gu: 'બાગમાં _ રમતાં હતાં.' },
    options: 'છોકરો,છોકરાં',
    answers: { gu: 'છોકરાં' }
  },
  {
    level: '0',
    question: { gu: 'નીરુમાના પિતાને પાંચ _ હતા. ' },
    options: 'છોકરો,છોકરાં',
    answers: { gu: 'છોકરાં' }
  },
  {
    level: '0',
    question: { gu: 'નીરુમા એમના માતા પિતા ના એક ના એક _ હતા.' },
    options: 'દીકરી, દીકરીઓ',
    answers: { gu: 'દીકરી' }
  },
  {
    level: '0',
    question: {
      gu: 'પૂજ્યશ્રી દિપકભાઈ અને નીરુમાએ દાદાની વાણી ચૌદ _ ના રૂપમાં લખી છે.'
    },
    options: 'આપ્તવાણી, આપ્તવાણીઓ',
    answers: { gu: 'આપ્તવાણીઓ' }
  },
  {
    level: '0',
    question: { gu: 'ભગવાન શ્રી કૃષ્ણ _ ના સંવર્ધક હતા.' },
    options: 'ગાય, ગાયો',
    answers: { gu: 'ગાયો' }
  },
  {
    level: '0',
    question: { gu: '_ રૂપિયાનો ખર્ચો થયો?' },
    options: 'કેટલું,કેટલા,કેટલી',
    answers: { gu: 'કેટલા' }
  },
  {
    level: '0',
    question: { gu: 'મેં દાદાજીનાં પદો _.' },
    options: 'સાંભળી,સાંભળ્યો,સાંભળ્યા',
    answers: { gu: 'સાંભળ્યા' }
  },
  {
    level: '0',
    question: { gu: 'ચાવી ના ઝૂડામાં ઘણી બધી _ હોય છે.' },
    options: 'ચાવી, ચાવીઓ',
    answers: { gu: 'ચાવીઓ' }
  },
  {
    level: '0',
    question: { gu: 'મેં નીરુમા ના રચિત સ્વરમણા ના ઘણા _ સાંભળ્યા છે.' },
    options: 'પદ, પદો',
    answers: { gu: 'પદો' }
  },
  {
    level: '0',
    question: { gu: 'સીમંધર સીટી માં _ ઘરો છે?' },
    options: 'કેટલા, કેટલું, કેટલો',
    answers: { gu: 'કેટલા' }
  },
  {
    level: '1',
    question: { gu: 'નીરુમા ના માતા પિતા ને પાંચ _ હતા અને એક _ હતી.' },
    options: 'દીકરો, દીકરાં, દીકરી, દીકરીઓ',
    answers: { gu: 'દીકરાં, દીકરી' }
  },
  {
    level: '1',
    question: {
      gu:
        'શ્રી સીમંધર સ્વામી ના સમોવસરણ માં સોના, ચાંદી, હીરા, માણિક ના _ હોય છે અને એક ખૂબ જ મોટું _ હોય છે જે કલ્પવૃક્ષ તરીકે ઓળખાય છે.'
    },
    options: 'બારણું, બારણાં, ઝાડ, ઝાડો',
    answers: { gu: 'બારણાં, ઝાડ' }
  },
  {
    level: '1',
    question: {
      gu: 'સમોવસરણ માં દેવ દેવીઓ _ _ ને સમોવસરણ ની બહાર રાખવામાં આવે છે.'
    },
    options: 'નું, ના, ને, ની, વિમાન, વિમાનો',
    answers: { gu: 'ના, વિમાનો' }
  },
  {
    level: '1',
    question: { gu: 'તમે કેરી _ કે કેરી નો રસ _?' },
    options: 'ખાધી, ખાધું, પીધી, પીધો',
    answers: { gu: 'ખાધી, પીધો' }
  },
  {
    level: '1',
    question: { gu: '_ માં બહુ બધાં _ ક્રિકેટ રમતાં હતાં.' },
    options: 'બગીચા, બગીચાઓ, છોકરાઓ,છોકરી,છોકરો',
    answers: { gu: 'બગીચા, છોકરાઓ' }
  },
  {
    level: '2',
    question: {
      gu:
        'પૂજ્ય નીરુમા અને પૂજ્ય દિપકભાઈ ના અથાક _ ના લીધે દાદાની વાણી મહાત્માઓ માટે ઓડિયો _ ના રૂપ માં, _ ના રૂપ માં, સત્સંગ વિડિઓ ના રૂપ માં ઉપલબ્ધ છે.'
    },
    options: 'પ્રયત્ન, પ્રયત્નો, કેસેટ, કેસેટો, પુસ્તક, પુસ્તકો',
    answers: { gu: 'પ્રયત્નો, કેસેટો, પુસ્તકો' }
  },
  {
    level: '2',
    question: {
      gu:
        'ત્રિમંદિર માં ઘણા દેવ _, વીતરાગ _ તથા ભગવાન શ્રી સીમંધર સ્વામી ને બેસાડવા માં આવે છે જ્યાં સવાર સાંજ તેમની _ કરવા માં આવે છે.'
    },
    options: 'દેવી, દેવીઓ, ભગવંત, ભગવંતો, આરતી, આરતીઓ',
    answers: { gu: 'દેવીઓ, ભગવંતો, આરતીઓ' }
  },
  {
    level: '2',
    question: {
      gu:
        'શ્રી સીમંધર સ્વામી ના સમોવસરણ માં _ નું ટોળું, _ નું ટોળું, _ નું ટોળું, _ અને _ ના અને ઘણા પશુ પક્ષીઓ ના ટોળા સ્વામી ની દેશના સાંભળે છે.'
    },
    options:
      'હાથી, હાથીઓ, ગાય, ગાયો, કબૂતર, કબૂતરો, ગધેડો, ગધેડાં, કૂતરું, કૂતરાં',
    answers: { gu: 'હાથીઓ, ગાયો, કબૂતરો, ગધેડાં, કૂતરાં' }
  }
]

const pageDataNew = Utils.fillInBlanksDataMassager(
  pageData,
  false,
  true,
  true,
  false,
  false,
  false
)

export default pageDataNew
