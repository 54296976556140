import Utils from '../util/Utils'
const pageData = [
  {
    level: '0',
    question: { gu: 'દાદાવાણી_ સપ્ટેમ્બર ૨૦૨૦માં ૨૫ વર્ષ પુરા થાય છે.' },
    options: 'માં,ને,થી,એ,નો,ની,નું',
    answers: { gu: 'ને' }
  },
  {
    level: '0',
    question: { gu: 'આ પાંચ આજ્ઞા_ હવે નિરંતર આરાધન થવું જોઈએ.' },
    options: 'માં,ને,થી,એ,નો,ની,નું',
    answers: { gu: 'નું' }
  },
  {
    level: '0',
    question: { gu: 'દાદાની વાણી વાંચવાથી અંદર_ આવરણો તૂટશે.' },
    options: 'માં,ને,થી,ના,એ,નો,ની,નું',
    answers: { gu: 'ના' }
  },
  {
    level: '0',
    question: { gu: 'નીરુમાએ દાદા_ સાથે ફરી તેમની વાણી ટેપ કરી.' },
    options: 'માં,ને,થી,એ,નો,ની,નું',
    answers: { gu: 'ની' }
  },
  {
    level: '0',
    question: { gu: 'જગત વેર_ બંધાયું છે.' },
    options: 'માં,ને,થી,એ,નો,ની,નું',
    answers: { gu: 'થી' }
  },
  {
    level: '0',
    question: { gu: 'નીરુમા_ કરેલી ટકોર આજે પણ મહાત્માઓને યાદ આવે છે.' },
    options: 'માં,ને,થી,એ,નો,નું',
    answers: { gu: 'એ' }
  },
  {
    level: '0',
    question: { gu: 'નીરુમાએ દાદા_ ખૂબ સેવા કરી હતી.' },
    options: 'માં,ને,થી,એ,નો,ની,નું',
    answers: { gu: 'ની' }
  },
  {
    level: '0',
    question: { gu: 'નીરુમાએ દાદા_ ખૂબ સેવા કરી હતી.' },
    options: 'માં,ને,થી,એ,નો,ની,નું',
    answers: { gu: 'ની' }
  },
  {
    level: '0',
    question: { gu: 'બે કલાકની જ્ઞાનવિધિ_ મનુષ્યની આખી દ્રષ્ટિ ફરી જાય છે.' },
    options: 'માં,ને,થી,એ,નો,ની,નું',
    answers: { gu: 'માં' }
  },
  {
    level: '0',
    question: { gu: 'આ તો મૂળ આત્મા_ વૈભવ પ્રાપ્ત થાય છે.' },
    options: 'માં,ને,થી,એ,નો,ની,નું',
    answers: { gu: 'નો' }
  },
  {
    level: '0',
    question: { gu: 'દાદાની વાણી નીરુમા_ ઝીલી લીધી.' },
    options: 'માં,ને,થી,એ,નો,ની,નું',
    answers: { gu: 'એ' }
  },
  {
    level: '0',
    question: { gu: 'દાદાનું જ્ઞાન એ કેવળજ્ઞાન_ મોબારું છે.' },
    options: 'માં,ને,થી,એ,નો,ની,નું',
    answers: { gu: 'નું' }
  },
  {
    level: '0',
    question: { gu: 'પૂજ્ય દાદાશ્રી પર ના મોહ_ પ્રશસ્ત રાગ કહેવાય છે.' },
    options: 'માં,ને,થી,એ,નો,ની,નું',
    answers: { gu: 'ને' }
  },
  {
    level: '0',
    question: { gu: 'તમામ લેપાયમાન ભાવો_ હું સર્વથા નિર્લેપ જ છું.' },
    options: 'માં,ને,થી,એ,નો,ની,નું',
    answers: { gu: 'થી' }
  },
  {
    level: '0',
    question: { gu: 'જ્ઞાનવિધિ_ દાદાશ્રી બોલાવે તેમ બોલવાનું હોય.' },
    options: 'માં,ને,થી,એ,નો,ની,નું',
    answers: { gu: 'માં' }
  },
  {
    level: '0',
    question: { gu: 'ગુરૂપૂર્ણિમા_ મહોત્સવ ખૂબ ધામધૂમથી ઉજવાયો હતો.' },
    options: 'માં,ને,થી,એ,નો,ની,નું',
    answers: { gu: 'નો' }
  },
  {
    level: '0',
    question: { gu: 'નીરુમા_ યાદથી આંખો ભીની થાય છે.' },
    options: 'માં,ને,થી,એ,નો,ની,નું',
    answers: { gu: 'ની' }
  },
  {
    level: '0',
    question: { gu: 'વ્યવસ્થિત એ દાદાશ્રી_ ઘણા અવતારનું સરવૈયું છે.' },
    options: 'માં,ને,થી,એ,નો,ની,નું',
    answers: { gu: 'નું' }
  },
  {
    level: '0',
    question: {
      gu:
        'દાદાશ્રી કહે છે અમે જ્ઞાન આપીએ પછી તમારે ફક્ત પાંચ આજ્ઞા_ જ રહેવાનું છે.'
    },
    options: 'માં,ને,થી,એ,નો,ની,નું',
    answers: { gu: 'માં' }
  },
  {
    level: '0',
    question: { gu: 'શુદ્ધાત્મામાં આવ્યા પછી વિજ્ઞાનઘન પદ_ જાણવું જોઈએ.' },
    options: 'માં,ને,થી,એ,નો,ની,નું',
    answers: { gu: 'ને' }
  },
  {
    level: '1',
    question: { gu: 'આ રીયલ _ આ રેલેટીવ નો ભેદ દાદા જ્ઞાન થકી કરાવે છે.' },
    options: 'અને,પણ,કારણ કે,માટે,અથવા',
    answers: { gu: 'અને' }
  },
  {
    level: '1',
    question: {
      gu: 'નીરુમાને ક્યારેય દાદાના દોષ નહોતા દેખાયા _ તેમનો પરમવિનય અવગાઢ હતો.'
    },
    options: 'અને,પણ,કારણ કે,માટે,અથવા',
    answers: { gu: 'કારણ કે' }
  },
  {
    level: '1',
    question: { gu: 'બુદ્ધિ તો ન્યાય ખોળે _ બીજાના દોષો દેખાડે.' },
    options: 'અને,પણ,કારણ કે,માટે,અથવા',
    answers: { gu: 'અને' }
  },
  {
    level: '1',
    question: { gu: 'મને ફોન આવ્યો _ મારાથી વાત થઇ નહીં.' },
    options: 'પણ,કારણ કે,માટે,અથવા',
    answers: { gu: 'પણ' }
  },
  {
    level: '1',
    question: {
      gu:
        'આત્મા અને અનાત્મા મિક્ષચર સ્વરૂપે ભેગા થાય છે _ દાદાશ્રી તેને જુદા પાડી શકે છે.'
    },
    options: 'અને,પણ,કારણ કે,તેથી,અથવા',
    answers: { gu: 'તેથી' }
  },
  {
    level: '1',
    question: {
      gu:
        'દાદાશ્રી આત્મા _ અનાત્માના પરમાણુ એ પરમાણુને પૃથ્થકરણ કરી, બન્ને છૂટા પાડી આપે છે.'
    },
    options: 'અને,પણ,કારણ કે,માટે,અથવા',
    answers: { gu: 'અને' }
  },
  {
    level: '1',
    question: { gu: 'ફળ _ ભાજી, કોઈ એક વસ્તુ ખા.' },
    options: 'અને,પણ,કારણ કે,માટે,અથવા',
    answers: { gu: 'અથવા' }
  },
  {
    level: '1',
    question: {
      gu: 'મંદિરે જવાનું મોળું થતું હતું _ બધા ફટાફટ ટેક્સીમાં બેસી ગયા.'
    },
    options: 'પણ,તેથી,અથવા',
    answers: { gu: 'તેથી' }
  },
  {
    level: '1',
    question: { gu: 'પોલીસ ચોરને જેલ લઇ ગયા, _ તેણે ચોરી કરતા પકડાયો હતો.' },
    options: 'અને,પણ,કારણ કે,માટે,અથવા',
    answers: { gu: 'કારણ કે' }
  },
  {
    level: '1',
    question: { gu: 'અમે ક્રિકેટ રમવા ગયા _ ત્યાં વરસાદ પાડવા લાગ્યો.' },
    options: 'પણ,કારણ કે,માટે,અથવા',
    answers: { gu: 'પણ' }
  },
  {
    level: '1',
    question: { gu: 'હમણાં તમને માત્ર રિક્ષા _ ટેક્સી જ મળશે.' },
    options: 'અને,પણ,કારણ કે,માટે,અથવા',
    answers: { gu: 'અથવા' }
  },
  {
    level: '1',
    question: { gu: 'તને સાઈકલ ચલાવતા આવાલતું નથી, _ તું હવે શીકી જા.' },
    options: 'અને,કારણ કે,માટે,અથવા',
    answers: { gu: 'માટે' }
  },
  {
    level: '1',
    question: { gu: 'મેં જ્ઞાન લીધું _ દુઃખો_ મુક્ત થયો.' },
    options: 'થી,માટે,પર,અને,માં',
    answers: { gu: 'અને,થી' }
  },
  {
    level: '2',
    question: {
      gu:
        'દાદાશ્રી_ ૧૯૫૮ માં આત્મજ્ઞાન પ્રાપ્ત થયું. પછી ૩૦ વર્ષ જ્ઞાનલહાણી કરી શક્યા. દાદાશ્રી_ કૃપાપાત્ર નીરુમા, નીરુમા દાદાશ્રી પાસેથી જ્ઞાન પ્રાપ્ત થયું. સૌના લાડલા પૂજ્યશ્રી ૧૭ વર્ષ_ વયે દાદા પાસે જ્ઞાન પામી, પૂજ્ય નીરુમા_ સહધ્યાયી રહી, છેલ્લા ૫૦ વષથી દાદાશ્રી_ સૂત્રધાર સમ, આપણી વચ્ચે આજે પ્રત્યક્ષ હાજર છે.'
    },
    options: 'ના,ની,ના,ના,ને',
    answers: { gu: 'ને,ના,ની,ના,ના' }
  },
  {
    level: '2',
    question: {
      gu:
        "વાણી _ આપણા સારા-નરસા અભિપ્રાયો, ભાવો કે લાગણીઓ_ અભિવ્યક્ત કરતું ખૂબ જ અસરદાર માધ્યમ અને આપણા વ્યક્તિત્વ_ અરિસો છે. જો એક તરફ વાણી દ્વારા નીકળેલા સારા શબ્દો સંબંધો_ સુધારે છે. આપણે ત્યાં કહેવત છે, 'તલવાર_ ઘા રૂઝાય પણ વાણી_ ઘા ન રૂઝાય.'"
    },
    options: 'ના,ને,ના,ન,એ,નો,ને',
    answers: { gu: 'એ,ને,નો,ને,ના,ના,ન' }
  },
  {
    level: '2',
    question: {
      gu:
        "કઠોર _ તંતીલી ભાષા_ બધા જ દોષો આ બે શબ્દો_ આવી જાય છે. એટલે નવરાશ_ ટાઈમ આવે તો 'દાદા ભગવાન' પાસે આપણે શક્તિઓ માંગ માંગ કરવી."
    },
    options: 'ના,ને,નો,માં,ની,નું,થી',
    answers: { gu: 'ને,ના,માં,નો' }
  },
  {
    level: '2',
    question: {
      gu:
        'આપણે વાણી એવી મધુર બોલવી જોઈએ, જેનાથી બીજા_ શીતળતા_ અનુભવ થાય. કડવા શબ્દો બોલવા _ સૌથી ખરાબ બાબત છે. કડવા શબ્દો_ કોઈપણ વાત_ સમાધાન થતું નથી.'
    },
    options: 'થી,એ,નું,નો,ને',
    answers: { gu: 'ને,નો,એ,થી,નું' }
  }
]

const pageDataNew = Utils.fillInBlanksDataMassager(
  pageData,
  false,
  true,
  true,
  false,
  false,
  false
)

export default pageDataNew
