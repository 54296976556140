import Utils from '../util/Utils'

const pageData = [
  {
    level: '0',
    question: {
      gu: 'આપણે બધા જમવાનું (snacks) _ ને ખાઈએ.'
    },
    options: 'વહેચી(distribute), વેચી(sell)',
    answers: { gu: 'વહેચી(distribute)' }
  },
  {
    level: '0',
    question: {
      gu: 'જૂની ગાડી (car) _ ને નવી ગાડી લઈશું.'
    },
    options: 'વહેચી(distribute), વેચી(sell)',
    answers: { gu: 'વેચી(sell)' }
  },
  {
    level: '0',
    question: { gu: 'સત્સંગ/પદો _ ને ખૂબ આનંદ થાય છે.' },
    answers: { gu: 'સાંભળી(Listen)' },
    options: 'સાંભળી(Listen),સંભાળી(taking-care)'
  },
  {
    level: '0',
    question: { gu: '_ તેની ભૂલ.' },
    answers: { gu: 'ભોગવે(Sufferer)' },
    options: 'ભોગવે(Sufferer),ભાગે(running)'
  },
  {
    level: '0',
    question: { gu: 'આવતી કાલે બધા ત્રિમંદિરે _.' },
    answers: { gu: 'જઈશું(will-go)' },
    options: 'જઈશું(will-go),જઈઆવ્યા(went)'
  },
  {
    level: '0',
    question: { gu: 'કોઈ ને _ નથી આપવું.' },
    answers: { gu: 'દુઃખ(Pain)' },
    options: 'સુખ(Happiness),દુઃખ(Pain)'
  },
  {
    level: '0',
    question: { gu: 'ગુરુપૂર્ણિમામાં બધા ને _ ખૂબ મજા આવશે.' },
    answers: { gu: 'મળવાની(to-meet)' },
    options: 'મારવાની(to-hit),મરવાની(to-die),મળવાની(to-meet)'
  },
  {
    level: '0',
    question: {
      gu: 'પૂજ્ય દાદાશ્રી એ કહ્યું છે કે ગુરુ સંસારમાં _ અને જ્ઞાની મોક્ષ આપે.'
    },
    answers: { gu: 'તારે' },
    options: 'હારે, તારે'
  },
  {
    level: '0',
    question: { gu: 'લોભ નો અર્થ એટલે _ પડાવી લેવું.' },
    answers: { gu: 'બીજાનું(Others)' },
    options: 'ભેજાનું(Brain),બીજાનું(Others),પોતાનું(Own)'
  },
  {
    level: '0',
    question: { gu: '_ માં ગરમ ગરમ જમવાનું જમી લો.' },
    answers: { gu: 'થાળી(Plate/Dish)' },
    options: 'વાણી(Speech),થાળી(Plate/Dish)'
  },
  {
    level: '0',
    question: { gu: '_ દીપક ભાઈને મારા વંદન.' },
    answers: { gu: 'પૂજ્યશ્રી' },
    options: 'પુજયશ્રી,પૂજ્યશ્રી,પૂજ્યશરી'
  },
  {
    level: '0',
    question: { gu: 'ઘણા યોગાસન _ માં બેસી ને થાય છે.' },
    answers: { gu: 'પદ્માસન' },
    options: 'પદ્માસન,પદ્મશન'
  },
  {
    level: '0',
    question: { gu: 'મને _ વર્ષ થયા છે.' },
    answers: { gu: 'તેત્રીસ' },
    options: 'ટેટ્રીસ,તેત્રીસ,ત્રાસ'
  },
  {
    level: '0',
    question: { gu: 'તું બહુ હોંશિયાર છે, તારા માં _ બહુ હોય.' },
    answers: { gu: 'બુદ્ધિ' },
    options: 'બધી,બુદ્ધિ,બુઢી'
  },
  {
    level: '0',
    question: {
      gu: 'જુદાપણાના _ વાક્યો ગોઠવી ફાઈલ-૧ અને શુદ્ધાત્મા જુદા જોવા.'
    },
    answers: { gu: 'ત્રણ' },
    options: 'ટ્રેન,ત્રણ,તારણ'
  },
  {
    level: '0',
    question: { gu: 'મને બધ્ધા ફળ ભાવે, પણ _ મને બધું પ્રિય છે.' },
    answers: { gu: 'દ્રાક્ષ' },
    options: 'દ્વેષ,દ્રાક્ષ,દ્રશ્ય'
  },
  {
    level: '0',
    question: {
      gu: 'રોજ ૧૦૦ થી વધારે _ સ્કૂલ ટ્રીપ (school trip) માં ત્રિમંદીર દર્શન કરવા આવે છે.'
    },
    answers: { gu: 'વિદ્યાર્થી' },
    options: 'વિદ્યાર્થી,વિવિધ'
  },
  {
    level: '0',
    question: {
      gu: 'હૈ _ વંદે એવા પ્રગટ એવા શુદ્ધાત્મા સ્વરૂપ પ્રભુ!'
    },
    answers: { gu: 'વિશ્વવંધ્ય' },
    options: 'વિશ્વવંધ્ય,વિષ'
  },
  {
    level: '0',
    question: { gu: 'પ્રાણાયામમાં ઊંડા _ લેવાના હોય છે.' },
    answers: { gu: 'શ્વાસ' },
    options: 'વિશ્વાસ,શ્વાસ'
  },
  {
    level: '0',
    question: {
      gu: 'સત્સંગ ના સંપર્ક માં રહેવાથી હવે જ્ઞાન માં ઘણી _ પડે છે.'
    },
    answers: { gu: 'સમજણ' },
    options: 'સમજણ,સજમણ'
  },
  {
    level: '0',
    question: { gu: 'નદીમાં _ વહી રહ્યું છે.' },
    answers: { gu: 'પાણી' },
    options: 'પાની,પાણી,વાણી'
  },
  {
    level: '0',
    question: { gu: 'આ _ ગરમ છે.' },
    answers: { gu: 'તપેલી' },
    options: 'ટપેલી,તપેલી,ટકલી,તાપોલી'
  },
  {
    level: '0',
    question: {
      gu: 'બગીચામાં જે છોકરી છે. _ નામ શીતલ છે. તે શું કરી રહી છે?'
    },
    answers: { gu: 'તેનું' },
    options: 'તેનું,તેણી,તું,તમે'
  },
  {
    level: '0',
    question: { gu: 'હું મારા ફ્રેન્ડ _ સાથે ફરવા જવું છું.' },
    answers: { gu: 'સર્કલ' },
    options: 'વર્તુળ,ગોળ,સર્કલ'
  },
  {
    level: '0',
    question: {
      gu: 'ગુરુ કેટલીક ધાર્મિક વિધિઓ કે _ કરવા બાબત ઉપદેશ આપતા હતા.'
    },
    answers: { gu: 'ક્રિયા' },
    options: 'કૃતિ,કર્મ,ક્રિયા,કાર્ય'
  },
  {
    level: '0',
    question: { gu: 'ક્રિયાથી માંડી _ બધું જ બધા ધર્મનું અલગ હોય છે.' },
    answers: { gu: 'નિયમો' },
    options: 'ફરજો,નિયમો,હક'
  },
  {
    level: '0',
    question: { gu: 'તમને આ કાર્ય _ આવે તો તમે આ કરો.' },
    answers: { gu: 'અનુકૂળ' },
    options: 'સાનુકૂળ,અનુકૂળ'
  },
  {
    level: '0',
    question: { gu: 'બધા જે _ ઉછર્યા હોય એવો વર્તાવ કરે.' },
    answers: { gu: 'વાતાવરણમાં' },
    options: 'આવરણમાં,વરણમાં,વાતાવરણમાં,વ્યવહારમાં'
  },
  {
    level: '0',
    question: { gu: 'તલવારના ઘા રૂઝાય પણ _ ઘા ન રૂઝાય.' },
    answers: { gu: 'વાણીના' },
    options: 'પાનીના,વાણીના,કરણી,વ્યવહાર'
  },
  {
    level: '0',
    question: {
      gu: 'આપણે વાણી એવી _ બોલવી જોઈએ, જેનાથી બીજા ને શીતળતા નો અનુભવ થાય.'
    },
    answers: { gu: 'મીઠી' },
    options: 'કડવી,મીઠી'
  },
  {
    level: '0',
    question: {
      gu: 'માનવી ટેક્નોલોજી માં જેટલો એડવાન્સ થતો જાય છે એટલો _ શાંતિ ગુમાવતો જાય છે.'
    },
    answers: { gu: 'અંતર' },
    options: 'બહારની,અંતર'
  },
  {
    level: '0',
    question: {
      gu: '_ અને શોક વચ્ચે હિલોળા ખાતી આ ટીનેજ અંતે ડીપ્રેસન સુધી જય ને પહોંચે છે.'
    },
    answers: { gu: 'આનંદ' },
    options: 'આણંદ,આનંદ,કંદ,વાળંદ'
  },
  {
    level: '0',
    question: { gu: 'દુઃખો _ અણસમજણ ના હોય છે.' },
    answers: { gu: 'માત્ર' },
    options: 'સત્ર,માત્ર,માત્રા,છાત્ર'
  },
  {
    level: '0',
    question: { gu: 'મેઘરાજાની _ બધા રાહ જોઈ રહ્યા હતા.' },
    answers: { gu: 'મહેરની' },
    options: 'મહેરની,લહેરની,કહેરની'
  },
  {
    level: '0',
    question: { gu: 'રામાયણ નો નવો _ શરૂ થવાનો હતો.' },
    answers: { gu: 'અધ્યાય' },
    options: 'સ્વાધ્યાય,અધ્યાય,અદ્યપન,સ્વધ્યાપન'
  },
  {
    level: '0',
    question: { gu: 'બારી માંથી સીધા જ રૂમમાં પ્રવેશતા _ ગતિ વધવા લાગી.' },
    answers: { gu: 'પવનની' },
    options: 'પવનની,પાવનની,સાવનની'
  },
  {
    level: '0',
    question: { gu: 'સફેદ રંગનું બીજું નામ _ છે.' },
    answers: { gu: 'શ્વેત' },
    options: 'શ્રોતા,શ્વેત,શ્વેતા'
  },
  {
    level: '0',
    question: { gu: 'આ નોટબુક લખનારના _ સારા છે.' },
    answers: { gu: 'અક્ષર' },
    options: 'અક્ષર,ક્ષર,સાક્ષર,સાક્ષરતા'
  },
  {
    level: '0',
    question: {
      gu: 'અરીસા સામે ઉભી રહી મેં પોતાને એક સરસ કોન્ફિડન્ટ _ આપી.'
    },
    answers: { gu: 'સ્માઈલ' },
    options: 'હારવાનું,આંસુ,સ્માઈલ,હસવાનું'
  },
  {
    level: '0',
    question: { gu: 'આત્મા જાણ્યો એણે _ જાણ્યું.' },
    answers: { gu: 'સર્વ' },
    options: 'સરવ,સર્વ,સર્વઃ'
  }
]

const pageDataNew = Utils.fillInBlanksDataMassager(
  pageData,
  false,
  true,
  true,
  true,
  false,
  false
)

export default pageDataNew
