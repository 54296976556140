import Utils from '../util/Utils'

const pageData = [
  {
    level: 0,
    levelDescription: 'Beginner',
    question: {
      gu: 'હું સત્સંગ _ હતો.',
      en: 'I was listening to satsang.',
      hi: 'में सत्संग सुन रहा था |'
    },
    options: 'સાંભળતું, સાંભળતો, સાંભળતી, સાંભળતાં',
    answers: {
      en: 'was listening',
      gu: 'સાંભળતો',
      hi: 'सुन रहा'
    }
  },
  {
    level: 0,
    levelDescription: 'Beginner',
    question: {
      gu: 'તમે ક્યાં _ હતા?',
      en: 'Where had you gone ?',
      hi: 'आप कहाँ गए थे ?'
    },
    options: 'ગયું, ગયો, ગઈ, ગયા',
    answers: {
      en: 'had you gone',
      gu: 'ગયા',
      hi: 'गए'
    }
  },
  {
    level: 0,
    levelDescription: 'Beginner',
    question: {
      gu: 'તેઓ સુખડી _ હતા.',
      en: 'They were eating Sukhadi.',
      hi: 'वे सुखड़ी खा रहे थे |'
    },
    options: 'ખાતા, ખાતી, ખાતો, ખાતું',
    answers: {
      en: 'were eating',
      gu: 'ખાતા',
      hi: 'खा'
    }
  },
  {
    level: 0,
    levelDescription: 'Beginner',
    question: {
      gu: 'તે શરબત મીઠું _.',
      en: 'That sherbet (sweet drink) was sweet.',
      hi: 'वह शरबत मीठा था |'
    },
    options: 'હતો, હતી, હતા, હતું',
    answers: {
      en: 'was',
      gu: 'હતું',
      hi: 'था'
    }
  },
  {
    level: 0,
    levelDescription: 'Beginner',
    question: {
      gu: 'અમે જમવા ગયા _.',
      en: 'We had gone for eating.',
      hi: 'हम खाना खाने गए थे |'
    },
    options: 'હતો, હતી, હતા, હતું',
    answers: {
      en: 'had gone',
      gu: 'હતા',
      hi: 'थे'
    }
  },
  {
    level: 0,
    levelDescription: 'Beginner',
    question: {
      gu: 'તે આરતી કરતી _.',
      en: 'She was doing aarti.',
      hi: 'वह आरती कर रही थी |'
    },
    options: 'હતો, હતી, હતા, હતું',
    answers: {
      en: 'was doing',
      gu: 'હતી',
      hi: 'थी'
    }
  },
  {
    level: 0,
    levelDescription: 'Beginner',
    question: {
      gu: 'તમે દર્શન _?',
      en: 'Did you do Darshan ?',
      hi: 'आपने दर्शन किए ?'
    },
    options: 'કર્યો, કરી, કર્યા, કરો',
    answers: {
      en: 'do',
      gu: 'કર્યા',
      hi: 'किए'
    }
  },
  {
    level: 0,
    levelDescription: 'Beginner',
    question: {
      gu: 'તેઓએ જ્ઞાન _.',
      en: 'They took gnan.',
      hi: 'उन्होंने ज्ञान लिया |'
    },
    options: 'લીધું, લીધો, લીધી, લીધા',
    answers: {
      en: 'took',
      gu: 'લીધું',
      hi: 'लिया'
    }
  },
  {
    level: 0,
    levelDescription: 'Beginner',
    question: {
      gu: 'હું _.',
      en: 'I came.',
      hi: 'मैं आया |'
    },
    options: 'આવ્યો, આવ્યા',
    answers: {
      en: 'came',
      gu: 'આવ્યો',
      hi: 'आया'
    }
  },
  {
    level: 0,
    levelDescription: 'Beginner',
    question: {
      gu: 'તું ગુજરાતી બોલવાનું _?',
      en: 'Did you learn to speak Gujarati ?(feminine)',
      hi: 'तू गुजराती बोलना सीखी ?'
    },
    options: 'શીખ્યા, શીખી',
    answers: {
      en: 'did you learn',
      gu: 'શીખી',
      hi: 'सीखी'
    }
  },
  {
    level: 0,
    levelDescription: 'Beginner',
    question: {
      gu: 'ગઈ કાલે શાળામાં રજા _.',
      en: 'Yesterday was holiday in the school.',
      hi: 'कल स्कूल में छुट्टी थी |'
    },
    options: 'હતો, હતું, હતી, હતાં',
    answers: {
      en: 'was',
      gu: 'હતી',
      hi: 'थी'
    }
  },
  {
    level: 0,
    levelDescription: 'Beginner',
    question: {
      gu: 'અમે ફરવા _ હતાં.',
      en: 'We had gone to roam around.',
      hi: 'हम घुमने गए थे |'
    },
    options: 'ગયું, ગયો, ગઈ, ગયા',
    answers: {
      en: 'had gone',
      gu: 'ગયા',
      hi: 'गए'
    }
  },
  {
    level: 0,
    levelDescription: 'Beginner',
    question: {
      gu: 'તમે જાદુનો શો _ ?',
      en: 'Did you see the magic show ?',
      hi: 'आपने जादू का शो देखा ?'
    },
    options: 'જોયો, જોયું, જોઈ, જોયા',
    answers: {
      en: 'Did you see',
      gu: 'જોયો',
      hi: 'देखा'
    }
  },
  {
    level: 0,
    levelDescription: 'Beginner',
    question: {
      gu: 'શું દહીં જામી _ ?',
      en: 'Did the curd freeze ?',
      hi: 'क्या दही जम गया ?'
    },
    options: 'ગયો, ગયું, ગઈ, ગયા',
    answers: {
      en: 'Did the curd freeze',
      gu: 'ગયું',
      hi: 'गया'
    }
  },
  {
    level: 0,
    levelDescription: 'Beginner',
    question: {
      gu: 'તે પુરસ્કાર (એવોર્ડ) _.',
      en: 'You won the award.',
      hi: 'तुमने पुरस्कार (अवार्ड) जीता |'
    },
    options: 'જીતી, જીત્યું, જીત્યો, જીત્યા',
    answers: {
      en: 'won',
      gu: 'જીત્યો',
      hi: 'जीता'
    }
  },
  {
    level: 0,
    levelDescription: 'Beginner',
    question: {
      gu: 'હું ગીત ગાતો _.',
      en: 'I was singing a song.',
      hi: 'में गीत गाता था |'
    },
    options: 'હતો, હતું, હતી, હતાં',
    answers: {
      en: 'was singing',
      gu: 'હતો',
      hi: 'था'
    }
  },
  {
    level: 0,
    levelDescription: 'Beginner',
    question: {
      gu: 'તેઓ _ હતાં.',
      en: 'They were dancing.',
      hi: 'वे नाचते थे |'
    },
    options: 'નાચતી, નાચતું, નાચતાં, નાચતો',
    answers: {
      en: 'were dancing',
      gu: 'નાચતાં',
      hi: 'नाचते'
    }
  },
  {
    level: 0,
    levelDescription: 'Beginner',
    question: {
      gu: 'બાળકો તોફાન _ હતાં.',
      en: 'The children were making mischief.',
      hi: 'बच्चे शरारत करते थे |'
    },
    options: 'કરતો, કરતી, કરતું, કરતાં',
    answers: {
      en: 'were making',
      gu: 'કરતાં',
      hi: 'करते'
    }
  },
  {
    level: 0,
    levelDescription: 'Beginner',
    question: {
      gu: 'બધાએ જ્ઞાન _.',
      en: 'Everyone took Gnan.',
      hi: 'सबने ज्ञान लिया |'
    },
    options: 'લીધો, લીધું, લીધા, લીધી',
    answers: {
      en: 'took',
      gu: 'લીધું',
      hi: 'लिया'
    }
  },
  {
    level: 0,
    levelDescription: 'Beginner',
    question: {
      gu: 'તેઓ સત્સંગમાં _ હતાં.',
      en: 'They were sitting in satsang.',
      hi: 'वे सत्संग में बैठे थे |'
    },
    options: 'બેઠા, બેઠી, બેઠો, બેઠું',
    answers: {
      en: 'were sitting',
      gu: 'બેઠા',
      hi: 'बैठे'
    }
  },
  {
    level: 0,
    levelDescription: 'Beginner',
    question: {
      gu: 'મેં કબાટ _.',
      en: 'I arranged the cupboard.',
      hi: 'मैंने अलमारी व्यवस्थित की |'
    },
    options: 'ગોઠવ્યું, ગોઠવ્યો, ગોઠવ્યા, ગોઠવી',
    answers: {
      en: 'arranged',
      gu: 'ગોઠવ્યો',
      hi: 'व्यवस्थित की'
    }
  },
  {
    level: 0,
    levelDescription: 'Beginner',
    question: {
      gu: 'તેઓએ સામાન _.',
      en: 'They unloaded the luggage.',
      hi: 'उन्होंने सामान उतारा |'
    },
    options: 'ઉતારી, ઉતાર્યું, ઉતાર્યો,ઉતાર્યા',
    answers: {
      en: 'unloaded',
      gu: 'ઉતાર્યો',
      hi: 'उतारा'
    }
  },
  {
    level: 0,
    levelDescription: 'Beginner',
    question: {
      gu: 'તું આજે કેટલા વાગે _ ?',
      en: 'At what time did you wake up today?',
      hi: 'तुम आज कितने बजे जागे ?'
    },
    options: 'ઉઠયા, ઉઠયું,ઉઠયો,ઉઠાડયો',
    answers: {
      en: 'did you wake up',
      gu: 'ઉઠયો',
      hi: 'जागे'
    }
  },
  {
    level: 0,
    levelDescription: 'Beginner',
    question: {
      gu: 'તમે આ સુવિચારો _ ?',
      en: 'Did you write these good thoughts ?',
      hi: 'आपने ये सुविचार लिखे ?'
    },
    options: 'લખ્યા, લખી, લખ્યો, લખ્યું',
    answers: {
      en: 'Did you write',
      gu: 'લખ્યા',
      hi: 'लिखे'
    }
  },
  {
    level: 0,
    levelDescription: 'Beginner',
    question: {
      gu: 'આપણે સમયસર _.',
      en: 'We reached on time.',
      hi: 'हम समय पर पहुंचे |'
    },
    options: 'પહોંચી, પહોંચ્યા, પહોચ્યું, પહોંચ્યો',
    answers: {
      en: 'reached',
      gu: 'પહોંચ્યા',
      hi: 'पहुंचे'
    }
  },
  {
    level: 1,
    levelDescription: 'Intermediate',
    question: {
      gu: 'ગઈ કાલે હું ત્રિમંદિરનાં દર્શને _.',
      en: 'Yesterday I had gone for Trimandir Darshan. (masculine)',
      hi: 'कल में त्रिमंदिर दर्शन करने गया था |'
    },
    options: 'ગયા હતા, ગયો હતો, ગયું હતું',
    answers: {
      en: 'had gone',
      gu: 'ગયો હતો',
      hi: 'गया था'
    }
  },
  {
    level: 1,
    levelDescription: 'Intermediate',
    question: {
      gu: 'અમે બધાં દાદાનગર હોલમાં સામાયિક _.',
      en: 'We all were doing Samayik in Dada Nagar Hall.',
      hi: 'हम सब दादानगर होल में सामायिक कर रहे थे |'
    },
    options: 'કરતી હતી, કરતા હતા, કરતો હતો, કરતું હતું',
    answers: {
      en: 'were doing',
      gu: 'કરતા હતા',
      hi: 'कर रहे थे'
    }
  },
  {
    level: 1,
    levelDescription: 'Intermediate',
    question: {
      gu: 'નીરુમાનો પુરુષાર્થ પરાક્રમ સુધી _.',
      en: "Niruma's purusharth had reached till parakram.",
      hi: 'नीरुमा का पुरुषार्थ पराक्रम तक पहुँचा था |'
    },
    options: 'પહોંચી હતી, પહોંચ્યા હતા, પહોંચ્યો હતો, પહોંચ્યું હતું',
    answers: {
      en: 'had reached',
      gu: 'પહોંચ્યો હતો',
      hi: 'पहुँचा था'
    }
  },
  {
    level: 1,
    levelDescription: 'Intermediate',
    question: {
      gu: 'કૃષ્ણ ભગવાને અર્જુનને આત્માનું જ્ઞાન _.',
      en: 'Lord Krishna had given atamgnan to Arjuna.',
      hi: 'कृष्ण भगवान ने अर्जुन को आत्मा का ज्ञान दिया था |'
    },
    options: 'આપ્યું હતું, આપી હતી, આપ્યો હતો, આપ્યા હતા',
    answers: {
      en: 'had given',
      gu: 'આપ્યું હતું',
      hi: 'दिया था'
    }
  },
  {
    level: 1,
    levelDescription: 'Intermediate',
    question: {
      gu: 'તમને કયું પદ _?',
      en: 'Which pad (hymn) did you like ?',
      hi: 'आप को कौन सा पद अच्छा लगा ?'
    },
    options: 'ગમ્યું, ગમ્યો, ગમી, ગમો',
    answers: {
      en: 'did you like',
      gu: 'ગમ્યું',
      hi: 'अच्छा लगा'
    }
  },
  {
    level: 1,
    levelDescription: 'Intermediate',
    question: {
      gu: 'તે દિવસે મેં નીરુમાના હાથે સુખડી _.',
      en: "That day I ate Sukhadi from Niruma's hands.",
      hi: 'उस दिन मैंने नीरुमा के हाथों से सुखडी खाई |'
    },
    options: 'ખાધો, ખાધી, ખાધું, ખાધા',
    answers: {
      en: 'ate',
      gu: 'ખાધી',
      hi: 'खाई'
    }
  },
  {
    level: 1,
    levelDescription: 'Intermediate',
    question: {
      gu: 'તેઓએ બધાં બાળકોને આશીર્વાદ _.',
      en: 'They gave blessings to all the children.',
      hi: 'उन्होंने सभी बच्चों को आशीर्वाद दिए |'
    },
    options: 'આપ્યો, આપી, આપ્યાં',
    answers: {
      en: 'gave',
      gu: 'આપ્યાં',
      hi: 'दिए'
    }
  },
  {
    level: 1,
    levelDescription: 'Intermediate',
    question: {
      gu: 'તમે જ્ઞાની સંગે જાત્રા _?',
      en: 'Did you experience a Jatra with the Gnani?',
      hi: 'आप ने ज्ञानी के साथ जात्रा का आनंद लिया ?'
    },
    options: 'માણી, માણ્યું, માણ્યો, માણ્યા',
    answers: {
      en: 'Did you experience',
      gu: 'માણી',
      hi: 'आनंद लिया'
    }
  },
  {
    level: 1,
    levelDescription: 'Intermediate',
    question: {
      gu: 'મારી બહેનપણી જોડે હું દાદા દરબારમાં _.',
      en: 'I went to Dada Darbar with my friend.',
      hi: 'मेरी सहेली के साथ मैं दादा दरबार में गई |'
    },
    options: 'ગયો, ગઈ, ગયું, ગયા',
    answers: {
      en: 'went',
      gu: 'ગઈ',
      hi: 'गई'
    }
  },
  {
    level: 1,
    levelDescription: 'Intermediate',
    question: {
      gu: 'તે તારા દોષને _.?',
      en: 'Did you see your fault?',
      hi: 'तुने अपने दोष को देखा |'
    },
    options: 'જોયો, જોઈ, જોયું',
    answers: {
      en: 'Did you see',
      gu: 'જોયો',
      hi: 'देखा'
    }
  },
  {
    level: 1,
    levelDescription: 'Intermediate',
    question: {
      gu: 'તેજસ વકતૃત્વ સ્પર્ધામાં ઈનામ _.',
      en: 'Tejas won the prize in Elocution competition.',
      hi: 'तेजस वक्तृता प्रतियोगिता में पुरस्कार जीता |'
    },
    options: 'જીત્યું, જીત્યો, જીતી, જીત્યા',
    answers: {
      en: 'won',
      gu: 'જીત્યો',
      hi: 'जीता'
    }
  },
  {
    level: 1,
    levelDescription: 'Intermediate',
    question: {
      gu: 'મેં ગઈ કાલે નવા પદો _.',
      en: 'Yesterday, I listened new Pads.',
      hi: 'मैंने कल नए पद सुने |'
    },
    options: 'સાંભળી, સાંભળ્યું, સાંભળ્યો, સાંભળ્યા',
    answers: {
      en: 'listened',
      gu: 'સાંભળ્યા',
      hi: 'सुने'
    }
  },
  {
    level: 1,
    levelDescription: 'Intermediate',
    question: {
      gu: 'અમે દરિયા કિનારે _.',
      en: 'We were sitting on the beach.',
      hi: 'हम समुद्र तट पर बैठे थे |'
    },
    options: 'બેઠી હતી, બેઠું હતું, બેઠા હતાં, બેઠો હતો',
    answers: {
      en: 'were sitting',
      gu: 'બેઠા હતાં',
      hi: 'बैठे थे'
    }
  },
  {
    level: 1,
    levelDescription: 'Intermediate',
    question: {
      gu: 'શિબિરમાં ઘણાં મહાત્માઓએ સેવા _.',
      en: 'Many Mahatmas had given seva in the shibir.',
      hi: 'शिबिर में अनेक महात्माओं ने सेवा दी थी |'
    },
    options: 'આપી હતી, આપ્યું હતું, આપતા હતાં, આપતો હતો',
    answers: {
      en: 'had given',
      gu: 'આપી હતી',
      hi: 'दी थी'
    }
  },
  {
    level: 1,
    levelDescription: 'Intermediate',
    question: {
      gu: 'રાજકોટ જ્ઞાનવિધિમાં ઘણાં નવા મહાત્માઓએ જ્ઞાન _.',
      en: 'Many new mahatmas took Gnan in Rajkot Gnanvidhi.',
      hi: 'राजकोट ज्ञानविधि में कई नए महात्माओं ने ज्ञान लिया |'
    },
    options: 'લીધાં, લીધી, લીધો, લીધું',
    answers: {
      en: 'took',
      gu: 'લીધું',
      hi: 'लिया'
    }
  },
  {
    level: 1,
    levelDescription: 'Intermediate',
    question: {
      gu: 'PMHT શિબિરમાં ઘણો આનંદ _.',
      en: 'Felt a lot of happiness in PMHT shibir.',
      hi: 'PMHT शिबिर में बहुत आनंद आया |'
    },
    options: 'આવ્યો, આવી, આવ્યા, આવ્યું',
    answers: {
      en: 'Felt',
      gu: 'આવ્યો',
      hi: 'आया'
    }
  },
  {
    level: 1,
    levelDescription: 'Intermediate',
    question: {
      gu: 'અમે ગુરુપૂર્ણિમા નિમિત્તે દર્શન કરવા _.',
      en: 'We went for darshan on the occasion of Gurupurnima.',
      hi: 'हम गुरुपूर्णिमा के अवसर पर दर्शन करने गए थे |'
    },
    options: 'ગઈ હતી, ગયા હતાં, ગયો હતો, ગયું હતું',
    answers: {
      en: 'went',
      gu: 'ગયા હતાં',
      hi: 'गए थे'
    }
  },
  {
    level: 1,
    levelDescription: 'Intermediate',
    question: {
      gu: 'બાળકો બગીચામાં સંતાકૂકડી _.',
      en: 'The children were playing Hide & Seek in the garden.',
      hi: 'बच्चे बगीचे में लुकाछिपी खेल रहे थे |'
    },
    options: 'રમતા હતાં, રમતો હતો, રમતી હતી, રમતું હતું',
    answers: {
      en: 'were playing',
      gu: 'રમતા હતાં',
      hi: 'खेल रहे थे'
    }
  },
  {
    level: 1,
    levelDescription: 'Intermediate',
    question: {
      gu: 'તેઓ તળાવમાં પથ્થરો _.',
      en: 'They were throwing stones in the pond.',
      hi: 'वे तालाब में पत्थर फेंक रहे थे |'
    },
    options: 'ફેંકતી હતી, ફેંકતો હતો, ફેંકતા હતાં, ફેંકતું હતું',
    answers: {
      en: 'were throwing',
      gu: 'ફેંકતા હતાં',
      hi: 'फेंक रहे थे'
    }
  },
  {
    level: 1,
    levelDescription: 'Intermediate',
    question: {
      gu: 'એક્સિડન્ટ થયું ત્યારે મને વ્યવસ્થિતનું જ્ઞાન હાજર _.',
      en: 'At the time of accident, Knowledge of Vyavasthit was present to me.',
      hi: 'जब दुर्घटना हुई तब मुझे व्यवस्थित का ज्ञान हाज़िर रहा था |'
    },
    options: 'રહ્યો હતો, રહ્યા હતાં, રહી હતી, રહ્યું હતું',
    answers: {
      en: 'was present',
      gu: 'રહ્યું હતું',
      hi: 'हाज़िर रहा था'
    }
  },
  {
    level: 1,
    levelDescription: 'Intermediate',
    question: {
      gu: 'જોક્સ સાંભળી અમે ખૂબ _.',
      en: 'We laughed a lot listening to the jokes.',
      hi: 'चुटकुले सुनकर हम खूब हँसे |'
    },
    options: 'હસી, હસ્યાં, હસ્યો, હસ્યું',
    answers: {
      en: 'laughed',
      gu: 'હસ્યાં',
      hi: 'हँसे'
    }
  },
  {
    level: 1,
    levelDescription: 'Intermediate',
    question: {
      gu: 'તેણે જલ્દીથી ખીચડી-શાક _.',
      en: 'She quickly cooked khichdi-vegetable curry.',
      hi: 'उसने जल्दी से खिचड़ी-सब्जी पकाई |'
    },
    options: 'રાંધ્યું, રાંધ્યો, રાંધ્યા, રાંધી',
    answers: {
      en: 'cooked',
      gu: 'રાંધ્યા',
      hi: 'पकाई'
    }
  },
  {
    level: 1,
    levelDescription: 'Intermediate',
    question: {
      gu: 'મહાત્માઓ અડાલજ _.',
      en: 'Mahatmas stayed at Adalaj.',
      hi: 'महात्मा अडालज रुके |'
    },
    options: 'રોકાયા, રોકાઈ, રોકાયું, રોકાયો',
    answers: {
      en: 'stayed',
      gu: 'રોકાયા',
      hi: 'रुके'
    }
  },
  {
    level: 1,
    levelDescription: 'Intermediate',
    question: {
      gu: 'મમ્મીએ મને શાક લઈ આવવાનું _.',
      en: 'Mummy asked me to bring vegetables.',
      hi: 'माँ ने मुझे सब्जी लाने को कहा |'
    },
    options: 'કહ્યાં, કહ્યું, કહ્યો,કહી',
    answers: {
      en: 'asked',
      gu: 'કહ્યું',
      hi: 'कहा'
    }
  },
  {
    level: 1,
    levelDescription: 'Intermediate',
    question: {
      gu: 'બધાં સેવાર્થીઓ એક-બીજાને _.',
      en: 'All sevarthis met each-other.',
      hi: 'सभी सेवार्थी एक-दुसरे को मिले |'
    },
    options: 'મળ્યું, મળ્યો, મળ્યા, મળી',
    answers: {
      en: 'met',
      gu: 'મળ્યા',
      hi: 'मिले'
    }
  },
  {
    level: 2,
    levelDescription: 'Advanced',
    question: {
      gu: 'અઢાર વર્ષની વયે દાદાશ્રીના લગ્ન હીરાબા સાથે _ (થવું). તેઓ ઘણું સરળ જીવન _ (જીવવું). જીવનમાં ક્યારેય એમને પોતાની વ્યક્તિગત જરૂરીયાત માટે કોઈની પાસેથી પૈસો _ (લેવું) નથી. ઉલટું તેમણે ધંધાની વધારાની કમાણીથી ભક્તોને જાત્રા _ (કરાવવું)!',
      en: 'Dada was married to Hiraba at the age of 18 years. They lived very simple life. Throughout his life, he never borrowed money from anybody for his personal requirement. Instead, he took devotees to pilgrimage (jatra) from his extra income of business.',
      hi: 'अठारह साल की उमर मे दादाजी की शादी हिराबा के साथ हुई | वे बड़ी सादगी से जीवन जीते थे | जीवन में कभी भी उन्होंने अपने स्वखर्च के लिए किसीसे भी पैसे लिए नही थे |बल्कि उन्होंने धंधे की बची हुई कमाई में से भक्तो को जात्रा कराते थे |'
    },
    options:
      'થયો, થઇ, થયા, જીવતો હતો, જીવતા હતા, જીવતી હતી, લીધો, લીધી, લીધું, કરાવી, કરાવ્યું, કરાવ્યો',
    answers: {
      en: 'was married, lived, borrowed, took',
      gu: 'થયા, જીવતા હતા, લીધો, કરાવી',
      hi: 'हुई, जीते थे, लिए, कराते थे'
    }
  },
  {
    level: 2,
    levelDescription: 'Advanced',
    question: {
      gu: '૨૦૧૯માં ૧૧૨મી જન્મજયંતી મુંબઈમાં _ (ઊજવવું). જન્મજયંતી સાથે મુંબઈ ત્રિમંદિર પ્રાણ-પ્રતિષ્ઠાનું આયોજન પણ _ (થવું). તે દિવસો દરમ્યાન મુંબઈમાં ખૂબ વરસાદ _ (પડવું). પણ મહાત્માઓનો ઉત્સાહ જરાય ઓછો ન પડ્યો અને બધાં સેવામાં જોડાઈ કાર્યક્રમને ખૂબ _ (દીપાવવું).',
      en: "In 2019, Janmjayanti-112 was celebrated at Mumbai. Pran-Pratishtha Program of Mumbai Trimandir was also arranged with Janmjayanti. During those days, it rained heavily in Mumbai. But Mahatmas' excitement did not fall down and everyone joined in Seva & glorified the program.",
      hi: '२०१९ में ११२ वी जन्मजयंती मुंबई में मनाई गई थी | जन्म्जयंती के साथ में मुंबई त्रिमंदिर प्राण-प्रतिष्ठा का भी आयोजन हुआ था | उन दिनों मुंबई में बहुत बारिश गिरि | पर महात्माओ का उत्साह बिलकुल कम नही हुआ और सभी ने सेवा में जुडकर कार्यक्रम को और दिपाया |'
    },
    options:
      'ઊજવાયો હતો, ઊજવાઈ હતી, ઊજવાયું હતું, થયો, થઇ, થયા, થયું, પડ્યો, પડી, પડ્યા, પડ્યું, દીપાવી, દીપાવવું, દીપાવ્યો',
    answers: {
      en: 'was celebrated, was also arranged, rained, glorified',
      gu: 'ઊજવાઈ હતી, થયું, પડ્યો, દીપાવ્યો',
      hi: 'मनाई गई थी, हुआ था, गिरि, दिपाया'
    }
  },
  {
    level: 2,
    levelDescription: 'Advanced',
    question: {
      gu: 'મને એક સરસ અનુભવ _ (થવું). કોલેજ જવા માટે રસ્તે જતાં એકાએક મારો પગ _ (લપસવું) અને હું પડી ગયો. બીજા છોકરાઓનું ટોળું ત્યાં હતું. તેઓ મારા ઉપર ખૂબ હસવા _ (લાગવું). મને તરત વ્યવસ્થિતનું જ્ઞાન હાજર _ (થવું). પછી હું પણ છોકરાઓ જોડે હસવા _ (લાગવું) અને તેઓ હવે મારી મશ્કરી કરતા નથી. દાદાનું જ્ઞાન હંમેશા સમતા અને આનંદમાં રાખે છે.',
      en: "I had a nice experience. On the way to college, suddenly I slipped and fell down. A group of other boys were there. They started laughing at me a lot. On the spot, knowledge of Vyavasthit occured to me. Then I also started laughing with those boys, and after that they don't tease me. Dada's Gnan always keeps us in equanimity and bliss.",
      hi: 'मुझे एक अच्छा अनुभव हुआ | कोलेज जाते समय रास्ते में अचानक से मेरा पाँव फिसला और में गिर गया | कुछ दुसरे लड़को की टोली वहा पे थी | वो सभी मुझ पे हसने लगे | मुझको तुरंत ही व्यवस्थित का ज्ञान हाजर हुआ | फिर में भी उन लड़को के साथ हसने लगा | ओर फिर उन्होंने मेरा मजाक नहीं उडाया | दादा का ज्ञान हरपल समता ओर आनंद में रखता है |'
    },
    options:
      'થયો, થઇ, થયા, થયું, લપસ્યો, લપસી, લપસ્યા, લાગી, લાગ્યો, લાગ્યાં, લાગ્યું, થયો, થયું, થઈ, લાગ્યો, લાગી, લાગ્યાં',
    answers: {
      en: 'had, slipped, started, occured, started',
      gu: 'થયો, લપસ્યો, લાગ્યાં, થયું, લાગ્યો',
      hi: 'हुआ, फिसला, लगे, हुआ, लगा'
    }
  },
  {
    level: 2,
    levelDescription: 'Advanced',
    question: {
      gu: 'મેં ત્રિમંદિરનો પ્રસાદ _ (ચાખવું). તે સુખડી મને ખૂબ _ (ભાવવું). મારા મિત્રો પણ સાથે હતાં. તેમને ખૂબ ભૂખ _ (લાગવું). અમે ઉણોદરીમાં _ (જમવું). પછી નીરુમાની સમાધિએ દર્શન કરીને અમે બસ _ (પકડવું) અને ઘરે ગયા.',
      en: "I tasted Trimandir's Prasad. I liked that Sukhadi very much. My friends were also with me. They were very hungry. We ate at Unodari. Then after doing Darshan at Niruma's Samadhi, we caught the bus and went to home.",
      hi: 'मैने त्रिमंदिर का प्रसाद चखा | वो सुखडी मुझे बहुत अच्छी लगी | मेरे दोस्त भी साथ में थे | उन सब को बहुत भूख लगी थी | हमने उणोदारी में खाना खाया | फिर नीरुमा की समाधी पर दर्शन कर के हमने बस पकड़ी और घर गए |'
    },
    options:
      'ચાખ્યો, ચાખી, ચાખ્યું, ભાવો, ભાવી, ભાવ્યું, લાગ્યો હતો, લાગી હતી, લાગ્યું હતું, જમ્યો, જમ્યાં, જમી, પકડ્યો, પકડી, પકડ્યું',
    answers: {
      en: 'tasted, liked, were very hungry, ate, caught',
      gu: 'ચાખ્યો, ભાવી, લાગી હતી, જમ્યાં, પકડી',
      hi: 'चखा, अच्छी लगी, लगी थी, खाया, पकड़ी'
    }
  },
  {
    level: 2,
    levelDescription: 'Advanced',
    question: {
      gu: 'પૂજ્યશ્રીનો આજનો સત્સંગ માન ઉપર _. જેમાં તેમણે સુંદર સૂચનો _. રોજનાં ત્રણ અપમાન જમા કરવાનું _. અને કોઈને દુઃખ _ હોય, તેનાં પ્રતિક્રમણ કરવાનું _.',
      en: "Pujyashree's today's satsang was on Maan. In that, he did beautiful directives. He taught to accumulate three insults daily. And suggested to do pratikraman if given hurt to anybody.",
      hi: 'पूज्यश्री का आज का सत्संग मान के उपर था | जिसमे उन्होंने कई सुन्दर सुझाव किए | दैनिक तीन अपमान जमा करना सिखाया | और किसी को दुःख दिया गया हो, उसके प्रतिक्रमण करने का सूचन किया |'
    },
    options:
      'હતી, હતાં, હતો, કર્યાં, કરી, કર્યો, શીખવ્યા, શીખવ્યો, શીખવ્યું, અપાયું, અપાયો, અપાઈ, સૂચવ્યું, સૂચવ્યા, સૂચવ્યો',
    answers: {
      en: 'was, did, taught, given, suggested',
      gu: 'હતો, કર્યાં, શીખવ્યું, અપાયું, સૂચવ્યું',
      hi: 'था, किए, सिखाया, दिया गया हो, सूचन किया'
    }
  },
  {
    level: 2,
    levelDescription: 'Advanced',
    question: {
      gu: 'અમે નાના હતા ત્યારે અમને શાળાએ જવું ખૂબ _ હતું. અમે રોજ સાઇકલ લઈને શાળાએ _. શિક્ષકોનો ખૂબ આદર _. અને ધ્યાનપૂર્વક _. પરિક્ષામાં સારું રિઝલ્ટ _. અને માતા- પિતાનું નામ રોશન _.',
      en: 'In our childhood, We loved to go to school very much. We went to school by bicycle daily. Respected the teachers a lot. And studied attentively. We got good results and made our parents proud.',
      hi: 'जब हम छोटे थे, तब हमें पाठशाला जाना बहुत पसंद था | हम रोज साईकिल पे पाठशाला जाते | अध्यापको का खूब आदर करते थे | और पूरी लगन से पढ़ते थे | कसोटी में अच्छा परिणाम लाते थे | और माता-पिता का नाम रोशन करते थे |'
    },
    options:
      'ગમતા, ગમતી, ગમતું, જતો, જતાં, જતી, કરતો, કરતાં, કરતી, કરતું, ભણતો, ભણતી, ભણતાં, લાવતી, લાવતાં, લાવતો, કરતાં, કરતો, કરતી',
    answers: {
      en: 'loved, went, Respected, studied, got, made',
      gu: 'ગમતું, જતાં, કરતાં, ભણતાં, લાવતાં, કરતાં',
      hi: 'पसंद था, जाते, करते थे, पढ़ते थे, लाते थे, करते थे'
    }
  },
  {
    level: 2,
    levelDescription: 'Advanced',
    question: {
      gu: 'તેઓ અડાલજ શિબિરમાં _. પછી થોડાં દિવસો અડાલજમાં જ _. શિબિર ભરી અને સત્સંગો _. અડાલજનું વાતાવરણ તેઓને ખૂબ જ _. ધીમે-ધીમે તેઓ કાયમ માટે અહીં જ શિફ્ટ થઈ _.',
      en: 'They came to Adalaj Shibir. Then they lived at Adalaj for some days. Attended Shibir and listened to satsangs. They liked the atmosphere of Adalaj very much. Gradually they shifted here permanently.',
      hi: 'वें सब अडालज शिबिर में आए | थोड़े दिन अडालज मे ही रुके | शिबिर में जाते और सत्संग सुनते थे | वहाँ का वातावरण उनको बहुत पसंद आया |  धीरे-धीरे वो हमेशा के लिए यहीं शिफ्ट हो गए |'
    },
    options:
      'આવ્યાં, આવ્યો, આવી, રહ્યો, રહ્યાં, રહયું, સાંભળ્યું, સાંભળ્યો, સાંભળ્યાં, ગમ્યું, ગમ્યા, ગમ્યો, ગઈ, ગયો, ગયાં',
    answers: {
      en: 'came, lived, listened, liked, shifted',
      gu: 'આવ્યાં, રહ્યાં, સાંભળ્યા, ગમ્યું, ગયાં',
      hi: 'आए, रुके, सुनते थे, पसंद आया, गए'
    }
  },
  {
    level: 2,
    levelDescription: 'Advanced',
    question: {
      gu: 'દેશ-વિદેશથી ઘણાં મહાત્માઓએ પાલીતાણા જાત્રાનો લાભ _. બધાને જાત્રામાં ખૂબ સારાં અનુભવો _. સત્સંગમાં ઘણાએ પોતાના અનુભવો _. બધાને જાત્રામાં  એડજસ્ટમેન્ટ લેવાના પણ _. ઘણાં મહાત્માઓએ જાત્રામાં સેવા પણ _.',
      en: 'Many Mahatmas from the country and abroad took advantage of the Palitana Jatra. All had nice experiences in the Jatra. Many of them described their experiences in satsang. All had to take adjustments also in the jatra. Many Mahatmas gave Seva too in the jatra.',
      hi: 'देश-विदेश से सभी महात्माओ ने पालिताना जात्रा का लाभ लिया | सबको जात्रा में कई अच्छे अनुभव हुए | सत्संग में काफी महात्माओने अपने अनुभवों का वर्णन किया | सबको जात्रा में एडजस्टमेंट लेना भी आया | कई महात्माओं ने जात्रा में सेवा भी दी |'
    },
    options:
      'લીધા લીધી, લીધો, થયાં, થયો, થયું, વર્ણવ્યું, વર્ણવ્યાં, વર્ણવ્યો, આવ્યાં, આવ્યો, આવ્યું, આપ્યા, આપી, આપ્યું',
    answers: {
      en: 'took, had, described, had to take, gave',
      gu: 'લીધો, થયાં, વર્ણવ્યાં, આવ્યાં, આપી',
      hi: 'लिया, हुए, वर्णन किया, आया, दी'
    }
  }
]
const pageDataNew = Utils.fillInBlanksDataMassager(
  pageData,
  false,
  true,
  true,
  false,
  true,
  true,
  true
)

export default pageDataNew
