<template>
  <div
    class="text-center pt-6"
    :style="{ backgroundColor: 'var(--v-primary-lighten6)' }"
  >
    <v-container fluid class="pb-0">
      <HomepageSectionTitle i18nTitle="Contact Us"></HomepageSectionTitle>

      <v-row justify="center" align="center" class="mt-3">
        <v-img
          alt="Learn Gujarati"
          class="shrink mr-2"
          contain
          src="../assets/dbflogo.svg"
          :width="70"
        >
        </v-img>
      </v-row>
      <v-row
        class="no-gutters"
        :class="$vuetify.breakpoint.mdAndUp ? 'mt-10' : 'mt-3'"
        justify="center"
        align="center"
      >
        <v-col
          cols="12"
          md="6"
          class="gray--text text-md-end pr-md-4 d-flex align-center justify-center justify-sm-end"
        >
          <div class="text-overline primary--text mr-2">Call:</div>
          <div>+91 9328661166 / +91 9328661177</div>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="gray--text text-md-start pl-md-4 d-md-flex align-center"
        >
          <span class="text-overline primary--text mr-2">Email:</span>
          <div v-for="w in emails" :key="w" class="otherWebsite">
            <a :href="'mailto:' + w" target="_blank" :title="w">{{ w }}</a>
          </div>
        </v-col>
      </v-row>
      <v-row class="no-gutters mt-2" justify="center" align="center">
        <span class="text-overline primary--text mr-2">Other Websites:</span>
        <div v-for="w in otherWebsites" :key="w.name" class="otherWebsite">
          <a :href="w.link" target="_blank" :title="w.name">{{ w.name }}</a>
        </div>
      </v-row>

      <v-row justify="center" align="center" class="pa-1 socialMediaSection">
        <a
          v-for="icon in socialMedia"
          :key="icon.icon"
          :href="icon.url"
          target="_blank"
          class="text-decoration-none mr-5 mr-sm-10">

          <v-icon
            v-if="icon.type === 'mdi'"
            color="primary"
            :large="$vuetify.breakpoint.smAndUp">
            {{ icon.icon }}
          </v-icon>
        
          <v-img
            v-else-if="icon.type === 'local'"
            :src="icon.icon" :width="$vuetify.breakpoint.smAndUp ? 30 : 20"></v-img>
        
        </a>
      </v-row>
      <v-row justify="center" align="center" class="mt-5 ma-auto gray--text">
        <div class="mr-md-5">
          <a href="https://www.dadabhagwan.org/terms-of-use/" target="_blank"
            >Terms of Use</a
          >

          |

          <a href="https://www.dadabhagwan.org/privacy-policy/" target="_blank"
            >Privacy Policy</a
          >
        </div>
        <br />
        <div class="mr-2 ml-2">
          Copyright © {{ new Date().getFullYear() }} Dada Bhagwan Foundation.
          <br v-if="!$vuetify.breakpoint.mdAndUp" />All rights reserved.
        </div>
      </v-row>
      <v-row
        justify="center"
        align="center"
        class="mb-3 gray--text text-body-2"
      >
        v{{ $store.state.version }}
      </v-row>
    </v-container>
  </div>
</template>

<script>
import HomepageSectionTitle from '../components/common/HomepageSectionTitle.vue'

export default {
  components: {
    HomepageSectionTitle
  },
  data: () => ({
    emails: ['webmaster@dadabhagwan.org', 'learngujarati@dadabhagwan.org'],
    socialMedia: [
      {
        type: 'mdi',
        icon: 'mdi-whatsapp',
        url: 'https://whatsapp.com/channel/0029Va5FOF9HrDZcQvJ3JT2s/'
      },
      {
        type: 'mdi',
        icon: 'mdi-pinterest',
        url: 'https://in.pinterest.com/dadabhagwanfoundation/'
      },
      {
        type: 'mdi',
        icon: 'mdi-youtube',
        url: 'https://www.youtube.com/user/dadabhagwan/'
      },
      {
        type: 'mdi',
        icon: 'mdi-facebook',
        url: 'https://www.facebook.com/DadaBhagwanFoundation'
      },
      {
        type: 'mdi',
        icon: 'mdi-instagram',
        url: 'https://www.instagram.com/dadabhagwanfoundation/'
      },
      {
        type: 'local',
        icon: require('@/assets/x-twitter.svg'),
        url: 'https://twitter.com/dadabhagwan/'
      },
      {
        type: 'local',
        icon: require('@/assets/youtube-music.svg'),
        url: 'https://www.youtube.com/c/dadabhagwanmusic/'
      }
    ],
    otherWebsites: [
      { name: 'Main Website', link: 'https://www.dadabhagwan.org' },
      { name: 'Hindi Website', link: 'https://hindi.dadabhagwan.org' },
      { name: 'Gujarati Website', link: 'https://www.dadabhagwan.in' },
      { name: 'Spanish Website', link: 'https://www.dadabhagwan.es' },
      { name: 'German Website', link: 'https://www.dadabhagwan.de' },
      { name: 'Portuguese Website', link: 'https://br.dadabhagwan.de' },
      { name: 'Youth Website', link: 'https://youth.dadabhagwan.org' },
      { name: 'Kids Website', link: 'https://kids.dadabhagwan.org' },
      {
        name: 'Website for Followers',
        link: 'https://satsang.dadabhagwan.org'
      },
      { name: 'Dadabhagwan TV', link: 'https://www.dadabhagwan.tv' },

      { name: 'Dadabhagwan FM', link: 'https://www.dadabhagwan.fm' }
    ]
  })
}
</script>
<style scoped>
.socialMediaSection {
  background-color: var(--v-primary-lighten5);
}
.socialMedia:not(:last-child) {
  margin-right: 40px;
}
.otherWebsite:not(:last-child) a {
  padding-right: 20px;
}
.otherWebsite a {
  color: var(--v-gray-base) !important;
  position: relative;
  text-decoration-line: none;
}
.otherWebsite:not(:last-child) a:before {
  content: '';
  position: absolute;
  height: 13px;
  width: 2px;
  background-color: #636363;
  top: 50%;
  transform: translateY(-50%);
  right: 8px;
}
</style>
