import Utils from '../util/Utils'

const pageData = [
  {
    level: '0',
    question: { gu: 'મારા ઘર પાસે _ ફરતો હતો.' },
    options: 'કૂતરું,કૂતરો,કૂતરી',
    answers: { gu: 'કૂતરો' }
  },
  {
    level: '0',
    question: { gu: 'અમે મામાનાં ઘરે ચા _.' },
    options: 'પીધી,પીધું,પીધો',
    answers: { gu: 'પીધી' }
  },
  {
    level: '0',
    question: { gu: '_ આરતી દાદાની, ત્રિવિધ તાપ ટાળે.' },
    options: 'પહેલું,પહેલો,પહેલી',
    answers: { gu: 'પહેલી' }
  },
  {
    level: '0',
    question: { gu: 'ગામથી ટપાલ _ છે.' },
    options: 'આવ્યો,આવી,આવ્યું',
    answers: { gu: 'આવી' }
  },
  {
    level: '0',
    question: { gu: '_ કલમમાં જગત કલ્યાણની ભાવના છે.' },
    options: 'નવમો,નવમું,નવમી',
    answers: { gu: 'નવમી' }
  },
  {
    level: '0',
    question: { gu: 'કબૂતર ઉડીને પાસે _.' },
    options: 'આવ્યું,આવી,આવ્યો',
    answers: { gu: 'આવ્યું' }
  },
  {
    level: '0',
    question: { gu: 'પ્રિયા એના મમ્મી-પપ્પાની વાત _ હતી.' },
    options: 'સાંભળતો,સાંભળતી,સાંભળતું',
    answers: { gu: 'સાંભળતી' }
  },
  {
    level: '1',
    question: { gu: 'દાદાનાં મહાત્માઓને મહી _ આરો વર્તે છે.' },
    options: 'ચોથો,ચોથી,ચોથું',
    answers: { gu: 'ચોથો' }
  },
  {
    level: '1',
    question: {
      gu: 'મેં દાદાજીનો સત્સંગ _ (સાંભળવું) અને પછી જ્ઞાન _ (લેવવું).'
    },
    options: 'સાંભળ્યો,સાંભળી,સાંભળ્યું,લીધો,લીધું,લીધી',
    answers: { gu: 'સાંભળ્યો, લીધું' }
  },
  {
    level: '1',
    question: { gu: 'જ્ઞાન લીધા પછી _ જીવન ફેરફાર થઇ _.' },
    options: 'મારો,મારી,મારું,ગયો,ગઈ,ગયું',
    answers: { gu: 'મારું, ગયું' }
  },
  {
    level: '1',
    question: { gu: 'નીરુમાનાં પ્રેમ_ રંગ એવો _ કે તેઓ ભૂલાતા નથી.' },
    options: 'નો,ની,નું,હતી,હતો,હતું',
    answers: { gu: 'નો, હતો' }
  },
  {
    level: '1',
    question: { gu: 'દાદાશ્રીને ૧૯૫૮માં સુરત રેલવે સ્ટેશન પર જ્ઞાન _.' },
    options: 'થયો,થઇ,થયાં,થયું',
    answers: { gu: 'થયું' }
  },
  {
    level: '1',
    question: { gu: 'નીરુમાએ દાદાશ્રીની બધી વાણી ટેપરેકોર્ડરમાં ઝીલી _.' },
    options: 'લીધો,લીધી,લીધાં,લીધું',
    answers: { gu: 'લીધી' }
  },
  {
    level: '1',
    question: { gu: 'મેં પેંડો _ (ખાવવું) અને શરબત _ (પીવવું).' },
    options: 'ખાધા,ખાધો,ખાધું,પીધું,પીધી,પીધો',
    answers: { gu: 'ખાધો, પીધું' }
  },
  {
    level: '1',
    question: { gu: 'હું ત્રિમંદિર _ (જવું) અને આરતી _ (કરવું).' },
    options: 'ગયા,ગઈ,ગયો,કરો,કરી,કરું',
    answers: { gu: 'ગઈ, કરી' }
  },
  {
    level: '2',
    question: {
      gu: 'ઘણાં દિવસના પ્રવાસ બાદ આજે _ વાર પૂજ્યશ્રીનો દાદાનગર હોલમાં સત્સંગ છે. આ _ સત્સંગ સાંભળવા માટે મહાત્માઓ બહુ ઉત્સુક છે. _ પૂજ્યશ્રી જય સચ્ચિદાનંદ કરે છે, પછી સત્સંગ શરૂ થાય છે. પૂજ્યશ્રીનું _ વાક્ય સાંભળીને જ બધાં આનંદિત થઇ જાય છે.'
    },
    options: 'પહેલું,પહેલી,પહેલાં,પહેલો',
    answers: { gu: 'પહેલી, પહેલો, પહેલાં, પહેલું' }
  },
  {
    level: '2',
    question: {
      gu: '_ નામ વરુણ છે. હું દસ વર્ષનો છું. _ પાસે દાદાની પ્રાર્થનાની ચોપડી છે. _ અભ્યાસ શરૂ કરતા પહેલાં હું એ ચોપડી વાંચું છું. _ મિત્રો પણ આવી રીતે અભ્યાસ કરે છે અને બધાંને સરસ રિઝલ્ટ મળે છે.'
    },
    options: 'મારી,મારો,મારું,મારાં',
    answers: { gu: 'મારું, મારી, મારો, મારાં' }
  },
  {
    level: '2',
    question: {
      gu: 'પૂજ્ય દીપકભાઈ દેસાઈનો જન્મ _ (૯) મેં, ૧૯૫૮નાં રોજ મુંબઈ શહેરમાં _ (થવું) હતો. પૂજ્યશ્રીને _ (૬) માર્ચ, ૧૯૭૧નાં રોજ, પરમ પૂજ્ય દાદાશ્રી પાસેથી આત્મજ્ઞાન પ્રાપ્ત _ (થવું). તેઓશ્રી આજે પ્યોરીટી અને વિનમ્રતાની મૂર્તિ _ (બનવું) છે.'
    },
    options: 'નવમો,નવમી,થયો,થઇ,છઠ્ઠો,છઠ્ઠી,થયું,થયાં,બન્યાં,બની',
    answers: { gu: 'નવમી, થયો, છઠ્ઠી, થયું, બન્યાં' }
  },
  {
    level: '2',
    question: {
      gu: 'નીરુમા તેમનાં ઘરમાં સૌથી _ (નાનું) ઉંમરનાં હતાં. તેઓ ૧૯૬૮માં દાદા પાસે _. આ _ (૧) જ મુલાકાતમાં તેમણે હૃદયથી પોતાનું સર્વસ્વ સમર્પણ કરી દીધું. નાનપણથી જ તેમની ઈચ્છા માનવજાતની સેવા માટે ડોક્ટર _ (બનવું) હતી.'
    },
    options: 'નાનો,નાની,આવી,આવ્યાં,પહેલો,પહેલી,બનવાનું,બનવાની,બનવાનો',
    answers: { gu: 'નાની, આવ્યાં, પહેલી, બનવાની' }
  },
  {
    level: '2',
    question: {
      gu: 'આજે દાદા_ જન્મજયંતી_ દિવસ છે. બધા ગામેગામથી દર્શન માટે _ છે. મેં _ નાની બહેનને પણ બોલાવી છે.'
    },
    options: 'નો,ની,નું,આવ્યાં,આવ્યો,મારાં,મારો,મારી',
    answers: { gu: 'ની, નો, આવ્યાં, મારી' }
  }
]

const pageDataNew = Utils.fillInBlanksDataMassager(
  pageData,
  false,
  true,
  true,
  false,
  false,
  false
)

export default pageDataNew
