import Utils from '../util/Utils'

const pageData = [
  {
    level: '0',
    question: { gu: 'આકાશ નો રંગ _ છે.' },
    imageFileName: 'sky.jpg',
    answers: { gu: 'વાદળી' }
  },
  {
    level: '0',
    question: { gu: 'પાંદડા નો રંગ _ છે.' },
    imageFileName: 'leaves.jpg',
    answers: { gu: 'લીલો' }
  },
  {
    level: '0',
    question: { gu: 'લોહી નો રંગ _ છે.' },
    imageFileName: 'blood.jpg',
    answers: { gu: 'લાલ' }
  },
  {
    level: '0',
    question: { gu: 'નીરુમાની સાડીનો રંગ _ છે.' },
    imageFileName: 'niruma.jpg',
    answers: { gu: 'સફેદ' }
  },
  {
    level: '0',
    question: { gu: 'સૂરજ નો રંગ _ છે.' },
    imageFileName: 'sun.jpg',
    answers: { gu: 'પીળો' }
  },
  {
    level: '0',
    question: { gu: 'નારંગી નો રંગ _ છે.' },
    imageFileName: 'orange.jpg',
    answers: { gu: 'નારંગી' }
  },
  {
    level: '0',
    question: { gu: 'કમળ નો રંગ _ છે.' },
    imageFileName: 'lotus.jpg',
    answers: { gu: 'ગુલાબી' }
  },
  {
    level: '0',
    question: { gu: 'વાળ નો રંગ _ છે.' },
    imageFileName: 'hair.jpg',
    answers: { gu: 'કાળો' }
  },
  {
    level: '0',
    question: { gu: 'માટી નો રંગ _ છે.' },
    imageFileName: 'clay.jpg',
    answers: { gu: 'કથ્થઈ' }
  },
  {
    level: '0',
    question: { gu: 'ઝાડ નો રંગ _ છે.' },
    imageFileName: 'trees.jpg',
    answers: { gu: 'લીલો' }
  },
  {
    level: '0',
    question: { gu: 'દૂધ નો રંગ _ છે.' },
    imageFileName: 'milk.jpg',
    answers: { gu: 'સફેદ' }
  },
  {
    level: '0',
    question: { gu: 'રીંગણ નો રંગ _ છે.' },
    imageFileName: 'eggplant.jpg',
    answers: { gu: 'જાંબલી' }
  },
  {
    level: '0',
    question: { gu: 'ગુલાબ નો રંગ _ છે.' },
    imageFileName: 'roses.jpg',
    answers: { gu: 'લાલ' }
  },
  {
    level: '0',
    question: { gu: 'લીંબુ નો રંગ _ છે.' },
    imageFileName: 'lemon.jpg',
    answers: { gu: 'પીળો' }
  },
  {
    level: '0',
    question: { gu: 'ધજા નો રંગ _ છે.' },
    imageFileName: 'flag.jpg',
    answers: { gu: 'નારંગી' }
  },
  {
    level: '0',
    question: { gu: 'જાંબુ નો રંગ _ છે.' },
    imageFileName: 'plum.jpg',
    answers: { gu: 'જાંબલી' }
  },
  {
    level: '0',
    question: { gu: 'સુરજમુખી નો રંગ _ છે.' },
    imageFileName: 'sunflower.jpg',
    answers: { gu: 'પીળો' }
  },
  {
    level: '0',
    question: { gu: 'દાદા ની ટોપી નો રંગ _ છે.' },
    imageFileName: 'dada.jpg',
    answers: { gu: 'કાળો' }
  },
  {
    level: '0',
    question: { gu: 'વાસણ નો રંગ _ છે.' },
    imageFileName: 'cutlery.jpg',
    answers: { gu: 'ચાંદી' }
  },
  {
    level: '0',
    question: { gu: 'દાંત નો રંગ _ છે.' },
    imageFileName: 'teeth.jpg',
    answers: { gu: 'સફેદ' }
  },
  {
    level: '0',
    question: { gu: 'તરબૂચ નો રંગ _ છે.' },
    imageFileName: 'watermelon.jpg',
    answers: { gu: 'લાલ' }
  },
  {
    level: '0',
    question: { gu: 'લાકડા નો રંગ _ છે.' },
    imageFileName: 'wood.jpg',
    answers: { gu: 'કથ્થઈ' }
  },
  {
    level: '0',
    question: { gu: 'સફરજન નો રંગ _ છે.' },
    imageFileName: 'apple.jpg',
    answers: { gu: 'લાલ' }
  },
  {
    level: '0',
    question: { gu: 'વાદળા નો રંગ _ છે.' },
    imageFileName: 'clouds.jpg',
    answers: { gu: 'વાદળી' }
  },
  {
    level: '0',
    question: { gu: 'પાકી કેરી નો રંગ _ છે.' },
    imageFileName: 'ripe_mango.jpg',
    answers: { gu: 'પીળો' }
  },
  {
    level: '0',
    question: { gu: 'કાચી કેરી નો રંગ _ છે.' },
    imageFileName: 'raw_mango.jpg',
    answers: { gu: 'લીલો' }
  },
  {
    level: '0',
    question: { gu: 'નદી ના પાણી નો રંગ _ છે.' },
    imageFileName: 'water.jpg',
    answers: { gu: 'વાદળી' }
  },
  {
    level: '0',
    question: { gu: 'ચેરી નો રંગ _ છે.' },
    imageFileName: 'cherry.jpg',
    answers: { gu: 'લાલ' }
  },
  {
    level: '0',
    question: { gu: 'ચાવી નો રંગ _ છે.' },
    imageFileName: 'keys.jpg',
    answers: { gu: 'ચાંદી' }
  },
  {
    level: '0',
    question: { gu: 'ટમેટા નો રંગ _ છે.' },
    imageFileName: 'tomato.jpg',
    answers: { gu: 'લાલ' }
  }
]
pageData.forEach(v => {
  v.imagePath = 'colors/' + v.imageFileName
  v.options =
    'કથ્થઈ, કાળો, ગુલાબી, ચાંદી, જાંબલી, નારંગી, પીળો, લાલ, લીલો, વાદળી, સફેદ'
})
const pageDataNew = Utils.fillInBlanksDataMassager(
  pageData,
  false,
  true,
  true,
  true,
  false,
  false
)

export default pageDataNew
