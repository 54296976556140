import Utils from '../util/Utils'

const pageData = [
  {
    level: '0',
    question: { gu: '૧૯' },
    options: 'એકાણું,ઓગણીસ,ઓગણત્રીસ',
    answers: { gu: 'ઓગણીસ' }
  },
  {
    level: '0',
    question: { gu: '૮૮' },
    options: 'અઠ્યાસી,અઠાણું,ઈઠોતેર',
    answers: { gu: 'અઠ્યાસી' }
  },
  {
    level: '0',
    isAudio: true,
    question: { gu: 'ત્રાણું' },
    options: '૧૩,૮૩,૯૩',
    answers: { gu: '૯૩' }
  },
  {
    level: '0',
    question: { gu: 'તેર' },
    options: '૨૩,૧૩,૩૩',
    answers: { gu: '૧૩' }
  },
  {
    level: '0',
    question: { gu: '૫૯' },
    options: 'ઓગણસાઠ,પંચાણું,ઓગણચાલીસ',
    answers: { gu: 'ઓગણસાઠ' }
  },
  {
    level: '0',
    question: { gu: 'ચોસઠ' },
    options: '૭૪,૮૪,૬૪',
    answers: { gu: '૬૪' }
  },
  {
    level: '0',
    question: { gu: '૭૮' },
    options: 'અડસઠ,ઈઠોતેર,સિત્યાસી',
    answers: { gu: 'ઈઠોતેર' }
  },
  {
    level: '0',
    question: { gu: '૮૯' },
    options: 'નેવ્યાસી,અઠાણું,ઓગણએંસી',
    answers: { gu: 'નેવ્યાસી' }
  },
  {
    level: '0',
    question: { gu: 'છેતાળીસ' },
    options: '૩૬,૬૪,૪૬',
    answers: { gu: '૪૬' }
  },
  {
    level: '0',
    question: { gu: 'સડસઠ' },
    options: '૬૮,૬૭,૭૬',
    answers: { gu: '૬૭' }
  },
  {
    level: '0',
    isAudio: true,
    question: { gu: 'ઓગણચાલીસ' },
    options: '૩૯,૪૯,૨૯',
    answers: { gu: '૩૯' }
  },
  {
    level: '0',
    question: { gu: '૯૮' },
    options: 'અઠ્યાસી,અઠાણું,ઈઠોતેર',
    answers: { gu: 'અઠાણું' }
  },
  {
    level: '0',
    question: { gu: '૬૯' },
    options: 'ઓગણસાઠ,છન્નું,ઓગણસિત્તેર',
    answers: { gu: 'ઓગણસિત્તેર' }
  },
  {
    level: '0',
    isAudio: true,
    question: { gu: 'ચુંમોતેર' },
    options: '૯૪,૪૭,૭૪',
    answers: { gu: '૭૪' }
  },
  {
    level: '0',
    question: { gu: '૮૭' },
    options: 'અડસઠ,ઈઠોતેર,સિત્યાસી',
    answers: { gu: 'સિત્યાસી' }
  },
  {
    level: '0',
    question: { gu: 'ઓગણત્રીસ' },
    options: '૨૯,૩૯,૪૯',
    answers: { gu: '૨૯' }
  },
  {
    level: '0',
    question: { gu: '૫૬' },
    options: 'પાંસઠ,છપ્પન,છેતાળીસ',
    answers: { gu: 'છપ્પન' }
  },
  {
    level: '0',
    question: { gu: '૪૯' },
    options: 'ઓગણચાળીસ,ઓગણપચાસ,ચોરાણું',
    answers: { gu: 'ઓગણપચાસ' }
  },
  {
    level: '0',
    isAudio: true,
    question: { gu: 'નવ્વાણું' },
    options: '૮૯,૯૯,૯૮',
    answers: { gu: '૯૯' }
  },
  {
    level: '0',
    question: { gu: '૩૫' },
    options: 'ત્રેપન,પચ્ચીસ,પાંત્રીસ',
    answers: { gu: 'પાંત્રીસ' }
  },
  {
    level: '0',
    question: { gu: '૭૯' },
    options: 'નેવ્યાસી,સત્તાણું,ઓગણએંસી',
    answers: { gu: 'ઓગણએંસી' }
  },
  {
    level: '0',
    isAudio: true,
    question: { gu: 'અઠ્યાવીસ' },
    options: '૨૭,૨૮,૩૮',
    answers: { gu: '૨૮' }
  },
  {
    level: '0',
    question: { gu: 'ચોરાસી' },
    options: '૮૪,૪૮,૯૪',
    answers: { gu: '૮૪' }
  },
  {
    level: '0',
    question: { gu: '૪૫' },
    options: 'પંચાવન,ચોપન,પિસ્તાળીસ',
    answers: { gu: 'પિસ્તાળીસ' }
  },
  {
    level: '0',
    question: { gu: '૫૮' },
    options: 'સત્તાવાન,અઠ્ઠાવન,પંચાસી',
    answers: { gu: 'અઠ્ઠાવન' }
  }
]

export default Utils.singleChoiceDataMassager(
  pageData,
  true,
  false,
  true,
  true,
  false,
  undefined
)
