import Utils from '../util/Utils'
const pageData = [
  {
    level: '0',
    question: { gu: '_ શુદ્ધાત્મા છું.' },
    options: 'હું, મને, મેં',
    answers: { gu: 'હું' }
  },
  {
    level: '0',
    question: { gu: '_ કેમ છો?' },
    options: 'તમને, તમે',
    answers: { gu: 'તમે' }
  },
  {
    level: '0',
    question: { gu: '_ જાણીએ છીએ.' },
    options: 'હું, તું, અમે, તમે',
    answers: { gu: 'અમે' }
  },
  {
    level: '0',
    question: { gu: '_ સુખડી ખાય છે.' },
    options: 'હું, તે, તમે',
    answers: { gu: 'તે' }
  },
  {
    level: '0',
    question: { gu: '_ ક્યાં જાય છે?' },
    options: 'અમે, તું, તમે',
    answers: { gu: 'તું' }
  },
  {
    level: '0',
    question: { gu: '_ જ્ઞાની સંગે ગરબા રમ્યા.' },
    options: 'અમે, હું, મને',
    answers: { gu: 'અમે' }
  },
  {
    level: '0',
    question: { gu: '_ બહુ આનંદ થાય છે.' },
    options: 'હું, મેં, મને',
    answers: { gu: 'મને' }
  },
  {
    level: '0',
    question: { gu: '_ સમર કેમ્પમાં મજા આવી?' },
    options: 'તું, તને, તે',
    answers: { gu: 'તને' }
  },
  {
    level: '0',
    question: { gu: '_ ચા બનાવી !' },
    options: 'હું, મેં, મને',
    answers: { gu: 'મેં' }
  },
  {
    level: '0',
    question: { gu: '_ બાળક મોટો થઈને આપ્તપુત્ર થશે.' },
    options: 'તું, તે, તને',
    answers: { gu: 'તે' }
  },
  {
    level: '1',
    question: { gu: '_ બધાં સામાયિક કરવા બેસીએ છીએ.' },
    options: 'હું, મને, અમે, અમને',
    answers: { gu: 'અમે' }
  },
  {
    level: '1',
    question: { gu: 'ગાડીમાં બેઠા-બેઠા _ એક વિચાર આવ્યો.' },
    options: 'હું, મેં, મને',
    answers: { gu: 'મને' }
  },
  {
    level: '1',
    question: {
      gu: 'જાત્રામાં ઘણાં સેવાર્થીઓ હાજર હતાં. _ ખૂબ દિલથી સેવા કરી.'
    },
    options: 'તે, તેઓએ, તેણે, તેને',
    answers: { gu: 'તેઓએ' }
  },
  {
    level: '1',
    question: { gu: 'જે સુખ _ પામ્યો, તે સુખ આખું જગત પામો.' },
    options: 'હું, મેં, મને, અમને',
    answers: { gu: 'હું' }
  },
  {
    level: '1',
    question: { gu: 'આજે જમવામાં કારેલાનું શાક છે, _ ફાવશે?' },
    options: 'તું, તેઓ, તમને',
    answers: { gu: 'તમને' }
  },
  {
    level: '1',
    question: { gu: '_ મહાત્માઓની સેવાનો ખૂબ લાભ મળ્યો છે.' },
    options: 'અમે, અમને, મેં',
    answers: { gu: 'અમને' }
  },
  {
    level: '1',
    question: {
      gu:
        'નીરુમાએ એક-એક આપ્તપુત્રને ઘડ્યા હતાં. આજે _ ગામેગામ સત્સંગ કરી જ્ઞાનની પુષ્ટિ આપે છે.'
    },
    options: 'તે, તેઓ, તેને',
    answers: { gu: 'તેઓ' }
  },
  {
    level: '1',
    question: { gu: '_ જ્ઞાની સંગે જાત્રા માણજો રે, મહાત્માઓ!' },
    options: 'તું, તમે, તમને',
    answers: { gu: 'તમે' }
  },
  {
    level: '1',
    question: { gu: '_ પદ સાંભળવું બહુ ગમે છે.' },
    options: 'તે, તેઓ, તેને',
    answers: { gu: 'તેને' }
  },
  {
    level: '1',
    question: { gu: 'બજારમાંથી _ થોડાંક ગલકા લઇ આવીશ?' },
    options: 'તું, તમે, તેઓ, તને',
    answers: { gu: 'તું' }
  },
  {
    level: '2',
    question: {
      gu:
        '_ નામ વાસુ છે. _ બાર વર્ષનો છું. _ હમણાં સ્કૂલનું સાતમું ધોરણ પૂરું કર્યું. _ મોટા થઈને દાદાની ખૂબ સેવા કરવાની ભાવના છે.'
    },
    options: 'હું, મેં, મને, મારી, મારું, મારો',
    answers: { gu: 'મારું, હું, મેં, મને' }
  },
  {
    level: '2',
    question: {
      gu:
        'A: _ ક્યાં જાઓ છો?\nB: _ અડાલજ જાઉં છું. _ બહુ ટાઈમથી જ્ઞાનીના દર્શન કર્યા નથી. તો હવે _ ઈચ્છા થઇ કે દર્શન જલ્દી કરી આવું.\nA: બહુ સરસ!'
    },
    options: 'તું, તમે, તમને, હું, મેં, મને',
    answers: { gu: 'તમે, હું, મેં, મને' }
  },
  {
    level: '2',
    question: {
      gu:
        'કાલે દાદાની જન્મજયંતી છે. આજે ઘણાં મહાત્માઓ સેવામાં જોડાયા છે. _ ખૂબ ઉત્સાહથી કામ કરી રહ્યા છે. આટલું કામ હોવા છતાં _ જરાય થાક લાગતો નથી. એક નાનું બાળક પણ મદદ કરી રહ્યું છે. _ પોતાના મા-બાપ કહે એમ કરી રહ્યું છે. _ પણ ખૂબ આનંદ થાય છે.'
    },
    options: 'તમે, તેઓ, તને, તેઓને, તું, તે, તમને, તેને',
    answers: { gu: 'તેઓ, તેઓને, તે, તેને' }
  },
  {
    level: '2',
    question: {
      gu:
        'A: _ શું વાંચે છે?\nB: _ દાદાવાણી વાંચું છું.\nA: _ શું છે?\nB: દાદા ભગવાન એ જ્ઞાની પુરુષ થયાં. આ તેમની પ્રત્યક્ષ વાણીનું સંકલન છે.\nA: વાહ! _ પણ વાંચવા આપીશ?\nB: હા જરૂર! _ પણ વાંચીને આનંદ થશે.'
    },
    options: 'તમે, તું, મેં, હું, તે, તેઓ, મને, તેઓને, તમને',
    answers: { gu: 'તું, હું, તે, મને, તમને' }
  }
]
const pageDataNew = Utils.fillInBlanksDataMassager(
  pageData,
  false,
  true,
  true,
  false,
  false,
  false
)

export default pageDataNew
