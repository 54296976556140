const sentenceforming = [
  {
    level: '0',
    question: {
      gu: ['દાદા અને નીરુમા મારી જોડે છે'],
      en: 'Dada and Niruma are with me.',
      hi: 'दादा और नीरुमा मेरे साथ है |'
    },
    options: 'દાદા,એને,અને,અમારે,નીરુમા,મારુ,મારી,મારો,જોડે,હશે,હતા,છે'
  },
  {
    level: '0',
    question: {
      gu: ['દાદાજી કાળી ટોપી પહેરતા હતા'],
      en: 'Dadaji used to wear black hat.',
      hi: 'दादाजी काली टोपी पहेनते थे |'
    },
    options:
      'દાદાજીને,દાદાજી,દાદાજીના,કાળી,ટોપી,પહેરીને,પહેરતા,પહેર્યા,હતી,હતા,હતુ'
  },
  {
    level: '0',
    question: {
      gu: ['કોઈને દુઃખ ન આપીશ'],
      en: "Don't give unhappiness to anyone.",
      hi: 'किसीको दुःख मत दो |'
    },
    options: 'કોઈને,કોઈકને,સુખ,દુઃખ,નથી,ન,આપવું,આપીશ,પોતાને,આપ્યુ'
  },
  {
    level: '0',
    question: {
      gu: ['ખાવાનું સરસ છે'],
      en: 'Food is good.',
      hi: 'खाना अच्छा है |'
    },
    options: 'ખાવાનું,ખાવા,ખાવામાં,સરસ,છું,હતું,હશે,છે'
  },
  {
    level: '0',
    question: {
      gu: ['મારી યાદ આપજો'],
      en: 'Give my regards.',
      hi: 'मेरी याद देना |'
    },
    options: 'મારુ,મારો,મારી,યાદ,આપજો,આપીશ,આપ'
  },
  {
    level: '0',
    question: {
      gu: ['હું શુદ્ધાત્મા છું'],
      en: 'I am pure soul.',
      hi: 'मैं शुद्धात्मा हूँ |'
    },
    options: 'તું,હું,તમે,શુદ્ધાત્મા,છે,છું,હતો,હોઈ'
  },
  {
    level: '0',
    question: {
      gu: ['હું પૂજ્યશ્રીને જલ્દી મળીશ'],
      en: 'I will meet Pujyashree very soon.',
      hi: 'मैं पूज्यश्री को जल्दी मिलूँगा |'
    },
    options: 'હું,મને,આપણે,પૂજ્યશ્રીને,જલ્દી,મળ્યા,મળ્યો,મળીશ,હ'
  },
  {
    level: '0',
    question: {
      gu: ['હું તેંત્રીસ વર્ષનો થવાનો છું'],
      en: 'I will turn 33 years old.',
      hi: 'मैं तेंतीस साल का होनेवाला हूँ |'
    },
    options:
      'વર્ષનો,વર્ષ,બત્રીસ,હું,થયો,છું,તેંત્રીસ,થશે,ત્રેવીસ,ત્રીસ,મને,થવાનો,છે,હ'
  },
  {
    level: '0',
    question: {
      gu: ['હું ખાઉં છું'],
      en: "I'm eating.",
      hi: 'मैं खा रहा हूँ |'
    },
    options: 'અમે,તમે,હું,ખાઉં,ખાધું,ખાવાનો,છું,હતી,હતો,'
  },
  {
    level: '0',
    question: {
      gu: ['શું તમે સાંજે સત્સંગમાં જોડાશો'],
      en: 'Will you join satsang in the evening?',
      hi: 'क्या आप शाम को सत्संग में जुड़ेंगे?'
    },
    options: 'શું,તમે,તારો,તારું,બપોરે,સાંજે,સત્સંગમાં,સત્સંગનો,જોડજે,જોડાશો'
  },
  {
    level: '1',
    question: {
      gu: ['પક્ષીઓ આકાશમાં ઉડી રહ્યા છે', 'આકાશમાં પક્ષીઓ ઉડી રહ્યા છે'],
      en: 'Birds are flying in the sky.',
      hi: 'पक्षी आकाश में उड़ रहे है |'
    },
    possibleAnswers: [],
    options: 'પક્ષી,પક્ષીઓ,આકાશમાં,ઉડતા,હતા,ઉડી,રહ્યા,રહ્યું,હશે,છે'
  },
  {
    level: '1',
    question: {
      gu: ['તમને ઠંડુ દૂધ પીવું ગમે છે'],
      en: 'Do you like drinking cold milk?',
      hi: 'आपको ठंडा दूध पीना अच्छा लगता है ?'
    },
    options:
      'તારે,તમને,તમારે,ઠંડુ,દૂધ,પીવે,પાવે,પીવું,ગમે,ગમતું,હતું,છું,હશે,છે'
  },
  {
    level: '1',

    question: {
      gu: ['એને દાદાના પદો સાંભળવા બહુ ગમે છે'],
      en: "He loves listening to Dada's Pads (Hymn).",
      hi: 'उसको दादा के पद सुनना बहुत अच्छा लगता है |'
    },
    options:
      'એની,એને,પેલી,દાદાના,દાદા,પદો,સાંભળવો,સાંભળવા,સાંભળવી,બહુ,ગમતું,ગમી,ગમે,ગમશે,હતું,છે'
  },
  {
    level: '1',

    question: {
      gu: ['મેં નીરુમા સાથે નેપાળની જાત્રા કરી હતી'],
      en: "I did Nepal's jatra with Niruma.",
      hi: 'मैंने नीरुमा के साथ नेपाल की जात्रा की थी |'
    },
    options: 'હું,મેં,નીરુમાએ,નીરુમા,સાથે,નેપાળની,જાત્રા,કરી,હતી,કરું,હત'
  },
  {
    level: '1',
    question: {
      gu: ['મને ટેનિસ રમવું બહુ ગમે છે', 'ટેનિસ રમવું મને બહુ ગમે છે'],
      en: 'I like to play Tennis very much.',
      hi: 'मुझे टेनिस खेलना बहुत अच्छा लगता है |'
    },
    possibleAnswers: [],
    options: 'મારો,મને,હું,ટેનિસ,રમવું,રમવા,રમો,બહુ,ગમશે,ગમે,ગમતું,હતું,હશે,છે'
  },
  {
    level: '1',

    question: {
      gu: [
        'ગુરુપૂર્ણિમામાં બધાને મળવાની ખૂબ મજા આવશે',
        'બધાને ગુરુપૂર્ણિમામાં મળવાની ખૂબ મજા આવશે'
      ],
      en: 'It will be lot of fun to meet everybody at Gurupurnima.',
      hi: 'गुरुपूर्णिमा में सबको मिलने में खूब मज़ा आएगा |'
    },
    possibleAnswers: [],
    options:
      'ગુરુપૂર્ણિમામાં,ખૂબ,મળવાનો,મળવાનું,મળવાની,માં,આવશે,બધાને,મજા,આવી,આવ્યો,હતો,હતી,બધાન'
  },
  {
    level: '1',

    question: {
      gu: ['નીરુમા હંમેશા સફેદ કપડાં પહેરતા હતા'],
      en: 'Niruma always wore white clothes.',
      hi: 'नीरुमा हमेशा सफ़ेद कपडे पहेनते थे |'
    },
    options:
      'નીરુમા,નીરુમાએ,નીરૂમાના,હંમેશા,સફેદ,કપડાં,પહેરીને,પહેરતા,પહેર્યા,હતી,હતા,હત'
  },
  {
    level: '1',
    question: {
      gu: ['નીરુમાનો જન્મ ઔરંગાબાદમાં થયો હતો'],
      en: 'Niruma was born in Aurangabad.',
      hi: 'नीरुमा का जन्म औरंगाबाद में हुआ था |'
    },
    options:
      'નીરૂમા,નીરુમાએ,નીરુમાનો,જન્મ,ઔરંગાબાદના,ઔરંગાબાદમાં,થયો,થઇ,થયું,હતું,હતો,હ'
  },
  {
    level: '1',

    question: {
      gu: ['હવે હું આપ્તવાણી ૧૩ વાંચીશ', 'હું હવે આપ્તવાણી ૧૩ વાંચીશ'],
      en: 'Now I will read Aptvani 13.',
      hi: 'अब में आप्तवाणी १३ पढूंगा |'
    },
    possibleAnswers: [],
    options: 'હવે,હમણાં,હું,આપ્તવાણી,૧૩,વાંચીશ,વાંચું છું,વાંચ'
  },
  {
    level: '1',
    question: {
      gu: ['કાલે આપણે બધા ત્રિમંદિર જઈશું'],
      en: 'Tomorrow we will all go to Trimandir.',
      hi: 'कल हम सब त्रिमंदिर जाएँगे |'
    },
    options: 'કાલે,આજે,આપણે,તમે,બધા,ત્રિમંદિર,ત્રિમંદિરની,ગઈ,જઈ આવ્યા,જઈશું'
  },
  {
    level: '2',

    question: {
      gu: ['અમે બધા રોજ ચરણવિધિ કરીએ છીએ', 'ચરણવિધિ અમે બધા રોજ કરીએ છીએ'],
      en: 'All of us are doing CharanVidhi everyday.',
      hi: 'हम सब रोज़ चरणविधि कर रहे है |'
    },
    possibleAnswers: [],
    options: 'તમે,કરું,કરી,કરીએ,છીએ,હતો,કરશો,કરીશ,અમે,અમારી,બધા,રોજ,ચરણવિધિ'
  },
  {
    level: '2',

    question: {
      gu: ['દાદાની જન્મજયંતિમાં મહાત્માઓએ કષાયરહિત સેવા કરી'],
      en: "At Dada's Janmajayanti, Mahatmas did Kshayrahit seva.",
      hi: 'दादा की जन्मजयंती पर महात्माओने कषायरहित सेवा की |'
    },
    options:
      'દાદાના,દાદાની,જન્મજયંતિમાં,જન્મજયંતિના,જન્મજયંતીની,મહાત્માઓએ,મહાત્માઓના,મહાત્માઓને,કષાયરહિત,સેવા,સેવામાં,સેવાની,કરી'
  },
  {
    level: '2',

    question: {
      gu: [
        'દાદાજી બરોડામાં મામાની પોળમાં ઘણા વર્ષો રહ્યા હતા',
        'દાદાજી ઘણા વર્ષો બરોડામાં મામાની પોળમાં રહ્યા હતા'
      ],
      en: 'Dadaji lived in Baroda in Mama ni Pol for many years.',
      hi: 'दादाजी बरोड़ा में मामा की पोल में बहुत साल रहे थे |'
    },
    possibleAnswers: [],
    options:
      'બરોડામાં,મામાની,રહે છે,રહ્યા,હતા,મામાનો,મામાનું,પોળમાં,પોળના,ઘરમાં,ઘણા,થોડા,દાદાજી,વર્ષો'
  },
  {
    level: '2',
    question: {
      gu: [
        'મેં દાદાજીનું જ્ઞાન ૧૯૮૨માં મુંબઈમાં લીધું હતું',
        'મેં દાદાજીનું જ્ઞાન મુંબઈમાં ૧૯૮૨માં લીધું હતું',
        '૧૯૮૨માં મુંબઈમાં મેં દાદાજીનું જ્ઞાન લીધું હતું',
        '૧૯૮૨માં દાદાજીનું જ્ઞાન મેં મુંબઈમાં લીધું હતું'
      ],
      en: "I took Dadaji's Gnan in Mumbai in 1982.",
      hi: 'मैंने दादाजी का ज्ञान मुंबई में १९८२ में लिया था |'
    },
    possibleAnswers: [],
    options:
      'દાદાજીનું,દાદાજીના,જ્ઞાન,હું,મેં,૧૯૮૨માં,૧૯૮૨ની,મુંબઈમાં,મુંબઈનાં,લીધું,હતું,લેતા હ'
  },
  {
    level: '2',

    question: {
      gu: [
        'નીરુમાએ દાદાજીની દીકરીની જેમ સેવા કરી હતી',
        'નીરુમાએ દાદાજીની સેવા દીકરીની જેમ કરી હતી',
        'નીરુમાએ દીકરીની જેમ દાદાજીની સેવા કરી હતી'
      ],
      en: "Niruma did Dadaji's seva like a daughter.",
      hi: 'नीरुमा ने दादाजी की बेटी की तरह सेवा की थी |'
    },
    possibleAnswers: [],
    options:
      'નીરૂમાના,નીરુમાએ,દાદાજીની,દીકરીના,દીકરીની,દીકરીને,જેમ,સેવા,સેવામાં,કરી,કરાવ્યું,કર્યું,કરતા,હતી,હતું,હત'
  },
  {
    level: '2',

    question: {
      gu: [
        'નીરુમા દાદાના જ્ઞાનને પદોમાં બહુ સુંદર રીતે ગાતા હતા',
        'નીરુમા દાદાના જ્ઞાનને બહુ સુંદર રીતે પદોમાં ગાતા હતા'
      ],
      en: "Niruma used to sing Dada's Gnan in pads very beautifully.",
      hi: 'नीरुमा दादा के ज्ञान को पदो में बहुत सुन्दर तरीके से गाते थे |'
    },
    possibleAnswers: [],
    options:
      'દાદાના,જ્ઞાનને,જ્ઞાનમાં,બહુ,પદોમાં,પદોના,સુંદર,રીતે,ગાતું હતું,ગાતા,હતા,નીરુમા'
  },
  {
    level: '2',

    question: {
      gu: [
        'અમે જૂની ગાડી વેચીને નવી ગાડી લઈશું',
        'જૂની ગાડી વેચીને અમે નવી ગાડી લઈશું'
      ],
      en: 'We will sell the old car and get a new one.',
      hi: 'हम पुरानी गाडी बेचकर नई गाडी लेंगे |'
    },
    possibleAnswers: [],
    options: 'ગાડી,અમે,જૂની,જૂનો,નવી,નવો,લઈશું,હમણાં,વેચીને,ગાડી,અ'
  },
  {
    level: '2',

    question: {
      gu: [
        'તમે દર બે દિવસે ગુજરાતી શીખો છો',
        'દર બે દિવસે તમે ગુજરાતી શીખો છો'
      ],
      en: 'You are learning Gujarati every two days.',
      hi: 'आप हर दो दिन गुजराती सिखते है |'
    },
    possibleAnswers: [],
    options:
      'ગુજરાતી,તારી,તમે,તમારી,હું,ત્રણ,શીખો,શીખું,છું,છો,દિવસે,હતી,હતું,દર,બે,શી'
  },
  {
    level: '2',

    question: {
      gu: [
        'સત્સંગના સંપર્કમાં રહેવાથી જ્ઞાનની વધારે સમજણ પડશે',
        'સત્સંગના સંપર્કમાં રહેવાથી જ્ઞાનની સમજણ વધારે પડશે'
      ],
      en: 'You will understand Gnan more by staying in touch with satsang.',
      hi: 'सत्संग के संपर्क में रहने से ज्ञान की ज्यादा समझ मिलेगी |'
    },
    options:
      'સંપર્કમાં,જ્ઞાનનું,સમજણ,સત્સંગના,ઓછી,પડે છે,હવે,જ્ઞાનની,પડશે,વધારે,રહેવાથી,રહ'
  },
  {
    level: '2',

    question: {
      gu: ['ઝવેરબાના સંસ્કાર ખૂબ જ ઊંચા હતા'],
      en: "Zaverba's nurturing values were very high.",
      hi: 'ज़वेरबा के संस्कार खूब ऊँचे थे |'
    },
    options: 'ઝવેરબાને,ઝવેરબાના,ઝવેરબાની,સંસ્કાર,ખૂબ,જ,ઊંચું,ઊંચા,હતું,હતા,હ'
  }
].sort((a, b) => a.level - b.level)
sentenceforming.forEach(v => (v.options = v.options.split(',')))
sentenceforming.forEach(v => (v.answer = v.question.gu.map(s => s.split(' '))))
sentenceforming.forEach(v => {
  v.punctuation = v.question.en.slice(-1)
  v.optionsState = {
    data: v.options.map(option => {
      return {
        text: option,
        enabled: true,
        wordStateIndex: null,
        onOptionDrag: function(wordState) {
          if (wordState.actual != '') {
            v.optionsState.onWordClear(wordState.actual)
          }
          this.enabled = false
          v.wordsState.onOptionDrag(this.text, wordState)
        },
        onOptionSelection: function() {
          var unanswered = v.wordsState.onOptionSelection(this.text)
          if (unanswered) {
            this.enabled = false
          }
        }
      }
    }),
    onWordClear: function(wordText) {
      v.optionsState.data.find(
        o => !o.enabled && o.text == wordText
      ).enabled = true
    },
    reset: function() {
      v.optionsState.data.forEach(e => (e.enabled = true))
    }
  }
  var wordsCounter = 0
  var questionWords = v.question.gu[0].split(' ')
  v.wordsState = {
    question: v.question.gu,
    words: questionWords.map(word => {
      return {
        query: true,
        expected: v.question.gu.map(q => q.split(' ')[wordsCounter]),
        actual: '',
        hasFocus: wordsCounter++ == 0,
        reset: function() {
          if (this.query) this.actual = ''
        },
        wordLength: function() {
          return this.expected[0].length
        },
        onWordClear: function() {
          if (this.actual == '') {
            return
          }
          v.optionsState.onWordClear(this.actual)
          this.actual = ''
          v.wordsState.updateHasFocus()
        },
        isAnsweredCorrectly: function() {
          return this.expected.some(v => v == this.actual)
        },
        isAnsweredCorrectlyByIndex: function(index) {
          return this.expected[index] == this.actual
        }
      }
    }),
    onOptionDrag: function(text, wordState) {
      wordState.actual = text
      this.updateHasFocus()
    },
    onOptionSelection: function(text) {
      var unanswered = v.wordsState.words.find(w => w.hasFocus)
      if (unanswered) {
        unanswered.actual = text
        this.updateHasFocus()
      }
      return unanswered
    },
    updateHasFocus: function() {
      v.wordsState.words.forEach(w => (w.hasFocus = false))
      var unanswered = v.wordsState.words.find(w => w.query && w.actual == '')
      if (unanswered) unanswered.hasFocus = true
    },
    isAnsweredCorrectly: function() {
      var correct = false
      for (var i = 0; i < v.question.gu.length; i++) {
        correct =
          correct ||
          v.wordsState.words.every(v => v.isAnsweredCorrectlyByIndex(i))
      }
      return correct
    },
    reset: function() {
      this.words.forEach(v => v.reset())
      this.words.find(v => v.query).hasFocus = true
    }
  }
})
const defaultLevels = {
  0: 'Beginner',
  1: 'Intermediate',
  2: 'Advanced'
}
const levels = Array.from(new Set(sentenceforming.map(v => v.level))).map(
  v => ({
    text: defaultLevels[v],
    value: v
  })
)
export default {
  instructionKey: 'sentenceFormingInstruction',
  levels: levels,
  showQuestionHeader: true,
  doNotShowGujQuestion: true,
  showQuestionWords: true,
  forceShowPossibleAnswers: true,
  sentences: sentenceforming,
  reset: function() {
    sentenceforming.forEach(v => {
      v.wordsState.reset()
      v.optionsState.reset()
    })
  }
}
