<template>
  <div>
    <v-app>
      <v-navigation-drawer v-model="drawer" temporary fixed right>
        <v-list>
          <v-list-item>
            <v-img
              alt="Learn Gujarati"
              class="shrink mr-2"
              contain
              src="./assets/lg-logo.svg"
              transition="scale-transition"
              :width="50"
            >
            </v-img>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list>
          <v-list-item>
            <v-list-item-title>
              <a
                :style="{ textDecoration: 'none' }"
                class="text-body registerLink"
                target="_blank"
                href="https://docs.google.com/forms/d/e/1FAIpQLSe5x6llFPNdCbeC05ta3OX85_QPqIL3UBFRwEN8MrQIUZzAcg/viewform?fbclid=IwAR1cH9sm3HGcY0adsF_i20V56laxpeyMPKsE-aFyZrtiBDaZIG9W_ce-JwA"
              >
                {{ $t('Register') }}</a
              >
            </v-list-item-title>
          </v-list-item>
          <span>
            <v-list-item class="" @click="$router.push('/#practice')">
              <v-list-item-title>{{ $t('Practice') }}</v-list-item-title>
            </v-list-item>
            <v-list-item :href="'/study-material'">
              <router-link :to="'/study-material'" class="menuLink">
                {{ $t('study-material') }}
              </router-link>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item @click="scrollToElement('contactUs')">
              <v-list-item-title>{{ $t('Contact Us') }}</v-list-item-title>
            </v-list-item>
          </span>
        </v-list>

        <!--  -->
      </v-navigation-drawer>
      <v-app-bar
        app
        color="#fff"
        :height="$vuetify.breakpoint.smAndDown ? 70 : 100"
      >
        <div class="d-flex float-left">
          <div class="mr-auto">
            <router-link to="/">
              <v-img
                alt="Learn Gujarati"
                class="shrink mr-10"
                contain
                src="./assets/lg-logo.svg"
                transition="scale-transition"
                :width="$vuetify.breakpoint.smAndDown ? 60 : 100"
                :height="$vuetify.breakpoint.smAndDown ? 60 : 100"
              >
              </v-img>
            </router-link>
          </div>
          <div v-if="!$vuetify.breakpoint.smAndDown" class="ma-auto d-flex">
            <ToolbarMenu :menu="practicePlayMenu"> </ToolbarMenu>
            <div class="ma-auto">
              <router-link
                :to="'/study-material'"
                class="menuLink pr-10 text-h6 font-weight-regular"
              >
                {{ $t('study-material') }}
              </router-link>
            </div>
            <div class="ma-auto">
              <a
                class="menuLink pr-10 text-h6 font-weight-regular"
                :style="{ cursor: 'pointer' }"
                @click="scrollToElement('contactUs')"
                >{{ $t('Contact Us') }}</a
              >
            </div>
          </div>
        </div>
        <v-spacer></v-spacer>
        <div
          v-if="
            !$vuetify.breakpoint.smAndDown && $route.name !== 'study-material'
          "
        >
          <LanguageSelection />
        </div>
        <div v-else class="float-right">
          <LanguageSelection />
        </div>

        <v-app-bar-nav-icon
          v-if="$vuetify.breakpoint.smAndDown"
          @click="drawer = !drawer"
          color="anchor"
        ></v-app-bar-nav-icon>
      </v-app-bar>
      <v-main>
        <Loader v-if="loading" />
        <div v-else>
          <Breadcrumb v-if="!hideBreadcrumb" class="mb-4 mt-2" />
          <router-view></router-view>
          <Contactus id="contactUs" />
        </div>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import Contactus from './components/Contactus'
import LanguageSelection from './components/LanguageSelection.vue'
import ToolbarMenu from './components/common/ToolbarMenu.vue'
import Breadcrumb from './components/common/Breadcrumb.vue'
import Loader from './components/common/Loader.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  components: {
    Contactus,
    LanguageSelection,
    ToolbarMenu,
    Breadcrumb,
    Loader
  },
  computed: {
    hideBreadcrumb() {
      return this.$route?.meta?.hideBreadcrumbOnMainPage
    },
    ...mapGetters(['loading']),
    practicePlayMenu() {
      let menus = { name: 'Practice', items: [] }

      this.$store.state.practicePlayCategories.forEach((cat) => {
        let m = { id: cat.id, name: cat.name, link: cat.link }
        m.subMenu = this.$store.state.practicePlayPages
          .filter((p) => {
            return p.categoryId == m.id
          })
          .map((p) => {
            return { id: p.id, name: p.name, link: p.link }
          })
        menus.items.push(m)
      })
      return menus
    }
  },

  mounted() {
    this.$firebaseAnalytics.logEvent('home_page_visited')
  },
  data: () => ({
    drawer: false,
    giftbox: true
  }),

  methods: {
    closeBox() {
      this.giftbox = false
    },

    scrollToElement(name) {
      const el = this.$el.querySelector('#' + name)
      if (el) {
        // Use el.scrollIntoView() to instantly scroll to the element
        el.scrollIntoView({ behavior: 'smooth' })
      }
      if (this.drawer) {
        this.drawer = false
      }
    }
  }
}
</script>
<style>
.center {
  margin: auto;
  width: 50%;
}
.red--text /deep/ label {
  color: var(--v-primary-base);
}

a.menuLink:hover {
  color: var(--v-primary-base) !important;
}
@font-face {
  font-family: 'Ekatra';
  src: local('Ekatra'), url(./assets/fonts/Ekatra-N.ttf) format('truetype');
  size-adjust: 125%;
}
@font-face {
  font-family: 'EkatraItalic';
  src: local('Ekatra-I'), url(./assets/fonts/Ekatra-I.ttf) format('truetype');
  size-adjust: 110%;
}
@font-face {
  font-family: 'EkatraBold';
  src: local('Ekatra-B'), url(./assets/fonts/Ekatra-B.ttf) format('truetype');
  size-adjust: 110%;
}
@font-face {
  font-family: 'EkatraItalicBold';
  src: local('Ekatra-T'), url(./assets/fonts/Ekatra-T.ttf) format('truetype');
  size-adjust: 110%;
}
.kakko-input-blank {
  border: 1px solid #000;
  outline: 3px solid #000;
  width: 100%;
  height: 50px;
  text-align: center;
  font-size: xx-large;
}

.shadowAnimatedButton:hover {
  color: white !important;
  background-color: var(--v-primary-base) !important;
}
.shadowAnimatedButton {
  box-shadow: 0 0 0 0 var(--v-primary-base);
  animation: pulse-primary 2s infinite;
  border-radius: 40px;
  border: 2px solid;
  line-height: 1rem;
  text-decoration: none;
  background-color: #ffffff;
  transition: all 0.6s;
  font-size: 16px !important;
}
.v-list-item .v-list-item__title,
.v-list-item .v-list-item__subtitle,
.v-select__selections {
  line-height: 1.5rem !important;
}
@keyframes pulse-primary {
  0% {
    box-shadow: 0 0 0 0 rgb(1, 81, 196, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgb(1, 81, 196, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgb(1, 81, 196, 0);
  }
}
</style>
<style lang="scss">
$font-family: 'Roboto', 'Ekatra', serif;
.v-application {
  .headline,
  [class*='display-'],
  [class*='text-'] {
    font-family: $font-family !important;
  }
  font-family: $font-family !important;
}
.menuLink {
  text-decoration: none;
  color: var(--v-gray-darken4) !important;
}
.v-btn--plain:not(.v-btn--active):not(.v-btn--loading):not(:focus):not(:hover)
  .v-btn__content {
  opacity: 1 !important;
}
</style>
