<template>
  <v-dialog v-model="visible" max-width="310" persistent>
    <v-card>
      <v-card-title class="headline cartTitle">
        {{ cardTitle || $t('Are you sure?') }}
      </v-card-title>
      <v-card-text v-if="cardText">
        <p v-html="cardText"></p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="primary" text @click="$emit('areYouSure', true)">
          {{ yesBtnTxt || 'Yes' }}
        </v-btn>

        <v-btn color="primary" text @click="$emit('areYouSure', false)">
          {{ noBtnTxt || 'No' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ['cardTitle', 'cardText', 'visible', 'yesBtnTxt', 'noBtnTxt'],
  computed: {
    show: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit('close')
        }
      }
    }
  }
}
</script>
<style scoped>
.cartTitle {
  border-bottom: 1px solid var(--v-primary-base);
  margin: 5px;
  margin-bottom: 20px;
  font-size: 1.25rem !important;
  word-break: break-word;
}
</style>
