const vowels =
[
  { eng: 'aa', guj: 'આ', hindi: 'आ', symbol: 'ા' },
  { eng: 'i', guj: 'ઇ', hindi: 'इ', symbol: 'િ' },
  { eng: 'ee', guj: 'ઈ', hindi: 'ई', symbol: 'ી' },
  { eng: 'u', guj: 'ઉ', hindi: 'उ', symbol: 'ુ' },
  { eng: 'oo', guj: 'ઊ', hindi: 'ऊ', symbol: 'ૂ' },
  { eng: 'e', guj: 'એ', hindi: 'ए', symbol: 'ે' },
  { eng: 'ai', guj: 'ઐ', hindi: 'ऐ', symbol: 'ૈ' },
  { eng: 'o', guj: 'ઓ', hindi: 'ओ', symbol: 'ો' },
  { eng: 'au', guj: 'ઔ', hindi: 'औ', symbol: 'ૌ' },
  { eng: 'am', guj: 'અં', hindi: 'अं', symbol: 'ં' },
  { eng: 'ah', guj: 'અ:', hindi: 'अः', symbol: 'ઃ' }
]
export default vowels
