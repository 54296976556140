const studyMaterial = [
  {
    section: 100,
    en: 'Swar(Vowels)',
    gu: 'સ્વર',
    hi: 'स्वर',
    de: '',
    es: '',
    doc: {
      en: 'Swar Symbols',
      gu: 'સ્વર ચિહ્ન',
      hi: 'स्वर चिह्न',
      de: '',
      es: ''
    },
    url: {
      es: '',
      de: '',
      en: 'https://drive.google.com/file/d/1UWT78AMd9MPQT-uo02uQ6IS9K0SODc_7/view?usp=sharing'
    },
    secure: null,
    pageIds: 'learnbarakhadi'
  },
  {
    section: 100,
    en: 'Swar(Vowels)',
    gu: 'સ્વર',
    hi: 'स्वर',
    de: 'Swar(Vokale)',
    es: '',
    doc: {
      en: 'Swar Words',
      gu: 'સ્વર શબ્દો',
      hi: 'स्वर शब्द',
      de: 'Swar(Vokale) Wörter',
      es: ''
    },
    url: {
      es: '',
      de: 'https://drive.google.com/file/d/1Cl0R1kIQ3Alc164oAaX-u_WE2XjLSLHt/view?usp=sharing',
      en: 'https://drive.google.com/file/d/1AHT5Govxmd7kljD20ToHr1VO0zQzMaKv/view?usp=sharing'
    },
    secure: null,
    pageIds: 'learnbarakhadi'
  },
  {
    section: 200,
    en: 'Kakko & Barakhadi',
    gu: 'કક્કો અને બારાખડી',
    hi: 'कक्को और बाराखडी',
    de: '',
    es: 'Kakko(Abecedario/Alfabeto)',
    doc: {
      en: 'Kakko',
      gu: 'કક્કો',
      hi: 'कक्को ',
      de: '',
      es: 'Palabras del abecedario / Palavras do alfabeto'
    },
    url: {
      es: 'https://drive.google.com/file/d/1VGHQ23fpdOwSX41F2gURta6KSS2lV7hV/view?usp=sharing',
      de: '',
      en: 'https://drive.google.com/file/d/1rS9rNMCiibwfsLysb8iPYpq6yS8mVtxP/view?usp=sharing'
    },
    secure: null,
    pageIds: 'learnkakko'
  },
  {
    section: 200,
    en: 'Kakko & Barakhadi',
    gu: 'કક્કો અને બારાખડી',
    hi: 'कक्को और बाराखडी',
    de: '',
    es: '',
    doc: {
      en: 'Barakhadi',
      gu: 'બારાખડી',
      hi: 'बाराखडी',
      de: '',
      es: ''
    },
    url: {
      es: '',
      de: '',
      en: 'https://drive.google.com/file/d/1JtY0RTN7glt_dhdXo3A6TVf7WVanzCQj/view?usp=sharing'
    },
    secure: null,
    pageIds: 'learnbarakhadi'
  },
  {
    section: 200,
    en: 'Kakko & Barakhadi',
    gu: 'કક્કો અને બારાખડી',
    hi: 'कक्को और बाराखडी',
    de: 'Barakhadi',
    es: '',
    doc: {
      en: 'Barakhadi Words',
      gu: 'બારાખડી શબ્દો',
      hi: 'बाराखडी शब्द',
      de: 'Barakhadi Wörter',
      es: ''
    },
    url: {
      es: '',
      de: 'https://drive.google.com/file/d/1YLKbzVennXGZEeSJYpDAj3OQLvLztpi0/view?usp=sharing',
      en: 'https://drive.google.com/file/d/1q22ItHttbKF146C_GFzRFHEzboeTotzq/view?usp=sharing'
    },
    secure: null,
    pageIds: 'learnbarakhadi'
  },
  {
    section: 300,
    en: 'Noteworthy Characters',
    gu: 'નોંધપાત્ર કેરેકટર્સ',
    hi: 'नोटवर्थी केरेक्टर्स',
    de: '',
    es: '',
    doc: {
      en: 'Noteworthy Characters with Words',
      gu: 'નોંધપાત્ર કેરેકટર્સ શબ્દો સાથે ',
      hi: 'नोटवर्थी केरेक्टर्स शब्दों के साथ',
      de: '',
      es: ''
    },
    url: {
      es: '',
      de: '',
      en: 'https://drive.google.com/file/d/11MEuA5T5iP2Y0uzd3-cEoa4u5cGq81qb/view?usp=sharing'
    },
    secure: null,
    pageIds: 'learnkakko'
  },
  {
    section: 400,
    en: 'Special and Joint letters',
    gu: 'સ્પેશ્યલ અને સંયુક્ત અક્ષરો',
    hi: 'स्पेशियल और संयुक्त अक्षर',
    de: '',
    es: '',
    doc: {
      en: 'Special and Joint letters',
      gu: 'સ્પેશ્યલ અને સંયુક્ત અક્ષર',
      hi: 'स्पेशियल और संयुक्त अक्षर',
      de: '',
      es: ''
    },
    url: {
      es: '',
      de: '',
      en: 'https://drive.google.com/file/d/1VSDWhiYjsz1XBbYEBTk0B67Xcvzxa-Et/view?usp=sharing'
    },
    secure: null,
    pageIds:
      'jointLetters, matchwords, buildword, jointWords, matchtrimantra, matchnavkalamo'
  },
  {
    section: 400,
    en: 'Special and Joint letters',
    gu: 'સ્પેશ્યલ અને સંયુક્ત અક્ષરો',
    hi: 'स्पेशियल और संयुक्त अक्षर',
    de: '',
    es: '',
    doc: {
      en: 'Additional Words-Special and Joint letters',
      gu: 'વધુ શબ્દો - સ્પેશ્યલ અને સંયુક્ત અક્ષરો',
      hi: 'अन्य शब्द-स्पेशियल और संयुक्त अक्षर',
      de: '',
      es: ''
    },
    url: {
      es: '',
      de: '',
      en: 'https://drive.google.com/file/d/1REopLAjtoSoYD4_KV8x8Crenu43BaNeh/view?usp=sharing'
    },
    secure: null,
    pageIds:
      'jointLetters, matchwords, buildword, jointWords, matchtrimantra, matchnavkalamo'
  },
  {
    section: 500,
    en: 'Nishchay',
    gu: 'નિશ્ચય',
    hi: 'निश्चय',
    de: '',
    es: '',
    doc: {
      en: 'Nishchay Writing',
      gu: 'નિશ્ચય લેખન',
      hi: 'निश्चय लेखन',
      de: '',
      es: ''
    },
    url: {
      es: '',
      de: '',
      en: 'https://drive.google.com/file/d/15dra-HnIr1SWYBaszuZZ37lyZClojdm1/view?usp=sharing'
    },
    secure: null,
    pageIds: ''
  },
  {
    section: 650,
    en: 'Reading Material',
    gu: 'રીડીંગ મટીરીયલ',
    hi: 'रीडिंग मटीरियल',
    de: '',
    es: '',
    doc: {
      en: 'For reading with mentors',
      gu: 'મેંટર સાથે વાંચવા માટે ',
      hi: 'मेंटर के साथ पढ़ने के लिए',
      de: '',
      es: ''
    },
    url: {
      es: '',
      de: '',
      en: 'https://drive.google.com/drive/folders/1Tdr2Vq9-ntLb3svL2T5N8zqeFkTjDKlQ?usp=sharing'
    },
    secure: true,
    pageIds: ''
  },
  {
    section: 650,
    en: 'Reading Material',
    gu: 'રીડીંગ મટીરીયલ',
    hi: 'रीडिंग मटीरियल',
    de: '',
    es: '',
    doc: {
      en: 'For self reading',
      gu: 'જાતે વાંચવા માટે',
      hi: 'स्वयं पढ़ने के लिए',
      de: '',
      es: ''
    },
    url: {
      es: '',
      de: '',
      en: 'https://drive.google.com/drive/folders/1yxPRCkbxss4E0Y0JuXN53LhYo-z1Cvss?usp=sharing'
    },
    secure: null,
    pageIds: ''
  },
  {
    section: 700,
    en: 'Colors',
    gu: 'રંગ',
    hi: 'रंग',
    de: '',
    es: '',
    doc: {
      en: 'Colors',
      gu: 'રંગો',
      hi: 'रंग',
      de: '',
      es: ''
    },
    url: {
      es: '',
      de: '',
      en: 'https://drive.google.com/file/d/1DVU-p8Hm2uziEvwuoyAzkDQemmN1FrhQ/view?usp=sharing'
    },
    secure: null,
    pageIds: 'matchcolors'
  },
  {
    section: 800,
    en: 'Numbers',
    gu: 'અંક',
    hi: 'अंक',
    de: '',
    es: '',
    doc: {
      en: 'Numbers 0-100',
      gu: 'અંક - ૦ થી ૧૦૦',
      hi: 'अंक ०-१००',
      de: '',
      es: ''
    },
    url: {
      es: '',
      de: '',
      en: 'https://drive.google.com/file/d/1XXzdyCiu_h-pb6ahv6CYQ8HFt54-MA7q/view?usp=sharing'
    },
    secure: null,
    pageIds: 'fillintheblanksForNumbers, matchnumbers'
  },
  {
    section: 800,
    en: 'Numbers',
    gu: 'અંક',
    hi: 'अंक',
    de: '',
    es: '',
    doc: {
      en: 'Numbers 200 +',
      gu: 'અંક ૨૦૦ +',
      hi: 'अंक २०० +',
      de: '',
      es: ''
    },
    url: {
      es: '',
      de: '',
      en: 'https://drive.google.com/file/d/1FvGNV-zDwPXxyjfNyGX98yZgS59OuMe_/view?usp=sharing'
    },
    secure: null,
    pageIds: 'fillintheblanksForNumbers'
  },
  {
    section: 800,
    en: 'Numbers',
    gu: 'અંક',
    hi: 'अंक',
    de: '',
    es: '',
    doc: {
      en: 'Rhyming Numbers',
      gu: 'પ્રાસવાળા અંકો',
      hi: 'तुकांत वाले अंक',
      de: '',
      es: ''
    },
    url: {
      es: '',
      de: '',
      en: 'https://drive.google.com/file/d/1dkYTzI-uefKeGfcOXqknGJv0hpkz3hQ3/view?usp=sharing'
    },
    secure: null,
    pageIds: 'fillintheblanksForNumbers'
  },
  {
    section: 800,
    en: 'Numbers',
    gu: 'અંક',
    hi: 'अंक',
    de: '',
    es: '',
    doc: {
      en: 'Important Years - Akram Vignan',
      gu: 'અક્રમ વિજ્ઞાનનાં મહત્વનાં વર્ષો',
      hi: 'अक्रम विज्ञान के महत्व के वर्ष',
      de: '',
      es: ''
    },
    url: {
      es: '',
      de: '',
      en: 'https://drive.google.com/file/d/1eLq3DWp7BsMhVNXcQCfHQI3ScBvILo67/view?usp=sharing'
    },
    secure: null,
    pageIds: 'fillintheblanksForNumbers'
  },
  {
    section: 900,
    en: 'Building blocks of Sentence Formation - I',
    gu: 'વાક્ય રચના - ૧',
    hi: 'वाक्य रचना-१',
    de: 'Building blocks of Sentence Formation - I',
    es: '',
    doc: {
      en: 'Pronouns',
      gu: 'સર્વનામ',
      hi: 'सर्वनाम',
      de: 'Pronouns',
      es: ''
    },
    url: {
      es: '',
      de: 'https://drive.google.com/file/d/18ugPAqkYtLiHlnSNieLK-iNHgWxeXO6U/view?usp=share_link',
      en: 'https://drive.google.com/file/d/1rIQpkRCObgI6bMWzZiBiaJKiUepC3Jpu/view?usp=sharing'
    },
    secure: null,
    pageIds: 'pronouns'
  },
  {
    section: 900,
    en: 'Building blocks of Sentence Formation - I',
    gu: 'વાક્ય રચના - ૧',
    hi: 'वाक्य रचना-१',
    de: 'Building blocks of Sentence Formation - I',
    es: 'Pilares de la formación de oraciones - I / Bases da formação de orações - I',
    doc: {
      en: 'Gender',
      gu: 'લિંગ',
      hi: 'लिंग',
      de: 'Gender',
      es: 'Géneros / Gêneros'
    },
    url: {
      es: 'https://drive.google.com/file/d/1OkrNfJL_NIcOx4jHh4DDOEJ_OeAtZGZZ/view?usp=sharing',
      de: 'https://drive.google.com/file/d/1WOnYjZwVp7oWB6NMBta10SHef4I1xWlP/view?usp=sharing',
      en: 'https://drive.google.com/file/d/1l8BTtsiLNfhlZg7586EbtcLCgyO30Rg7/view?usp=sharing'
    },
    secure: null,
    pageIds: 'genders, singular-plural'
  },
  {
    section: 900,
    en: 'Building blocks of Sentence Formation - I',
    gu: 'વાક્ય રચના - ૧',
    hi: 'वाक्य रचना-१',
    de: 'Building blocks of Sentence Formation - I',
    es: '',
    doc: {
      en: 'Sentence Formation',
      gu: 'વાક્ય રચના',
      hi: 'वाक्य रचना',
      de: 'Sentence Formation',
      es: ''
    },
    url: {
      es: '',
      de: 'https://drive.google.com/file/d/1tzuFlbLnAGZZSxOuvwMCw6EITjvRs-W9/view?usp=share_link',
      en: 'https://drive.google.com/file/d/1GvWQQm2lrgfUgsFjN_cLlbKqKZKZsESp/view?usp=sharing'
    },
    secure: null,
    pageIds: 'sentenceforming'
  },
  {
    section: 900,
    en: 'Building blocks of Sentence Formation - I',
    gu: 'વાક્ય રચના - ૧',
    hi: 'वाक्य रचना-१',
    de: 'Building blocks of Sentence Formation - I',
    es: '',
    doc: {
      en: 'Interrogative Sentence Formation',
      gu: 'પ્રશ્નાર્થ વાક્ય રચના',
      hi: 'प्रश्नवाचक वाक्य रचना',
      de: 'Interrogative Sentence Formation',
      es: ''
    },
    url: {
      es: '',
      de: 'https://drive.google.com/file/d/1EXYEJUWDzUW93kJO_OGZpByzh3fs9RGw/view?usp=share_link',
      en: 'https://drive.google.com/file/d/1kd9fgcDtab1wFFs4EvizSOmlLL3yyXh4/view?usp=sharing'
    },
    secure: null,
    pageIds: 'interrogatives'
  },
  {
    section: 1000,
    en: 'Building blocks of Sentence Formation - II',
    gu: 'વાક્ય રચના - ૨',
    hi: 'वाक्य रचना-२',
    de: 'Building blocks of Sentence Formation - II',
    es: 'Pilares de la formación de oraciones - II / Bases da formação de orações - II',
    doc: {
      en: 'Present Tense',
      gu: 'વર્તમાન કાળ',
      hi: 'वर्तमान काल',
      de: 'Present Tense',
      es: 'Tiempo Presente / Tempo Presente'
    },
    url: {
      es: 'https://drive.google.com/file/d/12eAau1LVFra9U3yuGlPx4ReSm7OCphMt/view?usp=sharing',
      de: 'https://drive.google.com/file/d/1TkoPspt7bHF8l36BFUhcj3Q5HgdSw0xA/view?usp=share_link',
      en: 'https://drive.google.com/file/d/1W3D4klzc1uq_0cYT4J4tK4Li1Txf0RFo/view'
    },
    secure: null,
    pageIds: 'present-tense, identifytenses'
  },
  {
    section: 1000,
    en: 'Building blocks of Sentence Formation - II',
    gu: 'વાક્ય રચના - ૨',
    hi: 'वाक्य रचना-२',
    de: 'Building blocks of Sentence Formation - II',
    es: '',
    doc: {
      en: 'Future Tense',
      gu: 'ભવિષ્ય કાળ',
      hi: 'भविष्य काल',
      de: 'Future Tense',
      es: ''
    },
    url: {
      es: '',
      de: 'https://drive.google.com/file/d/1fN4-hcS1YyxzAIcUhPtIyIfG4vC5kgeR/view?usp=share_link',
      en: 'https://drive.google.com/file/d/1px88d_6iQ2DsDrqIHXk2TzZi0-x1hAWN/view'
    },
    secure: null,
    pageIds: 'future-tense, identifytenses'
  },
  {
    section: 1000,
    en: 'Building blocks of Sentence Formation - II',
    gu: 'વાક્ય રચના - ૨',
    hi: 'वाक्य रचना-२',
    de: 'Building blocks of Sentence Formation - II',
    es: '',
    doc: {
      en: 'Past Tense',
      gu: 'ભૂત કાળ',
      hi: 'भूतकाल',
      de: 'Past Tense',
      es: ''
    },
    url: {
      es: '',
      de: 'https://drive.google.com/file/d/1TWhle24j077Q80oLdkkp0RBg-NTkhy8t/view?usp=share_link',
      en: 'https://drive.google.com/file/d/1_Y333wxEEJooMpzgMSCjfXk9zErDRNQb/view'
    },
    secure: null,
    pageIds: 'past-tense, identifytenses'
  },
  {
    section: 1000,
    en: 'Building blocks of Sentence Formation - II',
    gu: 'વાક્ય રચના - ૨',
    hi: 'वाक्य रचना-२',
    de: '',
    es: '',
    doc: {
      en: 'Verbs in Simple Tense',
      gu: 'ક્રિયાપદ',
      hi: 'क्रियापद',
      de: '',
      es: ''
    },
    url: {
      es: '',
      de: '',
      en: 'https://drive.google.com/file/d/1lNvm-N_jgXahb94asAeKP1XlVxQ03C0C/view?usp=sharing'
    },
    secure: null,
    pageIds: 'present-tense, future-tense, past-tense, identifytenses'
  },
  {
    section: 1000,
    en: 'Building blocks of Sentence Formation - II',
    gu: 'વાક્ય રચના - ૨',
    hi: 'वाक्य रचना-२',
    de: 'Building blocks of Sentence Formation - II',
    es: 'Pilares de la formación de oraciones - II / Bases da formação de orações - II',
    doc: {
      en: 'Useful Words Part - I',
      gu: 'ઉપયોગી શબ્દ ભાગ - ૧',
      hi: 'उपयोगी शब्द भाग-१',
      de: 'Useful words',
      es: 'Palabras Útiles Parte- I / Palavras Úteis Parte - I'
    },
    url: {
      es: 'https://drive.google.com/file/d/14DKU6zQJ3hzZYWFI_YV5JGgOE5GWr5v4/view?usp=sharing',
      de: 'https://drive.google.com/file/d/1Pui_GM93EdjjH-hlWGNMTN81E3OPyNm2/view?usp=share_link',
      en: 'https://drive.google.com/file/d/1mzbWPP8i9tRly7fNYOtiCwAcHJ50bdqj/view?usp=sharing'
    },
    secure: null,
    pageIds: 'useful-words'
  },
  {
    section: 1000,
    en: 'Building blocks of Sentence Formation - II',
    gu: 'વાક્ય રચના - ૨',
    hi: 'वाक्य रचना-२',
    de: 'Building blocks of Sentence Formation - II',
    es: 'Pilares de la formación de oraciones - II / Bases da formação de orações - II',
    doc: {
      en: 'Useful Words Part - II',
      gu: 'ઉપયોગી શબ્દ ભાગ - ૨',
      hi: 'उपयोगी शब्द भाग-२',
      de: 'Useful words (Interrogatives)',
      es: 'Palabras Útiles Parte- II / Palavras Úteis Parte - II'
    },
    url: {
      es: 'https://drive.google.com/file/d/117HE8AYZn2hULSxxpFfGUQlPbTLBipZX/view?usp=sharing',
      de: 'https://drive.google.com/file/d/1EVRZn_V9qnmWFr9cnJuXt68AcDIFrYG9/view?usp=share_link',
      en: 'https://drive.google.com/file/d/1wUeM1j591OX3c7bDmhK8zJKGoIT2Db87/view'
    },
    secure: null,
    pageIds: 'useful-words'
  },
  {
    section: 1100,
    en: 'Quiz on Videos',
    gu: 'વિવિધ વિડિયો અને ક્વીઝ',
    hi: 'विविध वीडियो और क्विज',
    de: '',
    es: '',
    doc: {
      en: 'Access all Quizzes here',
      gu: 'બધી ક્વિઝ માટે અહીં ક્લિક કરો ',
      hi: 'सारे क्विज के लिए यहाँ पर क्लिक करे',
      de: '',
      es: ''
    },
    url: {
      es: '',
      de: '',
      en: 'https://drive.google.com/file/d/1w-kGhRvbDaoOTXJSVOUkgQngU75ofdij/view?usp=sharing'
    },
    secure: true,
    pageIds: ''
  },
  {
    section: 1100,
    en: '',
    gu: '',
    hi: '',
    de: '',
    es: 'Cuestionario de Videos / Questionários de Vídeos',
    doc: {
      en: '',
      gu: '',
      hi: '',
      de: '',
      es: 'PALABRAS CLAVE- Por qué no deberíamos comer huevos / PALAVRAS CHAVES - Por que não deveríamos comer ovos'
    },
    url: {
      es: 'https://drive.google.com/file/d/1k5TJSTsv4Tjpra6w6NMjV6Mp8mvUkL6c/view?usp=sharing',
      de: '',
      en: ''
    },
    secure: null,
    pageIds: ''
  },
  {
    section: 1100,
    en: '',
    gu: '',
    hi: '',
    de: '',
    es: 'Cuestionario de Videos / Questionários de Vídeos',
    doc: {
      en: '',
      gu: '',
      hi: '',
      de: '',
      es: 'TEXTO - Por qué no deberíamos comer huevos- Español'
    },
    url: {
      es: 'https://drive.google.com/file/d/1B9H83ru2aAkE4J2iDu_bW5b6CMSwmjp_/view?usp=sharing',
      de: '',
      en: ''
    },
    secure: null,
    pageIds: ''
  },
  {
    section: 1100,
    en: '',
    gu: '',
    hi: '',
    de: '',
    es: 'Cuestionario de Videos / Questionários de Vídeos',
    doc: {
      en: '',
      gu: '',
      hi: '',
      de: '',
      es: 'TEXTO - Por que não deveríamos comer ovos - Português'
    },
    url: {
      es: 'https://drive.google.com/file/d/1zHGZUiZlayE6eetPlUJrfOIbzYkSpW_P/view?usp=sharing',
      de: '',
      en: ''
    },
    secure: null,
    pageIds: ''
  },
  {
    section: 1200,
    en: 'JJ 100 word by word Translation',
    gu: 'JJ 100 શબ્દે શબ્દ નો અનુવાદ',
    hi: 'JJ 100 शब्द दर शब्द अनुवाद',
    de: '',
    es: '',
    doc: {
      en: 'Video-1 English Translation',
      gu: 'વીડિયો - ૧ અંગ્રેજી અનુવાદ',
      hi: 'वीडियो-१ अंग्रेजी अनुवाद',
      de: '',
      es: ''
    },
    url: {
      es: '',
      de: '',
      en: 'https://drive.google.com/drive/folders/1QWsCepe39mElFmT9IFR5ahevsq1KO4R_?usp=sharing'
    },
    secure: null,
    pageIds: ''
  },
  {
    section: 1200,
    en: 'JJ 100 word by word Translation',
    gu: 'JJ 100 શબ્દે શબ્દ નો અનુવાદ',
    hi: 'JJ 100 शब्द दर शब्द अनुवाद',
    de: '',
    es: '',
    doc: {
      en: 'Video-1 Hindi Translation',
      gu: 'વીડિયો - ૧ હિન્દી અનુવાદ',
      hi: 'वीडियो-१ हिंदी अनुवाद',
      de: '',
      es: ''
    },
    url: {
      es: '',
      de: '',
      en: 'https://drive.google.com/drive/folders/1sfdAfDDPcVESfE3PskWJ_fniNbWBtkqN?usp=sharing'
    },
    secure: null,
    pageIds: ''
  },
  {
    section: 1200,
    en: 'JJ 100 word by word Translation',
    gu: 'JJ 100 શબ્દે શબ્દ નો અનુવાદ',
    hi: 'JJ 100 शब्द दर शब्द अनुवाद',
    de: '',
    es: '',
    doc: {
      en: 'Video-2 English Translation',
      gu: 'વીડિયો - ૨ અંગ્રેજી અનુવાદ',
      hi: 'वीडियो-२ अंग्रेजी अनुवाद',
      de: '',
      es: ''
    },
    url: {
      es: '',
      de: '',
      en: 'https://drive.google.com/drive/folders/15zwVDsYyVuskivzl_GuZGkem9lMuqDoF?usp=sharing'
    },
    secure: null,
    pageIds: ''
  },
  {
    section: 1200,
    en: 'JJ 100 word by word Translation',
    gu: 'JJ 100 શબ્દે શબ્દ નો અનુવાદ',
    hi: 'JJ 100 शब्द दर शब्द अनुवाद',
    de: '',
    es: '',
    doc: {
      en: 'Video-2 Hindi Translation',
      gu: 'વીડિયો - ૨ હિન્દી અનુવાદ',
      hi: 'वीडियो-२ हिंदी अनुवाद',
      de: '',
      es: ''
    },
    url: {
      es: '',
      de: '',
      en: 'https://drive.google.com/drive/folders/1mANiM68qbadSTgK9kVMYTocELIyp7UCq?usp=sharing'
    },
    secure: null,
    pageIds: ''
  }
]
export default studyMaterial
