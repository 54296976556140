import Utils from '../util/Utils'

const pageData = [
  {
    level: '0',
    question: {
      gu: 'હું દાદાનાં દર્શન _.',
      en: "I will do Dada's Darshan.",
      hi: 'मैं दादा के दर्शन करूंगा / करूंगी|'
    },
    options: 'કરીશું, કરશે, કરીશ, કરશો',
    answers: {
      gu: 'કરીશ',
      en: 'will do',
      hi: 'करूंगा / करूंगी'
    }
  },
  {
    level: '0',
    question: {
      gu: 'તું અડાલજ શિબિરમાં _?',
      en: 'Will you come in Adalaj Shibir?',
      hi: 'तुम अडालज शिबिर में आओगे / आओगी?'
    },
    options: 'આવશું, આવશો, આવીશ, આવશે',
    answers: {
      gu: 'આવીશ',
      en: 'Will come',
      hi: 'आओगे / आओगी'
    }
  },
  {
    level: '0',
    question: {
      gu: 'તેઓ સત્સંગમાં પૂજ્યશ્રીને _.',
      en: 'They will listen to Pujyashree in satsang.',
      hi: 'वे पूज्यश्री को सत्संग में सुनेंगे|'
    },
    options: 'સાંભળશે, સાંભળીશ, સાંભળીશું, સાંભળશો',
    answers: {
      gu: 'સાંભળશે',
      en: 'will listen',
      hi: 'सुनेंगे'
    }
  },
  {
    level: '0',
    question: {
      gu: 'તમે ત્રિમંત્ર _?',
      en: 'Will you speak Trimantra?',
      hi: 'आप त्रिमंत्र बोलेंगे?'
    },
    options: 'બોલીશ, બોલશો, બોલશે, બોલીશું',
    answers: {
      gu: 'બોલશો',
      en: 'Will speak',
      hi: 'बोलेंगे'
    }
  },
  {
    level: '0',
    question: {
      gu: 'અમે સુખડી _.',
      en: 'We will eat Sukhadi.',
      hi: 'हम सुखडी खाएँगे|'
    },
    options: 'ખાઈશ, ખાઈશું, ખાશે, ખાશે',
    answers: {
      gu: 'ખાઈશું',
      en: 'will eat',
      hi: 'खाएँगे'
    }
  },
  {
    level: '0',
    question: {
      gu: 'તે મારી વાત _?',
      en: 'Will he accept my point?',
      hi: 'वो मेरी बात मानेंगे?'
    },
    options: 'માનશે, માનીશ, માનશું, માનશો',
    answers: {
      gu: 'માનશે',
      en: 'Will accept',
      hi: 'मानेंगे'
    }
  },
  {
    level: '0',
    question: {
      gu: 'મને જમવામાં શીરો _.',
      en: 'I will like sweet dish (Shiro) in bhojan.',
      hi: 'मुझे खाने में मीठा (शिरो) पसंद आएगा|'
    },
    options: 'ભાવશો, ભાવશે, ભાવીશ, ભાવીશું',
    answers: {
      gu: 'ભાવશે',
      en: 'will like',
      hi: 'पसंद आएगा'
    }
  },
  {
    level: '0',
    question: {
      gu: 'તેઓને પોલીસવાળા _.',
      en: 'Police will catch them.',
      hi: 'उनको पुलिसवाले पकड़ेंगे|'
    },
    options: 'પકડીશ, પકડીશું, પકડશો, પકડશે',
    answers: {
      gu: 'પકડશે',
      en: 'will catch',
      hi: 'पकड़ेंगे'
    }
  },
  {
    level: '0',
    question: {
      gu: 'તમને વહેલું ઊઠવાનું _?',
      en: 'Will you be comfortable to get up early?',
      hi: 'आप जल्दी उठ सकोगे?'
    },
    options: 'ફાવીશ, ફાવશે, ફાવશો, ફાવીશું',
    answers: {
      gu: 'ફાવશે',
      en: 'Will be comfortable',
      hi: 'सकोगे'
    }
  },
  {
    level: '0',
    question: {
      gu: 'તે ખાંડ ચામાં _?',
      en: 'Will that sugar dissolve in tea?',
      hi: 'वो चीनी चाय में पिगलेगी?'
    },
    options: 'ઓગળીશું, ઓગળશે, ઓગળીશ, ઓગળશો',
    answers: {
      gu: 'ઓગળશે',
      en: 'Will dissolve',
      hi: 'पिगलेगी'
    }
  },
  {
    level: '0',
    question: {
      gu: 'હું આત્માના ગુણોને _.',
      en: 'I will identify virtues of Soul.',
      hi: 'मैं आत्मा के गुणों को पहचानूंगा / पहचानूंगी|'
    },
    options: 'ઓળખીશું, ઓળખાશે, ઓળખીશ, ઓળખશો',
    answers: {
      gu: 'ઓળખીશ',
      en: 'will identify',
      hi: 'पहचानूंगा / पहचानूंगी'
    }
  },
  {
    level: '0',
    question: {
      gu: 'તમે ભક્તિપદો _?',
      en: 'Will you sing Bhaktipad?',
      hi: 'आप भक्तिपद गाएंगे?'
    },
    options: 'ગાશો, ગાશે, ગાઈશ, ગાવું',
    answers: {
      gu: 'ગાશો',
      en: 'Will sing',
      hi: 'गाएंगे'
    }
  },
  {
    level: '0',
    question: {
      gu: 'અમે અક્રમ વિજ્ઞાનને _.',
      en: 'We will understand Akram Vignan.',
      hi: 'हम अक्रम विज्ञान को समझेंगे|'
    },
    options: 'સમજશો, સમજશે, સમજીશું, સમજાશે',
    answers: {
      gu: 'સમજીશું',
      en: 'will understand',
      hi: 'समझेंगे'
    }
  },
  {
    level: '0',
    question: {
      gu: 'અમે દિલથી સેવા _.',
      en: 'We will do seva heartily.',
      hi: 'हम ह्रदयपूर्वक सेवा करेंगे।'
    },
    options: 'કરીશું, કરશે, કરીશ, કરશો',
    answers: {
      gu: 'કરીશું',
      en: 'will do',
      hi: 'करेंगे'
    }
  },
  {
    level: '0',
    question: {
      gu: 'અમે શિબિરમાં ધન્યતા _.',
      en: 'We will feel blessed in the Shibir.',
      hi: 'हम शिविर में धन्यता अनुभव करेंगे।'
    },
    options: 'અનુભવીશ, અનુભવીશું, અનુભવાશે, અનુભવશો',
    answers: {
      gu: 'અનુભવીશું',
      en: 'will feel blessed',
      hi: 'अनुभव करेंगे'
    }
  },
  {
    level: '0',
    question: {
      gu: 'આપણે પારાયણમાં _.',
      en: 'We will meet at Parayan.',
      hi: 'हम पारायण में मिलेंगे।'
    },
    options: 'મળશે, મળીશ, મળવું, મળીશું',
    answers: {
      gu: 'મળીશું',
      en: 'will meet',
      hi: 'मिलेंगे'
    }
  },
  {
    level: '0',
    question: {
      gu: 'તેઓ અડાલજ _.',
      en: 'They will stay at Adalaj.',
      hi: 'वे अडालज रुकेंगे।'
    },
    options: 'રોકીશું, રોકાશે, રોકીશ, રોકશો',
    answers: {
      gu: 'રોકાશે',
      en: 'will stay',
      hi: 'रुकेंगे'
    }
  },
  {
    level: '0',
    question: {
      gu: 'હું એકાવતારી _.',
      en: 'I will be Ekavtaari.',
      hi: 'मैं एकावतारी बनूँगा।'
    },
    options: 'થઇ, થઈશ, થશો, થાઇશ',
    answers: {
      gu: 'થઈશ',
      en: 'will be',
      hi: 'बनूँगा'
    }
  },
  {
    level: '0',
    question: {
      gu: 'હું મહાત્માઓ માટે બાદશાહી ખીચડી _.',
      en: 'I will cook Badshahi Kichadi for Mahatmas.',
      hi: 'मैं महात्माओ के लिए बादशाही खिचड़ी पकाऊंगा / पकाऊंगी।'
    },
    options: 'રાંધીશુ, રાંધીશ, રાંધશો, રંધાશે',
    answers: {
      gu: 'રાંધીશ',
      en: 'will cook',
      hi: 'पकाऊंगा / पकाऊंगी'
    }
  },
  {
    level: '0',
    question: {
      gu: 'તેઓ સ્વામીના દર્શન _.',
      en: "They will do Swami's darshan.",
      hi: 'वे स्वामी के दर्शन करेंगे।'
    },
    options: 'કરશો, કરશે, કરીશ, કરશો',
    answers: {
      gu: 'કરશે',
      en: 'will do',
      hi: 'करेंगे'
    }
  },
  {
    level: '0',
    question: {
      gu: 'મને સાયકલ ચલાવતા આવડી _.',
      en: 'I will be able to learn to ride Bicycle.',
      hi: 'मुझे सायकल चलाना आ जाएगा|'
    },
    options: 'જશે, જઈશ, જશો, જાઈશ',
    answers: {
      gu: 'જશે',
      en: 'will be',
      hi: 'आ जाएगा'
    }
  },
  {
    level: '0',
    question: {
      gu: 'અમે જ્ઞાનીના પરમ વિનયમાં _.',
      en: 'We will remain in absolute humility of Gnani.',
      hi: 'हम ज्ञानी के परमविनय में रहेंगे।'
    },
    options: 'રહીશે, રહીશું, રહીશ, રહેશો',
    answers: {
      gu: 'રહીશું',
      en: 'will remain',
      hi: 'रहेंगे'
    }
  },
  {
    level: '0',
    question: {
      gu: 'અમને નવી સેવાઓ _?',
      en: 'Will we get new sevas?',
      hi: 'हमें नई सेवाएँ मिलेंगी?'
    },
    options: 'મળીશું, મળશે, મળશો, મળીશ',
    answers: {
      gu: 'મળશે',
      en: 'Will get',
      hi: 'मिलेंगी'
    }
  },
  {
    level: '0',
    question: {
      gu: 'અમે પક્ષીઓને ચણ _.',
      en: 'We will feed gram to the birds.',
      hi: 'हम पंछियों को दानापानी देंगे।'
    },
    options: 'નાખીશ, નાખશો, નાખીશું, નાખશે',
    answers: {
      gu: 'નાખીશું',
      en: 'will feed',
      hi: 'देंगे'
    }
  },
  {
    level: '0',
    question: {
      gu: 'પૂજ્યશ્રી બધાં બાળકોને આશીર્વાદ _.',
      en: 'Pujyashree will give blessing to all the children.',
      hi: 'पूज्यश्री सभी बच्चो को आशीर्वाद देंगे।'
    },
    options: 'આપીશું, આપશો, આપશે, આપીશ',
    answers: {
      gu: 'આપશે',
      en: 'will give',
      hi: 'देंगे'
    }
  },
  {
    level: '1',
    question: {
      gu: 'આજે સાંજે હું ત્રિમંદિરના દર્શને _.',
      en: 'Today evening, I will go for Trimandir Darshan.',
      hi: 'आज शाम को में त्रिमंदिर दर्शन करने जाऊंगी / जाऊंगा|'
    },
    options: 'જઈશું, જઈશ, જશે, જશો',
    answers: {
      gu: 'જઈશ',
      en: 'will go',
      hi: 'जाऊंगी / जाऊंगा'
    }
  },
  {
    level: '1',
    question: {
      gu: 'આજે ગુરુપૂર્ણિમાનાં દિવસે દાદા બધાંને દર્શન _.',
      en: 'Today on Gurupurnima, Dada will give Darshan to all.',
      hi: 'आज गुरुपूर्णिमा के दिन दादा सब को दर्शन देंगे|'
    },
    options: 'આપીશું, આપશો, આપશે, આપીશ',
    answers: {
      gu: 'આપશે',
      en: 'will give',
      hi: 'देंगे'
    }
  },
  {
    level: '1',
    question: {
      gu: 'પ્રતિક્રમણથી તમે પણ હવે બધાં દોષો ભૂંસી _.',
      en: 'Now you will also clean all the mistakes by Pratikraman.',
      hi: 'प्रतिक्रमण से अब आप भी अपने सारे दोष साफ़ करदोगे|'
    },
    options: 'નાખશો, નાખીશું, નાખીશ, નાખશે',
    answers: {
      gu: 'નાખશો',
      en: 'will clean',
      hi: 'करदोगे'
    }
  },
  {
    level: '1',
    question: {
      gu: 'મમ્મી-પપ્પાનો વિનય ન _.',
      en: "Don't miss reverence of your parennts.",
      hi: 'माता पिता के विनय को मत चुकना|'
    },
    options: 'ચૂકીશું, ચૂકશે, ચૂકીશ, ચુકશે',
    answers: {
      gu: 'ચૂકીશ',
      en: 'miss',
      hi: 'चुकना'
    }
  },
  {
    level: '1',
    question: {
      gu: 'વારસ અમે દાદાનાં, કારુણ્યતા _.',
      en: 'Varas ame dada na, karunyta relavishu.',
      hi: 'वारस अमे दादा ना, कारुन्यता रेलाविशु|'
    },
    options: 'રેલાવીશ, રેલાવીશું, રેલાવશે, રેલાવશો',
    answers: {
      gu: 'રેલાવીશું',
      en: 'relavishu',
      hi: 'रेलाविशु'
    }
  },
  {
    level: '1',
    question: {
      gu: 'આજે સાંજે મને મારો મિત્ર _.',
      en: 'My friend will meet me today evening.',
      hi: 'आज शाम को मुझे मेरा दोस्त मिलेगा|'
    },
    options: 'મળશો, મળશે, મળીશું, મળીશ',
    answers: {
      gu: 'મળશે',
      en: 'will meet',
      hi: 'मिलेगा'
    }
  },
  {
    level: '1',
    question: {
      gu: 'દાદાનો સત્સંગ અહંકારને _.',
      en: "Dada's satsang will dissolve the Ego.",
      hi: 'दादा का सत्संग अहंकार को पिघलाएगा|'
    },
    options: 'ઓગાળશે, ઓગાળીશું, ઓગાળીશ, ઓગાળશો',
    answers: {
      gu: 'ઓગાળશે',
      en: 'will dissolve',
      hi: 'पिघलाएगा'
    }
  },
  {
    level: '1',
    question: {
      gu: 'આજે મહાત્માઓ _ આનંદનો અનુભવ!',
      en: 'Today Mahatmas will experience bliss.',
      hi: 'आज सभी महात्मा करेंगे आनंद का अनुभव!'
    },
    options: 'કરશો, કરશે, કરીશ, કરશો',
    answers: {
      gu: 'કરશે',
      en: 'will experience',
      hi: 'करेंगे'
    }
  },
  {
    level: '1',
    question: {
      gu: 'ઘરે બધાં સાથે એડજસ્ટ થતાં _?',
      en: 'Will you be able to adjust with everyone at home?',
      hi: 'घर में सब के साथ एडजस्ट हो सकोगे?'
    },
    options: 'ફાવશે, ફાવીશું, ફાવશો, ફાવીશ',
    answers: {
      gu: 'ફાવશે',
      en: 'Will be able',
      hi: 'सकोगे'
    }
  },
  {
    level: '1',
    question: {
      gu: 'પૂજ્યશ્રી સાથે બધાં મહાત્માઓ પણ _.',
      en: 'All Mahatmas will laugh with Pujyashree.',
      hi: 'सभी महात्मा पूज्यश्री के साथ हसेंगे|'
    },
    options: 'હસીશ, હસશે, હસીશું, હસશો',
    answers: {
      gu: 'હસશે',
      en: 'will laugh',
      hi: 'हसेंगे'
    }
  },
  {
    level: '1',
    question: {
      gu: 'જગત સાચા પ્રેમની વ્યાખ્યા અક્રમ વિજ્ઞાન થકી _.',
      en: 'The World will know true definition of Love through Akram Vignan.',
      hi: 'विश्व सच्चे प्रेम का मतलब अक्रम विज्ञान से जानेगा।'
    },
    options: 'જાણશે, જાણીશું, જાણશો, જાણીશ',
    answers: {
      gu: 'જાણશે',
      en: 'will know',
      hi: 'जानेगा'
    }
  },
  {
    level: '1',
    question: {
      gu: 'તમે તમારો જ્ઞાની સાથેનો અનુભવ _?',
      en: 'Will you share your experience with Gnani?',
      hi: 'आप अपना ज्ञानी के साथ का अनुभव बताएंगे?'
    },
    options: 'કહેશો, કહીશું, કહેશે, કહીશ',
    answers: {
      gu: 'કહેશો',
      en: 'Will share',
      hi: 'बताएंगे'
    }
  },
  {
    level: '1',
    question: {
      gu: 'આવતીકાલે પૂજયશ્રી બધા GNC ના બાળકોની પરીક્ષા માટે વિધિ _.',
      en: "Tomorrow Pujyashree will do vidhi for all GNC childeren's Exam.",
      hi: 'कल पूज्यश्री सभी GNC बच्चो के लिए विधि कराएँगे|'
    },
    options: 'કરાવશે, કરશે, કરીશું, કરાવતું',
    answers: {
      gu: 'કરાવશે',
      en: 'will do',
      hi: 'कराएँगे'
    }
  },
  {
    level: '1',
    question: {
      gu: 'દાદાની પાંચ આજ્ઞાથી એક અવતારીપદ ને _.',
      en: "With the help of Dada's five Agnan we will get/ will reach to the state of one life (before ultimate liberation).",
      hi: 'दादाकि पांच आज्ञा से एकअवतारी पद को पहुंचेंगे|'
    },
    options: 'પામીશું, પામશે, પામશે, પામીશ',
    answers: {
      gu: 'પામીશું',
      en: 'will get/ will reach',
      hi: 'पहुंचेंगे'
    }
  },
  {
    level: '1',
    question: {
      gu: 'આજનો લાઈવ સત્સંગ તમારે ત્યાં _.',
      en: "We will keep Today's Live Satsang at your place.",
      hi: 'आजका लाइव सत्संग आपके यहाँ रखेंगे|'
    },
    options: 'ગોઠવીશું, ગોઠવીશ, ગોઠવાશે, ગોઠવશે',
    answers: {
      gu: 'ગોઠવીશું',
      en: 'will keep',
      hi: 'रखेंगे'
    }
  },
  {
    level: '1',
    question: {
      gu: 'હવેથી હું તમને દરરોજ _.',
      en: 'Now I will bow down to you daily.',
      hi: 'अब मैं तुम्हें प्रतिदिन प्रणाम करूंगा|'
    },
    options: 'પગે લાગીશ, પગે લાગશો, પગે લાગશે, પગે લાગવું',
    answers: {
      gu: 'પગે લાગીશ',
      en: 'will bow down',
      hi: 'प्रणाम करूंगा'
    }
  },
  {
    level: '1',
    question: {
      gu: 'પૂજ્યશ્રી ભાવનગર ત્રિમંદીરના દ્વાર _.',
      en: 'Pujyashree will open Bhavnagar Trimandir Gate.',
      hi: 'भावनगर त्रिमंदिर के द्वार पूज्यश्री खोलेंगे।'
    },
    options: 'ખોલીશ, ખોલશે, ખોલો, ખોલીશું',
    answers: {
      gu: 'ખોલશે',
      en: 'will open',
      hi: 'खोलेंगे'
    }
  },
  {
    level: '1',
    question: {
      gu: 'નીરુમા કેહતા કે રસોઈ તો સાવ નાની બાબત છે, આમ _.',
      en: 'Niruma used to say that cooking is a very small matter, so I will learn it (quickly).',
      hi: 'निरूमा कहती थीं कि खाना बनाना तो बहुत छोटी चीज है, इसलिए (जल्दी) सीख लूंगी|'
    },
    options: 'શીખી જઈશું, શીખી જઈશ, શીખી જશે, શીખી જશો',
    answers: {
      gu: 'શીખી જઈશ',
      en: 'will learn',
      hi: 'सीख लूंगी'
    }
  },
  {
    level: '1',
    question: {
      gu: 'તમે ભોંયપથારીએ _?',
      en: 'Will you sleep at floorbed?',
      hi: 'आप जमीन पे सो जाओगे?'
    },
    options: 'સુઈ જાશું, સુઈ જશો, સુઈ જઈશ, સુઈ જશુ',
    answers: {
      gu: 'સુઈ જશો',
      en: 'Will sleep',
      hi: 'सो जाओगे'
    }
  },
  {
    level: '1',
    question: {
      gu: 'દાદા, મને તમારી ચોવીસ કલાકની નિરંતરની સેવા કરવાનો _?',
      en: 'Dada, will you give me a chance to serve you round the clock?',
      hi: 'दादा, मुझे चौबीस घंटे आपकी सेवा करने का मौका देंगे?'
    },
    options: 'મોકો આપશે, મોકો આપીશ, મોકો આપશો, મોકો આપજો',
    answers: {
      gu: 'મોકો આપશો',
      en: 'will give chance',
      hi: 'मौका देंगे'
    }
  },
  {
    level: '1',
    question: {
      gu: 'નીરુમાનો પ્રેમ એવો હતો કે તેઓ ક્યારેય _ નહિ.',
      en: "Niruma's love was such that (we) will never forget her.",
      hi: 'नीरुमा का प्यार ऐसा था कि वे कभी नहीं भूलेंगे।'
    },
    options: 'ભૂલવું, ભુલશું, ભુલાશે, ભૂલ',
    answers: {
      gu: 'ભુલાશે',
      en: 'will forget',
      hi: 'भूलेंगे'
    }
  },
  {
    level: '1',
    question: {
      gu: 'દાદાની વાણી વાંચવાથી અંદરના આવરણો _.',
      en: "Reading Dada's speech will break the inner coverings.",
      hi: 'दादाजी की वाणी पढ़ने से भीतर के आवरण टूट जायेंगे|'
    },
    options: 'તોડીશ, તુટીશ, તૂટશે, તોડશે',
    answers: {
      gu: 'તૂટશે',
      en: 'will break',
      hi: 'टूट जायेंगे'
    }
  },
  {
    level: '1',
    question: {
      gu: 'તારો અપરાધ અક્ષમ્ય છે. તને મૃત્યુદંડ ની સજા આપવામાં _.',
      en: 'Your guilt is unpardonable. You will be sentenced to death.',
      hi: 'आपका अपराध क्षमा योग्य नहीं है. तुम्हें मौत की सज़ा दी जाएगी|'
    },
    options: 'આવશે, આવીશ, આવાશે, આવીશું',
    answers: {
      gu: 'આવશે',
      en: 'will be',
      hi: 'दी जाएगी'
    }
  },
  {
    level: '1',
    question: {
      gu: 'આવતા વર્ષે દાદાની જન્મજયંતી ક્યાં શહેરમાં _?',
      en: "In which city will the Dada's birth anniversary be celebrated next year?",
      hi: 'अगले वर्ष किस शहर में दादाकी जयंती मनाई जाएगी?'
    },
    options: 'ઉજાવીશ, ઉજવીશ, ઉજવાશે, ઉજાસે',
    answers: {
      gu: 'ઉજવાશે',
      en: 'will be celebrated',
      hi: 'मनाई जाएगी'
    }
  },
  {
    level: '1',
    question: {
      gu: 'દાદા અત્યારે મહાવિદેહ ક્ષેત્રમાં છે. અમે પણ ત્યાં _.',
      en: 'Dada is currently in Mahavideh region. We will go there too.',
      hi: 'दादा इस समय महाविदेह क्षेत्र में हैं। हम भी वहां जाएंगे|'
    },
    options: 'જશે, જઈશ, જશો, જઈશું',
    answers: {
      gu: 'જઈશું',
      en: 'will go',
      hi: 'जाएंगे'
    }
  },
  {
    level: '2',
    question: {
      gu: 'કાલે ગુરુપૂર્ણિમાનાં દિવસે દાદા પૂર્ણ હાજર _ (હોવું). સવારે શોભાયાત્રા _ (નીકળવું) અને હજારો મહાત્માઓ અને મુમુક્ષુઓ દર્શન _ (કરવું). મહાત્માઓ ખૂટતી શક્તિઓ _ (માંગવું) અને ખૂબ પ્રગતિ કરી _ (લેવું).',
      en: 'Tomorrow on Gurupurnima Dada will be fully present. At morning Shobhayatra will be out and thousands of Mahatmas and Mumukshus will do darshan. Mahatmas will ask for lacking strength and will do a lot of progress.',
      hi: 'कल गुरुपूर्णिमा के दिन दादा पूर्णरूप में हाजर रहेंगे| सुबह शोभायात्रा निकलेगी और हजारों महात्मा और मुमुक्षु दर्शन करेंगे| महात्मा जरूरी शक्तियाँ मांगेंगे और बहुत प्रगति कर लेंगे|'
    },
    options:
      'હોઈશ, હશે, હોઈશો, હોઈશું, નીકળશે, નીકળશું, નીકળીશ, નીકળીશું, કરીશ, કરીશું, કરશે, કરશો, માંગશે, માંગીશું, માગશો, માંગ, લઈશ, લેશે, લઈશું, લાઈશું',
    answers: {
      gu: 'હશે, નીકળશે, કરશે, માંગશે, લેશે',
      en: 'will be, will be out, will do, will ask, will do',
      hi: 'रहेंगे, निकलेगी, करेंगे, मांगेंगे, लेंगे'
    }
  },
  {
    level: '2',
    question: {
      gu: 'મમ્મી: બેટા! કાલે તમારી એક્ઝામ છે. આપણે હવે _(ભણવું)?\nટીંકુ અને રીંકુ: હા મમ્મી! પણ પછી અમે _ (રમવું) ક્યારે?\nમમ્મી: એક્ઝામ પછી તમે રમજો. પણ હમણાં સારા માર્ક્સ _ (મળવું) તો આપણે લાયકાત વાળા _ (થવું) ને?\nટીંકુ અને રીંકુ: ઓ.કે. મમ્મી. પણ અમને ભણવાનું કંઈ યાદ જ નથી રહેતું.\nમમ્મી: ચાલો આપણે સાથે ભણીશું અને પહેલાં દાદાની ભણવા પહેલાની પ્રાર્થના _ (વાંચવું). દાદા બધી શક્તિ _ (આપવું).',
      en: "Mummy : Dear son ! Tomorrow you have an exam. Shall we study now? \nTinku and Rinku : Yes mummy ! But then when will we play?\nMummy : You will play after exams. But right now, if you will get good marks, then we will become qualified, right?\nTinku and Rinku : O.K. mummy. But we are unable to memorize anything.\nMummy : Let us will study together and first of all, we will read Dada's prayer before study. Dada will give all the strength.",
      hi: 'माँ : बेटा ! कल तुम्हारी एग्जाम हैं| अब हम पढेंगे?\nटिंकू और रिंकू : हाँ माँ ! पर फिर हम खेलेंगे कब?\nमाँ : आप एग्जाम के बाद खेलना| पर अभी अच्छे मार्क्स मिलेंगे, तभी हम लायक बनेंगे ना? \nटिंकू और रिंकू : ठीक हैं माँ ! पर हमें पढ़ाई कुछ याद ही नहीं रहेती|\nमाँ : चलो हम साथ में पढेंगे और पहले दादाजी की पढ़ाई से पहेले वाली प्रार्थना पढेंगे| दादा सब शक्ति देंगे| '
    },
    options:
      'ભણીશ, ભણશે, ભણીશું, ભણશો, રમશે, રમીશું, રમશો, રમીશ, મળશે, મળીશું, મળીશ, મળશો, થશે, થઈશું, થઈશ, થાઈશું, વાંચીશ,વાંચશો, વાંચીશું, વાંચશું, આપશે, આપીશું, આપીશ, આપ',
    answers: {
      gu: 'ભણીશું, રમીશું, મળશે, થઈશું, વાંચીશું, આપશે',
      en: 'Shall study, will play, will become, will read, will give',
      hi: 'पढेंगे, खेलेंगे, मिलेंगे, बनेंगे, पढेंगे, देंगे'
    }
  },
  {
    level: '2',
    question: {
      gu: 'A: જય સચ્ચિદાનંદ મહાત્મા! તમે કાલે અડાલજ શિબિરમાં _ (આવવું)?\nB: હા! સૌથી પહેલા _ (આવવું).\nA: વાહ! ખૂબ આનંદ થયો જાણીને. તમને પણ ત્યાં ખૂબ આનંદ _ (લાગવું).\nB: હા! અમે ઘરનાં પરિવાર સાથે બધાં જ _ (જવું).',
      en: 'A : Jay Sachidanand Mahatma ! Will you come to Adalaj Shibir?\nB : Yes ! Will come very first.\nA : Wow ! Very happy to know this. You will also feel lot of happiness there.\nB : Yes ! We will go with all family members.',
      hi: 'अ : जय सच्चिदानंद महात्मा ! कल आप अडालज शिबिर में आएंगे?\nब : हाँ ! सबसे पहले आऊंगा|\nअ : अरे वाह ! बहुत ख़ुशी हुई यह जानके| आप को भी वहाँ बहुत ख़ुशी होगी|\nब : हाँ ! हम घर के परिवार के साथ सब जाएंगे|'
    },
    options:
      'આવશો, આવશે, આવીશ, આવીશું, લાગીશ, લાગશે, લાગશો, લાગીશું, જઈશ, જશે, જઈશું, જાશે',
    answers: {
      gu: 'આવશો, આવીશ, લાગશે, જઈશું',
      en: 'Will come, Will come, will feel, will go',
      hi: 'आएंगे, आऊंगा, होगी, जाएंगे'
    }
  },
  {
    level: '2',
    question: {
      gu: '"અમે ભેખ જે લીધો છે જગત કલ્યાણ કાજે, ખૂણે-ખૂણે બ્રહ્માંડે પહોંચાડીને _ (રહેવું).\nદાદાની મોક્ષની વાતો સહુ કોઈને _ (કહેવું),\nકેવા હતાં એ જ્ઞાની, ઓળખાણ સહુને _ (દેવું),\nનીરુમા ને દીપકભાઈનું (2), સ્વપ્ન સાકાર _ (કરવું).\nઅમે ભેખ . . . "',
      en: '"Ame bhekh je lidho chhe Jagat kalyaan kaje, khune-khune brahmande pahochadine rahishu"\nDada ni moksh ni vato sahu koi ne kahishu.\nkeva hata e gnani, odkhan sahu ne daishu.\nNiruma ne Deepakbhai nu (2), Swapn sakar karishu.\nAme bhekh......."',
      hi: '"अमे भेख जे लीधो छे जगत कल्याण काजे, खूणे-खूणे ब्रह्मांडे पहोंचाडीने रहीशु|\nदादा नी मोक्ष नी वातो सहु कोई ने कहीशु,\nकेवा हता ए ज्ञानी, ओडखाण सहु ने दइशु,\nनीरुमा ने दीपकभाई नु (२), स्वपन साकार करीशु|\nअमे भेख......."\n'
    },
    options:
      'રહેશે, રહીશું, રહેશો, રહીશ, કહેશો, કહીશું, કહેશે, કહીશ, દઈશું, દેશે, દઈશ, દે, કરશે, કરશો, કરીશું, કરીશ',
    answers: {
      gu: 'રહીશું, કહીશું, દઈશું, કરીશું',
      en: 'rahishu, kahishu, daishu, karishu',
      hi: 'रहीशु, कहीशु,दइशु, करीशु'
    }
  },
  {
    level: '2',
    question: {
      gu: 'GNCના ઘણાં બાળકો દાદાના જન્મજયંતિ પ્રોગ્રામમાં લાભ _. બેબી ગર્લ્સ ભક્તિપદો પર નૃત્ય _ અને બેબી બોયઝ દાદાની વાણી નાટકોથી _. આ બધું જોઈને મહાત્માઓને ખુબ આનંદ _. દાદા, નીરુમાની સૂક્ષ્મમાં હાજરી હોય એવું _. પૂજયશ્રીનો રાજીપો _. સીમંધર સ્વામીનું મિશન પૂરું _ જ.',
      en: "Many GNC children will participate in the Dada's birth anniversary programme. Baby girls will dance on the bhaktipad.and the baby boys will act out Dada's speech. Mahatmas will be very happy to see all this. We will feel Dada, Niruma's subtle presence. (All) will get Pujyashri's Rajipo. Seemandhar Swami's mission will be completed definitely.",
      hi: 'दाद के जन्मदिन कार्यक्रम में GNC के कई बच्चों को लाभ मिलेगा। बच्चियाँ भक्ति गीतों पर नृत्य करेंगी। और बच्चे दादाजी की वाणी पर अभिनय करेंगे। यह सब देखकर महात्मा बहुत प्रसन्न होंगे। दादा, निरुमा की सूक्ष्म उपस्थिति हो ऐसा लगेगा। पूज्यश्री का राजीपा मिलेगा। सीमंधर स्वामी का मिशन जरूर पूरा होगा।'
    },
    options:
      'લઈશું, લેશે, લઈને, લેશું, કરશે, કરવું, કર, કરીશ, ભજવીશ, ભજશું, ભજવશે, ભજ, થશે, થાશે, થઈસે, થજો, વર્તજો, વર્તીશ, વર્તાશે, વર્તો, પામશો, પામીશ, પામ, પામશે, થઈસે, થશે, થજો, થાશે',
    answers: {
      gu: 'લેશે, કરશે, ભજવશે, થશે, વર્તાશે, પામશે, થશે',
      en: 'will participate, will dance, will act, will be, will feel, will get, will complete',
      hi: 'मिलेगा, करेंगी, करेंगे, होंगे, लगेगा, मिलेगा, होगा'
    }
  },
  {
    level: '2',
    question: {
      gu: 'સેવાર્થી ૧: અડાલજ ટીમ તમને સેવાર્થી ફોર્મ _, તમે ફોર્મ ભરી અને મને જાણ કરજો.\nસેવાર્થી ૨: જરૂર, તેમાં શું વિગત ભરવાની _?\nસેવાર્થી ૧: તે ફોર્મમાં તમારી આવડતો અને યોગ્યતાઓ _.\nસેવાર્થી ૨: નો પ્રોબ્લેમ, મને _.\nસેવાર્થી ૧: આ ફોર્મ ભરવાથી નાની મોટી સેવાનો લાભ તમને _.',
      en: 'Sevarthi 1: Adalaj team will send you Sevarthi form, fill the form and inform me.\nSevarthi 2: Okay, what details will have to be filled in it?\nSevarthi 1: It will ask your skills and qualifications in the form.\nSevarthi 2: No problem, I will be fine with it.\nSevarthi 1: By filling this form you will get the benefit of small and big Seva.',
      hi: 'सेवार्थी 1: अडालज टीम आपको सेवार्थी फॉर्म भेजेगी, फॉर्म भरें और मुझे सूचित करें।\nसेवार्थी 2: ठीक है, उसमें क्या विवरण भरना होगा?\nसेवार्थी 1: यह फॉर्म आपके कौशल और योग्यता के बारे में पूछेगा।\nसेवार्थी 2: कोई बात नहीं, मुझसे हो पायेगा।\nसेवार्थी 1: इस फॉर्म को भरने से आपको छोटी-बड़ी सेवाओं का लाभ मिलेगा।'
    },
    options:
      'મોકલો, મોકલશે, મોકલીશ, મોકલું, રહીશ, રહીશું, રહેશે, રહો, પૂછશે, પૂછે, પૂછીશ, પૂછો, ફાવીશ, ફવ, ફાવશે, ફાવ, માલિશ, મળીશ, મળીશું, મળશે',
    answers: {
      gu: 'મોકલશે, રહેશે, પૂછશે, ફાવશે, મળશે',
      en: 'will send, will have, will ask, will be, will get',
      hi: 'भेजेगी,भरना होगा, पूछेगा, हो पायेगा, मिलेगा'
    }
  },
  {
    level: '2',
    question: {
      gu: 'અક્રમ વિજ્ઞાની દાદા ભગવાનની ૧૧૭મી જન્મજયંતિ વડોદરામાં _. ગુરુપૂર્ણિમા આ વર્ષે અમેરિકાના શિકાગોમાં _. નીરુમાની પુણ્યતિથિની પ્રભાતફેરી અડાલજમાં _. બધા મહાત્માઓ પૂજ્યશ્રીનો જન્મદિવસ ઓનલાઈન _. બેસતા વર્ષે આપણા વ્હાલા દિપકભાઈ બધાને પૂર્ણ સ્વરૂપે દર્શન _. ભાવનગર પ્રાણ પ્રતિષ્ઠા માં સીમંધર સ્વામીના ચરણ સ્પર્શ _.',
      en: "The 117th birth anniversary of Akram Scientist Dada Bhagwan will be celebrated in Vadodara. Gurupurnima will happen this year in Chicago, USA. Prabhatferi in the moning of Niruma's death anniversary will begin in Adalaj. All Mahatmas will enjoy Pujyashri's birthday celebration online. On the occasion of New Year our dear Deepakbhai will give darshan to all in absolute stage. All will touch the feet of Seemandhar Swami in Bhavnagar Pran Pratistha.",
      hi: 'अक्रम विज्ञानी दादा भगवान की 117वीं जन्मजयंती वडोदरा में मनाई जाएगी| इस वर्ष गुरुपूर्णिमा अमेरिका के शिकागो में होगी। निरूमा की पुण्यतिथि की प्रभातफेरी अडालज से निकलेगी| सभी महात्मा ऑनलाइन पूज्यश्री के जन्मदिन का आनंद लेंगे। आने वाले नववर्ष में हमारे प्रिय दीपकभाई पूर्ण रूप में सभी को दर्शन देंगे। भावनगर प्राण प्रतिष्ठा में सीमंधर स्वामी के चरण स्पर्श करेंगे।'
    },
    options:
      'ઉજવાશે, ઉજવશો, ઉજવશે, ઉજાશે, થવું, થશે, થાજો, થાવું, નીકળશો, નીકળીશ, નીકળશે, નીકળો, માણીશ, માણશો, માણસ, માણશે, આપશે, આપીશ, આપશો, આપો, કરશે, કરીશ, કરીશું, કર',
    answers: {
      gu: 'ઉજવાશે, થશે, નીકળશે, માણશે, આપશે, કરશે',
      en: 'will be celebrated, will happen, will begin, will enjoy, will give, will touch',
      hi: 'मनाई जाएगी, होगी, निकलेगी, आनंद लेंगे, दर्शन देंगे, स्पर्श करेंगे'
    }
  },
  {
    level: '2',
    question: {
      gu: 'જયારે આપણે જ્ઞાનના વાક્યોને પ્રેકટિકલી જીવનમાં _, ત્યારેજ આપનો વ્યવહાર આદર્શ _, અને નિશ્ચયમાં મજબૂત _. ધીમે ધીમે જ્ઞાનની શ્રેણીઓ _. ચીકણા કર્મોને _ અને મહાવિદેહ ક્ષેત્રે _.',
      en: 'Only when we will put the sentences of knowledge into practical life, our behavior will become ideal, and our determination will strengthened. We will gradually climb the steps of knowledge. We will exhaust sticky karmas and We will reach Mahavideh Kshetra.',
      hi: 'जब हम ज्ञान के वाक्यों को व्यावहारिक जीवन में उतारेंगे तभी हमारा आचरण आदर्श बनेगा, और हमारा संकल्प मजबूत होगा। हम धीरे-धीरे ज्ञान की सीढ़ी चढ़ेंगे। हम चिकने कर्मों को निःशेष करेंगे और महाविदेह क्षेत्र तक पहुंचेंगे।'
    },
    options:
      'ઉતારીશ, ઉતારું, ઉતારીશું, ઉતારવું, બનીશ, બનશે, બનીશું, બનવું, થઈશું, થશે, થાશે, થવું, ચઢવું, ચઢીશ, ચઢ, ચઢીશું, ખપાવ, ખપાવીશું, ખપવું, ખપાવ્યું, પહોંચીશું, પચીસ, પોહચી, પહોંચ, પોંચ',
    answers: {
      gu: 'ઉતારીશું, બનશે, થઈશું, ચઢીશું, ખપાવીશું, પહોંચીશું',
      en: 'will put, will become, will strengthened, will climb, will exhaust, will reach',
      hi: 'उतारेंगे, बनेगा, होगा, चढ़ेंगे, करेंगे, पहुंचेंगे'
    }
  }
]
const pageDataNew = Utils.fillInBlanksDataMassager(
  pageData,
  false,
  true,
  true,
  false,
  true,
  true,
  true
)

export default pageDataNew
