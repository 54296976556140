import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { firebaseAnalytics } from './firebase'
import vuetify from './plugins/vuetify'
import VueConfetti from 'vue-confetti'
import VuePlyr from 'vue-plyr'
import { polyfill } from 'mobile-drag-drop'
import { scrollBehaviourDragImageTranslateOverride } from 'mobile-drag-drop/scroll-behaviour'
import i18n from './i18n'
import { Diff } from 'diff'

Vue.use(Diff)

// The second argument is optional and sets the default config values for every player.
Vue.use(VuePlyr, {
  plyr: {
    fullscreen: { enabled: false }
  },
  emit: ['ended']
})

Vue.config.productionTip = false
Vue.use(VueConfetti)
Vue.prototype.$firebaseAnalytics = firebaseAnalytics

new Vue({
  router,
  store,
  vuetify,
  i18n,
  firebaseAnalytics,
  render: (h) => h(App)
}).$mount('#app')

polyfill({
  dragImageTranslateOverride: scrollBehaviourDragImageTranslateOverride,
  dragStartConditionOverride: (event) => {
    return event.target.closest('[draggable=true]') !== null // Only drag elements where draggable=true
  }
})
window.addEventListener('dragenter', function (event) {
  event.preventDefault()
})
window.addEventListener('touchmove', function () {})
