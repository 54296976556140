<template>
  <div class="loaderContainer">
    <div class="loader">
      <img src="../../assets/dbflogo.svg" class="loader__icon" />
      <span class="loader__spinner"></span>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.loaderContainer {
  height: 100%;
  width: 100%;
  z-index: 999;
  background: rgb(250 251 252);
}
.loader {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-flex;
  position: relative;
  box-sizing: border-box;

  &__icon {
    width: 5rem;
    height: 5rem;
    z-index: 1999;
    animation: waveIconPulse 1.83s 0.4s infinite;
  }

  &__spinner {
    width: 15rem;
    height: 15rem;
    background: url(../../assets/wave.gif);
    background-size: contain;
    z-index: 999;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@keyframes waveIconPulse {
  0% {
    transform: scaleZ(1);
  }
  30% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  60% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  100% {
    transform: scaleZ(1);
  }
}
</style>
