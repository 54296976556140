const categories = [
  {
    id: 'building-blocks',
    image: 'building-blocks.svg',
    name: {
      en: 'Building Blocks',
      hi: 'बुनियादी अंश',
      gu: 'પાયાના અંશો'
    },
    description: {
      en: 'Basics of Gujarati reading & writing.',
      hi: 'गुजराती पढ़ने और लिखने के मूल सिद्धांत',
      gu: 'ગુજરાતી વાંચન & લેખનનાં મૂળ સિદ્ધાંતો'
    }
  },
  {
    id: 'vocabulary',
    image: 'vocabulary.svg',
    name: {
      en: 'Vocabulary',
      hi: 'शब्दावली',
      gu: 'શબ્દકોશ'
    },
    description: {
      en: 'Words in Gujarati',
      hi: 'शब्द गुजराती में',
      gu: 'ગુજરાતીમાં શબ્દો '
    }
  },
  {
    id: 'phrases',
    image: 'phrases.svg',
    name: {
      en: 'Phrases',
      hi: 'वाक्यांश',
      gu: 'શબ્દસમુહો'
    },
    description: {
      en: 'Use of phrases in Gujarati',
      hi: 'गुजराती में शब्द समूहों का उपयोग',
      gu: 'ગુજરાતી શબ્દ સમૂહોનો ઉપયોગ'
    }
  },
  {
    id: 'nishchay',
    image: 'nishchay.svg',
    name: {
      en: 'Nishchay',
      hi: 'निश्चय',
      gu: 'નિશ્ચય'
    },
    description: {
      en: 'Give me the absolute inner energy',
      hi: 'मुझे परम शक्ति दीजिए',
      gu: 'મને પરમ શક્તિ આપો'
    }
  },
  {
    id: 'numbers-and-colors',
    image: 'numbers-and-colors.svg',
    name: {
      en: 'Numbers & Colors',
      hi: 'अंक और रंग',
      gu: 'અંક અને રંગ'
    },
    description: {
      en: '1,2,3...& Colors in Gujarati',
      hi: '१,२,३ ... और रंग गुजराती में',
      gu: '૧,૨,૩...અને રંગો ગુજરાતીમાં '
    }
  },
  {
    id: 'basic-grammar',
    image: 'basic-grammar.svg',
    name: {
      en: 'Basic Grammar',
      hi: 'बुनियादी व्याकरण',
      gu: 'મૂળભૂત વ્યાકરણ'
    },
    description: {
      en: 'Basic Elements of Sentence Formation',
      hi: 'वाक्य निर्माण के मूल सिद्धांत',
      gu: 'વાક્ય રચનાના મૂળ સિદ્ધાંતો'
    }
  },
  {
    id: 'satsang-interpretation',
    image: 'satsang-interpretation.svg',
    name: {
      en: 'Satsang Interpretation',
      hi: 'सत्संग विवेचन',
      gu: 'સત્સંગ અનુવાદ'
    },
    description: {
      en: 'Listen & Learn',
      hi: 'सुने और सीखे',
      gu: 'સાંભળો અને શીખો'
    }
  },
  {
    id: 'fun-games',
    image: 'fun-games.svg',
    name: {
      en: 'Fun Games',
      hi: 'मजेदार खेल',
      gu: 'મસ્ત રમતો '
    },
    description: {
      en: 'Learn with fun',
      hi: 'मज़े के साथ सीखे',
      gu: 'મજા સાથે શીખીએ'
    }
  },
  {
    id: 'spoken',
    image: 'spoken.svg',
    name: {
      en: 'Spoken',
      hi: 'मौखिक',
      gu: 'મૌખિક'
    },
    description: {
      en: 'Speak Gujarati',
      hi: 'बोले गुजराती में',
      gu: 'બોલો ગુજરાતીમાં'
    }
  },
  {
    id: 'prayer',
    image: 'prayer.svg',
    name: {
      en: 'Prayer',
      hi: 'प्रार्थना',
      gu: 'પ્રાર્થના'
    },
    description: {
      en: 'Prayers before studying',
      hi: 'पढाई से पहले की प्रार्थना',
      gu: 'ભણવા પહેલાની પ્રાર્થના'
    }
  },
  {
    id: 'quizzes',
    image: 'quiz.jpg',
    name: {
      en: 'Quizzes',
      hi: 'प्रश्नोत्तरी',
      gu: 'પ્રશ્નોત્તરી'
    },
    description: {
      en: 'Study & Play',
      hi: 'पढ़ाई करो और खेलो',
      gu: 'અભ્યાસ અને રમો'
    }
  }
]

export default categories
