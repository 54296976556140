import Utils from '../util/Utils'

const pageData = [
  {
    level: '0',
    answers: { gu: 'past' },
    question: {
      gu:
        'એમણે પોતાનું સમગ્ર જીવન પરમ પૂજ્ય દાદાશ્રીના સુચરણોમાં સમર્પિત કરી દીધું હતું.'
    },
    wordAnswer: 'હતું',
    options: 'Past,Present,Future'
  },
  {
    level: '0',
    answers: { gu: 'past' },
    question: { gu: 'એક વાર દાદાને પગનું ફ્રેકચર થયેલું.' },
    wordAnswer: 'થયેલું',
    options: 'Past,Present,Future'
  },
  {
    level: '0',
    answers: { gu: 'future' },
    question: { gu: 'હું બધું તમારું સાચવી લઇશ.' },
    wordAnswer: 'લઇશ',
    options: 'Past,Present,Future'
  },
  {
    level: '0',
    answers: { gu: 'present' },
    question: {
      gu: 'નીરુમાને સતત અંદર રહ્યા કરે કે મારે દાદાની સેવામાં રહેવું છે.'
    },
    wordAnswer: 'છે',
    options: 'Past,Present,Future'
  },
  {
    level: '0',
    answers: { gu: 'future' },
    question: {
      gu: 'દાદા મને તમારી ચોવીસ કલાકની નિરંતરની સેવા કરવાનો મોકો આપજો.'
    },
    wordAnswer: 'આપજો',
    options: 'Past,Present,Future'
  },
  {
    level: '0',
    answers: { gu: 'present' },
    question: { gu: 'હજુ એક અવતાર આપણો થવાનો છે.' },
    wordAnswer: 'છે',
    options: 'Past,Present,Future'
  },
  {
    level: '0',
    answers: { gu: 'future' },
    question: { gu: 'છેક મોક્ષે જતાં સુધી જોડે જ રાખીશું.' },
    wordAnswer: 'રાખીશું',
    options: 'Past,Present,Future'
  },
  {
    level: '0',
    answers: { gu: 'present' },
    question: { gu: 'દાદાએ નીરુમાની ઠેઠ સુધીની જવાબદારી લીધી છે.' },
    wordAnswer: 'છે',
    options: 'Past,Present,Future'
  },
  {
    level: '0',
    answers: { gu: 'future' },
    question: {
      gu: 'મને કાગળ લખીશ કે તુર્ત જ આ દાદા તારી પાસે આવીને ઊભો રહેશે.'
    },
    wordAnswer: 'રહેશે',
    options: 'Past,Present,Future'
  },
  {
    level: '0',
    answers: { gu: 'future' },
    question: { gu: 'તું ચિંતા ના કરીશ.' },
    wordAnswer: 'કરીશ',
    options: 'Past,Present,Future'
  },
  {
    level: '0',
    answers: { gu: 'past' },
    question: { gu: 'દાદાનો દેહવિલય થયો ત્યારે બહુ મોટો ખાલીપો લાગેલો.' },
    wordAnswer: 'લાગેલો',
    options: 'Past,Present,Future'
  },
  {
    level: '0',
    answers: { gu: 'present' },
    question: { gu: 'આખા જગતમાં આ નીરુ જેટલું કોઈ ભાગ્યશાળી નથી.' },
    wordAnswer: 'નથી',
    options: 'Past,Present,Future'
  },
  {
    level: '0',
    answers: { gu: 'past' },
    question: { gu: 'અમારી પર એમનો પ્રેમ હતો.' },
    wordAnswer: 'હતો',
    options: 'Past,Present,Future'
  },
  {
    level: '0',
    answers: { gu: 'present' },
    question: { gu: 'તેને આ જ્ઞાન કેવું સુંદર રહે છે!' },
    wordAnswer: 'છે!',
    options: 'Past,Present,Future'
  },
  {
    level: '0',
    answers: { gu: 'future' },
    question: { gu: 'એમના પગ નીચેની ધૂળ ચઢાવશેને તોયે કલ્યાણ થશે.' },
    wordAnswer: 'થશે',
    options: 'Past,Present,Future'
  },
  {
    level: '0',
    answers: { gu: 'present' },
    question: { gu: 'ઝવેરબા જેવો તમારો પ્રેમ છે.' },
    wordAnswer: 'છે',
    options: 'Past,Present,Future'
  },
  {
    level: '0',
    answers: { gu: 'present' },
    question: { gu: 'તમારે આખા જગતના મધર થવાનું છે.' },
    wordAnswer: 'છે',
    options: 'Past,Present,Future'
  },
  {
    level: '0',
    answers: { gu: 'past' },
    question: { gu: 'પૂજ્ય નીરુમા ખરેખર એક અજોડ વાત્સલ્યમૂર્તિ સમાન જ હતા.' },
    wordAnswer: 'હતા',
    options: 'Past,Present,Future'
  },
  {
    level: '0',
    answers: { gu: 'past' },
    question: { gu: 'નીરુમાના પાછા આવવાના દિવસો ગણાતા હતા.' },
    wordAnswer: 'હતા',
    options: 'Past,Present,Future'
  },
  {
    level: '0',
    answers: { gu: 'future' },
    question: { gu: 'હું એકલી નહીં આવું, તમારી પાછળ બધાને લઈને આવીશ.' },
    wordAnswer: 'આવીશ',
    options: 'Past,Present,Future'
  },
  {
    level: '0',
    answers: { gu: 'past' },
    question: { gu: 'સાવ નાના રૂમમાં રેકોર્ડીંગ ચાલતું હતું.' },
    wordAnswer: 'હતું',
    options: 'Past,Present,Future'
  },
  {
    level: '0',
    answers: { gu: 'past' },
    question: { gu: 'ફક્ત કેમેરા સામે જોઈને બોલવાનું, એ સહેલું ન હતું.' },
    wordAnswer: 'હતું',
    options: 'Past,Present,Future'
  },
  {
    level: '0',
    answers: { gu: 'present' },
    question: { gu: 'આજે લાખો લોકો દાદાના જ્ઞાનને પામી રહ્યા છે.' },
    wordAnswer: 'છે',
    options: 'Past,Present,Future'
  },
  {
    level: '0',
    answers: { gu: 'past' },
    question: { gu: 'નીરુમાનો એક બહુ મોટો કાયદો હતો.' },
    wordAnswer: 'હતો',
    options: 'Past,Present,Future'
  },
  {
    level: '0',
    answers: { gu: 'future' },
    question: { gu: 'વ્યક્તિને દુ:ખ ના થવું જોઈએ.' },
    wordAnswer: 'જોઈએ',
    options: 'Past,Present,Future'
  },
  {
    level: '0',
    answers: { gu: 'past' },
    question: { gu: 'એક પ્રોમિસ અમે હીરાબાને લગ્ન વખતે આપ્યું હતું.' },
    wordAnswer: 'હતું',
    options: 'Past,Present,Future'
  },
  {
    level: '0',
    answers: { gu: 'future' },
    question: { gu: 'છેક મોક્ષે જતાં સુધી જોડે જ રાખીશું.' },
    wordAnswer: 'રાખીશું',
    options: 'Past,Present,Future'
  },
  {
    level: '0',
    answers: { gu: 'present' },
    question: { gu: 'નીરુબેન, તમે મધરની જેમ અમારી નર્સરી કરી છે.' },
    wordAnswer: 'છે',
    options: 'Past,Present,Future'
  },
  {
    level: '0',
    answers: { gu: 'present' },
    question: { gu: 'તમને ભૂલ વગરના કરવા એ અમારી જવાબદારી છે.' },
    wordAnswer: 'છે',
    options: 'Past,Present,Future'
  },
  {
    level: '0',
    answers: { gu: 'future' },
    question: { gu: 'આ સારું બને એટલે દાદાને ખવડાવી દઈશ.' },
    wordAnswer: 'દઈશ',
    options: 'Past,Present,Future'
  },
  {
    level: '0',
    answers: { gu: 'past' },
    question: { gu: 'ખાવાની બાબતમાં દાદા નીરુમાંની આજ્ઞામાં જ રહેતા.' },
    wordAnswer: 'રહેતા',
    options: 'Past,Present,Future'
  },
  {
    level: '0',
    answers: { gu: 'past' },
    question: {
      gu: 'અમીન પરિવારમાં પાંચ દીકરાઓ પછી લાડકવાઈ દીકરી તરીકે નીરુમા હતા.'
    },
    wordAnswer: 'હતા',
    options: 'Past,Present,Future'
  },
  {
    level: '0',
    answers: { gu: 'future' },
    question: { gu: 'પ્રેમથી રહેજો.' },
    wordAnswer: 'રહેજો',
    options: 'Past,Present,Future'
  },
  {
    level: '0',
    answers: { gu: 'past' },
    question: { gu: 'નીરુમાનો જન્મ થયો ત્યારે પેંડા વહેંચાયા હતા.' },
    wordAnswer: 'હતા',
    options: 'Past,Present,Future'
  },
  {
    level: '0',
    answers: { gu: 'future' },
    question: { gu: 'રસોઈ તો સાવ નાની બાબત છે, આમ શીખી જઈશ.' },
    wordAnswer: 'જઈશ',
    options: 'Past,Present,Future'
  },
  {
    level: '0',
    answers: { gu: 'past' },
    question: {
      gu: 'માત્ર બેસતા વર્ષના દિવસે હવેલીએ કૃષ્ણ ભગવાનના દર્શન કરવા જતા.'
    },
    wordAnswer: 'જતા',
    options: 'Past,Present,Future'
  },
  {
    level: '0',
    answers: { gu: 'past' },
    question: { gu: 'દીકરીનો જન્મ થતાં પરિવારમાં બધા ખૂબ આનંદિત થઈ ગયા હતા.' },
    wordAnswer: 'હતા',
    options: 'Past,Present,Future'
  },
  {
    level: '0',
    answers: { gu: 'past' },
    question: { gu: 'આગળ જતાં તેમણે ડૉક્ટરની લાઈન પસંદ કરી હતી.' },
    wordAnswer: 'હતી',
    options: 'Past,Present,Future'
  },
  {
    level: '0',
    answers: { gu: 'past' },
    question: { gu: 'નીરુમાની યાદશક્તિ જોરદાર હતી.' },
    wordAnswer: 'હતી',
    options: 'Past,Present,Future'
  },
  {
    level: '0',
    answers: { gu: 'past' },
    question: { gu: 'નીરુમા એમના મમ્મીને ધર્મના પુસ્તકો વાંચી સંભળાવતા.' },
    wordAnswer: 'સંભળાવતા',
    options: 'Past,Present,Future'
  },
  {
    level: '0',
    answers: { gu: 'present' },
    question: { gu: 'કોઈ અંબાલાલ મૂળજીભાઈ પટેલ નામના જ્ઞાની પુરુષ રહે છે.' },
    wordAnswer: 'છે',
    options: 'Past,Present,Future'
  },
  {
    level: '0',
    answers: { gu: 'present' },
    question: {
      gu: 'સંસારનું કોઈ સુખ કે દુ:ખ ન અડે માટે મારે એ જ્ઞાન લેવું જ છે.'
    },
    wordAnswer: 'છે',
    options: 'Past,Present,Future'
  },
  {
    level: '0',
    answers: { gu: 'present' },
    question: { gu: 'આ દાદા મને ક્યાં લઈ જાય છે?' },
    wordAnswer: 'છે',
    options: 'Past,Present,Future'
  },
  {
    level: '0',
    answers: { gu: 'future' },
    question: { gu: 'દુનિયામાં ડૉક્ટરો તો ઘણાં થશે.' },
    wordAnswer: 'થશે',
    options: 'Past,Present,Future'
  },
  {
    level: '0',
    answers: { gu: 'past' },
    question: { gu: 'નીરુમા 20 વર્ષ દાદાની સેવામાં રહ્યા.' },
    wordAnswer: 'રહ્યા',
    options: 'Past,Present,Future'
  },
  {
    level: '0',
    answers: { gu: 'present' },
    question: { gu: 'એમનો બાપ મામાની પોળે બેઠેલો છે.' },
    wordAnswer: 'છે',
    options: 'Past,Present,Future'
  },
  {
    level: '0',
    answers: { gu: 'present' },
    question: { gu: '1987 ની વાત છે.' },
    wordAnswer: 'છે',
    options: 'Past,Present,Future'
  },
  {
    level: '0',
    answers: { gu: 'present' },
    question: { gu: 'આ નીરુબેન એમ.બી.બી.એસ થયા છે.' },
    wordAnswer: 'છે',
    options: 'Past,Present,Future'
  },
  {
    level: '0',
    answers: { gu: 'present' },
    question: { gu: 'કેટલાય અવતારોથી આવી ને આવી રીતે અમારી જોડે જ છે.' },
    wordAnswer: 'છે',
    options: 'Past,Present,Future'
  },
  {
    level: '0',
    answers: { gu: 'present' },
    question: { gu: 'દાદા તમને શું કહે છે?' },
    wordAnswer: 'છે',
    options: 'Past,Present,Future'
  },
  {
    level: '0',
    answers: { gu: 'present' },
    question: { gu: 'અમેરીકાથી કંઇ જોઈએ છે?' },
    wordAnswer: 'છે',
    options: 'Past,Present,Future'
  },
  {
    level: '0',
    answers: { gu: 'past' },
    question: { gu: 'બધાનો ફફડાટ વધી ગયો.' },
    wordAnswer: 'ગયો',
    options: 'Past,Present,Future'
  },
  {
    level: '0',
    answers: { gu: 'past' },
    question: { gu: 'નીરુમાની સેવામાં ઘણી બ્રહ્મચારી બહેનો હતી.' },
    wordAnswer: 'હતી',
    options: 'Past,Present,Future'
  }
]

export default Utils.singleChoiceDataMassager(
  pageData,
  true,
  false,
  true,
  true,
  false,
  'fillInTheBlanksNumbersInstruction',
  undefined,
  true
)
