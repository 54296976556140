import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: {
          base: '#0151c4',
          lighten6: '#f9fcff',
          lighten5: '#ebf3fe',
          lighten4: '#a0b6ff',
          lighten3: '#819bff',
          lighten2: '#6182fb',
          lighten1: '#3e69de',
          darken1: '#003ba6',
          darken2: '#00268b',
          darken3: '#001471',
          darken4: '#000058'
        },
        secondary: '#0151c4',
        accent: '#8c9eff',
        error: '#b71c1c',
        anchor: '#0251c2',
        gray: '#878889',
        practiceCard: '#46178f',
        newDesign: '#0151c4'
      }
    },
    options: {
      customProperties: true
    }
  }
})
