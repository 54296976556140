import Utils from '../util/Utils'

const pageData = [
  {
    level: 0,
    question: {
      gu: 'હું મારા મિત્ર સાથે _ છું.'
    },
    options: 'રમું, રમે, રમીએ, રમો',
    answers: {
      gu: 'રમું'
    }
  },
  {
    level: 0,
    question: {
      gu: 'તમે બધાં ક્યાં _ છો?'
    },
    options: 'જાય, જાઓ, જાઉં',
    answers: {
      gu: 'જાઓ'
    }
  },
  {
    level: 0,
    question: {
      gu: 'તે બાળક જોરથી _ છે.'
    },
    options: 'રડવું, રડો, રડે, રડીએ',
    answers: {
      gu: 'રડે'
    }
  },
  {
    level: 0,
    question: {
      gu: 'હું ત્રિમંદિર જાઉં _.'
    },
    options: 'છીએ, છે, છું, છો',
    answers: {
      gu: 'છું'
    }
  },
  {
    level: 0,
    question: {
      gu: 'અમે સત્સંગ _ છીએ.'
    },
    options: 'સાંભળું, સાંભળી, સાંભળીએ, સાંભળો',
    answers: {
      gu: 'સાંભળીએ'
    }
  },
  {
    level: 0,
    question: {
      gu: 'તમે શું કહો _?'
    },
    options: 'છો, છું, છે, છીએ',
    answers: {
      gu: 'છો'
    }
  },
  {
    level: 0,
    question: {
      gu: 'તેઓ દાદાને _ છે.'
    },
    options: 'પગે લાગુ, પગે લાગે, પગે લાગો, પગે લાગી',
    answers: {
      gu: 'પગે લાગે'
    }
  },
  {
    level: 0,
    question: {
      gu: 'મને ખીચું બહુ _ છે.'
    },
    options: 'ભાવે, ભાવી, ભાવો, ભાવી',
    answers: {
      gu: 'ભાવે'
    }
  },
  {
    level: 0,
    question: {
      gu: 'તેઓને સત્સંગમાં બહુ _ છે.'
    },
    options: 'ગમવું, ગમી, ગમો, ગમે',
    answers: {
      gu: 'ગમે'
    }
  },
  {
    level: 0,
    question: {
      gu: 'તે સુખડી _ છે.'
    },
    options: 'બનાવો, બનાવે, બનાવું',
    answers: {
      gu: 'બનાવે'
    }
  },
  {
    level: 0,
    question: {
      gu: 'અમે શાળામાં _ છીએ.'
    },
    options: 'ભણવું, ભણીએ, ભણવા, ભણું',
    answers: {
      gu: 'ભણીએ'
    }
  },
  {
    level: 0,
    question: {
      gu: 'હું નિયમિત કસરત _ છું.'
    },
    options: 'કરીએ, કરવું, કરું,કરવા',
    answers: {
      gu: 'કરું'
    }
  },
  {
    level: 0,
    question: {
      gu: 'તમે શ્રેષ્ઠ દાન _ છો.'
    },
    options: 'આપું, આપીએ, આપવું, આપો',
    answers: {
      gu: 'આપો'
    }
  },
  {
    level: 0,
    question: {
      gu: 'તેઓ સ્વામીનાં દર્શન _ છે.'
    },
    options: 'કરે, કરવું, કરો, કરીએ',
    answers: {
      gu: 'કરે'
    }
  },
  {
    level: 0,
    question: {
      gu: 'મને ફરવા જવું બહુ _ છે.'
    },
    options: 'ગમવું, ગમી, ગમો, ગમે',
    answers: {
      gu: 'ગમે'
    }
  },
  {
    level: 0,
    question: {
      gu: 'તેઓ સાઇકલ ચલાવે _.'
    },
    options: 'છો, છું, છે, છીએ',
    answers: {
      gu: 'છે'
    }
  },
  {
    level: 0,
    question: {
      gu: 'તે માણસ ઝડપથી _ છે.'
    },
    options: 'દોડો, દોડવું, દોડવા, દોડે',
    answers: {
      gu: 'દોડે'
    }
  },
  {
    level: 0,
    question: {
      gu: 'તેઓને ગાડીમાં _ છે.'
    },
    options: 'જવું, જવા, જવાના, જઈએ',
    answers: {
      gu: 'જવું'
    }
  },
  {
    level: 0,
    question: {
      gu: 'આપણે પૌષ્ટિક આહાર _ છીએ.'
    },
    options: 'લેવો, લેવાના, લેવું, લઈએ',
    answers: {
      gu: 'લઈએ'
    }
  },
  {
    level: 0,
    question: {
      gu: 'તે બાળક હજુ _ છે.'
    },
    options: 'સૂતેલો, સુતેલું, સુવું, સુતેલી',
    answers: {
      gu: 'સુતેલું'
    }
  },
  {
    level: 0,
    question: {
      gu: 'હું કાયમ બીજાને મદદ _ છું.'
    },
    options: 'કરીએ, કરવું, કરું, કરવા',
    answers: {
      gu: 'કરું'
    }
  },
  {
    level: 0,
    question: {
      gu: 'તેઓ આજે જ્ઞાન લેવાના _.'
    },
    options: 'છો, છું, છે, છીએ',
    answers: {
      gu: 'છે'
    }
  },
  {
    level: 0,
    question: {
      gu: 'તમારા ફોનની ઘંટડી _ છે.'
    },
    options: 'વાગે, વાગવું, વાગો, વાગવો',
    answers: {
      gu: 'વાગે'
    }
  },
  {
    level: 0,
    question: {
      gu: 'મને જ્ઞાન લીધા પછી ખૂબ આનંદ _ છે.'
    },
    options: 'આવી, આવો, આવે, આવું',
    answers: {
      gu: 'આવે'
    }
  },
  {
    level: 0,
    question: {
      gu: 'આજે પૂજ્યશ્રી નો લાઇવ સત્સંગ _.'
    },
    options: 'છો, છું, છે, છીએ',
    answers: {
      gu: 'છે'
    }
  },
  {
    level: 1,
    question: {
      gu: 'હું જ્ઞાન લીધા પછી ખૂબ જ આનંદ _.'
    },
    options: 'અનુભવે છે, અનુભવું છું, અનુભવીએ છીએ',
    answers: {
      gu: 'અનુભવું છું'
    }
  },
  {
    level: 1,
    question: {
      gu: 'અમે બધાં હોલમાં આગળ જઈને _.'
    },
    options: 'બેસો છો, બેસીએ છીએ, બેસે છે',
    answers: {
      gu: 'બેસીએ છીએ'
    }
  },
  {
    level: 1,
    question: {
      gu: 'દાદા હંમેશા મારી જોડે _.'
    },
    options: 'રહીએ છીએ, રહે છે, રહું છું',
    answers: {
      gu: 'રહે છે'
    }
  },
  {
    level: 1,
    question: {
      gu: 'તું કઢીમાં બહુ જ મીઠું _ !'
    },
    options: 'નાખીએ છીએ, નાખો છો, નાખે છે',
    answers: {
      gu: 'નાખે છે'
    }
  },
  {
    level: 1,
    question: {
      gu: 'આપ્તપુત્ર ભાઈઓ ત્રિમંદિરનો દરવાજો _.'
    },
    options: 'ખોલો છો, ખોલે છે, ખોલું છું',
    answers: {
      gu: 'ખોલે છે'
    }
  },
  {
    level: 1,
    question: {
      gu: 'નીરુમા મહાત્માઓને માટે ખીચું _.'
    },
    options: 'રાંધી છે, રાંધીએ છીએ, રાંધુ છું, રાંધે છે',
    answers: {
      gu: 'રાંધે છે'
    }
  },
  {
    level: 1,
    question: {
      gu: 'મને નીરુમાને _.'
    },
    options: 'મળવું છે, મળે છે, મળીએ છીએ',
    answers: {
      gu: 'મળવું છે'
    }
  },
  {
    level: 1,
    question: {
      gu: 'તમને આજે રાત્રે અડાલજ _?'
    },
    options: 'પાછા જાય છે, પાછા જવું છે, પાછી જાય છે, પાછી જઈએ છીએ',
    answers: {
      gu: 'પાછા જવું છે'
    }
  },
  {
    level: 1,
    question: {
      gu: 'છોકરાંઓને ક્રિકેટ વગેરે રમત સરસ _.'
    },
    options: 'આવડું છું, આવડો છો, આવડે છે',
    answers: {
      gu: 'આવડે છે'
    }
  },
  {
    level: 1,
    question: {
      gu: 'તમને હમણાં _ છે?'
    },
    options: 'જમીને, જમવું, જમવાનું, જમી',
    answers: {
      gu: 'જમવું'
    }
  },
  {
    level: 1,
    question: {
      gu: 'અમે રોજ આપ્તવાણીનું વાંચન _.'
    },
    options: 'કરવું છે, કરીએ છીએ, કરવા છે, કરુ છું',
    answers: {
      gu: 'કરીએ છીએ'
    }
  },
  {
    level: 1,
    question: {
      gu: 'તેઓ હમેંશા હિત, મીત અને પ્રિય વાણી _.'
    },
    options: 'બોલે છે, બોલું છું, બોલવું છે, બોલીએ છીએ',
    answers: {
      gu: 'બોલે છે'
    }
  },
  {
    level: 1,
    question: {
      gu: 'આપણે દાદાની પાંચ આજ્ઞા જ _.'
    },
    options: 'પકડવાનું છે, પકડવાની છે, પકડવું છે, પકડવા છે',
    answers: {
      gu: 'પકડવાની છે'
    }
  },
  {
    level: 1,
    question: {
      gu: 'મોક્ષ ખીચડી બનાવવા કરતાં પણ _.'
    },
    options: 'સહેલું છે, સહેલા છે, સહેલો છે, સહેલુ છું',
    answers: {
      gu: 'સહેલો છે'
    }
  },
  {
    level: 1,
    question: {
      gu: 'એક જ નિશ્ચય : આપણે મોક્ષે _.'
    },
    options: 'જવું છે, જવું છું, જવા છીએ, જવું છો',
    answers: {
      gu: 'જવું છે'
    }
  },
  {
    level: 1,
    question: {
      gu: 'દ્વેષથી જ આખો સંસાર _.'
    },
    options: 'ઊભું છે, ઊભો છે, ઊભા છો, ઊભું છું',
    answers: {
      gu: 'ઊભો છે'
    }
  },
  {
    level: 1,
    question: {
      gu: 'પૂજ્યશ્રી નિરંતર આત્મામાં જ _.'
    },
    options: 'રહું છું, રહો છો, રહે છે, રહ્યા છીએ',
    answers: {
      gu: 'રહે છે'
    }
  },
  {
    level: 1,
    question: {
      gu: 'દાદાએ નીરૂમાને મલ્લિનાથની ઉપમા _.'
    },
    options: 'આપી છે, આપવી છે, આપી છીએ , આપો છો',
    answers: {
      gu: 'આપી છે'
    }
  },
  {
    level: 1,
    question: {
      gu: 'આપણે એકબીજાની સ્પર્ધામાં ન _ જોઈએ.'
    },
    options: 'ઉતારવા, ઉતરવું, ઉતારવો, ઉતરે',
    answers: {
      gu: 'ઉતરવું'
    }
  },
  {
    level: 1,
    question: {
      gu: 'બાળકો સુંદર ચિત્રો _.'
    },
    options: 'દોરું છું, દોરીએ છીએ, દોરે છે, દોરે છો',
    answers: {
      gu: 'દોરે છે'
    }
  },
  {
    level: 1,
    question: {
      gu: 'વસંતઋતુમાં સુંદર ફૂલો _.'
    },
    options: 'ખીલું છું, ખીલું છે, ખીલે છું, ખીલે છે',
    answers: {
      gu: 'ખીલે છે'
    }
  },
  {
    level: 1,
    question: {
      gu: 'અક્રમ એક્સપ્રેસમાંથી બાળકોને ઘણું જાણવા _.'
    },
    options: 'મળે છે, મળો છો, મળું છું, મળીએ છીએ',
    answers: {
      gu: 'મળે છે'
    }
  },
  {
    level: 1,
    question: {
      gu: 'દાદાની જન્મજયંતિ અમે ઘરે પણ _.'
    },
    options: 'ઉજવું છું, ઉજવો છો, ઉજવી છી, ઉજવીએ છીએ',
    answers: {
      gu: 'ઉજવીએ છીએ'
    }
  },
  {
    level: 1,
    question: {
      gu: 'તમે લાઇવ સત્સંગ _ ?'
    },
    options: 'સાંભળો છો, સાંભળું છું, સાંભળીએ છીએ, સાંભળે છે',
    answers: {
      gu: 'સાંભળો છો'
    }
  },
  {
    level: 1,
    question: {
      gu: 'હું દરેક શિબિરમાં હાજરી _.'
    },
    options: 'આપે છે, આપો છો, આપીએ છીએ, આપું છું',
    answers: {
      gu: 'આપું છું'
    }
  },
  {
    level: 2,
    question: {
      gu: "હું આજે બહુ ખુશ _. મારાં મિત્રો સાથે હું દાદાની જન્મજયંતીએ _ (જવું) છું. અમને રસ્તામાં ભક્તિનો પણ લાભ _ (મળવું) છે. આપ્તપુત્ર ભાઈઓ પણ અમારી સાથે છે. મેં તેમને પૂછ્યું, 'તમને નીરુમાની યાદ _ (આવવું) છે?' તેઓ _ (કહેવું) છે, 'હા, ક્ષણે, ક્ષણે.'"
    },
    options:
      'છે, છું, છીએ, જાઉં, જઈએ, જાઓ, મળે, મળવો, મળવું, આવું, આવે, આવીએ, કહીએ, કહે',
    answers: {
      gu: 'છું, જાઉં, મળે, આવે, કહે'
    }
  },
  {
    level: 2,
    question: {
      gu: 'મારું નામ વીહુ છે. મને ખીચડી ઓછી _ (ભાવવું). છતાં હું થોડુંક _ (ખાવવું). અમને બાળકોની શિબિરમાં એડજસ્ટ એવરીવ્હેર થવાનું _ (શીખવાડવું). એડજસ્ટ થવાથી મારાં મમ્મી-પપ્પા મારાં પર રાજી છે અને આશીર્વાદ _ (મળવું). અમે બધાં હળી-મળીને _ (રહેવું).'
    },
    options:
      'ભાવી છે, ભાવે છે, ભાવો છો, ખાઈ છે, ખાઉં છું, ખાઈએ છીએ, શીખવાડે છે, શીખવાડું છું, શીખવાડો છો, મળ્યું છું, મળે છે, મળ્યો છો, રહું છું, રહે છે, રહીએ છીએ',
    answers: {
      gu: 'ભાવે છે, ખાઉં છું, શીખવાડે છે, મળે છે, રહીએ છીએ'
    }
  },
  {
    level: 2,
    question: {
      gu: 'બધાં મહાત્માઓ અડાલજની બસ _ (પકડવું). આજે પારાયણ શરૂ થઇ છે. પારાયણમાં અમને પૂજ્યશ્રીનો સાંનિધ્ય _ (મળવું). તેઓ જ્યારે _ (હસવું) ત્યારે મહાત્માઓનું દિલ ઠરી જાય છે. તમે ક્યારે _ (આવવું)?'
    },
    options:
      'પકડું છું, પકડે છે, પકડીએ છીએ, મળીએ છીએ, મળે છે, મળું છું, હસીએ છીએ, હસું છું, હસે છે, આવીએ છીએ, આવો છો, આવું છું',
    answers: {
      gu: 'પકડે છે, મળે છે, હસે છે, આવો છો'
    }
  },
  {
    level: 2,
    question: {
      gu: 'File1: તું આટલી ખારી કઢી કેમ _ (બનાવવું)?\nFile2: શું?! ખારી?! તમને તો બધું ખારું જ _ (લાગવું) ! કાલથી તમે રસોઈ કરજો.\nFile3: મમ્મી-પપ્પા, જય સચ્ચિદાનંદ!\nFile1&2: જય સચ્ચિદાનંદ બેટા! દાદા માફ કરજો, અમે પ્રતિક્રમણ _ (કરવું).'
    },
    options:
      'બનાવીએ છીએ, બનાવે છે, બનાવું છું, લાગુ છું, લાગવી છીએ, લાગે છે, કરું છું, કરે છે, કરીએ છીએ',
    answers: {
      gu: 'બનાવે છે, લાગે છે, કરીએ છીએ'
    }
  },
  {
    level: 2,
    question: {
      gu: "સીમંધર સ્વામી મહાવિદેહ ક્ષેત્રમાં _ (વિચરવું). અમે સ્વામીને રોજ નમસ્કાર _ (કરવું). સ્વામીનાં દર્શન હું રોજ ત્રિમંદિરમાં _ (કરવું). મારાં સાથેનાં મહાત્માઓને મેં પૂછ્યું, 'તમે રોજ ત્રિમંદિર દર્શન માટે _ (આવવું)?' તેઓએ કહ્યું કે તેઓ રોજ _ (આવવું)."
    },
    options:
      'વિચરીએ છીએ, વિચરે છે, વિચરો છો, કરું છું, કરીએ છીએ, કરે છે, કરો છો, આવો છો, આવીએ છીએ, આવે છે, આવું છું',
    answers: {
      gu: 'વિચરે છે, કરીએ છીએ, કરું છું, આવો છો, આવે છે'
    }
  },
  {
    level: 2,
    question: {
      gu: 'મારે દાદાવાણી _. દાદાના વિજ્ઞાનને _. અને દાદાના જ્ઞાનને હૃદયમાં _. મારે પાંચ આજ્ઞા નિરંતર _. એવું હું પ્રોમિસ _.'
    },
    options:
      'વાંચવું છે, વાંચે છે, વાંચવી છે, સમજવું છે, સમજવો છે, સમજવા છે, ઉતરે છે, ઉતરવું છું, ઉતારવું છે, પાળવું છે, પાળું છું, પાળવી છે, આપું છું, આપો છો, આપી છે',
    answers: {
      gu: 'વાંચવી છે, સમજવું છે, ઉતારવું છે, પાળવી છે, આપું છું'
    }
  },
  {
    level: 2,
    question: {
      gu: 'જ્ઞાનીઓની વાણી સ્યાદ્વાદ વાણી હોય _.બધાં જીવો આ વાણી _.તત્વની સાચી સમજ _. જેઓ આ વાણીને હ્રદયમાં _, તેમનું ચોક્કસ કલ્યાણ _.'
    },
    options:
      'છો, છે, છીએ, સાંભળે છે, સાંભળો છો, સાંભળીએ છીએ, મેળવો છે, મેળવે છે, મેળવીએ છીએ, ધરે છે, ધરો છો, ધરીએ છીએ,  કરો છો, કરીએ છીએ, કરે છે',
    answers: {
      gu: 'છે, સાંભળે છે, મેળવે છે, ધરે છે, કરે છે'
    }
  },
  {
    level: 2,
    question: {
      gu: 'પ્રશ્નકર્તા : અન્યાય થતાં લોકો ન્યાય કેમ _ ? દાદાશ્રી : જે લોકો જ્ઞાનની સાચી સમજણને _, તેઓ  _ કે જે કાંઈ પણ _ તે જ ખરું ન્યાયસ્વરૂપ _.'
    },
    options:
      'ખોળો છો, ખોળે છે, ખોળીએ છીએ, પકડે છે, પકડો છો, પકડીએ છીએ, માનીએ છીએ, માનો છો, માને છે, બનો છો, બને છે, બનું છું, છીએ, છો, છે',
    answers: {
      gu: 'ખોળે છે, પકડે છે, માને છે, બને છે, છે'
    }
  }
]
const pageDataNew = Utils.fillInBlanksDataMassager(
  pageData,
  false,
  true,
  true,
  false,
  false,
  false
)

export default pageDataNew
